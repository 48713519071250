


















import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop } from 'vue-property-decorator';
import TeacherEditorForm from '@/components/teachers/TeacherEditorForm.vue';
import TeacherActions from '@/components/teachers/TeacherActions.vue';
import { FormError } from '@/errors';
import { Editor } from '@/types/global.types';

@Component({
  components: {
    TeacherEditorForm,
    TeacherActions
  }
})
export default class ClassworkEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  $refs!: {
    form: TeacherEditorForm
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  showActions(event: any) {
    CommonUtils.hideLoading();
    this.$eventBus.$emit('actions', {
      type: 'teacher',
      event,
      input: {
        teacher: this.input.teacher,
        mode: this.input.action
      }
    });
  }

  isDirty() {
    return this.$refs.form.isDirty()
  }

  typeChange() {
    this.input.loadData = false
  }

  doApply() {
    CommonUtils.showLoading();
    return this.$refs.form.onSave().then(() => {
      this.localValue = false;
      if (!this.isModal) {
        this.$eventBus.$emit('refreshTeachers');
      }
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      }
      return Promise.resolve(true);
    }).finally(() => {
      CommonUtils.hideLoading();
      return Promise.resolve(false);
    });
  }

  created() {
    this.$eventBus.$on('deleteTeacher', (inviteIds: any) => {
      if (!this.input.data) {
        return;
      }
      for (const i in inviteIds) {
        if (inviteIds[i] === this.input.data.inviteId) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteTeacher');
  }
}
