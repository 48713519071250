
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditorSection from '@/components/common/EditorSection.vue';
import StandardsPicker from '../pickers/StandardsPicker.vue';
import EditorSectionList from '@/components/common/EditorSectionList.vue';
import MyFilesPicker from '../pickers/MyFilesPicker.vue';
import DateTimeUtils from '@/utils/date-time-utils';
import { UserSession } from '@/common/user-session';
import ld from 'lodash';
import { VForm } from 'vuetify/lib';
import Confirm from './../../components/core/Confirm.vue';
import draggable from 'vuedraggable';
import { FormError } from '@/errors';
import FileServices from '@/services/file-services';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';

const classes = namespace('classes');
const classwork = namespace('classwork');
const settings = namespace('settings');

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: {
    StandardsPicker,
    EditorSection,
    EditorSectionList,
    MyFilesPicker,
    draggable,
    TextEditorFullScreenButton
  }
})
export default class ClassworkEditorForm extends Vue {
@Prop({
  type: Object,
  default: () => {
    return { loadData: true, data: {} };
  }
})
input!: any

@Prop({ type: Boolean, default: true })
isModal!: boolean;

localValue = false;
readOnlyWeight: any = null;
readOnlyClass: any = {};
readOnlyUnit: any = null;
showUnitFilter = false;
localRefreshKey = CommonUtils.generateUUID();
isAddingClasswork = false;
dialogs: any = {
  myFiles: false,
  myStandards: false
}

tabs = [
  {
    label: this.$t('descriptionLabel'),
    active: true,
    section: 'description'
  },
  {
    label: this.$t('multipleChoice'),
    active: false,
    section: 'multipleChoice'
  },
  {
    label: this.$t('standardsLabel'),
    active: false,
    section: 'standards'
  },
  {
    label: this.$t('attachmentsLabel'),
    active: false,
    section: 'attachments'
  }
];

$refs!: {
  classworkEditorForm: InstanceType<typeof VForm>,
  confirm: Confirm,
  observer: any,
  filesPicker: MyFilesPicker
}

unitList:any = [];
defaultClass:any = {
  value: 0
};

defaultUnit = {
  title: '',
  id: 0
}

dateError = '';
dateRules = [() => '']

selectedClass = this.defaultClass;
typeWeights:any = [];
selectedType: any = '';
localReadOnlyMode = false;
isSectionsHovered = false;
isTextFieldsVisible = true;

  @classwork.Action
  addClasswork!: () => Promise<any>;

  @classwork.Action
  updateClasswork!: () => Promise<any>;

  @classes.Getter
  getClassUnits!: any;

  @classes.Getter('getClassItems')
  classList!: Array<any>;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getLessonSectionLabel')
  getLessonSectionLabel!: (section: string) => string;

  @settings.Getter
  getSettings!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get availableTypes() {
    if (this.localReadOnlyMode) {
      if (this.readOnlyWeight) {
        return [this.readOnlyWeight];
      }
      return [];
    }

    const typeKey = this.classworkType ? 'm' : 'g'
    const classKey = this.classworkSelectedClass.value;
    let classTypes:any;
    if (this.getSettings.accountSettings.scoreWeights) {
      classTypes = this.getSettings.accountSettings.scoreWeights.filter((a: any) => a.type === typeKey && a.subjectId === classKey)
      if (CommonUtils.isEmpty(classTypes)) {
      // default types
        return this.getSettings.accountSettings.scoreWeights.filter((a: any) => a.type === typeKey && a.subjectId === 0);
      } else {
        return classTypes;
      }
    } else {
      return [];
    }
  }

  @Watch('value')
  updateLocalValue(v: boolean) {
    this.localValue = v;
  }

  @Watch('localValue')
  updateValue(v: boolean) {
    this.$emit('update:value', v);
  }

  get localSelectedTab() {
    return this.$store.state.classwork.selectedTab;
  }

  set localSelectedTab(tab: number) {
    // Index change needed as dynamically filtered tabs are automatically added at the end in vuetify
    let realIdx = tab;

    if (this.isAddingClasswork && this.classworkType === 1) {
      if (tab === 1) {
        realIdx = this.filteredTabs.length - 2;
      } else if (tab === 2) {
        realIdx = this.filteredTabs.length - 1;
      } else if (tab > 2) {
        realIdx = tab - 2;
      }
    }

    this.tabs = this.tabs.map(t => Object.assign(t, { active: this.filteredTabs[realIdx].section === t.section }));
    this.$store.commit('classwork/setSelectedTab', tab);
  }

  get classworkId() {
    return this.$store.state.classwork.classworkId;
  }

  set classworkId(id: number) {
    this.$store.commit('classwork/setClassworkId', id);
  }

  get classworkType() {
    return this.$store.state.classwork.classworkType;
  }

  set classworkType(val: number) {
    this.$store.commit('classwork/setClassworkType', val);
  }

  get classworkTitle() {
    return this.$store.state.classwork.classworkTitle;
  }

  set classworkTitle(val: string) {
    this.$store.commit('classwork/setClassworkTitle', val);
  }

  get classworkStartDate() {
    return this.$store.state.classwork.classworkStartDate;
  }

  set classworkStartDate(val: string) {
    this.$store.commit('classwork/setClassworkStartDate', val);
  }

  get classworkEndDate() {
    return this.$store.state.classwork.classworkEndDate;
  }

  set classworkEndDate(val: string) {
    this.$store.commit('classwork/setClassworkEndDate', val);
  }

  get classworkPoints() {
    return this.$store.state.classwork.classworkPoints;
  }

  set classworkPoints(val: string) {
    this.$store.commit('classwork/setClassworkPoints', val);
  }

  get classworkSelectedClass() {
    this.selectedClass = this.classList.find((c: any) => c.value === this.$store.state.classwork.classworkClassId);
    if (!this.selectedClass) {
      this.selectedClass = this.defaultClass;
    }
    return this.selectedClass;
  }

  set classworkSelectedClass(val: any) {
    this.selectedClass = val;
    this.$store.commit('classwork/setClassworkClassId', val.value);
    this.loadUnitOptions();
    this.validateDate();
  }

  get unitSelection() {
    if (this.localReadOnlyMode) {
      return this.readOnlyUnit;
    } else {
      return this.classworkSelectedUnit;
    }
  }

  set unitSelection(val: any) {
    if (!this.localReadOnlyMode) {
      this.classworkSelectedUnit = val;
    }
  }

  get unitOptions() {
    if (this.localReadOnlyMode) {
      return [this.readOnlyUnit]
    } else {
      return this.unitList
    }
  }

  get classworkSelectedUnit() {
    const selectedUnit = this.unitList.find((u: any) => u.id === this.$store.state.classwork.classworkUnitId)
    return selectedUnit || this.defaultUnit;
  }

  set classworkSelectedUnit(val: any) {
    this.$store.commit('classwork/setClassworkUnitId', val.id);
  }

  get scoreWeight() {
    if (this.localReadOnlyMode) {
      return this.readOnlyWeight;
    } else {
      return this.classworkScoreWeight
    }
  }

  set scoreWeight(val: any) {
    if (this.localReadOnlyMode) {
      this.readOnlyWeight = val;
    } else {
      this.classworkScoreWeight = val;
    }
  }

  get classworkScoreWeight() {
    return this.typeWeights.find((w: any) => w.scoreWeightId === this.$store.state.classwork.classworkScoreWeightId);
  }

  set classworkScoreWeight(val: any) {
    this.$store.commit('classwork/setClassworkScoreWeightId', val.scoreWeightId);
  }

  get classworkDescription() {
    return this.$store.state.classwork.classworkDescription;
  }

  set classworkDescription(val: string) {
    this.$store.commit('classwork/setClassworkDescription', val);
  }

  get classworkQuestions() {
    return this.$store.state.classwork.questions;
  }

  set classworkQuestions(val: any) {
    this.$store.commit('classwork/setClassworkQuestions', val);
  }

  get classworkTransferId() {
    return this.$store.state.classwork.classworkTransferId;
  }

  set classworkTransferId(id: number) {
    this.$store.commit('classwork/setClassworkTransferId', id);
  }

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get localEditorSectionConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  loadUnitOptions() {
    this.unitList = [];
    if (!this.selectedClass || this.selectedClass.value === 0) { // All Classes
      this.showUnitFilter = false;
    } else { // filter on selected class
      this.showUnitFilter = true;
      // find units for this.selectedClass.classID
      const allUnits = this.getClassUnits(this.selectedClass.value);
      for (const i in allUnits) {
        this.unitList.push({ title: allUnits[i].num + ' - ' + allUnits[i].title, id: allUnits[i].id });
      }
    }
  }

  // ---- MULTIPLE CHOICE FUNCTIONS ----
  multipleChoiceHolder: Array<any> = [];

  get filteredTabs() {
    return this.tabs.filter(a => {
      if (a.section === 'multipleChoice' && this.classworkType === 0) {
        return false;
      }
      return true;
    })
  }

  addMultipleChoice(param?: any) {
    if (param && typeof param === 'string') {
      this.multipleChoiceHolder.push({
        id: 'mc' + CommonUtils.generateUUID(),
        value: param
      })
    } else {
      this.multipleChoiceHolder.push({
        id: 'mc' + CommonUtils.generateUUID(),
        value: ''
      })
    }
  }

  deleteMultipleChoice(i: number) {
    this.multipleChoiceHolder.splice(i, 1);
  }

  async clearMultipleChoice() {
    return this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: this.$t('multipleChoice') }),
      text: this.$t('clearAllMsg', { text: this.$t('multipleChoice') }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.multipleChoiceHolder = [];
      }
    });
  }

  onMultipleChoiceHolderChange() {
    this.classworkQuestions = this.multipleChoiceHolder.filter(r => CommonUtils.hasText(r.value)).map(a => a.value);
  }

  moveSectionUp(index: any) {
    const clone = ld.cloneDeep(this.multipleChoiceHolder);
    clone[index - 1] = this.multipleChoiceHolder[index];
    clone[index] = this.multipleChoiceHolder[index - 1];
    this.multipleChoiceHolder = clone;
    (this.$refs as any)['moveButton' + (index - 1)][0].$el.focus();
  }

  moveSectionDown(index: any) {
    const clone = ld.cloneDeep(this.multipleChoiceHolder);
    clone[index + 1] = this.multipleChoiceHolder[index];
    clone[index] = this.multipleChoiceHolder[index + 1];
    this.multipleChoiceHolder = clone;
    (this.$refs as any)['moveButton' + (index + 1)][0].$el.focus();
  }

  // ---- ATTACHMENT FUNCTIONS -----
  get attachments(): Array<any> {
    return this.$store.state.classwork.attachments || [];
  }

  set attachments(val: Array<any>) {
    this.$store.commit('classwork/setAttachments', val);
  }

  get attachmentItems() {
    return this.attachments.map((a, i) => {
      const name = (a.name || a.filename || a.fileName || '');
      return {
        key: name + i,
        shortValue: '',
        value: name,
        data: a
      }
    })
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  deleteAttachment(item: any, index: number) {
    this.attachments = this.attachments.filter((s, i) => i !== index);
  }

  toggleAttachmentPrivateFlag(item: any, index: number) {
    const newAttachments = ld.cloneDeep(this.attachments);
    const attachment = newAttachments.find((a, i) => i === index);
    attachment.privateFlag = !attachment.privateFlag;
    ld.set(newAttachments, index, attachment);
    this.attachments = newAttachments;
  }

  addGoogleDriveAttachments() {
    this.$google.setAuthToken(UserSession.getGoogleAuthToken());
    this.$google.openPicker((data: any) => {
      this.updateAttachments([{
        filename: data.name,
        fileType: 'drive',
        url: data.url,
        id: data.id,
        privateFlag: false
      }])
    });
  }

  dropAttachment(e: any) {
    e.preventDefault();
    if (this.localReadOnlyMode) {
      return;
    }
    this.localSelectedTab = this.classworkType ? 3 : 2;
    const fileCount = e.dataTransfer.files.length;
    const droppedFiles = [];
    for (let i = 0; i < fileCount; i++) {
      droppedFiles.push(e.dataTransfer.files[i]);
    }
    this.$refs.filesPicker.appendFiles(droppedFiles);
    this.$refs.filesPicker.saveFiles();
  }

  clearAttachments() {
    const attachmentsLabel = this.$t('attachmentsLabel') as string;
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: attachmentsLabel }),
      text: this.$t('clearAllMsg', { text: attachmentsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.attachments = [];
      }
    });
  }

  openLink(attachment: any) {
    const url = attachment.url;
    const name = attachment.name || attachment.filename || attachment.fileName;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', name);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', this.userInfo.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', '0');
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  // ---- STANDARD FUNCTIONS
  get standardItems() {
    return this.standards.map((s) => {
      return {
        key: s.id,
        shortValue: s.id,
        value: s.desc,
        data: s
      }
    })
  }

  get standards(): Array<any> {
    return this.$store.state.classwork.standards || [];
  }

  set standards(val: Array<any>) {
    this.$store.commit('classwork/setStandards', val);
  }

  deleteStandard(item: any, index: number) {
    this.standards = this.standards.filter((s, i) => i !== index);
  }

  clearStandards() {
    const standardsLabel = this.getLessonSectionLabel('myStandards');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: standardsLabel }),
      text: this.$t('clearAllMsg', { text: standardsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.standards = [];
      }
    });
  }

  insertStandards(param: any) {
    this.standards = param;
    for (const i in this.standards) {
      this.standards[i].value = this.standards[i].desc;
      this.standards[i].shortValue = this.standards[i].id;
    }
  }
  // --- END STANDARD FUNCTIONS ---

  openDialog(type: string) {
    for (const key in this.dialogs) {
      this.dialogs[key] = type === key;
    }
  }

  resetValidation() {
    try {
      const form: any = this.$refs.classworkEditorForm;
      form.resetValidation();
    } catch (e) {
    }
  }

  validateDate() {
    const workS = this.classworkStartDate;
    const workE = this.classworkEndDate;
    const classS = this.selectedClass.cSd || '';
    const classE = this.selectedClass.cEd || '';
    if (DateTimeUtils.isThisDateBeforeThatDate(workS, classS)) {
      this.dateError = this.$t('assignmentErrorMsg3').toString();
    } else if (DateTimeUtils.daysDiff(classS, workS) > 365) {
      this.dateError = this.$t('assignmentErrorMsg4').toString();
    } else if (DateTimeUtils.isThisDateBeforeThatDate(classE, workE)) {
      this.dateError = this.$t('assignmentErrorMsg5').toString();
    } else if (DateTimeUtils.daysDiff(workE, classE) > 365) {
      this.dateError = this.$t('assignmentErrorMsg6').toString();
    } else {
      this.dateError = '';
    }
    this.dateRules = [() => this.dateError]
  }

  public async saveClasswork() {
    this.validateDate();
    this.multipleChoiceHolder = this.multipleChoiceHolder.filter(e => CommonUtils.hasText(e.value));
    const form: any = this.$refs.classworkEditorForm;
    const dateFieldsValid = await this.$refs.observer.validate();
    if (form.validate() && dateFieldsValid && CommonUtils.hasNoText(this.dateError)) {
      if (this.localPage !== 'classwork') {
        CommonUtils.showLoading();
      } else {
        this.$store.commit('classwork/setListLoading', true);
      }
      if (!this.isAddingClasswork) { // is editing existing classwork
        return Promise.resolve().then(() => {
          return this.updateClasswork()
        }).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg33') as string);
          }
        });
      } else {
        return this.addClasswork().then((resp:any) => {
          if (this.input.showClassworkPickerAfterSave && this.input.fromLessonAction) {
            const localData = { title: this.classworkTitle, classID: this.classworkSelectedClass.value, description: this.classworkDescription };
            this.$eventBus.$emit('classworkHasBeenAddedFromLessonAction', { resp: resp.resp.data, localData: localData });
          } else if (this.input.showClassworkPickerAfterSave && !this.input.fromLessonAction) {
            const localData = { title: this.classworkTitle, classID: this.classworkSelectedClass.value, description: this.classworkDescription };
            this.$eventBus.$emit('classworkHasBeenAdded', { resp: resp.resp.data, localData: localData });
          }

          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg32') as string);
          }
        });
      }
    } else {
      return Promise.reject(new FormError());
    }
  }

  updateAttachments(files: Array<any>) {
    const extensions = ['ico', 'png', 'jpeg', 'jpg', 'svg', 'tif', 'webp'];
    files.forEach(a => {
      if (!this.attachmentExists(a) && !extensions.includes(this.getExtension(a))) {
        this.attachments.push(a);
      }
    });
    const imageFiles = files.filter(a => extensions.includes(this.getExtension(a)));
    if (imageFiles.length > 0) {
      return this.$refs.confirm.confirm({
        title: this.$t('imageActionsLabel'),
        text: this.$t('imageActionsMsg'),
        option1ButtonAlternativeText: this.$t('attachLabel'),
        option2ButtonAlternativeText: this.$t('embedLabel')
      }).then(async result => {
        if (result === 1) {
          files.forEach(a => {
            if (!this.attachmentExists(a)) {
              this.attachments.push(a);
            }
          });
        } else if (result === 2) {
          imageFiles.forEach((img: any) => {
            let url = img.url;
            if (FileServices.isGoogleDriveFile(url)) {
              const driveId = FileServices.guessGoogleDriveId(url);
              if (CommonUtils.hasText(driveId)) {
                url = `https://drive.google.com/uc?id=${driveId}&export=download`;
              }
            }
            this.classworkDescription += `<p><img style="width: 500px; max-width: 100%; height: auto;" src="${url}" alt="Image"/></p>`;
          });
          this.localSelectedTab = 0
        }
        return Promise.resolve();
      });
    }
  }

  attachmentExists(file: any) {
    return this.attachments.some(a => this.getFileName(a) === this.getFileName(file));
  }

  getExtension(file: any) {
    const name = this.getFileName(file);
    return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
  }

  getFileName(file: any) {
    return file.fileName || file.filename || file.name || '';
  }

  @Watch('input')
  doInit() {
    this.localRefreshKey = CommonUtils.generateUUID();
    this.typeWeights = this.getSettings.accountSettings.scoreWeights;
    if (!this.input.loadData) {
      const questions = this.classworkQuestions;
      for (const q in questions) {
        this.addMultipleChoice(questions[q]);
      }
      return;
    }
    this.$store.commit('classwork/clearClassworkForm');
    if (this.input.action === 'A') {
      this.isAddingClasswork = true;

      // set local data
      if (this.input.classId && this.input.classId !== 0) {
        this.classworkSelectedClass = this.classList.find((a) => { return a.value === this.input.classId });
        this.loadUnitOptions();
        if (this.input.unit) {
          this.classworkSelectedUnit = this.unitList.find((a:any) => { return a.id === this.input.unit.id }) || this.defaultUnit;
        }
        if (this.input.startDate && this.input.endDate) {
          this.classworkStartDate = this.input.startDate;
          this.classworkEndDate = this.input.endDate;
        }
      } else {
        this.classworkSelectedClass = this.defaultClass;
      }
      if (this.input.type) {
        this.classworkType = this.input.type;
      }
    } else if (this.input.action === 'E' || this.input.action === 'V') {
      this.localReadOnlyMode = this.input.classwork.isReadOnly || this.$currentUser.isStudent || false;
      this.isAddingClasswork = false;

      // set local data
      this.classworkType = (this.input.classwork.isAssignment) ? 0 : 1;
      this.classworkTitle = this.input.classwork.classworkTitle || this.input.classwork.assignmentTitle || this.input.classwork.assessmentTitle;
      if (this.localReadOnlyMode) {
        this.readOnlyClass = { value: this.input.classwork.subjectId, text: this.input.classwork.className };
        if (this.input.classwork.unitId !== 0) {
          this.readOnlyUnit = { id: this.input.classwork.unitId, title: this.input.classwork.unitNum + ' - ' + this.input.classwork.unitTitle }
        }
      } else {
        this.classworkSelectedClass = this.classList.find((a) => { return a.value === this.input.classwork.subjectId }) || { value: 0 };
        this.loadUnitOptions();
        if (this.input.classwork.unitId === 0) {
          this.classworkSelectedUnit = this.defaultUnit;
        } else {
          this.classworkSelectedUnit = this.unitList.find((a:any) => { return a.id === this.input.classwork.unitId });
        }
      }
      this.classworkStartDate = this.input.classwork.classworkStart || this.input.classwork.assignmentStart || this.input.classwork.assessmentStart;
      this.classworkEndDate = this.input.classwork.classworkEnd || this.input.classwork.assignmentEnd || this.input.classwork.assessmentEnd;
      if (this.input.classwork.totalPoints !== 0) {
        this.classworkPoints = this.input.classwork.totalPoints;
      }
      this.classworkDescription = this.input.classwork.classworkDesc || this.input.classwork.assignmentDesc || this.input.classwork.assessmentDesc;
      this.classworkId = this.input.classwork.classworkId || this.input.classwork.assignmentId || this.input.classwork.assessmentId;
      this.insertStandards(this.input.classwork.standards);
      this.attachments = ld.cloneDeep(this.input.classwork.attachments);
      // multiple choice
      if (this.classworkType === 1) {
        for (const q in this.input.classwork.options) {
          this.addMultipleChoice(this.input.classwork.options[q])
        }
      }
      // score weight type
      if (this.input.classwork.scoreWeightId !== 0) {
        if (this.localReadOnlyMode) {
          if (this.input.classwork.scoreWeightId > 0) {
            this.readOnlyWeight = { scoreWeightId: this.input.classwork.scoreWeightId, name: this.input.classwork.scoreWeightName };
          }
        } else {
          this.classworkScoreWeight = this.typeWeights.filter((a: any) => a.scoreWeightId === this.input.classwork.scoreWeightId)[0]
        }
      }
    }
    this.$store.commit('classwork/setCleanState');
  }

  created() {
    this.doInit();
  }

  setTransferId(c: number) {
    this.classworkTransferId = c;
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }
}
