
import { UserSession } from '@/common/user-session';
import EditorSection from '@/components/common/EditorSection.vue';
import EditorSectionList from '@/components/common/EditorSectionList.vue';
import { FormError } from '@/errors';
import FileServices from '@/services/file-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VForm } from 'vuetify/lib';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import MyFilesPicker from '../pickers/MyFilesPicker.vue';
import MyListPicker from '../pickers/MyListPicker.vue';
import SchoolListPicker from '../pickers/SchoolListPicker.vue';
import StandardsPicker from '../pickers/StandardsPicker.vue';
import StrategiesPicker from '../pickers/StrategiesPicker.vue';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';

const units = namespace('units');
const classes = namespace('classes');
const settings = namespace('settings');

const attributeKeyMapping: any = {
  lesson: 'unitLessonText',
  tab2: 'unitHomeworkText',
  tab3: 'unitNotesText',
  tab4: 'unitSection4Text',
  tab5: 'unitSection5Text',
  tab6: 'unitSection6Text',
  description: 'unitDesc'
};

@Component({
  components: {
    EditorSectionList,
    EditorSection,
    StandardsPicker,
    SchoolListPicker,
    MyListPicker,
    StrategiesPicker,
    MyFilesPicker,
    TextEditorFullScreenButton
  }
})
export default class UnitEditorForm extends Vue {
  @Prop({ type: Object, default: () => { return { isReadOnly: false } } })
  input!: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @units.Action
  init!: (value?: any) => Promise<any>;

  @units.State
  sections!: Array<any>;

  @classes.Getter('getClassItems')
  classItems!: Array<any>;

  @classes.Getter('getLessonBankItems')
  lessonBankItems!: Array<any>;

  @classes.Getter('getDistrictLessonBankItems')
  districtLessonBankItems!: Array<any>;

  @units.Action('getUnit')
  getUnit!: (params?: number) => Promise<any>;

  @units.Action('save')
  doSave!: () => Promise<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @classes.Getter
  getClassStart!: (value: number) => string;

  @classes.Getter
  getClassEnd!: (value: number) => string;

  @units.Mutation
  addUpdatedField!: (value: string) => void;

  @units.Mutation
  clearUpdatedFields!: () => void;

  @units.Mutation
  initSections!: () => void;

  @units.Mutation
  setEditorInitialized!: (value: boolean) => void;

  @settings.Getter('getLessonSectionLabel')
  getLessonSectionLabel!: (section: string) => string;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter('getSharedClasses')
  sharedClass!: any[];

  $refs!: {
    confirm: Confirm,
    embedFiles: Confirm,
    unitForm: InstanceType<typeof VForm> & { validate: () => boolean, resetValidation: () => void },
    observer: any,
    filesPicker: MyFilesPicker
  }

  dialogs: any = {
    standards: false,
    myStandards: false,
    schoolStandards: false,
    strategies: false,
    myFiles: false
  };

  errorMsg = '';
  localEmbedSections: Array<string> = [];
  lessonStart = '';
  lessonEnd = '';
  yearId = 0;
  isSectionsHovered = false;
  isTextFieldsVisible = true;
  localRefreshKey = CommonUtils.generateUUID();

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get localEditorSectionConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get formatToShow() {
    return DateTimeUtils.formatToShow;
  }

  get getSharedClass():any[] {
    const sharedClasses:any = [];
    Object.entries(this.sharedClass).forEach((sharedClass: any) => {
      if (sharedClass[1].yearId === 0) {
        sharedClass[1].text = ld.truncate(sharedClass[1].className, { length: 30 });
        sharedClass[1].value = sharedClass[1].classId;
        sharedClasses.push(sharedClass[1]);
      }
    });
    return sharedClasses.sort((sc1:any, sc2:any) => {
      return sc1.className.localeCompare(sc2.className);
    });
  }

  get classBankItems(): Array<any> {
    const items: Array<any> = [];
    if (this.userMode === 'T') {
      ld.cloneDeep(this.classItems).forEach(c => {
        c.isBank = false;
        items.push(c);
      });
    }

    if (this.userMode === 'A') {
      ld.cloneDeep(this.districtLessonBankItems).forEach(c => {
        c.isBank = true;
        items.push(c);
      });
    }

    if (['A', 'T'].includes(this.userMode)) {
      ld.cloneDeep(this.lessonBankItems).forEach(c => {
        c.isBank = true;
        items.push(c);
      });
      if (this.localPage === 'lesson-banks') {
        ld.cloneDeep(this.getSharedClass).forEach(c => {
          c.isBank = true;
          items.push(c);
        });
      }
    }
    return items.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text);
    });
  }

  get localReadOnlyMode() {
    return this.input.isReadOnly || false;
  }

  get unit() {
    return this.$store.state.units.unit;
  }

  set unit(val: any) {
    this.$store.commit('units/setUnit', val);
  }

  set editorInitialized(val: boolean) {
    this.$store.commit('units/setEditorInitialized', val);
  }

  get editorInitialized(): boolean {
    return this.$store.state.units.editorInitialized;
  }

  get enabledSections() {
    return this.sections.filter(s => s.enabled);
  }

  get subjectId(): number {
    return +this.unit.subjectId || 0;
  }

  set subjectId(value: number) {
    this.unit.subjectId = value;
  }

  get unitNum(): string {
    return this.unit.unitNum || '';
  }

  set unitNum(value: string) {
    this.unit.unitNum = value;
    this.addUpdatedField('unitNum');
  }

  get unitTitle(): string {
    return this.unit.unitTitle || '';
  }

  set unitTitle(value: string) {
    this.unit.unitTitle = value;
    this.addUpdatedField('unitTitle');
  }

  get unitDesc(): string {
    return this.unit.unitDesc || '';
  }

  set unitDesc(value: string) {
    this.unit.unitDesc = value;
    this.addUpdatedField('unitDesc');
  }

  get unitColor(): string {
    return this.unit.unitColor || this.color;
  }

  set unitColor(value: string) {
    this.unit.unitColor = value;
    this.addUpdatedField('unitColor');
  }

  get color(): string {
    return this.unit.color || this.classColor;
  }

  get classColor() {
    const classItem = this.classBankItems.find(c => +c.value === +this.subjectId);
    return classItem ? classItem.color || this.$vuetify.theme.currentTheme.primary : this.$vuetify.theme.currentTheme.primary;
  }

  get unitStart(): string {
    return this.unit.unitStart || '';
  }

  set unitStart(value: string) {
    this.unit.unitStart = value;
    this.addUpdatedField('unitStart');
  }

  get unitEnd(): string {
    return this.unit.unitEnd || '';
  }

  set unitEnd(value: string) {
    this.unit.unitEnd = value;
    this.addUpdatedField('unitEnd');
  }

  get applyStandards(): string {
    return this.unit.applyStandards || '';
  }

  set applyStandards(value: string) {
    this.unit.applyStandards = value;
    this.addUpdatedField('applyStandards');
  }

  get unitLessonText(): string {
    return this.unit.unitLessonText || '';
  }

  set unitLessonText(value: string) {
    this.unit.unitLessonText = value;
    this.addUpdatedField('unitLessonText');
  }

  get unitHomeworkText(): string {
    return this.unit.unitHomeworkText || '';
  }

  set unitHomeworkText(value: string) {
    this.unit.unitHomeworkText = value;
    this.addUpdatedField('unitHomeworkText');
  }

  get unitNotesText(): string {
    return this.unit.unitNotesText || '';
  }

  set unitNotesText(value: string) {
    this.unit.unitNotesText = value;
    this.addUpdatedField('unitNotesText');
  }

  get unitSection4Text(): string {
    return this.unit.unitSection4Text || '';
  }

  set unitSection4Text(value: string) {
    this.unit.unitSection4Text = value;
    this.addUpdatedField('unitSection4Text');
  }

  get unitSection5Text(): string {
    return this.unit.unitSection5Text || '';
  }

  set unitSection5Text(value: string) {
    this.unit.unitSection5Text = value;
    this.addUpdatedField('unitSection5Text');
  }

  get unitSection6Text(): string {
    return this.unit.unitSection6Text || '';
  }

  set unitSection6Text(value: string) {
    this.unit.unitSection6Text = value;
    this.addUpdatedField('unitSection6Text');
  }

  get standards(): Array<any> {
    return this.unit.standards || [];
  }

  set standards(value: Array<any>) {
    this.unit.standards = value;
    this.addUpdatedField('standards');
  }

  get myStandards(): Array<any> {
    return this.unit.myStandards || [];
  }

  set myStandards(value: Array<any>) {
    this.unit.myStandards = value;
    this.addUpdatedField('myStandards');
  }

  get schoolStandards(): Array<any> {
    return this.unit.schoolStandards || [];
  }

  set schoolStandards(value: Array<any>) {
    this.unit.schoolStandards = value;
    this.addUpdatedField('schoolStandards');
  }

  get strategies(): Array<any> {
    return this.unit.strategies || [];
  }

  set strategies(value: Array<any>) {
    this.unit.strategies = value;
    this.addUpdatedField('strategies');
  }

  get attachments(): Array<any> {
    return this.unit.attachments || [];
  }

  set attachments(value: Array<any>) {
    this.unit.attachments = value;
    this.addUpdatedField('attachments');
  }

  get action(): string {
    return this.unit.action || '';
  }

  set action(value: string) {
    this.unit.action = value;
  }

  get unitId(): number {
    return this.unit.unitId || 0;
  }

  set unitId(value: number) {
    this.unit.unitId = value;
  }

  get localActiveTab() {
    return this.sections.findIndex(s => s.active);
  }

  set localActiveTab(tab: number) {
    const section = this.sections.find((s, i) => i === +tab);
    if (section) {
      this.$store.commit('units/setActiveTab', section.section);
    }
  }

  get hasStandards() {
    return CommonUtils.isNotEmpty(this.standards);
  }

  get standardItems() {
    return this.standards.map(s => {
      return {
        key: s.dbId,
        shortValue: s.id,
        value: s.desc
      }
    })
  }

  get classYearId() {
    const currentClass = this.classBankItems.find(c => c.value === this.subjectId);
    return currentClass ? currentClass.yearId : 0;
  }

  get enabledLessonTextSections() {
    return this.enabledSections.filter((s: any) => ['description', 'lesson', 'tab2', 'tab3', 'tab4', 'tab5', 'tab6'].includes(s.section));
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  openDialog(type: string) {
    for (const key in this.dialogs) {
      this.dialogs[key] = type === key;
    }
  }

  openLink(attachment: any) {
    const url = attachment.url;
    const name = attachment.name || attachment.filename || attachment.fileName;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', name);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', this.input.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', this.input.collaborateSubjectId);
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  deleteStandard(item: any) {
    this.standards = this.standards.filter(s => s.dbId !== item.key);
  }

  clearStandards() {
    const standardsLabel = this.getLessonSectionLabel('sharedStandards');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: standardsLabel }),
      text: this.$t('clearAllMsg', { text: standardsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.standards = [];
      }
    });
  }

  get hasMyStandards() {
    return CommonUtils.isNotEmpty(this.myStandards);
  }

  get myStandardItems() {
    return this.myStandards.map(s => {
      return {
        key: s.dbId,
        shortValue: s.id,
        value: s.desc
      }
    })
  }

  deleteMyStandard(item: any) {
    this.myStandards = this.myStandards.filter(s => s.dbId !== item.key);
  }

  clearMyStandards() {
    const myClassesLabel = this.getLessonSectionLabel('myStandards');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: myClassesLabel }),
      text: this.$t('clearAllMsg', { text: myClassesLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.myStandards = [];
      }
    });
  }

  get hasSchoolStandards() {
    return CommonUtils.isNotEmpty(this.schoolStandards);
  }

  get schoolStandardItems() {
    return this.schoolStandards.map(s => {
      return {
        key: s.dbId,
        shortValue: s.id,
        value: s.desc
      }
    })
  }

  deleteSchoolStandard(item: any) {
    this.schoolStandards = this.schoolStandards.filter(s => s.dbId !== item.key);
  }

  clearSchoolStandards() {
    const schoolListLabel = this.getLessonSectionLabel('schoolStandards');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: schoolListLabel }),
      text: this.$t('clearAllMsg', { text: schoolListLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.schoolStandards = [];
      }
    });
  }

  get hasStrategies() {
    return CommonUtils.isNotEmpty(this.strategies);
  }

  get strategyItems() {
    return this.strategies.map(s => {
      return {
        key: s.strategyId,
        shortValue: s.code,
        value: s.title
      }
    })
  }

  deleteStrategy(item: any) {
    this.strategies = this.strategies.filter(s => s.dbId !== item.key);
  }

  clearStrategies() {
    const strategiesLabel = this.getLessonSectionLabel('strategies');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: strategiesLabel }),
      text: this.$t('clearAllMsg', { text: strategiesLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.strategies = [];
      }
    });
  }

  get hasAttachments() {
    return CommonUtils.isNotEmpty(this.attachments);
  }

  get attachmentItems() {
    return this.attachments.map((a, i) => {
      const name = (a.name || a.filename || a.fileName || '');
      return {
        key: name + i,
        shortValue: '',
        value: name,
        data: a
      }
    })
  }

  deleteAttachment(item: any, index: number) {
    this.attachments = this.attachments.filter((s, i) => i !== index);
  }

  toggleAttachmentPrivateFlag(item: any, index: number) {
    const newAttachments = ld.cloneDeep(this.attachments);
    const attachment = newAttachments.find((a, i) => i === index);
    attachment.privateFlag = !attachment.privateFlag;
    ld.set(newAttachments, index, attachment);
    this.attachments = newAttachments;
  }

  addGoogleDriveAttachments() {
    this.$google.setAuthToken(UserSession.getGoogleAuthToken());
    this.$google.openPicker((data: any) => {
      this.updateAttachments([{
        filename: data.name,
        fileType: 'drive',
        url: data.url,
        id: data.id,
        privateFlag: false
      }])
    });
  }

  dropAttachment(e: any) {
    e.preventDefault();
    if (this.localReadOnlyMode) {
      return;
    }
    this.localActiveTab = this.enabledSections.findIndex((t) => t.section === 'attachments');
    const fileCount = e.dataTransfer.files.length;
    const droppedFiles = [];
    for (let i = 0; i < fileCount; i++) {
      droppedFiles.push(e.dataTransfer.files[i]);
    }
    this.$refs.filesPicker.appendFiles(droppedFiles);
    this.$refs.filesPicker.saveFiles();
  }

  clearAttachments() {
    const attachmentsLabel = this.$t('attachmentsLabel') as string;
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: attachmentsLabel }),
      text: this.$t('clearAllMsg', { text: attachmentsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.attachments = [];
      }
    });
  }

  localRules = {
    class: [(v: any) => !!v || this.$t('unitErrorMsg1')],
    unitNum: [(v: any) => !!v || this.$t('unitErrorMsg8')],
    unitTitle: [(v: any) => !!v || this.$t('unitErrorMsg7')],
    dateRules: [() => '']
  }

  validateDate() {
    if (this.classYearId === 0) {
      this.unitStart = '';
      this.unitEnd = '';
      return;
    }
    let classStart = '';
    let classEnd = '';
    this.errorMsg = '';
    if (this.userMode === 'T' && this.subjectId > 0) {
      classStart = this.getClassStart(this.subjectId);
      classEnd = this.getClassEnd(this.subjectId);
    }

    if (CommonUtils.hasText(this.unitStart) && CommonUtils.hasText(this.unitEnd) && DateTimeUtils.daysDiff(this.unitStart, this.unitEnd) < 0) {
      this.errorMsg = this.$t('unitErrorMsg2').toString();
    } else if (CommonUtils.hasText(this.unitStart) && CommonUtils.hasText(this.unitEnd) && DateTimeUtils.daysDiff(this.unitStart, this.unitEnd) > 365) {
      this.errorMsg = this.$t('rangeCannotExceedOneYear').toString();
    } else if (CommonUtils.hasText(this.unitStart) && DateTimeUtils.daysDiff(classStart, this.unitStart) < 0) {
      this.errorMsg = this.$t('unitErrorMsg3').toString();
    } else if (CommonUtils.hasText(this.unitStart) && DateTimeUtils.daysDiff(classStart, this.unitStart) > 365) {
      this.errorMsg = this.$t('unitErrorMsg4').toString();
    } else if (CommonUtils.hasText(this.unitStart) && DateTimeUtils.daysDiff(this.unitEnd, classEnd) < 0) {
      this.errorMsg = this.$t('unitErrorMsg5').toString();
    } else if (CommonUtils.hasText(this.unitStart) && DateTimeUtils.daysDiff(this.unitEnd, classEnd) > 365) {
      this.errorMsg = this.$t('unitErrorMsg6').toString();
    }

    this.localRules.dateRules = [() => this.errorMsg]
  }

  public async save() {
    const that = this;
    return Promise.resolve().then(async () => {
      this.validateDate();
      const form: any = this.$refs.unitForm;
      const dateFieldsValid = await this.$refs.observer.validate();
      if (form.validate() && dateFieldsValid && this.errorMsg === '') {
        const isNew = !that.unitId || that.unitId === 0;
        return this.doSave().then(() => {
          if (this.showSnackbarNotifications) {
            if (isNew) {
              this.$snotify.success(this.$t('statusMsg38') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg39') as string);
            }
          }
          return Promise.resolve();
        });
      } else {
        return Promise.reject(new FormError());
      }
    });
  }

  doInit() {
    this.errorMsg = '';
    this.editorInitialized = false;
    if (this.input.unitId) {
      this.getUnit(this.input.unitId).then((resp) => {
        this.yearId = resp.data.yearId || 0;
        this.lessonStart = resp.data.lessonStart || '';
        this.lessonEnd = resp.data.lessonEnd || '';
      });
    }
    this.init(this.input).then(() => {
      this.editorInitialized = true;
      this.$nextTick(() => {
        try {
          this.$refs.unitForm.resetValidation();
        } catch (e) {
        }
      });
    });
  }

  updateAttachments(files: Array<any>) {
    const extensions = ['ico', 'png', 'jpeg', 'jpg', 'svg', 'tif', 'webp'];
    files.forEach(a => {
      if (!this.attachmentExists(a) && !extensions.includes(this.getExtension(a))) {
        this.attachments.push(a);
      }
    });
    const imageFiles = files.filter(a => extensions.includes(this.getExtension(a)));
    if (imageFiles.length > 0) {
      return this.$refs.confirm.confirm({
        title: this.$t('imageActionsLabel'),
        text: this.$t('imageActionsMsg'),
        option1ButtonAlternativeText: this.$t('attachLabel'),
        option2ButtonAlternativeText: this.$t('embedLabel')
      }).then(async result => {
        if (result === 1) {
          files.forEach(a => {
            if (!this.attachmentExists(a)) {
              this.attachments.push(a);
            }
          });
        } else if (result === 2) {
          return this.$refs.embedFiles.confirm({
            title: this.$t('embedImagesLabel'),
            text: this.$t('imageActionsMsg2'),
            option1ButtonAlternativeText: this.$t('continueLabel')
          }).then(embedResult => {
            if (embedResult === 1) {
              if (CommonUtils.isNotEmpty(this.localEmbedSections)) {
                this.localEmbedSections.forEach(s => {
                  imageFiles.forEach((img: any) => {
                    let url = img.url;
                    if (FileServices.isGoogleDriveFile(url)) {
                      const driveId = FileServices.guessGoogleDriveId(url);
                      if (CommonUtils.hasText(driveId)) {
                        url = `https://drive.google.com/uc?id=${driveId}&export=download`;
                      }
                    }
                    const attributeKey = `${attributeKeyMapping[s]}`;
                    let html = ld.get(this, attributeKey);
                    html += `<p><img style="width: 500px; max-width: 100%; height: auto;" src="${url}" alt="Image"/></p>`;
                    ld.set(this, attributeKey, html);
                  });
                });
                this.localActiveTab = this.enabledSections.findIndex(s => this.localEmbedSections.includes(s.section));
              }
            }
            return Promise.resolve();
          });
        }
        return Promise.resolve();
      }).finally(() => {
        this.localEmbedSections = [];
      });
    }
  }

  attachmentExists(file: any) {
    return this.attachments.some(a => this.getFileName(a) === this.getFileName(file));
  }

  getExtension(file: any) {
    const name = this.getFileName(file);
    return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
  }

  getFileName(file: any) {
    return file.fileName || file.filename || file.name || '';
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  @Watch('input')
  onInputChange() {
    this.doInit();
  }

  @Watch('subjectId')
  onSubjectIdChange() {
    this.initSections();
    this.$nextTick(() => {
      this.localRefreshKey = CommonUtils.generateUUID();
    });
  }

  created() {
    this.doInit();
  }
}
