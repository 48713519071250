

























































































import EditorSectionList from '@/components/common/EditorSectionList.vue';
import { grades } from '@/constants';
import CommonUtils from '@/utils/common-utils';
import WebUtils from '@/utils/web-utils';
import ld from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MultiListPicker from './MultiListPicker.vue';

const settings = namespace('settings');
const standards = namespace('standards');
const classes = namespace('classes');

@Component({
  components: {
    MultiListPicker,
    EditorSectionList
  }
})
export default class StandardsPicker extends Vue {
    @Prop({ type: Boolean })
    value!: boolean;

    @Prop({ type: Number, required: true, default: 0 })
    classId!: number;

    @Prop({ type: Array, required: true, default: () => [] })
    standards!: Array<any>;

    category = 'VA';
    subject = '';
    framework = '';
    currentGrade = '';
    grade = '';
    selected: any = [];
    filterStandards = '';
    listViewLoaded = false;
    ignoreCheck = false;
    isShowSelected = false;
    localStandardItems: Array<any> = [];
    infiniteScroll = true;

    dialogs: any = {
      frameworks: false,
      subjects: false
    };

    @standards.State('standards')
    standardsData!: any;

    @classes.State('currentStandards')
    classCurrentStandards!: any;

    @settings.Getter('getActiveFrameworks')
    activeFrameworks!: any;

    @settings.Getter('getAllFrameworks')
    allFrameworks!: any;

    @settings.Getter('getUserType')
    userType!: string;

    @settings.Getter('getStandardSettings')
    standardSettings!: any;

    @settings.Getter('getLessonSectionLabel')
    getLessonSectionLabel!: any;

    @standards.Action
    loadStandards!: (params?: any) => Promise<any>;

    @standards.Action
    updateSelectedFrameworks!: (params?: any) => Promise<any>;

    @standards.Action
    updateSelectedSubjects!: (params?: any) => Promise<any>;

    @standards.Mutation
    setHaveStandards!: any;

    @standards.State
    haveStandards!: boolean;

    @standards.Mutation
    setStandards!: any;

    @settings.Mutation('setStandardSettings')
    setUserStandardSettings!: any;

    @classes.Mutation('setStandardSubjectSettings')
    setClassStandardSettings!: any;

    get localValue() {
      return this.value;
    }

    set localValue(val: boolean) {
      this.$emit('input', val);
    }

    get localActiveSubjects() {
      return ld.cloneDeep((this.standardsData || { showSubjectIds: [] }).showSubjectIds);
    }

    get localActiveFrameworks() {
      return ld.cloneDeep(this.activeFrameworks) || [];
    }

    get allSubjects() {
      return (this.standardsData || {}).subjects || [];
    }

    get localAllFrameworkItems() {
      return this.allFrameworks.map((f: any) => {
        return {
          key: f.id,
          value: f.name,
          shortValue: ''
        }
      });
    }

    get localAllSubjectItems() {
      return this.allSubjects.map((f: any) => {
        return {
          key: f.subjectId,
          value: f.subjectDesc,
          shortValue: ''
        }
      });
    }

    get localFrameworks() {
      const data = [];
      if (this.activeFrameworks && this.allFrameworks) {
        for (const i in this.allFrameworks) {
          const framework = this.allFrameworks[i];
          if (this.activeFrameworks.includes(framework.id)) {
            data.push({
              value: framework.id,
              text: framework.name
            });
          }
        }
      }
      return data;
    }

    get filteredLocalStandardItems() {
      const items = this.localStandardItems;
      if (CommonUtils.hasText(this.filterStandards)) {
        return items.filter(item => {
          const text = (item.value + '' + item.shortValue).toLowerCase();
          const filter = (this.filterStandards + '').toLowerCase();
          return text.includes(filter);
        });
      } else {
        return items;
      }
    }

    get filteredSelectedLocalStandardItems() {
      const that = this;

      return this.filteredLocalStandardItems.filter((item) => {
        return that.isSelected(item.data);
      });
    }

    @Watch('standardsData')
    @Watch('category')
    onStandardsDataChange() {
      const data = [];
      if (this.standardsData && this.standardsData.standards) {
        for (const i in this.standardsData.standards) {
          const standard = this.standardsData.standards[i];
          if (this.subject === standard.subject && (this.category === 'VA' || this.category === standard.category)) {
            data.push({
              key: standard.dbId,
              shortValue: standard.sI,
              value: standard.sD,
              data: standard,
              noExpressions: !standard?.withMathExpressions
            })
          }
        }
      }
      this.localStandardItems = data;
    }

    get localGrades() {
      return grades.filter(g => !['-999', '80', '99'].includes(g.value) && ![-999, 80, 99].includes(+g.value)).map(g => {
        return {
          value: +g.value,
          text: g.text
        }
      })
    }

    get localCategories() {
      const data = [{
        value: 'VA',
        text: this.$t('allCategoriesLabel')
      }];
      if (this.standardsData) {
        for (const i in this.standardsData.categories) {
          const category = this.standardsData.categories[i];
          if (category.subject === this.subject) {
            data.push({
              value: category.categoryId,
              text: category.categoryDesc
            });
          }
        }
      }
      return data;
    }

    get localSubjects() {
      const data = []
      if (this.standardsData && this.standardsData.showSubjectIds) {
        const activeSubjects = this.standardsData.showSubjectIds;
        for (const i in this.standardsData.subjects) {
          const s = this.standardsData.subjects[i];
          const txt = document.createElement('textarea');
          txt.innerHTML = s.subjectDesc;
          if (activeSubjects.includes(s.subjectId)) {
            data.push({
              value: s.subjectId,
              text: txt.value
            });
          }
        }
      }
      return data;
    }

    get selectedCount() {
      return this.selected.length;
    }

    doUpdateSelectedFrameworks(val: Array<any>) {
      const origSelected = ld.cloneDeep(this.activeFrameworks);
      const that = this;
      this.updateSelectedFrameworks({
        stateIds: val.join()
      }).then(() => {
        let diff = [];
        if (val.length < origSelected.length) {
          diff = ld.difference(origSelected, val);
          that.framework = val[0] || 'CC';
        } else {
          diff = ld.difference(val, origSelected);
          if (diff.length > 0) that.framework = diff[0];
        }
      })
    }

    doUpdateSelectedSubjects(val: Array<any>) {
      const origSelected = ld.cloneDeep(this.standardsData.showSubjectIds);
      const that = this;
      this.updateSelectedSubjects({
        stateId: this.standardsData.currentState,
        subjectIds: val.join()
      }).then(() => {
        let diff = [];
        if (val.length < origSelected.length) {
          diff = ld.difference(origSelected, val);
          that.subject = val[0] || 'L';
        } else {
          diff = ld.difference(val, origSelected);
          if (diff.length > 0) that.subject = diff[0];
        }
      })
    }

    async refresh() {
      this.setHaveStandards(false);
      this.setStandards({});
      CommonUtils.showLoading();
      return this.initialize().finally(CommonUtils.hideLoading);
    }

    async initialize() {
      const that = this;
      this.listViewLoaded = false;
      this.isShowSelected = false;
      return this.loadStandards(this.getParams()).then(() => {
        const standardsData = that.$store.state.standards.standards;
        if (standardsData !== null) {
          that.setStandardSettings();
          that.framework = standardsData.currentState;
          that.subject = standardsData.currentSubject;
          that.currentGrade = standardsData.currentGrade;
          that.listViewLoaded = true;

          if (!that.ignoreCheck) {
            if (!standardsData.subjects || standardsData.subjects.length === 0) {
              if (this.localValue) that.openDialog('frameworks');
            } else if (!standardsData.showSubjectIds || standardsData.showSubjectIds.length === 0) {
              if (this.localValue) that.openDialog('subjects')
            }
          }
        }
        that.ignoreCheck = false;
      });
    }

    setStandardSettings() {
      const standardsData = this.$store.state.standards.standards;
      this.setUserStandardSettings({
        state: standardsData.currentState,
        subject: standardsData.currentSubject,
        grade: standardsData.currentGrade
      });
      if (this.classId > 0) {
        this.setClassStandardSettings({
          state: standardsData.currentState,
          subject: standardsData.currentSubject,
          grade: standardsData.currentGrade,
          classId: this.classId
        });
      }
    }

    openDialog(type: string) {
      for (const key in this.dialogs) {
        this.dialogs[key] = type === key;
      }
    }

    getParams() {
      return {
        currentGrade: this.grade,
        currentState: this.framework,
        currentSubject: this.subject,
        currentClassId: this.classId || 0,
        action: 'getStandards'
      }
    }

    onItemTapped(item: any) {
      this.onChecked(item.data);
    }

    onChecked(item: any) {
      if (!this.isSelected(item)) {
        this.selected = this.selected.concat([{
          dbId: item.dbId,
          id: item.sI,
          desc: item.sD
        }]);
      } else {
        this.selected = this.selected.filter((s: any) => s.dbId !== item.dbId);
      }
    }

    isSelected(item: any) {
      return this.selected.findIndex((s: any) => s.dbId === item.dbId) > -1;
    }

    unselectAll() {
      this.selected = this.selected.filter((s: any) => !this.filteredLocalStandardItemsDBIds.includes(s.dbId));
    }

    get filteredLocalStandardItemsDBIds() {
      return this.filteredLocalStandardItems.map(fsi => fsi.key);
    }

    selectAll() {
      this.filteredLocalStandardItems.forEach((filteredItem:any) => {
        const item = filteredItem.data;
        if (!this.isSelected(item)) {
          this.selected = this.selected.concat([{
            dbId: item.dbId,
            id: item.sI,
            desc: item.sD
          }]);
        }
      })
    }

    showAll() {
      this.isShowSelected = false;
    }

    showSelected() {
      this.isShowSelected = true;
    }

    doApply() {
      this.$emit('update:standards', this.selected);
      this.localValue = false;
    }

    fetchStandards() {
      if (this.classId > 0) {
        const classCurrentStandard = this.classCurrentStandards[this.classId];
        if (classCurrentStandard) {
          this.subject = classCurrentStandard.subject;
          this.grade = classCurrentStandard.grade;
          this.framework = classCurrentStandard.state;
        }
      }

      if (!this.subject || !this.grade || !this.framework) {
        this.grade = this.standardSettings.currentGrade;
        this.framework = this.standardSettings.currentState;
        this.subject = this.standardSettings.currentSubject;
      }

      if (this.activeFrameworks.length > 0) {
        if (!this.activeFrameworks.includes(this.framework)) {
          this.framework = this.activeFrameworks[0];
        }
      } else {
        this.framework = '';
      }

      if (!this.framework) {
        this.framework = 'CC';
      }

      if (!this.subject) {
        this.subject = 'L';
      }

      this.category = 'VA';
      if (this.haveStandards) {
        this.onStandardsDataChange();
        this.listViewLoaded = true;
        return Promise.resolve();
      } else {
        return this.initialize();
      }
    }

    @Watch('subject')
    onSubjectChange(val: any) {
      if (this.listViewLoaded) {
        if (val !== this.standardsData.currentSubject) {
          this.category = 'VA';
          this.setHaveStandards(false);
          CommonUtils.showLoading();
          this.initialize().finally(CommonUtils.hideLoading);
        }
      }
    }

    @Watch('framework')
    onFrameworkChange(val: any) {
      if (this.listViewLoaded) {
        if (val !== this.standardsData.currentState) {
          this.category = 'VA';
          this.setHaveStandards(false);
          CommonUtils.showLoading();
          this.initialize().finally(() => {
            CommonUtils.hideLoading();
            this.onStandardsDataChange();
          });
        }
      }
    }

    @Watch('grade')
    onGradeChange(val: any) {
      if (this.listViewLoaded) {
        if (val !== this.standardsData.currentGrade) {
          this.category = 'VA';
          this.setHaveStandards(false);
          CommonUtils.showLoading();
          this.initialize().finally(CommonUtils.hideLoading);
        }
      }
    }

    @Watch('localValue')
    onValueChange() {
      if (this.value) {
        this.fetchStandards().then(() => {
          this.selected = this.standards;
        });
      } else {
        this.selected = [];
      }
    }

    @Watch('classId')
    onClassIdChange() {
      this.setHaveStandards(false);
      this.fetchStandards();
    }

    updated() {
      console.log(this.localGrades);
      WebUtils.initMathJax();
    }
}
