var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isModal
    ? _c(
        "pb-base-modal",
        {
          attrs: { input: _vm.input, onClose: _vm.doOnClose },
          on: { actions: _vm.showActions, apply: _vm.doApply },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", [
                      _vm.lessonBanksId > 0
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))]),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("resourcesLabel"))),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3390728662
          ),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _c(
            "div",
            [
              _c("resources-editor-form", {
                ref: "form",
                attrs: { input: _vm.input },
              }),
              _c("pb-confirm", { ref: "confirm" }),
            ],
            1
          ),
        ]
      )
    : _c(
        "pb-base-sub-page",
        {
          attrs: { input: _vm.input, onClose: _vm.doOnClose },
          on: { actions: _vm.showActions, apply: _vm.doApply },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", [
                    _vm.lessonBanksId > 0
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))])
                      : _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))]),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("resourcesLabel"))),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _c(
            "div",
            [
              _c("resources-editor-form", {
                ref: "form",
                attrs: { input: _vm.input },
              }),
              _c("pb-confirm", { ref: "confirm" }),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }