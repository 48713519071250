var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multi-list-picker", {
    attrs: {
      label: _vm.getLessonSectionLabel("strategies"),
      items: _vm.localStrategiesItems,
      selected: _vm.selected,
      withRefresh: true,
      contentClass: "strategies-picker-content"
    },
    on: { refresh: _vm.refresh, apply: _vm.doApply },
    scopedSlots: _vm._u([
      {
        key: "prepend-filter",
        fn: function() {
          return [
            _c("v-select", {
              attrs: {
                outlined: "",
                items: _vm.strategyGroups,
                label: _vm.$t("strategyFrameworkLabel")
              },
              model: {
                value: _vm.group,
                callback: function($$v) {
                  _vm.group = $$v
                },
                expression: "group"
              }
            })
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.localValue,
      callback: function($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }