










































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const firebase = namespace('firebase');
const settings = namespace('settings');

@Component
export default class Banner extends Vue {
  @settings.Mutation
  resetBrowserDimensions!: () => void;

  @firebase.Getter
  getPromptNotification!: string;

  @firebase.Getter
  getNotificationBlocked!: boolean;

  @firebase.Mutation
  setPromptNotificatin!: (params: string) => void;

  @firebase.Action
  promptNotificationPermission!: () => Promise<any>;

  blockMessageClosed = false;

  get showBlockedMessage() {
    return this.getNotificationBlocked && !this.blockMessageClosed
  }

  get leftPaddingWidth() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 0;
    }
    return 81;
  }

  get showFirebaseMsg() {
    return (this.getPromptNotification === 'true');
  }

  set showFirebaseMsg(val: any) {
    this.setPromptNotificatin(val);
  }

  get localFirebaseMsg() {
    return this.$t('authorizeNotifications');
  }

  closeFirebaseMsg() {
    this.showFirebaseMsg = 'blocked';
    this.$nextTick(() => {
      this.resetBrowserDimensions();
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.$eventBus.$emit('resize');
      });
    })
  }

  promptNotification() {
    this.promptNotificationPermission();
  }
}
