var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: {
        withActions: false,
        withApply: false,
        input: _vm.input,
        isModal: _vm.isModal,
        expandable: false,
        maxWidth: 800
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("editClassLabel")))])
              ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-data-table", {
                        ref: "table",
                        attrs: {
                          outlined: "",
                          headers: _vm.headers,
                          items: _vm.localTableItems,
                          "item-key": "subjectId",
                          "hide-default-footer": "",
                          "disable-pagination": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.subjectName",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.subjectName ||
                                          _vm.$t("notSetLabel")
                                      )
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.displayName",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.displayName
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.displayName ||
                                              _vm.$t("notSetLabel")
                                          )
                                        )
                                      ])
                                    : item.emailAddress
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.emailAddress ||
                                              _vm.$t("notSetLabel")
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.emailAddress",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.emailAddress ||
                                          _vm.$t("notSetLabel")
                                      )
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.subjectId",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "pb-btn",
                                    {
                                      attrs: { justify: "end" },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestSubjectRemove(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { text: "", color: "red" }
                                        },
                                        [_vm._v("fa-trash")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("removeLabel")) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedRows,
                          callback: function($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "removeSubjectConfirm" }),
      _c(
        "v-alert",
        {
          staticClass: "request-success-alert",
          attrs: {
            dense: "",
            dismissible: "",
            border: "left",
            type: "success",
            value: _vm.showRequestSuccess,
            transition: "fade-transition"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("requestRemoveSentLabel")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }