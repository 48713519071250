var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "editor",
      on: {
        drop: _vm.dropAttachment,
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragleave: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            { ref: "lessonEditorForm" },
            [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTextFieldsVisible,
                      expression: "isTextFieldsVisible",
                    },
                  ],
                  staticClass: "px-5 pt-4",
                },
                [
                  _c(
                    "v-expand-transition",
                    [
                      _vm.hasErrorMsg
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        dense: "",
                                        border: "left",
                                        type: "error",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isCalledByLessonBanks
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      autofocus: _vm.extraLesson !== 999,
                                      "hide-details": "auto",
                                      label: _vm.$t("titleLabel"),
                                      outlined: "",
                                      disabled: !_vm.canEditTitle,
                                      readonly: _vm.input.readonly,
                                    },
                                    model: {
                                      value: _vm.lessonTitle,
                                      callback: function ($$v) {
                                        _vm.lessonTitle = $$v
                                      },
                                      expression: "lessonTitle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "hide-details": "auto",
                                      outlined: "",
                                      label: _vm.$t("lessonBanksLabel"),
                                      rules: _vm.localRules.class,
                                      items: _vm.localClassItems,
                                      readonly: _vm.input.readonly,
                                    },
                                    model: {
                                      value: _vm.classId,
                                      callback: function ($$v) {
                                        _vm.classId = $$v
                                      },
                                      expression: "classId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "hide-details": "auto",
                                      outlined: "",
                                      label: _vm.$t("unitLabel"),
                                      clearable: !_vm.input.readonly,
                                      items: _vm.unitItems,
                                      readonly: _vm.input.readonly,
                                    },
                                    model: {
                                      value: _vm.unitId,
                                      callback: function ($$v) {
                                        _vm.unitId = $$v
                                      },
                                      expression: "unitId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.hasStatuses
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-chip-group",
                                        _vm._l(_vm.statuses, function (status) {
                                          return _c(
                                            "v-chip",
                                            {
                                              key: status.lessonStatusId,
                                              class: {
                                                "contrast-view":
                                                  _vm.highContrastMode,
                                              },
                                              attrs: {
                                                color: status.color,
                                                "text-color": "#FFFFFF",
                                                close: !_vm.input.readonly,
                                                tabindex: "-1",
                                              },
                                              on: {
                                                "click:close": function (
                                                  $event
                                                ) {
                                                  return _vm.deleteStatus(
                                                    status
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(status.statusText)
                                                ),
                                              ]),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _vm.editLink && _vm.linkedLesson
                            ? _c(
                                "v-row",
                                { staticClass: "pb-5" },
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("linkedFromLabel")) +
                                            ": "
                                        ),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          `${_vm.linkedLesson.teacherName}, ${
                                            _vm.linkedLesson.teacherYearName
                                          }, ${_vm.linkedLesson.className}, ${
                                            _vm.linkedLesson.dayOfWeek
                                          }, ${_vm.formatToShow(
                                            _vm.linkedLesson.date
                                          )}`
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.extraLesson === 999
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          autofocus: "",
                                          "hide-details": "auto",
                                          outlined: "",
                                          label: _vm.$t("classLabel") + "*",
                                          "aria-required": "true",
                                          rules: _vm.localRules.class,
                                          clearable: !_vm.input.readonly,
                                          items: _vm.localClassItems,
                                          readonly: _vm.input.readonly,
                                        },
                                        model: {
                                          value: _vm.classId,
                                          callback: function ($$v) {
                                            _vm.classId = $$v
                                          },
                                          expression: "classId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("pb-date-field", {
                                        attrs: {
                                          hideDetails: "auto",
                                          label:
                                            _vm.$t("lessonDateLabel") + "*",
                                          "aria-required": "true",
                                          rules: _vm.localRules.date,
                                          readonly: _vm.input.readonly,
                                        },
                                        model: {
                                          value: _vm.date,
                                          callback: function ($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canViewTitle
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          autofocus: _vm.extraLesson !== 999,
                                          "hide-details": "auto",
                                          label: _vm.$t("titleLabel"),
                                          outlined: "",
                                          disabled: !_vm.canEditTitle,
                                          readonly: _vm.input.readonly,
                                        },
                                        model: {
                                          value: _vm.lessonTitle,
                                          callback: function ($$v) {
                                            _vm.lessonTitle = $$v
                                          },
                                          expression: "lessonTitle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.editLink
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("pb-date-time-range-field", {
                                        ref: "lessonTime",
                                        attrs: {
                                          readonly: _vm.input.readonly,
                                          hideDetails: "auto",
                                          label: _vm.$t("lessonTimeLabel"),
                                          type: "time",
                                          start: _vm.startTime,
                                          end: _vm.endTime,
                                        },
                                        on: {
                                          "update:start": function ($event) {
                                            _vm.startTime = $event
                                          },
                                          "update:end": function ($event) {
                                            _vm.endTime = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          readonly: _vm.input.readonly,
                                          "hide-details": "auto",
                                          outlined: "",
                                          label: _vm.$t("unitLabel"),
                                          clearable: !_vm.input.readonly,
                                          items: _vm.unitItems,
                                        },
                                        model: {
                                          value: _vm.unitId,
                                          callback: function ($$v) {
                                            _vm.unitId = $$v
                                          },
                                          expression: "unitId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasStatuses
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-chip-group",
                                        _vm._l(_vm.statuses, function (status) {
                                          return _c(
                                            "v-chip",
                                            {
                                              key: status.lessonStatusId,
                                              class: {
                                                "contrast-view":
                                                  _vm.highContrastMode,
                                              },
                                              attrs: {
                                                color: status.color,
                                                "text-color": "#FFFFFF",
                                                close:
                                                  !_vm.input.readonly &&
                                                  _vm.inputData
                                                    .collaborateSubjectId === 0,
                                                tabindex: "-1",
                                              },
                                              on: {
                                                "click:close": function (
                                                  $event
                                                ) {
                                                  return _vm.deleteStatus(
                                                    status
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(status.statusText)
                                                ),
                                              ]),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              ),
              !_vm.isStackMode
                ? _c(
                    "div",
                    {
                      key: _vm.localRefreshKey,
                      on: {
                        mouseenter: function ($event) {
                          _vm.isSectionsHovered = true
                        },
                        mouseleave: function ($event) {
                          _vm.isSectionsHovered = false
                        },
                      },
                    },
                    [
                      _c("text-editor-full-screen-button", {
                        attrs: {
                          isSectionsHovered: _vm.isSectionsHovered,
                          isTextFieldsVisible: _vm.isTextFieldsVisible,
                        },
                        on: { hideOrShowTextFields: _vm.hideOrShowTextFields },
                      }),
                      _c(
                        "v-tabs",
                        {
                          staticClass: "editor-section-tabs",
                          attrs: {
                            "center-active": "",
                            centered: "",
                            "show-arrows": "",
                            "prev-icon": "fal fa-chevron-left",
                            "next-icon": "fal fa-chevron-right",
                          },
                          model: {
                            value: _vm.localActiveTab,
                            callback: function ($$v) {
                              _vm.localActiveTab = $$v
                            },
                            expression: "localActiveTab",
                          },
                        },
                        _vm._l(_vm.enabledSections, function (section) {
                          return _c("v-tab", { key: section.section }, [
                            _vm._v(_vm._s(section.label)),
                          ])
                        }),
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.initialized,
                              expression: "initialized",
                            },
                          ],
                          model: {
                            value: _vm.localActiveTab,
                            callback: function ($$v) {
                              _vm.localActiveTab = $$v
                            },
                            expression: "localActiveTab",
                          },
                        },
                        [
                          _vm._l(_vm.enabledSections, function (section) {
                            return [
                              section.section === "lesson"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c("pb-text-editor", {
                                            attrs: {
                                              config: _vm.localTextEditorConfig,
                                              fontStyle: section.textStyle,
                                              disabled:
                                                section.disabled ||
                                                _vm.input.readonly,
                                              noAccess: section.noAccess,
                                              stackMode: _vm.isStackMode,
                                            },
                                            model: {
                                              value: _vm.lessonText,
                                              callback: function ($$v) {
                                                _vm.lessonText = $$v
                                              },
                                              expression: "lessonText",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab2"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c("pb-text-editor", {
                                            attrs: {
                                              config: _vm.localTextEditorConfig,
                                              fontStyle: section.textStyle,
                                              disabled:
                                                section.disabled ||
                                                _vm.input.readonly,
                                              noAccess: section.noAccess,
                                              stackMode: _vm.isStackMode,
                                            },
                                            model: {
                                              value: _vm.tab2Text,
                                              callback: function ($$v) {
                                                _vm.tab2Text = $$v
                                              },
                                              expression: "tab2Text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab3"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c("pb-text-editor", {
                                            attrs: {
                                              config: _vm.localTextEditorConfig,
                                              fontStyle: section.textStyle,
                                              disabled:
                                                section.disabled ||
                                                _vm.input.readonly,
                                              noAccess: section.noAccess,
                                              stackMode: _vm.isStackMode,
                                            },
                                            model: {
                                              value: _vm.tab3Text,
                                              callback: function ($$v) {
                                                _vm.tab3Text = $$v
                                              },
                                              expression: "tab3Text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab4"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c("pb-text-editor", {
                                            attrs: {
                                              config: _vm.localTextEditorConfig,
                                              fontStyle: section.textStyle,
                                              disabled:
                                                section.disabled ||
                                                _vm.input.readonly,
                                              noAccess: section.noAccess,
                                              stackMode: _vm.isStackMode,
                                            },
                                            model: {
                                              value: _vm.tab4Text,
                                              callback: function ($$v) {
                                                _vm.tab4Text = $$v
                                              },
                                              expression: "tab4Text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab5"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c("pb-text-editor", {
                                            attrs: {
                                              config: _vm.localTextEditorConfig,
                                              fontStyle: section.textStyle,
                                              disabled:
                                                section.disabled ||
                                                _vm.input.readonly,
                                              noAccess: section.noAccess,
                                              stackMode: _vm.isStackMode,
                                            },
                                            model: {
                                              value: _vm.tab5Text,
                                              callback: function ($$v) {
                                                _vm.tab5Text = $$v
                                              },
                                              expression: "tab5Text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab6"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c("pb-text-editor", {
                                            attrs: {
                                              config: _vm.localTextEditorConfig,
                                              fontStyle: section.textStyle,
                                              disabled:
                                                section.disabled ||
                                                _vm.input.readonly,
                                              noAccess: section.noAccess,
                                              stackMode: _vm.isStackMode,
                                            },
                                            model: {
                                              value: _vm.tab6Text,
                                              callback: function ($$v) {
                                                _vm.tab6Text = $$v
                                              },
                                              expression: "tab6Text",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "strategies"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c(
                                            "editor-section",
                                            {
                                              attrs: {
                                                config:
                                                  _vm.localEditorSectionConfig,
                                                stackMode: _vm.isStackMode,
                                                readonly: _vm.input.readonly,
                                                label: section.label,
                                                clearable: _vm.hasStrategies,
                                                disabled: section.disabled,
                                                noAccess: section.noAccess,
                                              },
                                              on: {
                                                add: function ($event) {
                                                  return _vm.openDialog(
                                                    "strategies"
                                                  )
                                                },
                                                clear: _vm.clearStrategies,
                                              },
                                            },
                                            [
                                              _c("editor-section-list", {
                                                attrs: {
                                                  items: _vm.strategyItems,
                                                  showDelete:
                                                    !_vm.input.readonly,
                                                },
                                                on: {
                                                  deleteItem:
                                                    _vm.deleteStrategy,
                                                },
                                              }),
                                              _c("strategies-picker", {
                                                attrs: {
                                                  strategies: _vm.strategies,
                                                },
                                                on: {
                                                  "update:strategies":
                                                    function ($event) {
                                                      _vm.strategies = $event
                                                    },
                                                },
                                                model: {
                                                  value: _vm.dialogs.strategies,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogs,
                                                      "strategies",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogs.strategies",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "myStandards"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c(
                                            "editor-section",
                                            {
                                              attrs: {
                                                config:
                                                  _vm.localEditorSectionConfig,
                                                stackMode: _vm.isStackMode,
                                                readonly: _vm.input.readonly,
                                                label: section.label,
                                                clearable: _vm.hasMyStandards,
                                                disabled: section.disabled,
                                                noAccess: section.noAccess,
                                              },
                                              on: {
                                                add: function ($event) {
                                                  return _vm.openDialog(
                                                    "myStandards"
                                                  )
                                                },
                                                clear: _vm.clearMyStandards,
                                              },
                                            },
                                            [
                                              _c("editor-section-list", {
                                                attrs: {
                                                  items: _vm.myStandardItems,
                                                  showDelete:
                                                    !_vm.input.readonly,
                                                },
                                                on: {
                                                  deleteItem:
                                                    _vm.deleteMyStandard,
                                                },
                                              }),
                                              _c("my-list-picker", {
                                                attrs: {
                                                  standards: _vm.myStandards,
                                                },
                                                on: {
                                                  "update:standards": function (
                                                    $event
                                                  ) {
                                                    _vm.myStandards = $event
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.dialogs.myStandards,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogs,
                                                      "myStandards",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogs.myStandards",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "sharedStandards"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c(
                                            "editor-section",
                                            {
                                              attrs: {
                                                config:
                                                  _vm.localEditorSectionConfig,
                                                stackMode: _vm.isStackMode,
                                                readonly: _vm.input.readonly,
                                                label: section.label,
                                                clearable: _vm.hasStandards,
                                                disabled: section.disabled,
                                                noAccess: section.noAccess,
                                              },
                                              on: {
                                                add: function ($event) {
                                                  return _vm.openDialog(
                                                    "standards"
                                                  )
                                                },
                                                clear: _vm.clearStandards,
                                              },
                                            },
                                            [
                                              _c("editor-section-list", {
                                                attrs: {
                                                  items: _vm.standardItems,
                                                  showDelete:
                                                    !_vm.input.readonly,
                                                  useVueHTMLShortValue: true,
                                                },
                                                on: {
                                                  deleteItem:
                                                    _vm.deleteStandard,
                                                },
                                              }),
                                              _c("standards-picker", {
                                                attrs: {
                                                  classId: _vm.classId,
                                                  standards: _vm.standards,
                                                },
                                                on: {
                                                  "update:standards": function (
                                                    $event
                                                  ) {
                                                    _vm.standards = $event
                                                  },
                                                },
                                                model: {
                                                  value: _vm.dialogs.standards,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogs,
                                                      "standards",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogs.standards",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "schoolStandards"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c(
                                            "editor-section",
                                            {
                                              attrs: {
                                                config:
                                                  _vm.localEditorSectionConfig,
                                                stackMode: _vm.isStackMode,
                                                readonly: _vm.input.readonly,
                                                label: section.label,
                                                clearable:
                                                  _vm.hasSchoolStandards,
                                                disabled: section.disabled,
                                                noAccess: section.noAccess,
                                              },
                                              on: {
                                                add: function ($event) {
                                                  return _vm.openDialog(
                                                    "schoolStandards"
                                                  )
                                                },
                                                clear: _vm.clearSchoolStandards,
                                              },
                                            },
                                            [
                                              _c("editor-section-list", {
                                                attrs: {
                                                  items:
                                                    _vm.schoolStandardItems,
                                                  showDelete:
                                                    !_vm.input.readonly,
                                                },
                                                on: {
                                                  deleteItem:
                                                    _vm.deleteSchoolStandard,
                                                },
                                              }),
                                              _c("school-list-picker", {
                                                attrs: {
                                                  standards:
                                                    _vm.schoolStandards,
                                                },
                                                on: {
                                                  "update:standards": function (
                                                    $event
                                                  ) {
                                                    _vm.schoolStandards = $event
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.dialogs.schoolStandards,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogs,
                                                      "schoolStandards",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogs.schoolStandards",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "attachments"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c(
                                            "editor-section",
                                            {
                                              attrs: {
                                                config:
                                                  _vm.localEditorSectionConfig,
                                                stackMode: _vm.isStackMode,
                                                readonly: _vm.input.readonly,
                                                label: section.label,
                                                disabled: section.disabled,
                                                noAccess: section.noAccess,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  !_vm.input.readonly
                                                    ? {
                                                        key: "actions",
                                                        fn: function () {
                                                          return [
                                                            _vm.hasAttachments
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      "aria-label":
                                                                        _vm.$t(
                                                                          "clearAllLabel",
                                                                          {
                                                                            text: section.label,
                                                                          }
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          !section.disabled &&
                                                                            _vm.clearAttachments()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-trash-alt"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  "aria-label":
                                                                    _vm.$t(
                                                                      "addItemLabel",
                                                                      {
                                                                        text: section.label,
                                                                      }
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      !section.disabled &&
                                                                        _vm.openDialog(
                                                                          "myFiles"
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "fal fa-paperclip"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  "aria-label":
                                                                    _vm.$t(
                                                                      "addGoogleDriveLabel"
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      !section.disabled &&
                                                                        _vm.addGoogleDriveAttachments()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "fab fa-google-drive"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      }
                                                    : null,
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("editor-section-list", {
                                                attrs: {
                                                  disabled:
                                                    section.disabled ||
                                                    _vm.input.readonly,
                                                  items: _vm.attachmentItems,
                                                  showShortValue: false,
                                                  showDelete:
                                                    !_vm.input.readonly,
                                                  clickable: true,
                                                },
                                                on: {
                                                  otherAction:
                                                    _vm.toggleAttachmentPrivateFlag,
                                                  deleteItem:
                                                    _vm.deleteAttachment,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "value",
                                                      fn: function ({
                                                        value,
                                                        data,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "section-editor-link",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openLink(
                                                                      data
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(value)
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "other-actions",
                                                      fn: function ({
                                                        item,
                                                        index,
                                                      }) {
                                                        return [
                                                          _c("v-checkbox", {
                                                            staticClass:
                                                              "section-editor-checkbox",
                                                            attrs: {
                                                              disabled:
                                                                section.disabled,
                                                              readonly:
                                                                _vm.input
                                                                  .readonly,
                                                              label:
                                                                _vm.$t(
                                                                  "privateLabel"
                                                                ),
                                                              "input-value":
                                                                item.data
                                                                  .privateFlag,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.toggleAttachmentPrivateFlag(
                                                                  item,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "classwork"
                                ? _c(
                                    "v-tab-item",
                                    { key: section.section },
                                    [
                                      section.active
                                        ? _c(
                                            "editor-section",
                                            {
                                              attrs: {
                                                config:
                                                  _vm.localEditorSectionConfig,
                                                stackMode: _vm.isStackMode,
                                                readonly: _vm.input.readonly,
                                                label: section.label,
                                                clearable:
                                                  _vm.isClassworkClearable,
                                                disabled: false,
                                                noAccess: false,
                                              },
                                              on: {
                                                add: function ($event) {
                                                  return _vm.openDialog(
                                                    "classwork"
                                                  )
                                                },
                                                clear: _vm.clearClasswork,
                                              },
                                            },
                                            [
                                              _c("editor-section-list", {
                                                attrs: {
                                                  items: _vm.classworkItems,
                                                  showShortValue: false,
                                                  showDelete:
                                                    !_vm.input.readonly,
                                                },
                                                on: {
                                                  deleteItem:
                                                    _vm.deleteClasswork,
                                                },
                                              }),
                                              _c("classwork-editor", {
                                                attrs: {
                                                  isModal: true,
                                                  isSubpage: false,
                                                  input:
                                                    _vm.localClassworkInput,
                                                },
                                                model: {
                                                  value: _vm.dialogs.classwork,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogs,
                                                      "classwork",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogs.classwork",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "stack-mode-expansion-panel",
                      on: {
                        mouseenter: function ($event) {
                          _vm.isSectionsHovered = true
                        },
                        mouseleave: function ($event) {
                          _vm.isSectionsHovered = false
                        },
                      },
                    },
                    [
                      _c("text-editor-full-screen-button", {
                        attrs: {
                          isSectionsHovered: _vm.isSectionsHovered,
                          isTextFieldsVisible: _vm.isTextFieldsVisible,
                        },
                        on: { hideOrShowTextFields: _vm.hideOrShowTextFields },
                      }),
                      _c(
                        "v-expansion-panels",
                        {
                          key: _vm.localRefreshKey,
                          ref: "panels",
                          attrs: {
                            accordion: "",
                            multiple: "",
                            flat: "",
                            tile: "",
                            role: "tablist",
                          },
                          model: {
                            value: _vm.panels,
                            callback: function ($$v) {
                              _vm.panels = $$v
                            },
                            expression: "panels",
                          },
                        },
                        _vm._l(_vm.enabledSections, function (section, i) {
                          return _c(
                            "v-expansion-panel",
                            { key: i, attrs: { role: "tab" } },
                            [
                              _c("v-expansion-panel-header", [
                                _c("span", [_vm._v(_vm._s(section.label))]),
                                section.section === "strategies" &&
                                !_vm.input.readonly
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: { align: "end" },
                                      },
                                      [
                                        _vm.hasStrategies
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    section.disabled ||
                                                    _vm.input.readonly,
                                                  "aria-label": _vm.$t(
                                                    "clearAllLabel",
                                                    { text: section.label }
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.clearStrategies.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-trash-alt"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addItemLabel",
                                                { text: section.label }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openDialog(
                                                  "strategies"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fal fa-plus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                section.section === "myStandards" &&
                                !_vm.input.readonly
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: { align: "end" },
                                      },
                                      [
                                        _vm.hasMyStandards
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    section.disabled ||
                                                    _vm.input.readonly,
                                                  "aria-label": _vm.$t(
                                                    "clearAllLabel",
                                                    { text: section.label }
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.clearMyStandards.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-trash-alt"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addItemLabel",
                                                { text: section.label }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openDialog(
                                                  "myStandards"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fal fa-plus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                section.section === "sharedStandards" &&
                                !_vm.input.readonly
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: { align: "end" },
                                      },
                                      [
                                        _vm.hasStandards
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    section.disabled ||
                                                    _vm.input.readonly,
                                                  "aria-label": _vm.$t(
                                                    "clearAllLabel",
                                                    { text: section.label }
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.clearStandards.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-trash-alt"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addItemLabel",
                                                { text: section.label }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openDialog(
                                                  "standards"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fal fa-plus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                section.section === "schoolStandards" &&
                                !_vm.input.readonly
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: { align: "end" },
                                      },
                                      [
                                        _vm.hasSchoolStandards
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    section.disabled ||
                                                    _vm.input.readonly,
                                                  "aria-label": _vm.$t(
                                                    "clearAllLabel",
                                                    { text: section.label }
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.clearSchoolStandards.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-trash-alt"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addItemLabel",
                                                { text: section.label }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openDialog(
                                                  "schoolStandards"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fal fa-plus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                section.section === "attachments" &&
                                !_vm.input.readonly
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: { align: "end" },
                                      },
                                      [
                                        _vm.hasAttachments
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  "aria-label": _vm.$t(
                                                    "clearAllLabel",
                                                    { text: section.label }
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    !section.disabled &&
                                                      _vm.clearAttachments()
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-trash-alt"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addItemLabel",
                                                { text: section.label }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                !section.disabled &&
                                                  _vm.openDialog("myFiles")
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fal fa-paperclip"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addGoogleDriveLabel"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                !section.disabled &&
                                                  _vm.addGoogleDriveAttachments()
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fab fa-google-drive"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                section.section === "classwork" &&
                                !_vm.input.readonly
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: { align: "end" },
                                      },
                                      [
                                        _vm.isClassworkClearable
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    section.disabled ||
                                                    _vm.input.readonly,
                                                  "aria-label": _vm.$t(
                                                    "clearAllLabel",
                                                    { text: section.label }
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.clearClasswork.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-trash-alt"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addItemLabel",
                                                { text: section.label }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openDialog(
                                                  "classwork"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fal fa-plus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              section.section === "lesson"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.input.readonly,
                                          noAccess: section.noAccess,
                                          stackMode: _vm.isStackMode,
                                        },
                                        model: {
                                          value: _vm.lessonText,
                                          callback: function ($$v) {
                                            _vm.lessonText = $$v
                                          },
                                          expression: "lessonText",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab2"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.input.readonly,
                                          noAccess: section.noAccess,
                                          stackMode: _vm.isStackMode,
                                        },
                                        model: {
                                          value: _vm.tab2Text,
                                          callback: function ($$v) {
                                            _vm.tab2Text = $$v
                                          },
                                          expression: "tab2Text",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab3"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.input.readonly,
                                          noAccess: section.noAccess,
                                          stackMode: _vm.isStackMode,
                                        },
                                        model: {
                                          value: _vm.tab3Text,
                                          callback: function ($$v) {
                                            _vm.tab3Text = $$v
                                          },
                                          expression: "tab3Text",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab4"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.input.readonly,
                                          noAccess: section.noAccess,
                                          stackMode: _vm.isStackMode,
                                        },
                                        model: {
                                          value: _vm.tab4Text,
                                          callback: function ($$v) {
                                            _vm.tab4Text = $$v
                                          },
                                          expression: "tab4Text",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab5"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.input.readonly,
                                          noAccess: section.noAccess,
                                          stackMode: _vm.isStackMode,
                                        },
                                        model: {
                                          value: _vm.tab5Text,
                                          callback: function ($$v) {
                                            _vm.tab5Text = $$v
                                          },
                                          expression: "tab5Text",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "tab6"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.input.readonly,
                                          noAccess: section.noAccess,
                                          stackMode: _vm.isStackMode,
                                        },
                                        model: {
                                          value: _vm.tab6Text,
                                          callback: function ($$v) {
                                            _vm.tab6Text = $$v
                                          },
                                          expression: "tab6Text",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "strategies"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            stackMode: _vm.isStackMode,
                                            label: section.label,
                                            clearable: _vm.hasStrategies,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog(
                                                "strategies"
                                              )
                                            },
                                            clear: _vm.clearStrategies,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              showDelete: !_vm.input.readonly,
                                              items: _vm.strategyItems,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteStrategy,
                                            },
                                          }),
                                          _c("strategies-picker", {
                                            attrs: {
                                              strategies: _vm.strategies,
                                            },
                                            on: {
                                              "update:strategies": function (
                                                $event
                                              ) {
                                                _vm.strategies = $event
                                              },
                                            },
                                            model: {
                                              value: _vm.dialogs.strategies,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "strategies",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.strategies",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "myStandards"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            stackMode: _vm.isStackMode,
                                            label: section.label,
                                            clearable: _vm.hasMyStandards,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog(
                                                "myStandards"
                                              )
                                            },
                                            clear: _vm.clearMyStandards,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              showDelete: !_vm.input.readonly,
                                              items: _vm.myStandardItems,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteMyStandard,
                                            },
                                          }),
                                          _c("my-list-picker", {
                                            attrs: {
                                              standards: _vm.myStandards,
                                            },
                                            on: {
                                              "update:standards": function (
                                                $event
                                              ) {
                                                _vm.myStandards = $event
                                              },
                                            },
                                            model: {
                                              value: _vm.dialogs.myStandards,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "myStandards",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.myStandards",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "sharedStandards"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            stackMode: _vm.isStackMode,
                                            label: section.label,
                                            clearable: _vm.hasStandards,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog("standards")
                                            },
                                            clear: _vm.clearStandards,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              showDelete: !_vm.input.readonly,
                                              items: _vm.standardItems,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteStandard,
                                            },
                                          }),
                                          _c("standards-picker", {
                                            attrs: {
                                              classId: _vm.classId,
                                              standards: _vm.standards,
                                            },
                                            on: {
                                              "update:standards": function (
                                                $event
                                              ) {
                                                _vm.standards = $event
                                              },
                                            },
                                            model: {
                                              value: _vm.dialogs.standards,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "standards",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.standards",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "schoolStandards"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            stackMode: _vm.isStackMode,
                                            label: section.label,
                                            clearable: _vm.hasSchoolStandards,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog(
                                                "schoolStandards"
                                              )
                                            },
                                            clear: _vm.clearSchoolStandards,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              showDelete: !_vm.input.readonly,
                                              items: _vm.schoolStandardItems,
                                              useVueHTMLShortValue: true,
                                            },
                                            on: {
                                              deleteItem:
                                                _vm.deleteSchoolStandard,
                                            },
                                          }),
                                          _c("school-list-picker", {
                                            attrs: {
                                              standards: _vm.schoolStandards,
                                            },
                                            on: {
                                              "update:standards": function (
                                                $event
                                              ) {
                                                _vm.schoolStandards = $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dialogs.schoolStandards,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "schoolStandards",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogs.schoolStandards",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "attachments"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            stackMode: _vm.isStackMode,
                                            label: section.label,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              disabled:
                                                section.disabled ||
                                                _vm.input.readonly,
                                              items: _vm.attachmentItems,
                                              showShortValue: false,
                                              showDelete: !_vm.input.readonly,
                                              clickable: true,
                                            },
                                            on: {
                                              otherAction:
                                                _vm.toggleAttachmentPrivateFlag,
                                              deleteItem: _vm.deleteAttachment,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "value",
                                                  fn: function ({
                                                    value,
                                                    data,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "section-editor-link",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                data
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "other-actions",
                                                  fn: function ({
                                                    item,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "section-editor-checkbox",
                                                        attrs: {
                                                          disabled:
                                                            section.disabled,
                                                          readonly:
                                                            _vm.input.readonly,
                                                          label:
                                                            _vm.$t(
                                                              "privateLabel"
                                                            ),
                                                          "input-value":
                                                            item.data
                                                              .privateFlag,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleAttachmentPrivateFlag(
                                                              item,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              section.section === "classwork"
                                ? _c(
                                    "v-expansion-panel-content",
                                    { key: section.section },
                                    [
                                      _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            stackMode: _vm.isStackMode,
                                            label: section.label,
                                            clearable: _vm.isClassworkClearable,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog("classwork")
                                            },
                                            clear: _vm.clearClasswork,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              items: _vm.classworkItems,
                                              showShortValue: false,
                                              showDelete: !_vm.input.readonly,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteClasswork,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
              _c("pb-confirm", { ref: "confirm" }),
              _c(
                "pb-confirm",
                { ref: "embedFiles" },
                [
                  _c(
                    "v-chip-group",
                    {
                      attrs: {
                        "active-class": "accent1--text font-weight-bold",
                        column: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.localEmbedSections,
                        callback: function ($$v) {
                          _vm.localEmbedSections = $$v
                        },
                        expression: "localEmbedSections",
                      },
                    },
                    _vm._l(_vm.enabledLessonTextSections, function (item, i) {
                      return _c(
                        "v-chip",
                        { key: i, attrs: { value: item.section } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("my-files-picker", {
                ref: "filesPicker",
                attrs: { attachments: _vm.attachments },
                on: { "update:attachments": _vm.updateAttachments },
                model: {
                  value: _vm.dialogs.myFiles,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialogs, "myFiles", $$v)
                  },
                  expression: "dialogs.myFiles",
                },
              }),
              _c("classwork-lesson-action", {
                ref: "classworkAction",
                attrs: { input: _vm.input },
                on: { saveClasswork: _vm.doSaveClasswork },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }