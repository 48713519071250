var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "editor-header",
      class: { "mobile-editor-header": _vm.$vuetify.breakpoint.smAndDown }
    },
    [
      _c("div", { staticStyle: { "font-weight": "600" } }, [
        _vm._v(_vm._s(_vm.localClassName))
      ]),
      _vm.extraLesson === 999
        ? _c("div", { staticStyle: { "font-weight": "600" } }, [
            _vm._v(" " + _vm._s(_vm.$t("addExtraLessonLabel")) + " ")
          ])
        : _c("div", { staticStyle: { "white-space": "nowrap" } }, [
            !_vm.isEditExtraLesson
              ? _c("span", { staticStyle: { "font-weight": "normal" } }, [
                  _vm._v(_vm._s(_vm.formattedDate))
                ])
              : _vm._e(),
            _vm.isLocalLocked
              ? _c("i", { staticClass: "pl-2 fas fa-lock" })
              : _vm._e()
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }