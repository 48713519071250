var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-5 pt-4 mb-3" },
    [
      _c(
        "v-expand-transition",
        [
          _vm.hasErrorMsg
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-0",
                          attrs: { dense: "", border: "left", type: "error" }
                        },
                        [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }