var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "lessonBanksEditorForm", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-container",
            { staticClass: "pt-4" },
            [
              _vm.userType === "D"
                ? _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                color: "primary",
                                mandatory: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.saveToSchoolOrDistrict,
                                callback: function($$v) {
                                  _vm.saveToSchoolOrDistrict = $$v
                                },
                                expression: "saveToSchoolOrDistrict"
                              }
                            },
                            [
                              _c("v-btn", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("saveToSchoolLabel")))
                                ])
                              ]),
                              _c("v-btn", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("saveToDistrictLabel")))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mb-5",
                      attrs: { cols: _vm.userType === "D" ? 10 : 12 }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pb-4",
                        attrs: {
                          outlined: "",
                          "hide-details": "auto",
                          items: _vm.lessonLayoutItems,
                          label: _vm.$t("lessonLayoutLabel"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.lessonBankLayoutId,
                          callback: function($$v) {
                            _vm.lessonBankLayoutId = $$v
                          },
                          expression: "lessonBankLayoutId"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "auto",
                          label: _vm.$t("nameLabel") + "*",
                          "aria-required": "true",
                          outlined: "",
                          rules: _vm.localRules
                        },
                        model: {
                          value: _vm.lessonBanksTitle,
                          callback: function($$v) {
                            _vm.lessonBanksTitle = $$v
                          },
                          expression: "lessonBanksTitle"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }