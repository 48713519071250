




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Confirm from '@/components/core/Confirm.vue';
import { FormError } from '@/errors';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SchoolYearEditorContainer from '@/components/schoolyears/SchoolYearEditorContainer.vue';
import { cycleDays, weekDays } from '@/constants';
import moment from 'moment';
import { Deferred } from '@/common/deferred';
import ManageLessons from '@/components/classes/ManageLessons.vue';
import ImportLessons from './ImportLessons.vue';

const plans = namespace('plans');
const classes = namespace('classes');
const grades = namespace('grades');
const settings = namespace('settings');
const referencedata = namespace('referencedata');
const lessonlayouts = namespace('lessonlayouts');

@Component({
  components: { SchoolYearEditorContainer, ManageLessons, ImportLessons }
})
export default class SchoolYearEditorForm extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  input: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  isActive!: boolean;

  @settings.Getter('getCurrentYear')
  currentYear!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getUserType')
  userType!: string;

  @classes.State('schoolYearClasses')
  classes!: Array<any>;

  @classes.Getter('getClassItems')
  classItems!: Array<any>;

  @settings.Getter('getSchoolId')
  userSchoolId!: number;

  @settings.Getter('getDistrictId')
  userDistrictId!: number;

  @settings.Getter('getYearNames')
  allYearNames!: Array<string>;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getSchoolYearListLoading')
  schoolYearListLoading!: boolean;

  @settings.State
  adminSchoolYears!: Array<any>;

  @settings.Mutation
  setSchoolYearEditorInitialized!: (params: boolean) => void;

  @classes.Mutation
  setHaveClass!: (value: boolean) => void;

  @plans.Mutation
  setFetchPlans!: (value: string) => void;

  @classes.Action
  loadClasses!: (params?: any) => Promise<any>;

  @settings.Mutation
  setUpdatedSettings!: (params?: any) => void;

  @settings.Mutation
  setOriginalSettings!: (params?: any) => void;

  @settings.Action
  saveAdminYear!: (params?: any) => Promise<any>;

  @settings.Action('save')
  doSave!: (params?: any) => Promise<any>;

  @settings.Action
  addSchoolYear!: (params?: any) => Promise<any>;

  @settings.Action
  reloadSettings!: (params?: any) => Promise<any>;

  @settings.Action
  verifySchoolYear!: (params?: any) => Promise<any>;

  @settings.Getter('getEmailAddress')
  emailAddress!: any;

  @settings.Getter('getUserId')
  userId!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter('getOtherSettings')
  otherSettings!: any;

  @settings.Getter('getDateStyling')
  dateStyling!: any;

  @settings.Getter('getSchoolYears')
  schoolYears!: any;

  @settings.Getter
  highContrastMode!: boolean;

  @classes.Action
  getSharedClasses!: (params?: any) => Promise<any>;

  @classes.Action
  loadSchoolYearClasses!: (schoolYear?: number) => Promise<any>;

  @plans.Mutation
  resetPlans!: () => void;

  @plans.Action
  reloadPlans!: () => Promise<any>;

  @settings.State
  loading!: boolean;

  @referencedata.State
  holidays!: Array<any>;

  @lessonlayouts.Action
  loadLayouts!: () => Promise<any>;

  @grades.Action
  updateLetterGrades!: (params: any) => Promise<any>;

  @grades.Action
  loadYearsLetterGrades!: (params: any) => Promise<any>;

  @grades.Action
  setGradePeriods!: (params: any) => Promise<any>;

  @grades.Action
  loadYearsGradePeriods!: (params: any) => Promise<any>;

  localSelectedHolidays: Array<any> = [];
  localNoSchoolDays: Array<any> = [];
  localClassesToCopy: Array<any> = [];
  panels = [0, 1, 2, 3, 4];
  newSchoolYear = false;
  newSchoolYearText = '';
  error = '';
  step = 1;
  slideLeft = true;
  sliding = false;
  up: any = {};
  localLessonLayouts = [];
  localLessonLayoutsOrig = [];
  showManageLessons = false;
  showImportLessons = false;
  hasAdminLetterGrades = false;
  hasAdminGradePeriods = false;
  localSharedYears:any[] = [];
  localParentYearId = 0;

  $refs!: {
    confirm: Confirm
    prompt: Confirm,
    form: Vue & { validate: () => boolean, resetValidation: () => void },
    schoolYearDate: Vue & { validate: () => boolean, resetValidation: () => void },
    panels: Vue
  };

  localRules = {
    required: [(v: any) => !!v || this.$t('requiredLabel')],
    yearName: [(v: any) => !!v || this.$t('schoolYearErrorMsg10')],
    letterGradeName: [(v: any) => !!v || this.$t('letterGradesErrorMsg1')],
    letterGradePercentage: [(v: any) => (!!v || v === 0) || this.$t('letterGradesErrorMsg2'),
      (v: any) => !(isNaN(+v) || +v > 100 || +v < 0) || this.$t('letterGradesErrorMsg3')],
    gradePeriodStart: [(v: any) => !!v || this.$t('gradingPeriodErrorMsg1')],
    gradePeriodEnd: [(v: any) => !!v || this.$t('gradingPeriodErrorMsg3')],
    gradePeriodName: [(v: any) => !!v || this.$t('gradingPeriodErrorMsg5')],
    dateWithinRange: [(v: any) => !!v || this.$t('requiredMsg', { s: this.$t('dateLabel') }),
      (v: any) => !DateTimeUtils.isThisDateBeforeThatDate(DateTimeUtils.formatToSave(v), this.firstDay) || this.$t('endAfterStartMsg', { s: this.$t('dateLabel'), e: this.$t('yearStartLabel') }),
      (v: any) => !DateTimeUtils.isThisDateAfterThatDate(DateTimeUtils.formatToSave(v), this.lastDay) || this.$t('startBeforeEndMsg', { s: this.$t('dateLabel'), e: this.$t('yearEndLabel') })]
  }

  localRefreshKey = CommonUtils.generateUUID();

  get isInitializing() {
    return CommonUtils.isEmpty(Object.keys(this.up));
  }

  get hasErrorMsg() {
    return CommonUtils.hasText(this.error);
  }

  get arrayAddOrRemove() {
    return CommonUtils.arrayAddOrRemove;
  }

  get cycleDayItems() {
    if (this.cycle === 'two') {
      return [
        { text: this.$t('weekLabel') + ' 1', value: 1 },
        { text: this.$t('weekLabel') + ' 2', value: 2 }
      ]
    } else {
      return this.localCycleDayNames.map((item: any, i: any) => {
        return {
          text: `Day ${i + 1}`,
          value: i + 1
        };
      });
    }
  }

  get schoolItems() {
    return this.schools.map((s: any) => {
      return { text: s.schoolName, value: +s.schoolId };
    });
  }

  get schoolYearCurrentSchoolId() {
    const schoolId = this.$store.state.settings.schoolYearCurrentSchoolId;
    if (CommonUtils.isNotEmpty(this.schoolItems)) {
      if (this.schoolItems.find((i: any) => i.value === schoolId)) {
        return schoolId;
      }
      return this.userSchoolId || this.schoolItems[0].value;
    }
    return -1;
  }

  get sunShow() {
    if (CommonUtils.hasValue(this.otherSettings.sunShowSchoolYears) && CommonUtils.hasValue(this.otherSettings.sunShowSchoolYears.years[this.schoolYear.yearId])) {
      return this.isTrue(this.otherSettings.sunShowSchoolYears.years[this.schoolYear.yearId]);
    } else {
      return this.dateStyling.sunShow ? this.isTrue(this.dateStyling.sunShow) : false;
    }
  }

  set sunShow(value: any) {
    const obj = CommonUtils.hasValue(this.otherSettings.sunShowSchoolYears) ? this.otherSettings.sunShowSchoolYears : { years: {} }
    this.setValue('sunShowSchoolYears', ld.set(obj.years, this.schoolYear.yearId, value));
  }

  get monShow() {
    if (CommonUtils.hasValue(this.otherSettings.monShowSchoolYears) && CommonUtils.hasValue(this.otherSettings.monShowSchoolYears.years[this.schoolYear.yearId])) {
      return this.isTrue(this.otherSettings.monShowSchoolYears.years[this.schoolYear.yearId]);
    } else {
      return this.dateStyling.monShow ? this.isTrue(this.dateStyling.monShow) : true;
    }
  }

  set monShow(value: any) {
    const obj = CommonUtils.hasValue(this.otherSettings.monShowSchoolYears) ? this.otherSettings.monShowSchoolYears : { years: {} }
    this.setValue('monShowSchoolYears', ld.set(obj.years, this.schoolYear.yearId, value));
  }

  get tueShow() {
    if (CommonUtils.hasValue(this.otherSettings.tueShowSchoolYears) && CommonUtils.hasValue(this.otherSettings.tueShowSchoolYears.years[this.schoolYear.yearId])) {
      return this.isTrue(this.otherSettings.tueShowSchoolYears.years[this.schoolYear.yearId]);
    } else {
      return this.dateStyling.tueShow ? this.isTrue(this.dateStyling.tueShow) : true;
    }
  }

  set tueShow(value: any) {
    const obj = CommonUtils.hasValue(this.otherSettings.tueShowSchoolYears) ? this.otherSettings.tueShowSchoolYears : { years: {} }
    this.setValue('tueShowSchoolYears', ld.set(obj.years, this.schoolYear.yearId, value));
  }

  get wedShow() {
    if (CommonUtils.hasValue(this.otherSettings.wedShowSchoolYears) && CommonUtils.hasValue(this.otherSettings.wedShowSchoolYears.years[this.schoolYear.yearId])) {
      return this.isTrue(this.otherSettings.wedShowSchoolYears.years[this.schoolYear.yearId]);
    } else {
      return this.dateStyling.wedShow ? this.isTrue(this.dateStyling.wedShow) : true;
    }
  }

  set wedShow(value: any) {
    const obj = CommonUtils.hasValue(this.otherSettings.wedShowSchoolYears) ? this.otherSettings.wedShowSchoolYears : { years: {} }
    this.setValue('wedShowSchoolYears', ld.set(obj.years, this.schoolYear.yearId, value));
  }

  get thuShow() {
    if (CommonUtils.hasValue(this.otherSettings.thuShowSchoolYears) && CommonUtils.hasValue(this.otherSettings.thuShowSchoolYears.years[this.schoolYear.yearId])) {
      return this.isTrue(this.otherSettings.thuShowSchoolYears.years[this.schoolYear.yearId]);
    } else {
      return this.dateStyling.thuShow ? this.isTrue(this.dateStyling.thuShow) : true;
    }
  }

  set thuShow(value: any) {
    const obj = CommonUtils.hasValue(this.otherSettings.thuShowSchoolYears) ? this.otherSettings.thuShowSchoolYears : { years: {} }
    this.setValue('thuShowSchoolYears', ld.set(obj.years, this.schoolYear.yearId, value));
  }

  get friShow() {
    if (CommonUtils.hasValue(this.otherSettings.friShowSchoolYears) && CommonUtils.hasValue(this.otherSettings.friShowSchoolYears.years[this.schoolYear.yearId])) {
      return this.isTrue(this.otherSettings.friShowSchoolYears.years[this.schoolYear.yearId]);
    } else {
      return this.dateStyling.friShow ? this.isTrue(this.dateStyling.friShow) : true;
    }
  }

  set friShow(value: any) {
    const obj = CommonUtils.hasValue(this.otherSettings.friShowSchoolYears) ? this.otherSettings.friShowSchoolYears : { years: {} }
    this.setValue('friShowSchoolYears', ld.set(obj.years, this.schoolYear.yearId, value));
  }

  get satShow() {
    if (CommonUtils.hasValue(this.otherSettings.satShowSchoolYears) && CommonUtils.hasValue(this.otherSettings.satShowSchoolYears.years[this.schoolYear.yearId])) {
      return this.isTrue(this.otherSettings.satShowSchoolYears.years[this.schoolYear.yearId]);
    } else {
      return this.dateStyling.satShow ? this.isTrue(this.dateStyling.satShow) : false;
    }
  }

  set satShow(value: any) {
    const obj = CommonUtils.hasValue(this.otherSettings.satShowSchoolYears) ? this.otherSettings.satShowSchoolYears : { years: {} }
    this.setValue('satShowSchoolYears', ld.set(obj.years, this.schoolYear.yearId, value));
  }

  get weekDays() {
    return weekDays;
  }

  get showWeekDays() {
    const that: any = this;
    return this.weekDays.filter(d => that[d.value]).map(d => d.value);
  }

  set showWeekDays(days: Array<string>) {
    const that: any = this;
    this.weekDays.forEach(d => { that[d.value] = 'N' });
    if (CommonUtils.isNotEmpty(days)) {
      days.forEach(d => { that[d] = 'Y' });
    }
  }

  get yearNames() {
    return this.allYearNames.filter(n => n !== this.schoolYear.yearName);
  }

  get hasSelectAdminYears() {
    return CommonUtils.isNotEmpty(this.selectAdminYears.filter(y => y.schoolId > 0 || y.districtId > 0));
  }

  get dateRangeRule() {
    return [(start: string, end: string) => {
      if (CommonUtils.hasNoText(start)) {
        return this.$t('enterStartDate');
      }

      if (CommonUtils.hasNoText(end)) {
        return this.$t('enterEndDate');
      }
    }];
  }

  get selectAdminYears() {
    const newYear = ld.cloneDeep(this.currentYear);
    newYear.yearId = 0;
    newYear.parentYearId = 0;
    newYear.parentYearName = '';
    newYear.yearName = '';
    newYear.firstDay = '';
    newYear.lastDay = '';
    const say = this.adminSchoolYears
      .filter(s => {
        const current = moment();
        const lastDay = moment(s.lastDay, 'MM/DD/YYYY');
        return lastDay.isAfter(current);
      })
      .map(s => {
        const sy = ld.cloneDeep(s);
        sy.parentYearId = sy.yearId;
        sy.parentYearName = sy.parentYearName || sy.yearName;
        sy.yearId = 0;
        return sy;
      })
      .concat([newYear]);
    return say;
  }

  @Watch('cycle')
  onCycleChange() {
    if (
      this.schoolYearEditorInitialized &&
      this.cycle === 'cycle' &&
      CommonUtils.isEmpty(this.localCycleDayNames) &&
      +this.cycleDaysNum > 1 &&
      +this.cycleDaysNum < 21
    ) {
      if (+this.cycleDaysNum === 2) {
        this.localCycleDayNames = [{ dayName: 'A Day' }, { dayName: 'B Day' }];
      } else {
        for (let i = 0; i < +this.cycleDaysNum; i++) {
          this.localCycleDayNames.push({ dayName: `Day ${i + 1}` });
        }
      }
    }
  }

  @Watch('cycleDaysNum')
  onCycleDaysChange() {
    if (
      this.schoolYearEditorInitialized &&
      this.cycle === 'cycle' &&
      +this.cycleDaysNum > 1 &&
      +this.cycleDaysNum < 21
    ) {
      if (+this.cycleDaysNum > this.localCycleDayNames.length) {
        for (
          let i = this.localCycleDayNames.length;
          i < +this.cycleDaysNum;
          i++
        ) {
          this.localCycleDayNames.push({ dayName: `Day ${i + 1}` });
        }
      } else if (+this.cycleDaysNum < this.localCycleDayNames.length) {
        this.localCycleDayNames = this.localCycleDayNames.filter(
          (o: any, i: any) => i < +this.cycleDaysNum
        );
      }
    }
  }

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get cycleDays() {
    return cycleDays;
  }

  get showSave() {
    return (
      (this.yearId > 0 && this.userMode === 'T') ||
      this.userType === 'D' ||
      (this.yearId > 0 && this.userMode === 'A' && this.schoolId > 0) ||
      (this.yearId === 0 && this.userMode === 'A')
    );
  }

  get isEditable() {
    return (
      (this.yearId > 0 && this.userMode === 'T') ||
      this.userType === 'D' ||
      (this.yearId > 0 && this.userMode === 'A' && this.schoolId > 0) ||
      this.yearId === 0
    );
  }

  get isEditSchoolYearWithParent() {
    return this.parentYearId > 0 && this.yearId > 0;
  }

  get cyleDayNameRows() {
    const rows = this.localCycleDayNames.map(() => '*');
    return rows.length > 0 ? rows.join(',') : '*';
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  set updatedSettings(value: any) {
    this.setUpdatedSettings(value);
  }

  get localOrigSettings() {
    return this.$store.state.settings.originalSettings;
  }

  set localOrigSettings(value: any) {
    this.setOriginalSettings(value)
  }

  get schoolYear(): any {
    const newYear = {
      sunCycle: false,
      monCycle: true,
      tueCycle: true,
      wedCycle: true,
      thuCycle: true,
      friCycle: true,
      satCycle: false,
      yearId: 0,
      parentYearId: 0
    };

    if (this.currentYear) {
      const currentClone = ld.cloneDeep(this.currentYear);
      currentClone.yearId = 0;
      currentClone.parentYearId = 0;
      currentClone.parentYearName = '';
      currentClone.yearName = '';
      currentClone.firstDay = '';
      currentClone.lastDay = '';
      ld.assign(newYear, currentClone);
    }

    return this.input.data || newYear;
  }

  get currentYearName() {
    if (this.currentYear) {
      return this.currentYear.yearName;
    }
    return '';
  }

  get showCycleDays() {
    const that: any = this;

    return this.cycleDays
      .filter(d => {
        return CommonUtils.isTrue(that[d.value]);
      })
      .map(d => {
        return d.value;
      });
  }

  set showCycleDays(days: Array<string>) {
    cycleDays.forEach(d => this.setValue(d.value, false));
    if (CommonUtils.isNotEmpty(days)) {
      days.forEach(d => this.setValue(d, true));
    }
  }

  addRemoveClassId(classId: number, checked: true) {
    if (checked) {
      this.classIds = this.classIds.concat(classId);
    } else {
      this.classIds = this.classIds.filter(cId => cId !== classId);
    }
  }

  get formatToShow() {
    return DateTimeUtils.formatToShow;
  }

  get yearNameHint() {
    return `${this.$t('schoolYearLabel')}* (.e.g ${
      DateTimeUtils.getCurrentYearRange().range
    })`;
  }

  get actionBarTitle() {
    if (!this.isEditable) {
      return this.$t('schoolYearLabel');
    } else if (this.schoolYear.yearId > 0) {
      return this.$t('editSchoolYearLabel');
    } else {
      return this.$t('addSchoolYearLabel');
    }
  }

  get classIds(): Array<number> {
    return this.updatedSettings.classIds || [];
  }

  set classIds(value: Array<number>) {
    this.setValue('classIds', value);
  }

  get yearId(): number {
    return this.updatedSettings.yearId;
  }

  set yearId(value: number) {
    this.setValue('yearId', value);
  }

  get schoolId(): number {
    return this.updatedSettings.schoolId;
  }

  set schoolId(value: number) {
    this.setValue('schoolId', value);
  }

  get districtId(): number {
    return this.updatedSettings.districtId;
  }

  set districtId(value: number) {
    this.setValue('districtId', value);
  }

  get yearName(): string {
    return this.updatedSettings.yearName;
  }

  set yearName(value: string) {
    this.setValue('yearName', value);
  }

  get firstDay(): string {
    return this.updatedSettings.firstDay;
  }

  set firstDay(value: string) {
    this.setValue('firstDay', value);
  }

  get lastDay(): string {
    return this.updatedSettings.lastDay;
  }

  set lastDay(value: string) {
    this.setValue('lastDay', value);
  }

  get parentYearId(): number {
    return this.updatedSettings.parentYearId;
  }

  set parentYearId(value: number) {
    this.setValue('parentYearId', value);
  }

  get parentYearName(): string {
    return this.updatedSettings.parentYearName;
  }

  set parentYearName(value: string) {
    this.setValue('parentYearName', value);
  }

  get sunCycle(): boolean {
    return this.isTrue(this.updatedSettings.sunCycle);
  }

  set sunCycle(value: boolean) {
    this.setValue('sunCycle', value);
  }

  get monCycle(): boolean {
    return this.isTrue(this.updatedSettings.monCycle);
  }

  set monCycle(value: boolean) {
    this.setValue('monCycle', value);
  }

  get tueCycle(): boolean {
    return this.isTrue(this.updatedSettings.tueCycle);
  }

  set tueCycle(value: boolean) {
    this.setValue('tueCycle', value);
  }

  get wedCycle(): boolean {
    return this.isTrue(this.updatedSettings.wedCycle);
  }

  set wedCycle(value: boolean) {
    this.setValue('wedCycle', value);
  }

  get thuCycle(): boolean {
    return this.isTrue(this.updatedSettings.thuCycle);
  }

  set thuCycle(value: boolean) {
    this.setValue('thuCycle', value);
  }

  get friCycle(): boolean {
    return this.isTrue(this.updatedSettings.friCycle);
  }

  set friCycle(value: boolean) {
    this.setValue('friCycle', value);
  }

  get satCycle(): boolean {
    return this.isTrue(this.updatedSettings.satCycle);
  }

  set satCycle(value: boolean) {
    this.setValue('satCycle', value);
  }

  set schoolYearEditorInitialized(value: boolean) {
    this.setSchoolYearEditorInitialized(value);
  }

  get schoolYearEditorInitialized(): boolean {
    return this.$store.state.settings.schoolYearEditorInitialized;
  }

  get importLessons(): boolean {
    return this.isTrue(this.updatedSettings.importLessons);
  }

  set importLessons(value: boolean) {
    this.setValue('importLessons', value);
  }

  get importDays(): boolean {
    return this.isTrue(this.updatedSettings.importDays);
  }

  set importDays(value: boolean) {
    this.setValue('importDays', value);
  }

  get importDisplayDays(): boolean {
    return this.isTrue(this.updatedSettings.importDisplayDays);
  }

  set importDisplayDays(value: boolean) {
    this.setValue('importDisplayDays', value)
  }

  get importTimes(): boolean {
    return this.isTrue(this.updatedSettings.importTimes);
  }

  set importTimes(value: boolean) {
    this.setValue('importTimes', value);
  }

  get importTemplates(): boolean {
    return this.isTrue(this.updatedSettings.importTemplates);
  }

  set importTemplates(value: boolean) {
    this.setValue('importTemplates', value);
  }

  get importUnits(): boolean {
    return this.isTrue(this.updatedSettings.importUnits);
  }

  set importUnits(value: boolean) {
    this.setValue('importUnits', value);
  }

  get importAssignments(): boolean {
    return this.isTrue(this.updatedSettings.importAssignments);
  }

  set importAssignments(value: boolean) {
    this.setValue('importAssignments', value);
  }

  get importAssessments(): boolean {
    return this.isTrue(this.updatedSettings.importAssessments);
  }

  set importAssessments(value: boolean) {
    this.setValue('importAssessments', value);
  }

  get importStudents(): boolean {
    return this.isTrue(this.updatedSettings.importStudents);
  }

  set importStudents(value: boolean) {
    this.setValue('importStudents', value);
  }

  get isImportAllItems() {
    return this.importLessons && this.importDays && this.importDisplayDays && this.importTimes && this.importTemplates && this.importUnits && this.importAssessments && this.importAssignments && this.importStudents;
  }

  importAllItems() {
    this.importLessons = true;
    this.importDays = true;
    this.importDisplayDays = true;
    this.importTimes = true;
    this.importTemplates = true;
    this.importUnits = true;
    this.importAssessments = true;
    this.importAssignments = true;
    this.importStudents = true;
    this.importEvents = true;
  }

  get isImportNoItems() {
    return !this.importLessons && !this.importDays && !this.importDisplayDays && !this.importTimes && !this.importTemplates && !this.importUnits && !this.importAssessments && !this.importAssignments && !this.importStudents;
  }

  importNoItems() {
    this.importLessons = false;
    this.importDays = false;
    this.importDisplayDays = false;
    this.importTimes = false;
    this.importTemplates = false;
    this.importUnits = false;
    this.importAssessments = false;
    this.importAssignments = false;
    this.importStudents = false;
    this.importEvents = false;
  }

  get importEvents(): boolean {
    return this.isTrue(this.updatedSettings.importEvents);
  }

  set importEvents(value: boolean) {
    this.setValue('importEvents', value);
  }

  get minContainerHeight() {
    if (this.isModal) {
      if (this.userMode === 'T') {
        return '400px';
      } else {
        return '540px';
      }
    } else {
      return 'auto';
    }
  }

  get label() {
    let text = '';
    if (this.schoolYearEditorInitialized) {
      if (!this.yearId || this.yearId === 0) {
        text += this.$t('addLabel');
      } else {
        text += this.$t('editLabel');
      }
    }
    text += ' ' + this.$t('schoolYearLabel');
    return text;
  }

  get selectedLessonLayoutId() {
    return this.updatedSettings.currentLessonLayoutId;
  }

  set selectedLessonLayoutId(value: number) {
    this.setValue('currentLessonLayoutId', value);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  copyAllLocalClasses() {
    this.classIds = this.localClassesToCopy.map((c: any) => c.value);
  }

  copyNoLocalClasses() {
    this.classIds = [];
  }

  refreshKey() {
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  setValue(key: string, value: any): void {
    this.updatedSettings = ld.set(
      ld.cloneDeep(this.updatedSettings),
      key,
      value
    );
  }

  isValueTrue(key: string) {
    return this.isTrue(this.updatedSettings[key]);
  }

  getSchoolDayColor(key: string) {
    if (this.highContrastMode) {
      return this.isValueTrue(key) ? '#2C632C' : '#4F595F';
    } else {
      return this.isValueTrue(key) ? '#5cb85c' : '#5a656b';
    }
  }

  isTrue(value: any): boolean {
    return CommonUtils.isTrue(value);
  }

  prepareNoSchoolDetails() {
    const holidayTitle: Array<any> = [];
    const holidayStart: Array<any> = [];
    const holidayEnd: Array<any> = [];
    this.localSelectedHolidays.forEach(n => {
      if (n.checked) {
        holidayTitle.push(n.title);
        holidayStart.push(n.date);
        holidayEnd.push(n.date);
      }
    });

    this.localNoSchoolDays.forEach(n => {
      if (CommonUtils.isNotEmpty(n.startDate) || CommonUtils.isNotEmpty(n.endDate)) {
        n.startDate = n.startDate || n.endDate;
        n.endDate = n.endDate || n.startDate;
        holidayTitle.push(n.title);
        holidayStart.push(n.startDate);
        holidayEnd.push(n.endDate);
      }
    })
    this.setValue('holidayTitle', holidayTitle);
    this.setValue('holidayStart', holidayStart);
    this.setValue('holidayEnd', holidayEnd);
  }

  prepareSaveDetails() {
    const data = {
      lessonShift: false,
      lastDayError: false,
      lastDayWarning: false,
      cycleResetConfirmation: false,
      cycleResetWarning: false
    };

    this.setValue(
      'letterGrades',
      this.localLetterGrades.map((l: any) => {
        l.percent = l.percent || l.percentage;
        if (this.userMode === 'A') {
          delete l.letterGradeId;
        }
        delete l.yearId;
        return JSON.stringify(l);
      })
    );

    this.setValue(
      'gradePeriods',
      this.localGradePeriods.map((g: any) => {
        delete g.gradePeriodId;
        delete g.yearId;
        return JSON.stringify(g);
      })
    );

    this.setValue(
      'cycleDays',
      this.localCycleDayNames.map((c: any, i: any) => c.dayName || `Day ${i + 1}`)
    );

    const termName: Array<any> = [];
    const termFirstDay: Array<any> = [];
    const termStartsOn: Array<any> = [];
    this.localTerms
      .sort((a: any, b: any) => {
        return DateTimeUtils.daysDiff(b.firstDay, a.firstDay);
      })
      .forEach((item: any) => {
        if (this.classCycle !== 'oneWeek') {
          termName.push(item.termName);
          termFirstDay.push(item.firstDay);
          termStartsOn.push(item.startsOn);
        }
      });
    this.setValue('termName', termName);
    this.setValue('termFirstDay', termFirstDay);
    this.setValue('termStartsOn', termStartsOn);

    if (this.userMode === 'A') {
      this.setValue('adminYearId', this.yearId);
    }

    if (this.cycle === 'cycle') {
      this.classCycle = this.cycleDaysNum;
    }

    const gracePeriodDate = DateTimeUtils.currentDateAddDays(-7);
    if (this.schoolYear.yearId > 0) {
      if (
        (this.schoolYear.classCycle === 'oneWeek' && this.cycle !== 'one') ||
        (this.schoolYear.classCycle === 'twoWeeks' && this.cycle !== 'two') ||
        (!['oneWeek', 'twoWeeks'].includes(this.schoolYear.classCycle) &&
          this.cycle !== 'cycle') ||
        (this.cycle === 'cycle' &&
          this.schoolYear.classCycle !== this.cycleDaysNum)
      ) {
        data.lessonShift = true;
      }

      if (
        CommonUtils.hasText(this.schoolYear.firstDay) &&
        this.schoolYear.firstDay !== this.firstDay
      ) {
        data.lessonShift = true;
      }

      if (
        this.isTrue(this.sunCycle) !== this.sunCycle ||
        this.isTrue(this.monCycle) !== this.monCycle ||
        this.isTrue(this.tueCycle) !== this.tueCycle ||
        this.isTrue(this.wedCycle) !== this.wedCycle ||
        this.isTrue(this.thuCycle) !== this.thuCycle ||
        this.isTrue(this.friCycle) !== this.friCycle ||
        this.isTrue(this.friCycle) !== this.friCycle
      ) {
        data.lessonShift = true;
      }

      if (
        CommonUtils.hasText(this.schoolYear.lastDay) &&
        DateTimeUtils.daysDiff(this.lastDay, this.schoolYear.lastDay) > 0
      ) {
        if (DateTimeUtils.daysDiff(this.lastDay, gracePeriodDate) > 0) {
          data.lastDayError = true;
        } else if (this.userMode === 'T') {
          data.lastDayWarning = true;
        }
      }
    }

    const checkTerms = ld.cloneDeep(this.schoolYear.terms || []);
    if (CommonUtils.isNotEmpty(this.localTerms)) {
      for (const i in this.localTerms) {
        const term = this.localTerms[i];
        const checkTerm = checkTerms[i];
        let newOrChanged = false;
        if (CommonUtils.isEmpty(checkTerms) || !checkTerm) {
          newOrChanged = true;
        } else {
          if (term.firstDay !== checkTerm.firstDay) {
            newOrChanged = true;
          }
          if (term.startsOn !== checkTerm.startsOn) {
            newOrChanged = true;
          }
        }
        if (newOrChanged) {
          if (CommonUtils.isNotEmpty(checkTerms) && checkTerm) {
            if (
              DateTimeUtils.daysDiff(checkTerm.firstDay, gracePeriodDate) > 0
            ) {
              data.cycleResetConfirmation = true;
              break;
            }
          }
          if (DateTimeUtils.daysDiff(term.firstDay, gracePeriodDate) > 0) {
            data.cycleResetConfirmation = true;
            break;
          }
        }
      }
    }

    if (CommonUtils.isNotEmpty(checkTerms)) {
      for (let i = this.localTerms.length; i < checkTerms.length; i++) {
        const checkTerm = checkTerms[i];
        if (DateTimeUtils.daysDiff(checkTerm.firstDay, gracePeriodDate) > 0) {
          data.cycleResetConfirmation = true;
          break;
        } else {
          data.cycleResetWarning = true;
        }
      }
    }

    return Promise.resolve(data);
  }

  confirmCopyDisplayDays() {
    this.$refs.confirm.confirm({
      title: this.$t('displayDaysLabel'),
      text: this.$t('copyDisplayDaysMsg'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then(result => {
      if (result === 1) {
        this.copyDisplayDays();
      }
    });
  }

  copyDisplayDays() {
    const sunShowSchoolYears = {};
    const monShowSchoolYears = {};
    const tueShowSchoolYears = {};
    const wedShowSchoolYears = {};
    const thuShowSchoolYears = {};
    const friShowSchoolYears = {};
    const satShowSchoolYears = {};
    const sunShow = this.sunShow ? 'Y' : 'N';
    const monShow = this.monShow ? 'Y' : 'N';
    const tueShow = this.tueShow ? 'Y' : 'N';
    const wedShow = this.wedShow ? 'Y' : 'N';
    const thuShow = this.thuShow ? 'Y' : 'N';
    const friShow = this.friShow ? 'Y' : 'N';
    const satShow = this.satShow ? 'Y' : 'N';

    this.schoolYears.forEach((year: any) => {
      ld.set(sunShowSchoolYears, year.yearId, sunShow);
      ld.set(monShowSchoolYears, year.yearId, monShow);
      ld.set(tueShowSchoolYears, year.yearId, tueShow);
      ld.set(wedShowSchoolYears, year.yearId, wedShow);
      ld.set(thuShowSchoolYears, year.yearId, thuShow);
      ld.set(friShowSchoolYears, year.yearId, friShow);
      ld.set(satShowSchoolYears, year.yearId, satShow);
    });

    this.setValue('sunShowSchoolYears', sunShowSchoolYears);
    this.setValue('monShowSchoolYears', monShowSchoolYears);
    this.setValue('tueShowSchoolYears', tueShowSchoolYears);
    this.setValue('wedShowSchoolYears', wedShowSchoolYears);
    this.setValue('thuShowSchoolYears', thuShowSchoolYears);
    this.setValue('friShowSchoolYears', friShowSchoolYears);
    this.setValue('satShowSchoolYears', satShowSchoolYears);

    this.$eventBus.$emit('applyCopyDisplayDays');
  }

  validateSchoolYear(): Promise<any> {
    const form: any = this.$refs.form;
    this.error = this.validateForm();
    if (!form.validate() || CommonUtils.hasText(this.error)) {
      return Promise.reject(new FormError(this.error || ''));
    } else {
      return Promise.resolve();
    }
  }

  validateForm() {
    if (this.yearId === 0) {
      return '';
    }

    if (this.userMode === 'T' && this.yearNames.includes(this.yearName)) {
      return this.$t('yearErrorMsg1') as string;
    }

    if (this.cycle === 'cycle') {
      if (
        isNaN(+this.cycleDaysNum) ||
        CommonUtils.hasNoText(this.cycleDaysNum)
      ) {
        return this.$t('schoolYearErrorMsg1') as string;
      } else if (+this.cycleDaysNum < 2 || +this.cycleDaysNum > 20) {
        return this.$t('schoolYearErrorMsg2') as string;
      }
    }

    if (CommonUtils.hasNoText(this.firstDay)) {
      return this.$t('schoolYearErrorMsg5') as string;
    } else if (!DateTimeUtils.isDateFormatValid(this.firstDay)) {
      return this.$t('schoolYearErrorMsg6') as string;
    }

    if (CommonUtils.hasNoText(this.lastDay)) {
      return this.$t('schoolYearErrorMsg3') as string;
    } else if (!DateTimeUtils.isDateFormatValid(this.lastDay)) {
      return this.$t('schoolYearErrorMsg4') as string;
    }

    let numDays = DateTimeUtils.daysDiff(this.firstDay, this.lastDay);
    if (numDays < 0) {
      return this.$t('schoolYearErrorMsg7') as string;
    } else if (numDays > 365) {
      return this.$t('rangeCannotExceedOneYear') as string;
    }

    if (this.schoolYear.yearId > 0 && ['cycle', 'two'].includes(this.cycle)) {
      for (const i in this.classes) {
        const c = this.classes[i];
        if (!this.isTrue(c.useSchoolStart)) {
          numDays = DateTimeUtils.daysDiff(this.firstDay, c.cSd);
          if (numDays < 0) {
            return this.$t('schoolYearErrorMsg8') as string;
          } else if (numDays > 365) {
            return this.$t('schoolYearErrorMsg9') as string;
          }
        }
      }
    }

    if (CommonUtils.isNotEmpty(this.localTerms) && this.classCycle !== 'oneWeek') {
      for (const i in this.localTerms) {
        const term = this.localTerms[i];
        if (CommonUtils.hasNoText(term.firstDay)) {
          return this.$t('schoolYearErrorMsg0') as string;
        } else if (!DateTimeUtils.isDateFormatValid(term.firstDay)) {
          return this.$t('schoolYearErrorMsg0') as string;
        }
        numDays = DateTimeUtils.daysDiff(this.firstDay, term.firstDay);
        if (numDays < 0) {
          return this.$t('schoolYearErrorMsg0') as string;
        } else if (numDays > 365) {
          return this.$t('schoolYearErrorMsg14') as string;
        }
      }
    }

    if (this.userMode === 'A' || (this.userMode === 'T' && !this.hasAdminLetterGrades)) {
      for (const i in this.localLetterGrades) {
        const letterGrade = this.localLetterGrades[i];
        let percentage = letterGrade.percent || letterGrade.percentage;
        if (percentage) percentage += '';
        if (CommonUtils.hasNoText(letterGrade.name) || (percentage !== 0 && CommonUtils.hasNoText(percentage)) || isNaN(+percentage) || +percentage > 100 || +percentage < 0) {
          return this.$t('letterGradesErrorMsg0') as string;
        }
      }
    }

    if (this.userMode === 'A' || (this.userMode === 'T' && !this.hasAdminGradePeriods)) {
      for (const i in this.localGradePeriods) {
        const period = this.localGradePeriods[i];
        if (CommonUtils.hasNoText(period.startDate) || !DateTimeUtils.isDateFormatValid(period.startDate) || CommonUtils.hasNoText(period.endDate) ||
          !DateTimeUtils.isDateFormatValid(period.endDate) || CommonUtils.hasNoText(period.name)) {
          return this.$t('gradingPeriodErrorMsg0') as string;
        }
      }
      for (let i = 0; i < this.localGradePeriods.length; i++) {
        for (let j = 0; j < i; j++) {
          if (DateTimeUtils.isBetween(this.localGradePeriods[j].startDate, this.localGradePeriods[i].startDate, this.localGradePeriods[i].endDate) || DateTimeUtils.isBetween(this.localGradePeriods[j].endDate, this.localGradePeriods[i].startDate, this.localGradePeriods[i].endDate) || DateTimeUtils.isBetween(this.localGradePeriods[i].startDate, this.localGradePeriods[j].startDate, this.localGradePeriods[j].endDate)) {
            return this.$t('gradingPeriodErrorMsg2') as string;
          }
        }
      }
    }

    if (!this.sunCycle && !this.monCycle && !this.tueCycle && !this.wedCycle && !this.thuCycle && !this.friCycle && !this.satCycle) {
      return this.$t('schoolYearErrorMsg16') as string;
    }

    if (CommonUtils.hasText(this.schoolStartTime) && !DateTimeUtils.isTimeFormatValid(this.schoolStartTime)) {
      return this.$t('enterValidStartTime2') as string;
    } else if (CommonUtils.hasText(this.schoolEndTime) && !DateTimeUtils.isTimeFormatValid(this.schoolEndTime)) {
      return this.$t('enterValidEndTime2') as string;
    } else if (CommonUtils.hasText(this.schoolStartTime) && CommonUtils.hasText(this.schoolEndTime) && moment(this.schoolEndTime, 'HH:mm A').isBefore(moment(this.schoolStartTime, 'HH:mm A'))) {
      return this.$t('schoolLabel') + ' ' + this.$t('endTimeMustBeAfterStartTimeMsg') as string;
    }

    return '';
  }

  async confirmModifyYear(data: any): Promise<any> {
    if (this.schoolYear.yearId === 0 && this.userMode === 'A') {
      return Promise.resolve();
    } else if (!data.lastDayError && data.cycleResetConfirmation) {
      const title = this.$t('schoolYearChangeLabel');
      return this.$refs.confirm
        .confirm({
          title,
          text: this.$t('schoolYearMsg2'),
          option1ButtonAlternativeText: this.$t('addNewYearLabel')
        }).then(async result => {
          if (result === 1) {
            const suffix = ' (' + this.$t('newLabel') + ')';
            if (this.userMode === 'A') {
              this.yearName = this.yearName + suffix;
              this.setValue('adminYearId', 0);
            } else {
              this.newSchoolYearText = this.yearName + suffix;
              return this.$refs.prompt
                .confirm({
                  title: this.$t('addLabel') + ' ' + this.$t('yearLabel'),
                  text: this.$t('addSchoolYearMsg'),
                  option1ButtonAlternativeText: this.$t('okLabel')
                })
                .then(async (data: any) => {
                  if (data === 1) {
                    if (CommonUtils.hasNoText(this.newSchoolYearText)) {
                      this.error = this.$t('schoolYearErrorMsg10') as string;
                      return Promise.reject(new FormError(this.error));
                    } else if (this.yearNames.includes(this.newSchoolYearText)) {
                      this.error = this.$t('yearErrorMsg1') as string;
                      return Promise.reject(new FormError(this.error));
                    } else {
                      this.newSchoolYear = true;
                      this.yearName = this.newSchoolYearText;
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject(new FormError());
                  }
                });
            }
            return Promise.resolve();
          } else {
            return Promise.reject(new FormError());
          }
        });
    } else {
      const title = this.$t('schoolYearChangeLabel');
      let message;
      let okButtonText;
      let neutralButtonText;
      if (data.lastDayError) {
        message = this.$t('schoolYearMsg1');
        okButtonText = this.$t('addNewYearLabel');
      } else if (data.lessonShift) {
        const currentDate = DateTimeUtils.formatDate(moment().toDate(), 'MM/DD/YYYY');
        const gracePeriodDays = this.schoolYear.schoolId > 0 && this.schoolYear.yearId > 0 ? 30 : 7;
        if (
          DateTimeUtils.daysDiff(this.schoolYear.firstDay, currentDate) > gracePeriodDays &&
          DateTimeUtils.daysDiff(this.schoolYear.created, currentDate) > gracePeriodDays
        ) {
          message = this.$t('schoolYearMsg3', { days: gracePeriodDays });
          okButtonText = this.$t('addNewYearLabel');
        } else {
          message = this.$t('schoolYearMsg4');
          okButtonText = this.$t('addNewYearLabel');
          neutralButtonText = this.$t('modifyExistingYearLabel');
        }
      } else if (data.lastDayWarning) {
        message = this.$t('schoolYearMsg5');
        okButtonText = this.$t('addNewYearLabel');
        neutralButtonText = this.$t('modifyExistingYearLabel');
      } else if (data.cycleResetWarning) {
        message = this.$t('schoolYearMsg4');
        okButtonText = this.$t('addNewYearLabel');
        neutralButtonText = this.$t('modifyExistingYearLabel');
      }
      if (CommonUtils.hasText(message)) {
        return this.$refs.confirm
          .confirm({
            title,
            text: message,
            option1ButtonAlternativeText: okButtonText,
            option2ButtonAlternativeText: neutralButtonText
          })
          .then(async result => {
            if (result === 2) {
              return Promise.resolve();
            } else if (result === 1) {
              const suffix = ' (' + this.$t('newLabel') + ')';
              if (this.userMode === 'A') {
                this.yearName = this.yearName + suffix;
                this.setValue('adminYearId', 0);
              } else {
                this.newSchoolYearText = this.yearName + suffix;
                return this.$refs.prompt
                  .confirm({
                    title: this.$t('addLabel') + ' ' + this.$t('yearLabel'),
                    text: this.$t('addSchoolYearMsg'),
                    option1ButtonAlternativeText: this.$t('okLabel')
                  })
                  .then(async (data: any) => {
                    if (data === 1) {
                      if (CommonUtils.hasNoText(this.newSchoolYearText)) {
                        this.error = this.$t('schoolYearErrorMsg10') as string;
                        return Promise.reject(new FormError(this.error));
                      } else if (this.yearNames.includes(this.newSchoolYearText)) {
                        this.error = this.$t('yearErrorMsg1') as string;
                        return Promise.reject(new FormError(this.error));
                      } else {
                        this.newSchoolYear = true;
                        this.yearName = this.newSchoolYearText;
                        return Promise.resolve();
                      }
                    } else {
                      return Promise.reject(new FormError());
                    }
                  });
              }
              return Promise.resolve();
            } else {
              return Promise.reject(new FormError());
            }
          });
      } else {
        return Promise.resolve();
      }
    }
  }

  async confirmSchoolYearType(): Promise<any> {
    this.schoolId = this.schoolYearCurrentSchoolId;
    this.districtId = this.userDistrictId;
    if (this.userType === 'D') {
      return this.$refs.confirm
        .confirm({
          title: this.$t('schoolYearLabel'),
          text: this.$t('schoolYearMsg6'),
          option1ButtonAlternativeText: this.$t('saveToLabel', {
            text: this.$t('schoolLabel')
          }),
          option2ButtonAlternativeText: this.$t('saveToLabel', {
            text: this.$t('districtLabel')
          })
        })
        .then(result => {
          if (result === 2) {
            this.setValue('ayType', 'D');
          } else if (result === 1) {
            this.setValue('ayType', 'S');
          } else {
            return Promise.reject(new FormError());
          }
        });
    } else if (this.userMode === 'A') {
      this.setValue('ayType', 'S');
    }
    return Promise.resolve();
  }

  async verifyShiftLessons(): Promise<any> {
    if (this.userMode === 'T') {
      return this.verifySchoolYear().then(async data => {
        if (!data.isValid && CommonUtils.hasText(data.message)) {
          return this.$refs.confirm
            .confirm({
              title: this.$t('schoolYearLabel'),
              text: data.message,
              option1ButtonAlternativeText: this.$t('continueLabel')
            })
            .then(result => {
              if (result === 1) {
                return Promise.resolve();
              } else {
                return Promise.reject(new FormError());
              }
            });
        } else {
          return Promise.resolve();
        }
      });
    }
    return Promise.resolve();
  }

  async doAddSchoolYear() {
    return this.addSchoolYear().then(async (resp: any) => {
      if (resp.data.timedout) {
        return this.$refs.confirm.alert({
          title: this.$t('addSchoolYearLabel'),
          text: this.$t('addSchoolYearMsg2', { yearName: resp.data.yearName }),
          option1ButtonAlternativeText: this.$t('okLabel')
        }).then(() => {
          return Promise.resolve(resp);
        });
      } else {
        return Promise.resolve(resp);
      }
    });
  }

  async saveNewSchoolYear() {
    let newYear: any;
    return this.validateSchoolYear()
      .then(this.prepareNoSchoolDetails)
      .then(this.prepareSaveDetails)
      .then(async () => {
        return this.doAddSchoolYear().then((data) => {
          if (!data.data.timedout) {
            const newYearId = parseInt(data.data.currentYearId);
            newYear = data.data.years.find((y: any) => +y.yearId === newYearId)
          }
          return Promise.resolve(data);
        });
      })
      .then((resp: any) => {
        if (this.showSnackbarNotifications && !resp?.data.timedout) {
          this.$snotify.success(this.$t('schoolYearAddedLabel') as string);
        }
        if (this.userMode === 'A') {
          return Promise.resolve();
        } else {
          return this.reloadSettings();
        }
      })
      .then(async () => {
        if (this.userMode === 'A') {
          return Promise.resolve();
        } else {
          this.setHaveClass(false);
          return this.loadClasses().then(() => {
            return this.reloadPlans();
          });
        }
      })
      .then(() => {
        if (!this.isModal) {
          this.$eventBus.$emit('closeSubPage');
          this.$eventBus.$emit('newSchoolYearCreated', newYear)
        }
        this.newSchoolYear = false;
      });
  }

  async save() {
    const isNew = !this.yearId || this.yearId === 0;
    return this.validateSchoolYear()
      .then(this.prepareSaveDetails)
      .then(this.confirmModifyYear)
      .then(this.confirmSchoolYearType)
      .then(this.verifyShiftLessons)
      .then(() => {
        this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', true) : CommonUtils.showLoading();
        if (this.userMode === 'A') {
          return this.saveAdminYear();
        } else if (this.newSchoolYear) {
          this.setValue(
            'classIds',
            this.localClassesToCopy.map(c => c.value)
          );
          this.setValue('importLessons', true);
          this.setValue('importDays', true);
          this.setValue('importDisplayDays', true);
          this.setValue('importTimes', true);
          this.setValue('importTemplates', true);
          this.setValue('importUnits', true);
          this.setValue('importAssignments', true);
          this.setValue('importAssessments', true);
          this.setValue('importStudents', true);
          this.setValue('importEvents', true);
          return this.doAddSchoolYear();
        } else {
          if (!this.hasAdminLetterGrades) {
            this.updateLetterGrades({ yearId: this.yearId, letterGrades: this.localLetterGrades })
          }
          if (!this.hasAdminGradePeriods) {
            this.setGradePeriods({ yearId: this.yearId, gradePeriods: this.localGradePeriods })
          }
          this.setValue('localSelectedSchoolYearId', this.currentYear.yearId);
          return this.doSave();
        }
      })
      .then(() => {
        if (this.showSnackbarNotifications) {
          if (isNew) {
            this.$snotify.success(this.$t('schoolYearAddedLabel') as string);
          } else {
            this.$snotify.success(this.$t('schoolYearUpdateLabel') as string);
          }
        }
        if (this.userMode === 'A') {
          return Promise.resolve();
        } else {
          return this.reloadSettings();
        }
      })
      .then(async () => {
        if (this.userMode === 'A') {
          return Promise.resolve();
        } else {
          this.setHaveClass(false);
          return this.loadClasses().then(() => {
            return this.reloadPlans();
          });
        }
      })
      .then(() => {
        this.newSchoolYear = false;
      });
  }

  get cycle(): string {
    return this.updatedSettings.cycle || 'one';
  }

  set cycle(value: string) {
    this.setValue('cycle', value);
  }

  get cycleDaysNum(): string {
    return this.updatedSettings.cycleDaysNum || 2;
  }

  set cycleDaysNum(value: string) {
    this.setValue('cycleDaysNum', value);
  }

  get classCycle(): string {
    return this.updatedSettings.classCycle;
  }

  set classCycle(value: string) {
    this.setValue('classCycle', value);
  }

  get firstDayClone(): string {
    return ld.cloneDeep(this.firstDay) || '';
  }

  get lastDayClone(): string {
    return ld.cloneDeep(this.lastDay) || '';
  }

  get schoolStartTime(): string {
    return this.updatedSettings.schoolStartTime || '';
  }

  set schoolStartTime(value: string) {
    this.setValue('schoolStartTime', value);
  }

  get schoolEndTime(): string {
    return this.updatedSettings.schoolEndTime || '';
  }

  set schoolEndTime(value: string) {
    this.setValue('schoolEndTime', value);
  }

  get scheduleType() {
    if (
      this.classCycle === 'oneWeek' ||
      CommonUtils.hasNoText(this.classCycle)
    ) {
      return 'One Week';
    } else if (this.classCycle === 'twoWeeks') {
      return 'Two Weeks';
    } else {
      return `${this.cycleDaysNum} Day Cycle`;
    }
  }

  get localGradePeriods() {
    return this.$store.state.grades.localGradePeriods;
  }

  set localGradePeriods(periods: any) {
    this.$store.commit('grades/setLocalGradePeriods', periods);
  }

  get originalGradePeriods() {
    return this.$store.state.grades.origGradePeriods;
  }

  set originalGradePeriods(periods: any) {
    this.$store.commit('grades/setOriginalGradePeriods', periods);
  }

  get localLetterGrades() {
    return this.$store.state.grades.localLetterGrades;
  }

  set localLetterGrades(grades: any) {
    this.$store.commit('grades/setLocalLetterGrades', grades);
  }

  get originalLetterGrades() {
    return this.$store.state.grades.origLetterGrades;
  }

  set originalLetterGrades(grades: any) {
    this.$store.commit('grades/setOriginalLetterGrades', grades);
  }

  get localCycleDayNames() {
    return this.updatedSettings.cycleDayNames;
  }

  set localCycleDayNames(value: any) {
    this.setValue('cycleDayNames', value);
  }

  get localTerms() {
    return this.updatedSettings.terms;
  }

  set localTerms(terms: any) {
    this.setValue('terms', terms);
  }

  addGradingPeriod() {
    this.localGradePeriods.push({
      name: '',
      startDate: '',
      endDate: ''
    });
    this.showPanel('gradingPeriods');
  }

  addLetterGrade() {
    this.localLetterGrades.push({
      name: '',
      percentage: ''
    });
    this.showPanel('letterGrades');
  }

  addCycleReset() {
    this.localTerms.push({
      termName: '',
      firstDay: '',
      startsOn: 0
    });
    this.showPanel('cycleResets');
  }

  showPanel(key: string) {
    this.$refs.panels.$children.forEach((c: Vue, i: number) => {
      if (c.$vnode.key === key) {
        if (!this.panels.includes(i)) {
          this.panels.push(i);
        }
        return true;
      }
    })
  }

  removeGradingPeriod(index: number) {
    this.$delete(this.localGradePeriods, index);
  }

  removeLetterGrade(index: number) {
    this.$delete(this.localLetterGrades, index);
  }

  removeCycleReset(index: number) {
    this.$delete(this.localTerms, index);
  }

  removeNoSchoolDay(index: number) {
    this.$delete(this.localNoSchoolDays, index);
  }

  get firstAndLastDay() {
    return `${DateTimeUtils.formatToShow(
      this.firstDay
    )} - ${DateTimeUtils.formatToShow(this.lastDay)}`;
  }

  get startAndEndTime() {
    if (
      CommonUtils.hasText(this.schoolStartTime) ||
      CommonUtils.hasText(this.schoolEndTime)
    ) {
      return `${this.schoolStartTime} - ${this.schoolEndTime}`;
    }
    return '';
  }

  get hasStartAndEndTime() {
    return CommonUtils.hasText(this.startAndEndTime);
  }

  get isDirty(): boolean {
    return !ld.isEqual(this.localOrigSettings, this.updatedSettings) || this.gradesDirty;
  }

  get gradesDirty() {
    return !ld.isEqual(this.localGradePeriods, this.originalGradePeriods) || !ld.isEqual(this.localLetterGrades, this.originalLetterGrades);
  }

  get isNotDirty() {
    return !this.isDirty;
  }

  get editorLabel() {
    let prefix = '';
    if (this.schoolYearEditorInitialized) {
      if (this.yearId === 0) {
        prefix += this.$t('addLabel');
      } else {
        prefix += this.$t('editLabel');
      }
      prefix += ' ';
    }
    return prefix + this.$t('schoolYearLabel');
  }

  get canGoBack() {
    return (this.hasSelectAdminYears && this.step > 1) || this.step > 2;
  }

  get canContinue() {
    return this.step < 4;
  }

  get isCurrentYearDateOnOrWithinSourceYearDate():boolean {
    const parentYear = this.selectAdminYears.find(year => year.parentYearId === this.localParentYearId) || {};
    const parentFirstDay = parentYear.firstDay || '';
    const parentLastDay = parentYear.lastDay || '';
    if (!DateTimeUtils.isThisDateBeforeThatDate(DateTimeUtils.formatToSave(this.firstDay), parentFirstDay) && !DateTimeUtils.isThisDateAfterThatDate(DateTimeUtils.formatToSave(this.lastDay), parentLastDay)) {
      return true;
    } else {
      this.importEvents = false;
      return false;
    }
  }

  resetValidation() {
    this.$refs.form.resetValidation();
    this.$refs.schoolYearDate.resetValidation();
  }

  validate() {
    this.error = '';
    const isValid = this.$refs.form.validate();
    return this.validateSchoolYearDate() && isValid;
  }

  validateSchoolYearDate() {
    return !this.$refs.schoolYearDate || this.$refs.schoolYearDate.validate();
  }

  async continue(): Promise<boolean> {
    if (this.validate()) {
      this.setValue('uuid', CommonUtils.generateUUID());
      if (this.step < 3) {
        this.step++;
        return this.slide(true).then(() => {
          return Promise.resolve(true);
        });
      } else {
        this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', true) : CommonUtils.showLoading();
        return this.saveNewSchoolYear()
          .then(() => {
            if (this.localPage === 'school-years') this.$store.commit('settings/setSchoolYearListLoading', false);
            this.$eventBus.$emit('closeSubPage');
            return Promise.resolve(false);
          })
      }
    }
    return Promise.resolve(true);
  }

  back() {
    this.error = '';
    this.step--;
    return this.slide(false);
  }

  slide(left: boolean) {
    this.slideLeft = left;
    const deferred = new Deferred();
    const that = this;
    this.sliding = true;
    setTimeout(function() {
      that.sliding = false;
      deferred.resolve();
    }, 350);
    return deferred.promise;
  }

  @Watch('cycle')
  onCycleChanged() {
    if (this.schoolYearEditorInitialized && !this.schoolYearListLoading) {
      if (this.cycle === 'one' || CommonUtils.hasNoText(this.cycle)) {
        this.classCycle = 'oneWeek';
      } else if (this.cycle === 'two') {
        this.classCycle = 'twoWeeks';
      } else {
        this.classCycle = '' + this.cycleDaysNum;
      }
    }
  }

  @Watch('cycleDaysNum')
  onCycleDaysNumChanged() {
    if (this.schoolYearEditorInitialized && this.cycle === 'cycle') {
      this.classCycle = '' + this.cycleDaysNum;
    }
  }

  beforeDestroy() {
    this.schoolYearEditorInitialized = false;
  }

  initExpandedPanels() {
    this.panels = [0, 1, 2, 3, 4, 5];
  }

  @Watch('localParentYearId')
  onLocalParentYearChange() {
    if (this.yearId === 0 && this.userMode === 'T') {
      this.parentYearId = this.localParentYearId;
      this.initSchoolYear(
        this.selectAdminYears.find(s => s.parentYearId === this.parentYearId)
      );
    }
  }

  @Watch('input')
  doInit() {
    if (!this.isActive) return;
    if (this.input.loadData === false) {
      this.step = this.hasSelectAdminYears ? 1 : 2;
      this.$nextTick(() => {
        this.schoolYearEditorInitialized = true;
      })
      return this.loadLayoutsGeneral();
    }
    this.schoolYearEditorInitialized = false;
    Promise.resolve()
      .then(() => {
        this.initSchoolYear(this.schoolYear);
        if (this.hasSelectAdminYears && this.yearId === 0 && this.userMode === 'T') this.localParentYearId = this.selectAdminYears[0].parentYearId;
        return Promise.resolve();
      })
      .then(() => {
        if (this.userMode === 'T') {
          if (this.schoolYear.yearId === 0) {
            this.localLetterGrades = [];
            this.hasAdminLetterGrades = false;
            return Promise.resolve();
          }
          return this.loadYearsLetterGrades({ yearId: this.schoolYear.yearId }).then((d) => {
            this.hasAdminLetterGrades = d.haveAdminLetterGrades;
            this.localLetterGrades = d.letterGrades;
          });
        } else {
          return Promise.resolve();
        }
      })
      .then(async () => {
        if (this.userMode === 'T') {
          if (this.schoolYear.yearId === 0) {
            this.localGradePeriods = [];
            this.hasAdminGradePeriods = false;
            return Promise.resolve();
          }
          return this.loadYearsGradePeriods({ yearId: this.schoolYear.yearId }).then((d) => {
            this.hasAdminGradePeriods = d.haveAdminGradingPeriods;
            this.localGradePeriods = d.gradingPeriods;
          });
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        this.initExpandedPanels();
        this.schoolYearEditorInitialized = true;
        return Promise.resolve();
      }).then(async () => {
        return this.loadLayouts().then((res) => {
          this.localLessonLayoutsOrig = this.userMode === 'T' ? ld.cloneDeep(res.data.lessonlayouts) : (this.userMode === 'A' && this.userType === 'D' ? ld.cloneDeep(res.data.lessonlayouts.filter((layout: any) => layout.teacherId === 0)) : ld.cloneDeep(res.data.lessonlayouts.filter((layout: any) => layout.teacherId === 0 && layout.districtId === 0)));
          if (this.hasSelectAdminYears && this.yearId === 0 && this.parentYearId !== 0 && this.$currentUser.isTeacher) {
            this.initLessonLayouts(this.selectAdminYears.find(s => s.parentYearId === this.parentYearId));
          } else {
            this.initLessonLayouts(this.schoolYear);
          }
        });
      })
      .finally(() => {
        this.localOrigSettings = ld.cloneDeep(this.updatedSettings);
        this.originalGradePeriods = ld.cloneDeep(this.localGradePeriods);
        this.originalLetterGrades = ld.cloneDeep(this.localLetterGrades);
        CommonUtils.hideLoading();
      });
  }

  loadLayoutsGeneral() {
    this.loadLayouts().then((res) => {
      this.localLessonLayoutsOrig = this.userMode === 'T' ? ld.cloneDeep(res.data.lessonlayouts) : (this.userMode === 'A' && this.userType === 'D' ? ld.cloneDeep(res.data.lessonlayouts.filter((layout: any) => layout.teacherId === 0)) : ld.cloneDeep(res.data.lessonlayouts.filter((layout: any) => layout.teacherId === 0 && layout.districtId === 0)));
      this.localLessonLayouts = ld.cloneDeep(this.localLessonLayoutsOrig);
      this.localLessonLayouts.forEach((item: any) => {
        if (item.lessonLayoutId === -1) {
          item.lessonLayoutId = 0;
          if (this.schoolYear.yearId === 0 && this.schoolYear.parentYearId === 0) {
            item.text = 'Planbook Default';
          } else {
            item.text = this.schoolYear.yearName + ' Default';
          }
        } else {
          item.text = item.name;
        }
        item.value = item.lessonLayoutId;
      });
    });
  }

  @Watch('firstDay')
  @Watch('lastDay')
  onDateRangeChange() {
    this.localSelectedHolidays = this.holidays
      .filter(h => {
        return DateTimeUtils.isBetween(h.date, this.firstDay, this.lastDay);
      })
      .map(h => {
        const holiday = ld.cloneDeep(h);
        holiday.checked = true;
        holiday.label = `${holiday.title}, ${holiday.date}`;
        return holiday;
      });
    this.localNoSchoolDays = [];
    this.addNoSchoolDay('Winter Break');
    this.addNoSchoolDay('Spring Break');
    this.addNoSchoolDay('Planning Day - No School');
    this.addNoSchoolDay('Planning Day - No School');
  }

  addNoSchoolDay(title: string) {
    this.localNoSchoolDays.push({
      title: title || '',
      startDate: '',
      endDate: ''
    });
  }

  initSchoolYear(schoolYear: any) {
    const sy = ld.cloneDeep(schoolYear);
    if (sy.classCycle === 'oneWeek' || CommonUtils.hasNoText(sy.classCycle)) {
      sy.cycle = 'one';
      sy.classCycle = 'oneWeek';
      sy.cycleDaysNum = '2';
    } else if (sy.classCycle === 'twoWeeks') {
      sy.cycle = 'two';
      sy.cycleDaysNum = '2';
    } else {
      sy.cycle = 'cycle';
      sy.cycleDaysNum = sy.classCycle;
    }
    this.updatedSettings = sy;
    if (CommonUtils.isNotEmpty(sy.cycleDayNames)) {
      this.localCycleDayNames = ld.cloneDeep(sy.cycleDayNames);
    } else {
      this.localCycleDayNames = ld
        .cloneDeep(sy.dayNames || [])
        .map((n: any, i: number) => {
          return { dayName: n.dayName || `Day ${i + 1}` };
        });
    }
    this.localTerms = ld.cloneDeep(sy.terms || []);
    this.localGradePeriods = ld.cloneDeep(sy.gradePeriods || []);
    if (this.userMode === 'A') {
      this.localLetterGrades = ld.cloneDeep(sy.letterGrades || []);
    }
    this.localClassesToCopy = ld.cloneDeep(
      this.classItems.filter(c => !c.isGoogleClass)
    );
    this.classIds = this.localClassesToCopy.map(c => c.value);
    this.initLessonLayouts(sy);
    this.step = this.hasSelectAdminYears ? 1 : 2;
  }

  initLessonLayouts(schoolYear: any) {
    this.localLessonLayouts = ld.cloneDeep(this.localLessonLayoutsOrig);
    if (this.localLessonLayouts && this.localLessonLayouts.length > 0) {
      this.setValue('currentLessonLayoutId', schoolYear.lessonLayoutId > 0 && schoolYear.parentYearId !== 0 ? schoolYear.lessonLayoutId : 0);
      this.localLessonLayouts.forEach((item: any) => {
        if (item.lessonLayoutId === -1) {
          item.lessonLayoutId = 0;
          if (schoolYear.yearId === 0 && schoolYear.parentYearId === 0) {
            item.text = 'Planbook Default';
          } else {
            item.text = schoolYear.yearName + ' Default';
          }
        } else {
          item.text = item.name;
        }
        item.value = item.lessonLayoutId;
        if (this.input.data && this.input.data.lessonLayoutId === item.lessonLayoutId) {
          this.setValue('currentLessonLayoutId', item.lessonLayoutId);
        }
      });
    }
  }

  created() {
    this.doInit();
  }

  mounted() {
    const that = this;
    this.$nextTick(() => {
      this.$eventBus.$on('openManageLesson', () => {
        that.showManageLessons = true;
      });
      this.$eventBus.$on('openImportLessons', () => {
        CommonUtils.showLoading();
        that.getSharedClasses({
          sharedUserEmail: that.emailAddress,
          sharedUserKey: that.userId
        }).then((resp) => {
          const data = resp.data;
          that.localSharedYears = data.years || [];
          CommonUtils.hideLoading();
          that.showImportLessons = true;
        });
      });
    });
  }

  destroyed() {
    this.$eventBus.$off('openManageLesson');
    this.$eventBus.$off('openImportLessons');
  }
}
