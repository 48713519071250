var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLessonNav && !_vm.$vuetify.breakpoint.smAndDown
    ? _c(
        "base-modal-lesson-nav",
        {
          attrs: {
            input: _vm.input,
            onApply: _vm.doApply,
            applying: _vm.saving,
            onClose: _vm.doOnClose,
            cardClass: "lesson-editor-card",
            withApply: !_vm.localIsReadonly
          },
          on: { left: _vm.navigateLeft, right: _vm.navigateRight },
          scopedSlots: _vm._u(
            [
              {
                key: "action-button",
                fn: function() {
                  return [
                    _vm.stackMode
                      ? _c(
                          "pb-btn",
                          {
                            attrs: { icon: "", label: _vm.$t("tabModeLabel") },
                            on: { click: _vm.switchToTabMode }
                          },
                          [_c("v-icon", [_vm._v("fal fa-folder")])],
                          1
                        )
                      : _c(
                          "pb-btn",
                          {
                            attrs: {
                              icon: "",
                              label: _vm.$t("stackModeLabel")
                            },
                            on: { click: _vm.switchToStackMode }
                          },
                          [_c("v-icon", [_vm._v("fal fa-list-dropdown")])],
                          1
                        ),
                    !_vm.localIsReadonly && !_vm.editLink
                      ? _c("lesson-actions", {
                          attrs: { type: "button", input: _vm.getLessonInput() }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              {
                key: "header",
                fn: function() {
                  return [
                    _vm.isCalledByLessonBanks
                      ? _c(
                          "div",
                          [
                            _vm.input.action === "A"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("addLabel") +
                                        " " +
                                        _vm.$t("lessonLabel")
                                    )
                                  )
                                ])
                              : _c("lesson-editor-header")
                          ],
                          1
                        )
                      : _c("lesson-editor-header")
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3120580635
          ),
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _c("lesson-editor-form", {
            ref: "form",
            attrs: { input: _vm.input, isModal: _vm.isModal }
          }),
          _c("pb-confirm", { ref: "confirm" })
        ],
        1
      )
    : _c(
        "pb-base-editor",
        {
          attrs: {
            isModal: _vm.isModal,
            input: _vm.input,
            onApply: _vm.doApply,
            applying: _vm.saving,
            cardClass: "lesson-editor-card",
            withApply: !_vm.localIsReadonly,
            typeLabel: _vm.$t("lessonLabel"),
            isDirty: _vm.isDirty
          },
          on: { close: _vm.closeEditor },
          nativeOn: {
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "arrow-down",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.nextLesson()
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "arrow-up",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.previousLesson()
              }
            ]
          },
          scopedSlots: _vm._u([
            {
              key: "action-button",
              fn: function() {
                return [
                  _vm.stackMode
                    ? _c(
                        "pb-btn",
                        {
                          attrs: { icon: "", label: _vm.$t("tabModeLabel") },
                          on: { click: _vm.switchToTabMode }
                        },
                        [_c("v-icon", [_vm._v("fal fa-folder")])],
                        1
                      )
                    : _c(
                        "pb-btn",
                        {
                          attrs: { icon: "", label: _vm.$t("stackModeLabel") },
                          on: { click: _vm.switchToStackMode }
                        },
                        [_c("v-icon", [_vm._v("fal fa-list-dropdown")])],
                        1
                      ),
                  !_vm.localIsReadonly && !_vm.editLink
                    ? _c("lesson-actions", {
                        attrs: { type: "button", input: _vm.getLessonInput() }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "header",
              fn: function() {
                return [
                  _vm.input.lessonListsView
                    ? _c(
                        "div",
                        [
                          _vm.input.action === "A"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("addLabel") +
                                      " " +
                                      _vm.$t("lessonLabel")
                                  )
                                )
                              ])
                            : _c(
                                "v-row",
                                {
                                  staticClass: "d-flex flex-nowrap",
                                  staticStyle: { "white-space": "nowrap" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "text-left" },
                                    [
                                      _c(
                                        "pb-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            label: _vm.$t("previousLabel")
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.previousLesson.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.previousLesson.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fal fa-angle-left")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center my-auto",
                                      staticStyle: { overflow: "hidden" }
                                    },
                                    [_c("lesson-editor-header")],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "pb-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            label: _vm.$t("nextLabel")
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.nextLesson.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.nextLesson.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fal fa-angle-right")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _c("lesson-editor-header")
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _c("lesson-editor-form", {
            ref: "form",
            attrs: { input: _vm.input, isModal: _vm.isModal }
          }),
          _c("pb-confirm", { ref: "confirm" })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }