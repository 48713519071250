var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isModal
    ? _c(
        "pb-base-modal",
        {
          attrs: {
            input: _vm.input,
            onClose: _vm.doOnClose,
            cardClass: "lesson-view-mode-card",
            withApply: false,
            persistent: _vm.$vuetify.breakpoint.smAndDown
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "v-row",
                      { staticStyle: { "white-space": "nowrap" } },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "pb-btn",
                              {
                                attrs: {
                                  icon: "",
                                  label: _vm.$t("previousLabel")
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.previousLesson.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("fal fa-angle-left")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "auto" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "lesson-view-mode-header" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isLessonBanks
                                      ? _vm.localHeaderName
                                      : _vm.stringifyDate()
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "pb-btn",
                              {
                                attrs: { icon: "", label: _vm.$t("nextLabel") },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.nextLesson.apply(null, arguments)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("fal fa-angle-right")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2472286521
          ),
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _c("perfect-scrollbar", [
            _c(
              "div",
              { staticClass: "plans" },
              [
                _c("section-card", {
                  key: "calendar-day-object",
                  attrs: {
                    dayObject: _vm.dayObject,
                    showDetails: _vm.showDetails
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    : _c(
        "pb-base-sub-page",
        {
          attrs: {
            input: _vm.input,
            onClose: _vm.doOnClose,
            cardClass: "lesson-view-mode-card",
            withApply: false,
            subpage: true,
            expandable: false,
            withActions: false
          },
          nativeOn: {
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "arrow-down",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.nextLesson.apply(null, arguments)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "arrow-up",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.previousLesson.apply(null, arguments)
              }
            ]
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "pb-btn",
                            {
                              attrs: {
                                icon: "",
                                label: _vm.$t("previousLabel")
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.previousLesson.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("fal fa-angle-left")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "auto" } },
                        [
                          _c(
                            "div",
                            { staticClass: "lesson-view-mode-header" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isLessonBanks
                                    ? _vm.localHeaderName
                                    : _vm.stringifyDate()
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "pb-btn",
                            {
                              attrs: { icon: "", label: _vm.$t("nextLabel") },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.nextLesson.apply(null, arguments)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("fal fa-angle-right")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.localValue,
            callback: function($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue"
          }
        },
        [
          _c("perfect-scrollbar", [
            _c(
              "div",
              { staticClass: "plans" },
              [
                _c("section-card", {
                  key: "calendar-day-object",
                  attrs: {
                    dayObject: _vm.dayObject,
                    showDetails: _vm.showDetails
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }