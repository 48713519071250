
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ClassworkEditorForm from '@/components/classwork/ClassworkEditorForm.vue';
import ClassworkEditorHeader from '@/components/classwork/ClassworkEditorHeader.vue';
import ClassworkActions from '@/components/classwork/ClassworkActions.vue';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import { FormError } from '@/errors';
import { Editor } from '@/types/global.types';

const google = namespace('google');
const classwork = namespace('classwork');

@Component({
  components: {
    ClassworkEditorForm,
    ClassworkEditorHeader,
    ClassworkActions
  }
})
export default class ClassworkEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  isSubpage!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  localReadOnlyMode = false;
  autoClose = true;

  @classwork.State
  listLoading!: any;

  @classwork.Getter('isDirty')
  classworkFormDirty!: any;

  @google.State
  googleClasses!: any;

  @google.Getter
  getIsConnectedToGoogle!: boolean;

  $refs!: {
    form: ClassworkEditorForm,
    confirm: Confirm
  }

  get localIsAssignment():boolean {
    if (this.input.classwork) {
      return this.input.classwork.isAssignment;
    } else {
      return false;
    }
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get canTransferToGoogle(): boolean {
    return this.getIsConnectedToGoogle && this.googleClasses.length > 0;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  isDirty() {
    return this.classworkFormDirty
  }

  editGoogleClasswork(classwork: any) {
    var boxHt = 600
    var newTop = window.screenTop + ((window.innerHeight - boxHt) / 2);

    var boxWidth = 800
    var newLeft = window.screenLeft + ((window.innerWidth - boxWidth) / 2);

    const link = this.input.action === 'G' ? classwork.alternateLink : classwork.sourceScoreAlternateLink;
    const openWindow = window.open(link, '_blank', 'top=' + newTop + ',left=' + newLeft + ',height=' + boxHt + ',width=' + boxWidth + ',location=no,menubar=no,statusbar=no,fullscreen=no,titlebar=no,scrollbars=yes,resizable=yes');

    const that = this;
    let firstCheck = true;
    var timer = setInterval(function() {
      if (firstCheck && openWindow && openWindow.closed) {
        that.autoClose = false;
        clearInterval(timer);
      } else if (openWindow && openWindow.closed) {
        clearInterval(timer);
        that.$eventBus.$emit('closeSubPage');
        that.$eventBus.$emit('refreshClasswork');
      }
      firstCheck = false;
    }, 500);
  }

  refreshClasswork() {
    if (!this.autoClose) {
      this.$eventBus.$emit('refreshClasswork');
    }
    return Promise.resolve(false);
  }

  transferToGoogle(c: number) {
    this.$refs.form.setTransferId(c)
    this.$refs.form.saveClasswork().then(() => {
      this.localValue = false;
      this.$eventBus.$emit('closeSubPage');
      this.$eventBus.$emit('refreshClasswork');
    }).catch((error) => {
      console.log('error in saving', error);
      this.$refs.form.setTransferId(0)
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$store.commit('classwork/setListLoading', false);
    });
  }

  closeClassworkDialog() {
    this.localValue = false;
  }

  async doApply() {
    this.localPage === 'classwork' ? this.$store.commit('classwork/setListLoading', true) : CommonUtils.showLoading();
    return this.$refs.form.saveClasswork().then(() => {
      this.localValue = false;
      this.$eventBus.$emit('refreshClasswork');
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      } else {
        return Promise.resolve(true);
      }
    }).finally(() => {
      this.localPage === 'classwork' ? this.$store.commit('classwork/setListLoading', false) : CommonUtils.hideLoading();
      return Promise.resolve(false);
    });
  }

  @Watch('input')
  onInput() {
    if (this.input.action === 'G' || this.input.action === 'GG') {
      this.editGoogleClasswork(this.input.classwork);
    }
    if (this.input.classwork) {
      this.localReadOnlyMode = this.input.classwork.isReadOnly || false;
    }
  }

  typeChange() {
    this.input.loadData = false
  }

  created() {
    this.$eventBus.$on('tranferToGoogle', (c: number) => {
      this.transferToGoogle(c);
    });
    this.$eventBus.$on('deleteClasswork', (classworkArray: any) => {
      if (!this.input.classwork) {
        return;
      }
      for (const i in classworkArray) {
        if (this.input.classwork.isAssignment === classworkArray[i].isAssignment && this.input.classwork.classworkId === classworkArray[i].classworkId) {
          this.$eventBus.$emit('closeSubPage');
          this.$eventBus.$emit('refreshClasswork');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('tranferToGoogle');
    this.$eventBus.$off('deleteClasswork');
  }
}
