













import CommonUtils from '@/utils/common-utils';
import moment from 'moment-timezone';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const lessons = namespace('lessons');

@Component
export default class LessonEditorHeader extends Vue {
  @lessons.State
  dayOfWeek!: string;

  @lessons.State
  date!: string;

  @lessons.State
  lessonLock!: string;

  @lessons.State
  lessonId!: number;

  get isEditExtraLesson() {
    return (this.extraLesson > 0 && this.extraLesson !== 999) && (CommonUtils.hasNoText(this.date) || this.date === '12/31/9999');
  }

  get extraLesson(): number {
    return +this.$store.state.lessons.extraLesson;
  }

  set extraLesson(val: number) {
    this.$store.commit('lessons/setExtraLesson', val);
  }

  @lessons.State
  className!: string;

  get isLocalLocked() {
    return this.lessonLock === 'Y';
  }

  get localClassName() {
    return (this.className || '') + (this.isEditExtraLesson ? ` #${this.extraLesson}` : '');
  }

  get formattedDate() {
    if (CommonUtils.hasText(this.date) && CommonUtils.hasText(this.dayOfWeek)) {
      return `${moment(this.date, 'MM/DD/YYYY').format('ddd MMM DD')}`;
    }
  }
}
