var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "editor",
      on: {
        drop: _vm.dropAttachment,
        dragover: function($event) {
          $event.preventDefault()
        },
        dragleave: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            { ref: "classworkEditorForm", staticClass: "classwork-editor-tab" },
            [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTextFieldsVisible,
                      expression: "isTextFieldsVisible"
                    }
                  ],
                  staticClass: "px-5 py-2"
                },
                [
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.localReadOnlyMode && _vm.input.action === "A",
                          expression:
                            "!localReadOnlyMode && input.action === 'A'"
                        }
                      ],
                      attrs: { justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                color: "primary",
                                dense: "",
                                mandatory: ""
                              },
                              model: {
                                value: _vm.classworkType,
                                callback: function($$v) {
                                  _vm.classworkType = $$v
                                },
                                expression: "classworkType"
                              }
                            },
                            [
                              _c("v-btn", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("assignmentLabel")))
                                ])
                              ]),
                              _c("v-btn", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("assessmentLabel")))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              autofocus: "",
                              label: _vm.$t("titleLabel") + "*",
                              rules:
                                _vm.classworkTitle === ""
                                  ? _vm.classworkType == 0
                                    ? [_vm.$t("assignmentErrorMsg7")]
                                    : [_vm.$t("assessmentErrorMsg7")]
                                  : [],
                              "hide-details": "auto",
                              outlined: "",
                              readonly: _vm.localReadOnlyMode,
                              "aria-required": "true"
                            },
                            model: {
                              value: _vm.classworkTitle,
                              callback: function($$v) {
                                _vm.classworkTitle = $$v
                              },
                              expression: "classworkTitle"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pb-date-time-range-field", {
                            attrs: {
                              hideDetails: "auto",
                              label: _vm.$t("dateLabel"),
                              start: _vm.classworkStartDate,
                              end: _vm.classworkEndDate,
                              rules: _vm.dateRules,
                              readonly: _vm.localReadOnlyMode
                            },
                            on: {
                              "update:start": [
                                function($event) {
                                  _vm.classworkStartDate = $event
                                },
                                _vm.validateDate
                              ],
                              "update:end": [
                                function($event) {
                                  _vm.classworkEndDate = $event
                                },
                                _vm.validateDate
                              ]
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("totalPointsLabel"),
                              "hide-details": "auto",
                              rules: [
                                function(v) {
                                  return (
                                    Number.isInteger(+v) ||
                                    _vm.$t("assignmentErrorMsg8")
                                  )
                                }
                              ],
                              outlined: "",
                              readonly: _vm.localReadOnlyMode
                            },
                            model: {
                              value: _vm.classworkPoints,
                              callback: function($$v) {
                                _vm.classworkPoints = $$v
                              },
                              expression: "classworkPoints"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _vm.localReadOnlyMode
                            ? _c("v-select", {
                                staticClass: "mt-1",
                                attrs: {
                                  label: _vm.$t("classLabel") + "*",
                                  "display:inline-flex": "",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  items: [_vm.readOnlyClass],
                                  outlined: "",
                                  readonly: _vm.localReadOnlyMode,
                                  "aria-required": "true"
                                },
                                model: {
                                  value: _vm.readOnlyClass,
                                  callback: function($$v) {
                                    _vm.readOnlyClass = $$v
                                  },
                                  expression: "readOnlyClass"
                                }
                              })
                            : _c("v-select", {
                                staticClass: "mt-1",
                                attrs: {
                                  label: _vm.$t("classLabel") + "*",
                                  "display:inline-flex": "",
                                  "return-object": "",
                                  rules:
                                    _vm.classworkSelectedClass.value === 0
                                      ? [_vm.$t("assignmentErrorMsg1")]
                                      : [],
                                  "hide-details": "auto",
                                  items: _vm.classList,
                                  outlined: "",
                                  readonly: _vm.localReadOnlyMode,
                                  "aria-required": "true"
                                },
                                model: {
                                  value: _vm.classworkSelectedClass,
                                  callback: function($$v) {
                                    _vm.classworkSelectedClass = $$v
                                  },
                                  expression: "classworkSelectedClass"
                                }
                              })
                        ],
                        1
                      ),
                      _vm.unitList.length > 0 || _vm.readOnlyUnit
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _vm.showUnitFilter || _vm.readOnlyUnit
                                ? _c("v-select", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      label: _vm.$t("unitLabel"),
                                      "hide-details": "auto",
                                      items: _vm.unitOptions,
                                      "item-text": "title",
                                      "return-object": "",
                                      outlined: "",
                                      readonly: _vm.localReadOnlyMode
                                    },
                                    model: {
                                      value: _vm.unitSelection,
                                      callback: function($$v) {
                                        _vm.unitSelection = $$v
                                      },
                                      expression: "unitSelection"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm.availableTypes.length > 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-select", {
                                staticClass: "mt-1",
                                attrs: {
                                  label: _vm.$t("typeLabel"),
                                  "display:inline-flex": "",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  items: _vm.availableTypes,
                                  "item-text": "name",
                                  outlined: "",
                                  readonly: _vm.localReadOnlyMode
                                },
                                model: {
                                  value: _vm.scoreWeight,
                                  callback: function($$v) {
                                    _vm.scoreWeight = $$v
                                  },
                                  expression: "scoreWeight"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.unitList.length > 0 && _vm.availableTypes.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-select", {
                                staticClass: "mt-1",
                                attrs: {
                                  label: _vm.$t("typeLabel"),
                                  "display:inline-flex": "",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  items: _vm.availableTypes,
                                  "item-text": "name",
                                  outlined: "",
                                  readonly: _vm.localReadOnlyMode
                                },
                                model: {
                                  value: _vm.scoreWeight,
                                  callback: function($$v) {
                                    _vm.scoreWeight = $$v
                                  },
                                  expression: "scoreWeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  key: _vm.localRefreshKey,
                  on: {
                    mouseenter: function($event) {
                      _vm.isSectionsHovered = true
                    },
                    mouseleave: function($event) {
                      _vm.isSectionsHovered = false
                    }
                  }
                },
                [
                  _c("text-editor-full-screen-button", {
                    attrs: {
                      isSectionsHovered: _vm.isSectionsHovered,
                      isTextFieldsVisible: _vm.isTextFieldsVisible
                    },
                    on: { hideOrShowTextFields: _vm.hideOrShowTextFields }
                  }),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "editor-section-tabs",
                      attrs: { centered: "", "show-arrows": "" },
                      model: {
                        value: _vm.localSelectedTab,
                        callback: function($$v) {
                          _vm.localSelectedTab = $$v
                        },
                        expression: "localSelectedTab"
                      }
                    },
                    _vm._l(_vm.filteredTabs, function(section) {
                      return _c("v-tab", { key: section.section }, [
                        _vm._v(_vm._s(section.label))
                      ])
                    }),
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.localSelectedTab,
                        callback: function($$v) {
                          _vm.localSelectedTab = $$v
                        },
                        expression: "localSelectedTab"
                      }
                    },
                    [
                      _vm._l(_vm.filteredTabs, function(section) {
                        return [
                          section.section === "description"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  section.active
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          refreshKey: _vm.localRefreshKey,
                                          config: _vm.localTextEditorConfig,
                                          disabled: _vm.localReadOnlyMode
                                        },
                                        model: {
                                          value: _vm.classworkDescription,
                                          callback: function($$v) {
                                            _vm.classworkDescription = $$v
                                          },
                                          expression: "classworkDescription"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "multipleChoice"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  section.active
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            config: _vm.localEditorSectionConfig
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function() {
                                                  return [
                                                    _vm.multipleChoiceHolder
                                                      .length > 0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label": _vm.$t(
                                                                "clearAllLabel",
                                                                {
                                                                  text:
                                                                    section.label
                                                                }
                                                              )
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearMultipleChoice
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addMultipleChoice"
                                                          )
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addMultipleChoice
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("fal fa-plus")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              attrs: {
                                                options: { forceFallback: true }
                                              },
                                              on: {
                                                end:
                                                  _vm.onMultipleChoiceHolderChange
                                              },
                                              model: {
                                                value: _vm.multipleChoiceHolder,
                                                callback: function($$v) {
                                                  _vm.multipleChoiceHolder = $$v
                                                },
                                                expression:
                                                  "multipleChoiceHolder"
                                              }
                                            },
                                            _vm._l(
                                              _vm.multipleChoiceHolder,
                                              function(item, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass: "d-table"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell"
                                                      },
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "offset-y": ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var onMenu =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            ref:
                                                                              "moveButton" +
                                                                              i,
                                                                            refInFor: true,
                                                                            attrs: {
                                                                              disabled:
                                                                                _vm.localReadOnlyMode,
                                                                              small:
                                                                                "",
                                                                              text:
                                                                                "",
                                                                              "aria-label": _vm.$t(
                                                                                "dragReorderItemLabel",
                                                                                {
                                                                                  text:
                                                                                    section.label
                                                                                }
                                                                              )
                                                                            }
                                                                          },
                                                                          onMenu
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "fal fa-sort-alt"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "v-list",
                                                              [
                                                                i !== 0
                                                                  ? _c(
                                                                      "v-list-item",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.moveSectionUp(
                                                                              i
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "moveUpLabel"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                i !==
                                                                _vm
                                                                  .multipleChoiceHolder
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "v-list-item",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.moveSectionDown(
                                                                              i
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "moveDownLabel"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell classwork-editor-mc"
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            "aria-label":
                                                              section.label +
                                                              " " +
                                                              i
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.onMultipleChoiceHolderChange
                                                          },
                                                          model: {
                                                            value: item.value,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.value"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell"
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.localReadOnlyMode,
                                                              "x-small": "",
                                                              text: "",
                                                              "aria-label": _vm.$t(
                                                                "removeItemLabel",
                                                                {
                                                                  text:
                                                                    section.label
                                                                }
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteMultipleChoice(
                                                                  i
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "standards"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  section.active
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            config: _vm.localEditorSectionConfig
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function() {
                                                  return [
                                                    _vm.standards.length > 0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label": _vm.$t(
                                                                "clearAllLabel",
                                                                {
                                                                  text:
                                                                    section.label
                                                                }
                                                              )
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearStandards
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text:
                                                                section.label
                                                            }
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "myStandards"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("fal fa-plus")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              hideStudentDelete:
                                                _vm.$currentUser.isStudent,
                                              disabled: _vm.localReadOnlyMode,
                                              items: _vm.standardItems,
                                              showShortValue: true,
                                              label: _vm.$t("standardLabel")
                                            },
                                            on: {
                                              deleteItem: _vm.deleteStandard
                                            }
                                          }),
                                          _c("standards-picker", {
                                            attrs: {
                                              classId: _vm.defaultClass.value,
                                              standards: _vm.standards
                                            },
                                            on: {
                                              "update:standards": [
                                                _vm.insertStandards,
                                                function($event) {
                                                  _vm.standards = $event
                                                }
                                              ]
                                            },
                                            model: {
                                              value: _vm.dialogs.myStandards,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "myStandards",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.myStandards"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "attachments"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  section.active
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            config: _vm.localEditorSectionConfig
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function() {
                                                  return [
                                                    _vm.attachments.length > 0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label": _vm.$t(
                                                                "clearAllLabel",
                                                                {
                                                                  text:
                                                                    section.label
                                                                }
                                                              )
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearAttachments
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text:
                                                                section.label
                                                            }
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "myFiles"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fal fa-paperclip"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addGoogleDriveLabel"
                                                          )
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addGoogleDriveAttachments
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fab fa-google-drive"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              disabled: _vm.localReadOnlyMode,
                                              items: _vm.attachmentItems,
                                              showShortValue: false,
                                              clickable: true,
                                              label: _vm.$t("attachmentLabel")
                                            },
                                            on: {
                                              otherAction:
                                                _vm.toggleAttachmentPrivateFlag,
                                              deleteItem: _vm.deleteAttachment
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "value",
                                                  fn: function(ref) {
                                                    var value = ref.value
                                                    var data = ref.data
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "section-editor-link",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                data
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "other-actions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "section-editor-checkbox",
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "privateLabel"
                                                          ),
                                                          "input-value":
                                                            item.data
                                                              .privateFlag
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.toggleAttachmentPrivateFlag(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("pb-confirm", { ref: "confirm" }),
              _c("my-files-picker", {
                ref: "filesPicker",
                attrs: { attachments: _vm.attachments },
                on: { "update:attachments": _vm.updateAttachments },
                model: {
                  value: _vm.dialogs.myFiles,
                  callback: function($$v) {
                    _vm.$set(_vm.dialogs, "myFiles", $$v)
                  },
                  expression: "dialogs.myFiles"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }