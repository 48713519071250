
import WebUtils from '@/utils/web-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MultiListPicker from './MultiListPicker.vue';

const settings = namespace('settings');
const standards = namespace('standards');

@Component({
  components: {
    MultiListPicker
  }
})
export default class SchoolListPicker extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Array, required: true, default: () => [] })
  standards!: Array<any>;

  @settings.State
  userMode!: string;

  @settings.Getter('getLessonSectionLabel')
  getLessonSectionLabel!: any;

  @standards.State('schoolList')
  schoolListData!: any;

  @standards.Action
  loadSchoolList!: (params?: any) => Promise<any>;

  @standards.Mutation
  setSchoolList!: any;

  @standards.Mutation
  setHaveSchoolList!: any;

  selected: Array<any> = [];
  filter = ''
  listViewLoaded = false;
  group = '';

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localSchoolListData() {
    return this.schoolListData || {};
  }

  get localSchoolList() {
    return this.localSchoolListData.standards || [];
  }

  get localSchoolListItems() {
    return this.localSchoolList.map((s: any) => {
      return {
        key: s.dbId,
        shortValue: s.sI,
        value: s.sD
      }
    });
  }

  get selectedCount() {
    return this.selected.length;
  }

  @Watch('localValue')
  onValueChange() {
    if (this.value) {
      this.initialize().then(() => {
        this.selected = this.standards.map((s: any) => s.dbId);
      })
    } else {
      this.selected = [];
    }
  }

  unselectAll() {
    this.selected = [];
  }

  refresh() {
    this.setSchoolList({});
    this.initialize();
  }

  async initialize() {
    const that = this;
    this.listViewLoaded = false;
    return this.loadSchoolList({
      userMode: this.userMode
    }).then(() => {
      that.setHaveSchoolList(true);
      that.listViewLoaded = true;
    });
  }

  doApply(selected: Array<any>) {
    const newStandards = this.localSchoolList.filter((s: any) => selected.includes(s.dbId)).map((s: any) => {
      return {
        dbId: s.dbId,
        id: s.sI,
        desc: s.sD
      }
    });
    this.$emit('update:standards', newStandards);
    this.localValue = false;
  }

  created() {
    this.setHaveSchoolList(false);
  }

  updated() {
    WebUtils.initMathJax();
  }
}
