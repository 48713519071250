var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-divider"),
      _c(
        "v-card-title",
        { staticClass: "pb-0 pt-2 mb-3" },
        [
          _vm.item.noteId
            ? _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    color: _vm.notesTodosStyling.notesIconColor,
                  },
                },
                [_vm._v("fa-solid fa-note")]
              )
            : _vm._e(),
          _vm.item.toDoId
            ? _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    color: _vm.notesTodosStyling.toDosIconColor,
                  },
                },
                [_vm._v("fa-solid fa-clipboard-check")]
              )
            : _vm._e(),
          _vm.getHasText(_vm.item.googleId)
            ? _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    color: _vm.eventStyling.eventLabelFillColor,
                  },
                },
                [_vm._v("fa-brands fa-google")]
              )
            : _vm.item.eventId && _vm.item.noSchool
            ? _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    color: _vm.eventStyling.eventLabelFillColor,
                  },
                },
                [_vm._v("fa-solid fa-calendar-xmark")]
              )
            : _vm.item.eventId >= 0 && !_vm.item.noSchool
            ? _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    color: _vm.eventStyling.eventLabelFillColor,
                  },
                },
                [_vm._v("fa-solid fa-calendar-alt")]
              )
            : _vm._e(),
          _vm.item.assessmentId
            ? _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    color:
                      _vm.classworkStyling.currentAssessmentColor || "purple",
                  },
                },
                [_vm._v("fa-solid fa-clipboard-list-check")]
              )
            : _vm._e(),
          _vm.item.assignmentId
            ? _c(
                "v-icon",
                {
                  attrs: {
                    left: "",
                    color:
                      _vm.classworkStyling.currentAssignmentColor || "#5DA500",
                  },
                },
                [_vm._v("fa-solid fa-calendar-lines-pen")]
              )
            : _vm._e(),
          _vm.item.studentId && !_vm.item.noteId
            ? _c("v-icon", { attrs: { color: "accent1", left: "" } }, [
                _vm._v("fal fa-screen-users"),
              ])
            : _vm._e(),
          _vm.item.eventId >= 0 || _vm.item.googleId
            ? _c("div", [
                _vm.showDate(_vm.item.eventCurrentDate)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getMonth(_vm.item.eventCurrentDate)) +
                          " " +
                          _vm._s(
                            _vm.getDisplayDate(_vm.item.eventCurrentDate)
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm._v("   "),
                _vm.item.noSchool
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(" / " + _vm._s(_vm.$t("noSchoolLabel"))),
                    ])
                  : _vm._e(),
                _vm.getHasNoText(_vm.item.eventStartTime) &&
                _vm.getHasNoText(_vm.item.eventEndTime)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v("  / " + _vm._s(_vm.$t("allDayLabel")) + " "),
                    ])
                  : _vm._e(),
                _vm.getHasText(_vm.item.eventStartTime) &&
                _vm.getHasText(_vm.item.eventEndTime)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(
                        "  /  " +
                          _vm._s(_vm.item.eventStartTime) +
                          " - " +
                          _vm._s(_vm.item.eventEndTime) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.item.noteId
            ? _c("div", [
                _vm.showDate(_vm.item.JSON.noteDate)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getMonth(_vm.item.JSON.noteDate)) +
                          " " +
                          _vm._s(_vm.getDisplayDate(_vm.item.JSON.noteDate)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.item.subjectId != 0
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(
                        " / " + _vm._s(_vm.getClassNameById(_vm.item.subjectId))
                      ),
                    ])
                  : _vm._e(),
                _vm.item.studentId != 0
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(
                        " / " +
                          _vm._s(
                            _vm.getStudentsItemNameByID(_vm.item.studentId)
                          )
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.item.toDoId
            ? _c(
                "div",
                [
                  _vm.showDate(_vm.item.dueDate)
                    ? _c("span", { staticClass: "text-overline" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getMonth(
                                _vm.item.currentDate || _vm.item.dueDate
                              )
                            ) +
                            " " +
                            _vm._s(
                              _vm.getDisplayDate(
                                _vm.item.currentDate || _vm.item.dueDate
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasText(_vm.item.currentDate)
                    ? _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("fal fa-repeat"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.item.assessmentId
            ? _c("div", [
                _vm.showDate(_vm.item.assessmentEnd)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getMonth(_vm.item.assessmentEnd)) +
                          " " +
                          _vm._s(_vm.getDisplayDate(_vm.item.assessmentEnd)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.getHasText(_vm.item.className)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(" / " + _vm._s(_vm.item.className)),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.item.assignmentId
            ? _c("div", [
                _vm.showDate(_vm.item.assignmentEnd)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getMonth(_vm.item.assignmentEnd)) +
                          " " +
                          _vm._s(_vm.getDisplayDate(_vm.item.assignmentEnd)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.getHasText(_vm.item.className)
                  ? _c("span", { staticClass: "text-overline" }, [
                      _vm._v(" / " + _vm._s(_vm.item.className)),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.item.studentId && !_vm.item.noteId
            ? _c("div", [
                _c("span", { staticClass: "text-overline" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.getMonth(_vm.item.birthDate)) +
                      " " +
                      _vm._s(_vm.getDisplayDate(_vm.item.birthDate)) +
                      " "
                  ),
                ]),
                _vm.getCurrentDateToBirthdayTimeDifference(
                  _vm.item.birthDate
                ) >= 0
                  ? _c(
                      "div",
                      {
                        staticClass: "date-until-birthdate-container",
                        staticStyle: { display: "inline" },
                      },
                      [
                        _vm.getCurrentDateToBirthdayTimeDifference(
                          _vm.item.birthDate
                        ) === 0
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-overline date-until-birthdate-text",
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("todayLabel")) + " ")]
                            )
                          : _vm.getCurrentDateToBirthdayTimeDifference(
                              _vm.item.birthDate
                            ) === 1
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-overline date-until-birthdate-text",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("tommorowLabel")) + " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "text-overline date-until-birthdate-text",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("daysTillBirthdayMessage", {
                                        date: _vm.getCurrentDateToBirthdayTimeDifference(
                                          _vm.item.birthDate
                                        ),
                                      })
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "date-until-birthdate-container",
                        staticStyle: { display: "inline" },
                      },
                      [
                        _vm.getCurrentDateToBirthdayTimeDifference(
                          _vm.item.birthDate
                        ) === -1
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-overline date-until-birthdate-text",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("yesterdayLabel")) + " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "text-overline date-until-birthdate-text",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pastTillBirthdayMessage", {
                                        date: Math.abs(
                                          _vm.getCurrentDateToBirthdayTimeDifference(
                                            _vm.item.birthDate
                                          )
                                        ),
                                      })
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    ),
              ])
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "mr-3", attrs: { align: "center", justify: "end" } },
            [
              _vm.item.toDoId
                ? _c(
                    "div",
                    { staticClass: "ml-2" },
                    [
                      _vm.isTrueForTodo(_vm.item)
                        ? _c(
                            "pb-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "",
                                    large: "",
                                    color: "primary",
                                    focusBorder: false,
                                    label: _vm.isTrueForTodo(_vm.item)
                                      ? _vm.$t("doneLabel")
                                      : _vm.$t("markAsCompleteLabel"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateDoneTodo(_vm.item)
                                    },
                                  },
                                },
                                "pb-btn",
                                _vm.attrs,
                                false
                              ),
                              _vm.on
                            ),
                            [_c("v-icon", [_vm._v("fa-thin fa-circle-check")])],
                            1
                          )
                        : _c(
                            "pb-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "",
                                    large: "",
                                    color: "primary",
                                    focusBorder: false,
                                    label: _vm.isTrueForTodo(_vm.item)
                                      ? _vm.$t("doneLabel")
                                      : _vm.$t("markAsCompleteLabel"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateDoneTodo(_vm.item)
                                    },
                                  },
                                },
                                "pb-btn",
                                _vm.attrs,
                                false
                              ),
                              _vm.on
                            ),
                            [_c("v-icon", [_vm._v("fa-thin fa-circle")])],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm.item.noteId
        ? _c("v-card-text", { staticClass: "py-0 pb-2" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.item.JSON.noteText) },
            }),
          ])
        : _vm.item.toDoId
        ? _c(
            "v-card-text",
            { staticClass: "pt-2 pb-0" },
            [
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { "align-self": "center", cols: "auto" } },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "text-body-2",
                          class: { "contrast-view": _vm.highContrastMode },
                          attrs: {
                            color: _vm.getPriorityColor(_vm.item),
                            pill: "",
                            "text-color": "white",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.getPriorityText(
                                  _vm.item.customPriority || _vm.item.priority
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-col",
                    { staticStyle: { width: "70%" }, attrs: { cols: "auto" } },
                    [
                      _c("span", {
                        class: _vm.isTrueForTodo(_vm.item)
                          ? "text-decoration-line-through"
                          : "text-decoration-none",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.item.customToDoText || _vm.item.toDoText
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.item.eventId >= 0 && !_vm.item.googleId
        ? _c(
            "v-card-text",
            { staticClass: "pt-0 pb-2" },
            [
              _c(
                "v-row",
                { class: _vm.item.eventStickerId > 0 ? "mb-3" : "" },
                [
                  _vm.item.eventStickerId > 0
                    ? _c(
                        "v-col",
                        { attrs: { "align-self": "center", cols: "auto" } },
                        [
                          _c("event-sticker-image", {
                            attrs: {
                              stickerURL: _vm.stickerURL(
                                _vm.item.eventStickerId
                              ),
                              stickerBackgroundStyle:
                                _vm.stickerBackgroundStyle(
                                  _vm.item.stickerBackgroundStyle
                                ),
                              stickerSize: _vm.stickerSize(
                                _vm.item.stickerSize
                              ),
                              stickerBackgroundColor:
                                _vm.stickerBackgroundColor(
                                  _vm.item.stickerBackgroundColor
                                ),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.item.eventStickerId > 0
                    ? _c("v-divider", { attrs: { vertical: "" } })
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticStyle: { width: "65%" }, attrs: { cols: "auto" } },
                    [
                      _c("div", { staticClass: "pt-1" }, [
                        _vm._v(_vm._s(_vm.item.eventTitle)),
                      ]),
                      _c("div", {
                        class: _vm.hasContent(_vm.item.eventText)
                          ? "mt-2"
                          : "mt-0",
                        domProps: { innerHTML: _vm._s(_vm.item.eventText) },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.item.googleId
        ? _c("v-card-text", { staticClass: "pt-0 pb-2" }, [
            _c("div", { staticClass: "pt-1" }, [
              _vm._v(_vm._s(_vm.item.eventTitle)),
            ]),
            _c("div", {
              class: _vm.hasContent(_vm.item.eventText) ? "mt-2" : "mt-0",
              domProps: { innerHTML: _vm._s(_vm.item.eventText) },
            }),
          ])
        : _vm._e(),
      _vm.item.assessmentId
        ? _c("v-card-text", { staticClass: "py-0 pb-2" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.item.assessmentTitle) },
            }),
          ])
        : _vm._e(),
      _vm.item.assignmentId
        ? _c("v-card-text", { staticClass: "py-0 pb-2" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.item.assignmentTitle) },
            }),
          ])
        : _vm._e(),
      _vm.item.studentId && !_vm.item.noteId
        ? _c(
            "v-card-text",
            { staticClass: "pt-2 pb-0" },
            [
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { "align-self": "center", cols: "auto" } },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "primary", size: "2.5rem" } },
                        [
                          _vm.item.photoUrl && _vm.item.photoUrl != ""
                            ? _c("v-img", { attrs: { src: _vm.item.photoUrl } })
                            : _c("span", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(_vm.getInitials(_vm.item))),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("happyBirthdayLabel")) + ", "),
                    ]),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.getStudentLabel(_vm.item)) + "! "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }