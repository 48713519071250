
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import CommonUtils from '@/utils/common-utils';
import { VForm } from 'vuetify/lib';

const studentassignment = namespace('studentassigment');
const classes = namespace('classes');
const messages = namespace('messages');
const settings = namespace('settings');

@Component({
  components: {}
})
export default class StudentManageClassEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  $refs!: {
    form: InstanceType<typeof VForm>,
    removeSubjectConfirm: Confirm,
  }

  isApplying = false;
  isValidJoinCode = false;
  showRequestSuccess = false;

  headers:any[] = [];
  tableClassesItems: any = [];
  selectedRows:any[] = [];
  subjectDetail: [] = [];

  @Watch('localValue')
  onValueChange(v:any) {
    this.initialize();
  }

  @studentassignment.Action
  removeSubjectFromStudent!: (params?: any) => Promise<any>;

  @classes.Action
  loadClasses!: (params?: any) => Promise<any>;

  @classes.Getter
  getClassesMap!: any;

  @messages.Action
  addMessage!: (params?: any) => Promise<any>;

  @classes.Mutation
  setHaveClass!: (value: boolean) => void;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.State
  studentId!: number;

  @settings.State
  studentFirstName!: string;

  @settings.State
  studentLastName!: string;

  @settings.State
  studentEmailAddress!: string;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localTableItems () {
    return this.tableClassesItems
  }

  set localTableItems(val:any) {
    this.tableClassesItems = val;
  }

  get localAccountName() {
    if (this.userMode === 'S' && this.studentId > 0) {
      return this.studentFirstName + ' ' + this.studentLastName;
    }
  }

  get localEmailAddress() {
    if (this.userMode === 'S' && this.studentId > 0) {
      if (CommonUtils.hasText(this.studentEmailAddress)) {
        return this.studentEmailAddress;
      } else {
        return 'Account';
      }
    }
  }

  async initialize() {
    this.headers = [
      {
        text: this.$t('standardSubjectLabel'),
        value: 'subjectName',
        width: '40%',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('teacherLabel'),
        value: 'displayName',
        width: '340%',
        align: 'center',
        sortable: false
      },
      {
        text: 'Action',
        value: 'subjectId',
        width: '10%',
        align: 'center',
        sortable: false
      }
    ];
    CommonUtils.showLoading();
    this.loadStudentClasses();
  }

  loadStudentClasses() {
    this.setHaveClass(false);
    this.loadClasses().then((resp:any) => {
      if (resp.data !== 0) {
        this.tableClassesItems = resp.data.studentClasses;
        return resp.data.studentClasses;
      }
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  }

  requestSubjectRemove(subjectDetails: any) {
    return this.$refs.removeSubjectConfirm.confirm({
      title: this.$t('removeLabel'),
      text: this.$t('confirmDeleteGeneral', { type: this.$t('classLabel') }),
      option1ButtonAlternativeText: this.$t('removeLabel')
    }).then((result: any) => {
      if (result === 1) {
        CommonUtils.showLoading();
        const urlRemoveSubject = '<a target="_self" href="/assign-students?sbj=' + subjectDetails.subjectName + '&stname=' + this.localAccountName + '&rsid=' + subjectDetails.subjectId + '&stid=' + this.studentId + '">Remove ' + this.localAccountName + ' from ' + subjectDetails.subjectName + '</a>';
        const commentMessage = '<p><b>' + this.localAccountName + ' </b> request removal of <b> ' + subjectDetails.subjectName + '</b> <br> <br>  Link: <b>' + urlRemoveSubject + '</b>';
        const payload: any = {
          commentText: commentMessage,
          recipientId: subjectDetails.teacherId,
          recipientType: 'T',
          private: false
        }
        return this.addMessage(payload).then().finally(() => {
          //  alert message for sent
          CommonUtils.hideLoading();
          this.showRequestSuccess = true;
          return Promise.resolve(false);
        });
      }
    })
  }
}
