var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "classwork-editor-header" }, [
    _c("div", { staticStyle: { "font-weight": "600" } }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.action === "E" ? _vm.$t("editLabel") : _vm.$t("addLabel")
          ) +
          " " +
          _vm._s(_vm.$t("classworkLabel")) +
          " "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }