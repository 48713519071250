









































import { ActionsModal } from '@/types/global.types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormError } from '@/errors';
import Confirm from '../core/Confirm.vue';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';

const classwork = namespace('classwork');
const google = namespace('google');
const settings = namespace('settings');

@Component
export default class ClassworkActions extends Vue implements ActionsModal {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: {}, mode: 'P' };
    }
  })
  input!: any;

  @Prop({ required: false, type: String, default: 'absolute' })
  type!: string;

    actions = [
      {
        id: 'delete',
        icon: 'fal fa-trash-alt',
        label: 'deleteLabel'
      }
    ];

    showMenu = false;
    showingGoogleClasses = false;
    x = 0;
    y = 0;

    $refs!: {
        confirm: Confirm
    }

    get filteredActions() {
      return this.actions.filter(a => {
        if (a.id === 'delete' && this.input.mode === 'A') {
          return false;
        }
        return true;
      });
    }

    @classwork.Action
    deleteClasswork!: (param: any) => Promise<any>;

    @classwork.Action
    updateClasswork!: (param: any) => Promise<any>;

    @classwork.Getter
    getLocalClasswork!: any;

    @google.Getter
    getIsConnectedToGoogle!: boolean;

    @google.State
    googleClasses!: any;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    get googleClassesList(): any {
      return this.googleClasses;
    }

    doAction(action: any) {
      Promise.resolve().then(() => {
        if (action.id === 'delete') {
          return this.doDeleteEvent();
        }
      }).catch((error) => {
        if (!(error instanceof FormError)) {
          return Promise.reject(error);
        }
      }).finally(() => {
        this.showMenu = false;
        CommonUtils.hideLoading();
      });
    }

    async doDeleteEvent() {
      console.log(this.type)
      const that = this;
      that.showMenu = false;
      return Promise.resolve().then(() => {
        return this.$refs.confirm.confirm({
          title: this.$t('classworkLabel'),
          text: this.$t('confirmDeleteClasswork'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then(async result => {
          if (result === 1) {
            return this.deleteClasswork(this.input.classwork).then(() => {
              this.$eventBus.$emit('closeSubPage');
              this.$eventBus.$emit('refreshClasswork');

              if (this.showSnackbarNotifications) {
                this.$snotify.success(this.$t('statusMsg34') as string);
              }

              return Promise.resolve();
            });
          } else {
            return Promise.reject(new FormError());
          }
        })
      })
    }

    moveClassworkToGoogle(c: any) {
      this.showMenu = false;
      this.$eventBus.$emit('tranferToGoogle', c.id);
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }
}
