
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MultiListPicker from './MultiListPicker.vue';

const settings = namespace('settings');
const standards = namespace('standards');

@Component({
  components: {
    MultiListPicker
  }
})
export default class StrategiesPicker extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Array, required: true, default: () => [] })
  strategies!: Array<any>;

  @settings.State
  userMode!: string;

  @settings.Getter('getLessonSectionLabel')
  getLessonSectionLabel!: any;

  @standards.Getter('getStrategyGroups')
  strategyGroups!: any;

  @standards.Getter('getCurrentStrategyGroup')
  currentStrategyGroup!: any;

  @standards.State('strategies')
  strategiesData!: any;

  @standards.Action
  loadStrategies!: (params?: any) => Promise<any>;

  @standards.Mutation
  setStrategies!: any;

  @standards.Mutation
  setHaveStrategies!: any;

  filter = ''
  group = '';
  listViewLoaded = false;
  selected: Array<any> = [];
  localCachedStrategies: any = {};

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localStrategiesData() {
    return this.strategiesData || {};
  }

  get localStrategies() {
    return this.localStrategiesData.strategies || [];
  }

  get localStrategiesItems() {
    return this.localStrategies.map((s: any) => {
      return {
        key: s.strategyId,
        shortValue: s.code,
        value: s.title
      }
    });
  }

  get selectedCount() {
    return this.selected.length;
  }

  @Watch('localValue')
  onValueChange() {
    if (this.localValue) {
      this.selected = [];
      this.strategies.forEach((s: any) => {
        this.selected.push(s.strategyId);
        this.localCachedStrategies[s.strategyId] = s;
      });
      this.initialize();
    } else {
      this.selected = [];
      this.localCachedStrategies = {};
    }
  }

  @Watch('group')
  onGroupChange(val: any) {
    if (this.listViewLoaded) {
      if (val !== this.currentStrategyGroup) {
        this.setStrategies({});
        this.setHaveStrategies(false);
        this.initialize();
      }
    }
  }

  unselectAll() {
    this.selected = [];
  }

  refresh() {
    this.setStrategies({});
    this.setHaveStrategies(false);
    this.initialize();
  }

  async initialize() {
    const that = this;
    this.listViewLoaded = false;
    return this.loadStrategies({
      group: this.group
    }).then(() => {
      this.localStrategies.forEach((s: any) => {
        this.localCachedStrategies[s.strategyId] = s;
      });
      that.listViewLoaded = true;
      return Promise.resolve();
    });
  }

  doApply(selected: Array<any>) {
    const newStandards = selected.map((s: any) => this.localCachedStrategies[s]);
    this.$emit('update:strategies', newStandards);
    this.localValue = false;
  }

  created() {
    this.group = this.currentStrategyGroup;
    this.setHaveStrategies(false);
  }
}
