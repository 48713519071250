var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      staticStyle: { "overflow-y": "auto" },
      attrs: {
        "hide-overlay": "",
        persistent: "",
        "content-class":
          _vm.remindersMobileClickCounter === 2 ? "elevation-0" : ""
      },
      model: {
        value: _vm.localRemindersMobileDrawer,
        callback: function($$v) {
          _vm.localRemindersMobileDrawer = $$v
        },
        expression: "localRemindersMobileDrawer"
      }
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "pa-5 pb-16",
          attrs: {
            elevation: "0",
            flat: "",
            "min-height": _vm.height,
            "max-height": _vm.height
          }
        },
        [
          _c("reminders", {
            ref: "reminders",
            attrs: {
              value: _vm.remindersModel,
              mobileDrawerMode: true,
              offset: _vm.browserHeight
            },
            on: { hideRemindersMobileDrawer: _vm.hideRemindersMobileDrawer }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }