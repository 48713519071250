
import { Component, Prop, Vue } from 'vue-property-decorator';
import SchoolYearEditorForm from '@/components/schoolyears/SchoolYearEditorForm.vue';
import SchoolYearActions from '@/components/schoolyears/SchoolYearActions.vue';
import CommonUtils from '@/utils/common-utils';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import { FormError } from '@/errors';
import { Editor } from '@/types/global.types';

const settings = namespace('settings');

@Component({
  components: {
    SchoolYearEditorForm,
    SchoolYearActions
  }
})
export default class EventEditor extends Vue implements Editor {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  $refs!: {
    form: SchoolYearEditorForm,
    confirm: Confirm
  }

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getSchoolYearListLoading')
  schoolYearListLoading!: boolean;

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @settings.State
  loading!: boolean;

  @settings.Mutation
  setUpdatedSettings!: (params?: any) => void;

  localFullScreen = false;

  isDirty() {
    return this.$refs.form && this.$refs.form.isDirty;
  }

  typeChange() {
    this.input.loadData = false;
  }

  get yearId(): number {
    return this.updatedSettings.yearId;
  }

  get parentYearId() {
    return this.updatedSettings.parentYearId;
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  set updatedSettings(value: any) {
    this.setUpdatedSettings(value);
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get withActions() {
    return this.yearId > 0 && this.userMode === 'T';
  }

  get withApply() {
    return this.yearId > 0 || this.userMode === 'A';
  }

  get withBottomActions() {
    return this.userMode === 'T' && this.yearId === 0;
  }

  get step() {
    if (this.schoolYearEditorInitialized) {
      return this.$refs.form.step;
    }
    return -1;
  }

  get schoolYearEditorInitialized() {
    return this.$store.state.settings.schoolYearEditorInitialized;
  }

  get editorLabel() {
    if (this.schoolYearEditorInitialized && !this.loading) {
      return this.$refs.form.editorLabel;
    }
    return this.$t('schoolYearLabel');
  }

  get canGoBack() {
    if (this.schoolYearEditorInitialized) {
      return this.$refs.form.canGoBack;
    }
    return false;
  }

  get canContinue() {
    if (this.schoolYearEditorInitialized) {
      return this.$refs.form.canContinue;
    }
    return false;
  }

  get continueButtonLabel() {
    return this.$t(this.step !== 3 ? 'continueLabel' : 'addSchoolYearLabel')
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  async doContinue() {
    return this.$refs.form.continue().then(result => {
      this.localValue = result;
      return Promise.resolve(result)
    });
  }

  doApply() {
    if (this.withApply) {
      return this.doSave();
    } else {
      return this.doContinue();
    }
  }

  doSave() {
    this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', true) : CommonUtils.showLoading();
    return this.$refs.form.save().then(() => {
      this.localValue = false;
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      }
      return Promise.resolve(true);
    }).finally(() => {
      this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', false) : CommonUtils.hideLoading();
      return Promise.resolve(false)
    });
  }

  created() {
    this.$eventBus.$on('deleteYear', (years: any) => {
      for (const i in years) {
        if (this.input.data && years[i].yearId === this.input.data.yearId) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    });

    this.$eventBus.$on('applyCopyDisplayDays', this.doApply);
  }

  destroyed() {
    this.$eventBus.$off('deleteYear');
    this.$eventBus.$off('applyCopyDisplayDays');
  }
}
