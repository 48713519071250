var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-dialog",
        {
          ref: "loader",
          staticClass: "loader",
          attrs: { persistent: "", eager: "", "max-width": "150" },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "loader-card" },
            [
              _c("v-card-text", { staticClass: "text-center pt-3" }, [
                _c("object", {
                  staticClass: "mt-3",
                  attrs: {
                    data:
                      "https://cdn.planbook.com/images/planbook-loading-bump.svg",
                    width: "100",
                    height: "100",
                    alt: "Planbook Loading"
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }