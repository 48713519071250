var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.mobileDrawerMode
    ? _c(
        "pb-base-sub-page",
        {
          attrs: {
            withActions: false,
            onClose: _vm.cancelEditor,
            withApply: false,
            expandable: false,
            input: _vm.input,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.$t("remindersLabel")))]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "action-button",
                fn: function () {
                  return [
                    _c(
                      "v-menu",
                      {
                        staticStyle: { "min-height": "335px !important" },
                        attrs: {
                          "max-width": "900px",
                          "max-height": "390px",
                          "offset-x": "",
                          left: "",
                          "close-on-content-click": false,
                          transition: "scroll-y-transition",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on: onMenu }) {
                                return [
                                  _c(
                                    "pb-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          icon: "",
                                          label: _vm.$t("remindersFilterLabel"),
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "escape",
                                                undefined,
                                                $event.key,
                                                undefined
                                              )
                                            )
                                              return null
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      { ...onMenu }
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("fal fa-sliders-h"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1588493879
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              { staticClass: "mb-2" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t("searchLabel"),
                                    outlined: "",
                                    "hide-details": "auto",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.remindersSearchText,
                                    callback: function ($$v) {
                                      _vm.remindersSearchText = $$v
                                    },
                                    expression: "remindersSearchText",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              { staticClass: "mt-4 pb-3" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-card",
                                          { attrs: { outlined: "" } },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "title-on-border",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "remindersFilterLabel"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-chip-group",
                                                  {
                                                    attrs: {
                                                      "active-class":
                                                        "font-weight-bold",
                                                      column: "",
                                                      multiple: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.remindersFilter,
                                                      callback: function ($$v) {
                                                        _vm.remindersFilter =
                                                          $$v
                                                      },
                                                      expression:
                                                        "remindersFilter",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "pb-chip",
                                                      {
                                                        style: {
                                                          color:
                                                            _vm.todosReminderFilter
                                                              ? _vm
                                                                  .notesTodosStyling
                                                                  .toDosIconColor
                                                              : "",
                                                        },
                                                        attrs: {
                                                          filter: "",
                                                          outlined: "",
                                                          "input-value":
                                                            _vm.todosReminderFilter,
                                                          value: "toDos",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            _vm.todosReminderFilter =
                                                              !_vm.todosReminderFilter
                                                          },
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.todosReminderFilter =
                                                              !_vm.todosReminderFilter
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "toDoLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "pb-chip",
                                                      {
                                                        style: {
                                                          color:
                                                            _vm.notesReminderFilter
                                                              ? _vm
                                                                  .notesTodosStyling
                                                                  .notesIconColor
                                                              : "",
                                                        },
                                                        attrs: {
                                                          filter: "",
                                                          outlined: "",
                                                          "input-value":
                                                            _vm.notesReminderFilter,
                                                          value: "notes",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            _vm.notesReminderFilter =
                                                              !_vm.notesReminderFilter
                                                          },
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.notesReminderFilter =
                                                              !_vm.notesReminderFilter
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "notesLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "pb-chip",
                                                      {
                                                        style: {
                                                          color:
                                                            _vm.eventsReminderFilter
                                                              ? _vm.eventStyling
                                                                  .eventLabelFillColor
                                                              : "",
                                                        },
                                                        attrs: {
                                                          filter: "",
                                                          outlined: "",
                                                          "input-value":
                                                            _vm.eventsReminderFilter,
                                                          value: "events",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            _vm.eventsReminderFilter =
                                                              !_vm.eventsReminderFilter
                                                          },
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.eventsReminderFilter =
                                                              !_vm.eventsReminderFilter
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "eventsLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "pb-chip",
                                                      {
                                                        style: {
                                                          color:
                                                            _vm.assessmentsReminderFilter
                                                              ? _vm
                                                                  .classworkStyling
                                                                  .currentAssessmentColor
                                                              : "",
                                                        },
                                                        attrs: {
                                                          filter: "",
                                                          outlined: "",
                                                          "input-value":
                                                            _vm.assessmentsReminderFilter,
                                                          value: "assessments",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            _vm.assessmentsReminderFilter =
                                                              !_vm.assessmentsReminderFilter
                                                          },
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.assessmentsReminderFilter =
                                                              !_vm.assessmentsReminderFilter
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "assessmentsLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "pb-chip",
                                                      {
                                                        style: {
                                                          color:
                                                            _vm.assignmentsReminderFilter
                                                              ? _vm
                                                                  .classworkStyling
                                                                  .currentAssignmentColor
                                                              : "",
                                                        },
                                                        attrs: {
                                                          filter: "",
                                                          outlined: "",
                                                          "input-value":
                                                            _vm.assignmentsReminderFilter,
                                                          value: "assignments",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            _vm.assignmentsReminderFilter =
                                                              !_vm.assignmentsReminderFilter
                                                          },
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.assignmentsReminderFilter =
                                                              !_vm.assignmentsReminderFilter
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "assignmentsLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "pb-chip",
                                                      {
                                                        attrs: {
                                                          filter: "",
                                                          outlined: "",
                                                          "input-value":
                                                            _vm.studentsBirthdayReminderFilter,
                                                          value: "students",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            _vm.studentsBirthdayReminderFilter =
                                                              !_vm.studentsBirthdayReminderFilter
                                                          },
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.studentsBirthdayReminderFilter =
                                                              !_vm.studentsBirthdayReminderFilter
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "studentsLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            469317821
          ),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          !_vm.initialized
            ? _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  color: "primary",
                  "aria-label": "Loading Reminders",
                },
              })
            : _vm.initialized && _vm.eventsRemindersHaveData
            ? _c(
                "div",
                { staticClass: "editor" },
                [
                  _c(
                    "v-row",
                    { staticStyle: { overflow: "hidden" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "notes-todo-column",
                          staticStyle: { overflow: "hidden" },
                          style: { "max-height": _vm.contentHeight + "px" },
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            {
                              style: { height: _vm.contentHeight - 13 + "px" },
                            },
                            [
                              _vm._l(_vm.reminders, function (item, i) {
                                return _c("reminders-card", {
                                  key: i + _vm.localRefreshKey,
                                  attrs: { item: item },
                                  on: { updateDoneTodo: _vm.updateDoneTodo },
                                })
                              }),
                              _c("v-divider"),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                { attrs: { align: "center", flat: "" } },
                [
                  _c("v-divider"),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.$t("noDataAvailableLabel")) + " "),
                  ]),
                  _c("v-divider"),
                ],
                1
              ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "v-row",
            { staticClass: "text-center justify-space-between" },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.hideRemindersMobileDrawer()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("fal fa-times")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c("span", { staticClass: "v-card__title pa-0" }, [
                    _vm._v(_vm._s(_vm.$t("remindersLabel"))),
                  ]),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-menu",
                    {
                      staticStyle: { "min-height": "335px !important" },
                      attrs: {
                        "max-width": "900px",
                        "max-height": "390px",
                        "offset-x": "",
                        left: "",
                        "close-on-content-click": false,
                        transition: "scroll-y-transition",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on: onMenu }) {
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      icon: "",
                                      label: _vm.$t("remindersFilterLabel"),
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "escape",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        )
                                          return null
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  { ...onMenu }
                                ),
                                [_c("v-icon", [_vm._v("fal fa-sliders-h")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "mb-2" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("searchLabel"),
                                  outlined: "",
                                  "hide-details": "auto",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.remindersSearchText,
                                  callback: function ($$v) {
                                    _vm.remindersSearchText = $$v
                                  },
                                  expression: "remindersSearchText",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { staticClass: "mt-4 pb-3" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { outlined: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "title-on-border",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "remindersFilterLabel"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-chip-group",
                                                {
                                                  attrs: {
                                                    "active-class":
                                                      "font-weight-bold",
                                                    column: "",
                                                    multiple: "",
                                                  },
                                                  model: {
                                                    value: _vm.remindersFilter,
                                                    callback: function ($$v) {
                                                      _vm.remindersFilter = $$v
                                                    },
                                                    expression:
                                                      "remindersFilter",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      style: {
                                                        color:
                                                          _vm.todosReminderFilter
                                                            ? _vm
                                                                .notesTodosStyling
                                                                .toDosIconColor
                                                            : "",
                                                      },
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.todosReminderFilter,
                                                        value: "toDos",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.todosReminderFilter =
                                                            !_vm.todosReminderFilter
                                                        },
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          _vm.todosReminderFilter =
                                                            !_vm.todosReminderFilter
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("toDoLabel")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      style: {
                                                        color:
                                                          _vm.notesReminderFilter
                                                            ? _vm
                                                                .notesTodosStyling
                                                                .notesIconColor
                                                            : "",
                                                      },
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.notesReminderFilter,
                                                        value: "notes",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.notesReminderFilter =
                                                            !_vm.notesReminderFilter
                                                        },
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          _vm.notesReminderFilter =
                                                            !_vm.notesReminderFilter
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("notesLabel")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      style: {
                                                        color:
                                                          _vm.eventsReminderFilter
                                                            ? _vm.eventStyling
                                                                .eventLabelFillColor
                                                            : "",
                                                      },
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.eventsReminderFilter,
                                                        value: "events",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.eventsReminderFilter =
                                                            !_vm.eventsReminderFilter
                                                        },
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          _vm.eventsReminderFilter =
                                                            !_vm.eventsReminderFilter
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "eventsLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      style: {
                                                        color:
                                                          _vm.assessmentsReminderFilter
                                                            ? _vm
                                                                .classworkStyling
                                                                .currentAssessmentColor
                                                            : "",
                                                      },
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.assessmentsReminderFilter,
                                                        value: "assessments",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.assessmentsReminderFilter =
                                                            !_vm.assessmentsReminderFilter
                                                        },
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          _vm.assessmentsReminderFilter =
                                                            !_vm.assessmentsReminderFilter
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "assessmentsLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      style: {
                                                        color:
                                                          _vm.assignmentsReminderFilter
                                                            ? _vm
                                                                .classworkStyling
                                                                .currentAssignmentColor
                                                            : "",
                                                      },
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.assignmentsReminderFilter,
                                                        value: "assignments",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.assignmentsReminderFilter =
                                                            !_vm.assignmentsReminderFilter
                                                        },
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          _vm.assignmentsReminderFilter =
                                                            !_vm.assignmentsReminderFilter
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "assignmentsLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "pb-chip",
                                                    {
                                                      attrs: {
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.studentsBirthdayReminderFilter,
                                                        value: "students",
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.studentsBirthdayReminderFilter =
                                                            !_vm.studentsBirthdayReminderFilter
                                                        },
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          _vm.studentsBirthdayReminderFilter =
                                                            !_vm.studentsBirthdayReminderFilter
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "studentsLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.initialized
            ? _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  color: "primary",
                  "aria-label": "Loading Reminders",
                },
              })
            : _vm.initialized && _vm.eventsRemindersHaveData
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticStyle: { overflow: "hidden" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "notes-todo-column",
                          staticStyle: { overflow: "hidden" },
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            {
                              style: {
                                height: _vm.perfectScrollBarHeightMobile + "px",
                              },
                            },
                            [
                              _vm._l(_vm.reminders, function (item, i) {
                                return _c("reminders-card", {
                                  key: i + _vm.localRefreshKey,
                                  attrs: { item: item },
                                  on: { updateDoneTodo: _vm.updateDoneTodo },
                                })
                              }),
                              _c("v-divider"),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                { attrs: { align: "center", flat: "" } },
                [
                  _c("v-divider"),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.$t("noDataAvailableLabel")) + " "),
                  ]),
                  _c("v-divider"),
                ],
                1
              ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }