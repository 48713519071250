var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "editor",
      on: {
        drop: _vm.dropAttachment,
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragleave: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            { ref: "templateEditorForm", staticClass: "template-editor-tab" },
            [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTextFieldsVisible,
                      expression: "isTextFieldsVisible",
                    },
                  ],
                  staticClass: "px-5 pt-4 mb-4",
                },
                [
                  _c(
                    "v-row",
                    { key: _vm.localRefreshKey },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("titleLabel") + "*",
                              "aria-required": "true",
                              "hide-details": "auto",
                              outlined: "",
                              rules: [
                                (v) =>
                                  v !== "" || _vm.$t("editTemplateErrorMsg4"),
                              ],
                              clearable: _vm.isEditable,
                              readonly: !_vm.isEditable,
                              autofocus: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _vm.userType === "T"
                                      ? _c(
                                          "pb-chip",
                                          {
                                            staticClass: "alignSlot mr-6",
                                            attrs: {
                                              outlined: "",
                                              large: "",
                                              text: "",
                                            },
                                            on: {
                                              click: _vm.toggleFavorite,
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.toggleFavorite.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.localTemplate.favorite === 1
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("v-icon", {
                                                      staticClass:
                                                        "fa-solid fa-star",
                                                      attrs: {
                                                        color: "orange",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "span",
                                                  [
                                                    _c("v-icon", {
                                                      staticClass:
                                                        "fa-regular fa-star",
                                                    }),
                                                  ],
                                                  1
                                                ),
                                            _c(
                                              "span",
                                              { staticClass: "pl-1" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "markFavoriteLabel"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.localTemplate.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.localTemplate, "title", $$v)
                              },
                              expression: "localTemplate.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.lessonLayoutItems,
                              label: _vm.$t("lessonLayoutLabel"),
                              clearable: "",
                              disabled: !_vm.isEditable,
                            },
                            model: {
                              value: _vm.localLessonLayoutId,
                              callback: function ($$v) {
                                _vm.localLessonLayoutId = $$v
                              },
                              expression: "localLessonLayoutId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.userMode === "T"
                ? _c(
                    "v-expansion-panels",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isTextFieldsVisible,
                          expression: "isTextFieldsVisible",
                        },
                      ],
                      ref: "panels",
                      attrs: {
                        accordion: "",
                        multiple: "",
                        flat: "",
                        tile: "",
                        role: "tablist",
                      },
                      model: {
                        value: _vm.panels,
                        callback: function ($$v) {
                          _vm.panels = $$v
                        },
                        expression: "panels",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        { attrs: { role: "tab" } },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              top: "6px",
                                              right: "16px",
                                            },
                                            attrs: {
                                              absolute: "",
                                              icon: "",
                                              "aria-label": _vm.$t(
                                                "addItemLabel",
                                                { text: _vm.$t("classLabel") }
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.addAppliedClass.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("fal fa-plus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1131500836
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("classLabel")))])]
                          ),
                          _vm.localTemplate.appliedClasses.length > 0
                            ? _c(
                                "v-expansion-panel-content",
                                { staticClass: "pt-4 pb-5" },
                                _vm._l(
                                  _vm.localTemplate.appliedClasses,
                                  function (appliedClass, i) {
                                    return _c(
                                      "v-row",
                                      { key: i },
                                      [
                                        appliedClass.editable
                                          ? _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    label: _vm.$t("classLabel"),
                                                    "display:inline-flex": "",
                                                    "return-object": "",
                                                    "hide-details": "auto",
                                                    items: _vm.classList,
                                                    "item-text": "cN",
                                                    outlined: "",
                                                    dense: "",
                                                    "aria-label": _vm.$t(
                                                      "classNumLabel",
                                                      { num: i + 1 }
                                                    ),
                                                  },
                                                  model: {
                                                    value: appliedClass.class,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        appliedClass,
                                                        "class",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "appliedClass.class",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: _vm.$t("classLabel"),
                                                    "display:inline-flex": "",
                                                    "hide-details": "auto",
                                                    outlined: "",
                                                    dense: "",
                                                    disabled: "",
                                                    "aria-label": _vm.$t(
                                                      "classNumLabel",
                                                      { num: i + 1 }
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      appliedClass.class
                                                        .className,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        appliedClass.class,
                                                        "className",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "appliedClass.class.className",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "3" } },
                                          [
                                            _c("multi-select-field", {
                                              attrs: {
                                                dense: "",
                                                "hide-details": "auto",
                                                label: _vm.$t("dayLabel"),
                                                ariaLabel:
                                                  _vm.$t("dayLabel") +
                                                  " " +
                                                  (i + 1),
                                                suffix: "Days",
                                                items: appliedClass.dayList,
                                                value: appliedClass.days,
                                                disabled:
                                                  !appliedClass.editable,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.updateSelectedDays(
                                                    $event,
                                                    i
                                                  )
                                                },
                                                change: function ($event) {
                                                  _vm.panels = [0]
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "4" } },
                                          [
                                            _c("pb-date-time-range-field", {
                                              attrs: {
                                                hideDetails: "auto",
                                                label: _vm.$t("dateLabel"),
                                                id: i + 1,
                                                start: appliedClass.startDate,
                                                end: appliedClass.endDate,
                                                rules: appliedClass.dateRules,
                                                dense: true,
                                                disabled:
                                                  !appliedClass.editable,
                                              },
                                              on: {
                                                "update:start": [
                                                  function ($event) {
                                                    return _vm.$set(
                                                      appliedClass,
                                                      "startDate",
                                                      $event
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.validateDate(
                                                      appliedClass
                                                    )
                                                  },
                                                ],
                                                "update:end": [
                                                  function ($event) {
                                                    return _vm.$set(
                                                      appliedClass,
                                                      "endDate",
                                                      $event
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.validateDate(
                                                      appliedClass
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "px-0 mx-0",
                                            attrs: {
                                              cols: "12",
                                              sm: "1",
                                              "align-center": "",
                                            },
                                          },
                                          [
                                            appliedClass.editable
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      "aria-label": _vm.$t(
                                                        "removeItemLabel",
                                                        {
                                                          text: _vm.$t(
                                                            "classLabel"
                                                          ),
                                                        }
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeAppliedClass(
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", {
                                                      staticClass:
                                                        "fal fa-trash-alt",
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  key: _vm.localRefreshKey,
                  on: {
                    mouseenter: function ($event) {
                      _vm.isSectionsHovered = true
                    },
                    mouseleave: function ($event) {
                      _vm.isSectionsHovered = false
                    },
                  },
                },
                [
                  _c("text-editor-full-screen-button", {
                    style:
                      !_vm.isSectionsHovered && _vm.isTextFieldsVisible
                        ? "color: transparent !important;"
                        : "",
                    attrs: {
                      isSectionsHovered: _vm.isSectionsHovered,
                      isTextFieldsVisible: _vm.isTextFieldsVisible,
                    },
                    on: { hideOrShowTextFields: _vm.hideOrShowTextFields },
                  }),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "editor-section-tabs",
                      attrs: { centered: "", "show-arrows": "" },
                      model: {
                        value: _vm.localSelectedTab,
                        callback: function ($$v) {
                          _vm.localSelectedTab = $$v
                        },
                        expression: "localSelectedTab",
                      },
                    },
                    _vm._l(_vm.filteredTabs, function (section) {
                      return _c("v-tab", { key: section.section }, [
                        _vm._v(_vm._s(section.label)),
                      ])
                    }),
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.localSelectedTab,
                        callback: function ($$v) {
                          _vm.localSelectedTab = $$v
                        },
                        expression: "localSelectedTab",
                      },
                    },
                    [
                      _vm._l(_vm.filteredTabs, function (section, index) {
                        return [
                          section.section === "lesson"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localSelectedTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                        },
                                        model: {
                                          value:
                                            _vm.localTemplate.lessonContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localTemplate,
                                              "lessonContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localTemplate.lessonContent",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab2"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localSelectedTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                        },
                                        model: {
                                          value:
                                            _vm.localTemplate.homeworkContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localTemplate,
                                              "homeworkContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localTemplate.homeworkContent",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab3"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localSelectedTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                        },
                                        model: {
                                          value: _vm.localTemplate.notesContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localTemplate,
                                              "notesContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localTemplate.notesContent",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab4"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localSelectedTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                        },
                                        model: {
                                          value: _vm.localTemplate.tab4Content,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localTemplate,
                                              "tab4Content",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localTemplate.tab4Content",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab5"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localSelectedTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                        },
                                        model: {
                                          value: _vm.localTemplate.tab5Content,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localTemplate,
                                              "tab5Content",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localTemplate.tab5Content",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab6"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localSelectedTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                        },
                                        model: {
                                          value: _vm.localTemplate.tab6Content,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localTemplate,
                                              "tab6Content",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localTemplate.tab6Content",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "strategies"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable &&
                                  _vm.localSelectedTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.strategyItems.length > 0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "clearAllLabel",
                                                                  {
                                                                    text: section.label,
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearStrategies,
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text: section.label,
                                                            }
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "myStrategies"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("fal fa-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              items: _vm.strategyItems,
                                              showShortValue: true,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteStrategy,
                                            },
                                          }),
                                          _c("strategies-picker", {
                                            attrs: {
                                              strategies: _vm.strategyItems,
                                            },
                                            on: {
                                              "update:strategies": [
                                                _vm.insertStrategies,
                                                function ($event) {
                                                  _vm.strategyItems = $event
                                                },
                                              ],
                                            },
                                            model: {
                                              value: _vm.dialogs.myStrategies,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "myStrategies",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogs.myStrategies",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.localSelectedTab === index
                                    ? _c("editor-section-list", {
                                        attrs: {
                                          items: _vm.strategyItems,
                                          showDelete: false,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "myStandards"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable &&
                                  _vm.localSelectedTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.selectedMyStandards
                                                      .length > 0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "clearAllLabel",
                                                                  {
                                                                    text: section.label,
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearMyStandards,
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text: section.label,
                                                            }
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "myLists"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("fal fa-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              items: _vm.myStandardItems,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteMyStandard,
                                            },
                                          }),
                                          _c("my-list-picker", {
                                            attrs: {
                                              standards:
                                                _vm.selectedMyStandards,
                                            },
                                            on: {
                                              "update:standards": function (
                                                $event
                                              ) {
                                                _vm.selectedMyStandards = $event
                                              },
                                            },
                                            model: {
                                              value: _vm.dialogs.myLists,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "myLists",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.myLists",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.localSelectedTab === index
                                    ? _c("editor-section-list", {
                                        attrs: {
                                          items: _vm.selectedMyStandards,
                                          showShortValue: true,
                                          showDelete: false,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "schoolStandards"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable &&
                                  _vm.localSelectedTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.selectedSchools.length >
                                                    0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "clearAllLabel",
                                                                  {
                                                                    text: section.label,
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearSchools,
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text: section.label,
                                                            }
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "mySchools"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("fal fa-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              items: _vm.selectedSchools,
                                              showShortValue: true,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteSchool,
                                            },
                                          }),
                                          _c("school-list-picker", {
                                            attrs: {
                                              standards: _vm.selectedSchools,
                                            },
                                            on: {
                                              "update:standards": [
                                                _vm.insertSchools,
                                                function ($event) {
                                                  _vm.selectedSchools = $event
                                                },
                                              ],
                                            },
                                            model: {
                                              value: _vm.dialogs.mySchools,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "mySchools",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.mySchools",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.localSelectedTab === index
                                    ? _c("editor-section-list", {
                                        attrs: {
                                          items: _vm.selectedSchools,
                                          showShortValue: true,
                                          showDelete: false,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "sharedStandards"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable &&
                                  _vm.localSelectedTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.selectedStandards
                                                      .length > 0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "clearAllLabel",
                                                                  {
                                                                    text: section.label,
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearStandards,
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text: section.label,
                                                            }
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "myStandards"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("fal fa-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              items: _vm.selectedStandards,
                                              showShortValue: true,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteStandard,
                                            },
                                          }),
                                          _c("standards-picker", {
                                            attrs: {
                                              classId: 0,
                                              standards: _vm.selectedStandards,
                                            },
                                            on: {
                                              "update:standards": [
                                                _vm.insertStandards,
                                                function ($event) {
                                                  _vm.selectedStandards = $event
                                                },
                                              ],
                                            },
                                            model: {
                                              value: _vm.dialogs.myStandards,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "myStandards",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.myStandards",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.localSelectedTab === index
                                    ? _c("editor-section-list", {
                                        attrs: {
                                          items: _vm.selectedStandards,
                                          showShortValue: true,
                                          showDelete: false,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "attachments"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.isEditable &&
                                  _vm.localSelectedTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            label: section.label,
                                            config:
                                              _vm.localEditorSectionConfig,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.attachments.length > 0
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "clearAllLabel",
                                                                  {
                                                                    text: section.label,
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearAttachments,
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text: section.label,
                                                            }
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "myFiles"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fal fa-paperclip"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addGoogleDriveLabel"
                                                          ),
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addGoogleDriveAttachments,
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fab fa-google-drive"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              items: _vm.attachmentItems,
                                              showShortValue: false,
                                              clickable: true,
                                            },
                                            on: {
                                              otherAction:
                                                _vm.toggleAttachmentPrivateFlag,
                                              deleteItem: _vm.deleteAttachment,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "value",
                                                  fn: function ({
                                                    value,
                                                    data,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "section-editor-link",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                data
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "other-actions",
                                                  fn: function ({
                                                    item,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "section-editor-checkbox",
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "privateLabel"
                                                            ),
                                                          "input-value":
                                                            item.data
                                                              .privateFlag,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleAttachmentPrivateFlag(
                                                              item,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.localSelectedTab === index
                                    ? _c("editor-section-list", {
                                        attrs: {
                                          items: _vm.attachmentItems,
                                          showShortValue: false,
                                          clickable: true,
                                          showDelete: false,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function ({ value, data }) {
                                                return [
                                                  !data.privateFlag
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "section-editor-link",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                data
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("pb-confirm", { ref: "confirm" }),
              _c(
                "pb-confirm",
                { ref: "embedFiles" },
                [
                  _c(
                    "v-chip-group",
                    {
                      attrs: {
                        "active-class": "accent1--text font-weight-bold",
                        column: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.localEmbedSections,
                        callback: function ($$v) {
                          _vm.localEmbedSections = $$v
                        },
                        expression: "localEmbedSections",
                      },
                    },
                    _vm._l(_vm.enabledLessonTextSections, function (item, i) {
                      return _c(
                        "v-chip",
                        { key: i, attrs: { value: item.section } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("my-files-picker", {
                ref: "filesPicker",
                attrs: { attachments: _vm.attachments },
                on: { "update:attachments": _vm.updateAttachments },
                model: {
                  value: _vm.dialogs.myFiles,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialogs, "myFiles", $$v)
                  },
                  expression: "dialogs.myFiles",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }