var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        input: _vm.input,
        typeLabel: _vm.$t("studentLabel"),
        isDirty: _vm.isDirty,
        withActions: true,
        onApply: _vm.doApply,
        applying: _vm.listLoading,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", [
                _vm.input.action === "E"
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))])
                  : _vm.input.action === "V"
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("viewLabel")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))]),
                _c("span", [_vm._v(" " + _vm._s(_vm.$t("studentLabel")))]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "action-button",
          fn: function () {
            return [
              _c("student-actions", {
                attrs: {
                  type: "button",
                  input: { student: _vm.input.student, mode: _vm.input.action },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "div",
        [
          _c("student-editor-form", {
            ref: "form",
            attrs: { input: _vm.input },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }