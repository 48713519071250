var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type == "absolute",
        "position-x": _vm.x,
        "position-y": _vm.y
      },
      scopedSlots: _vm._u(
        [
          _vm.type == "button"
            ? {
                key: "activator",
                fn: function(ref) {
                  var onMenu = ref.on
                  return [
                    _c(
                      "pb-btn",
                      _vm._g(
                        {
                          attrs: {
                            icon: "",
                            right: "",
                            label: _vm.$t("actionsLabel")
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "escape",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        },
                        Object.assign({}, onMenu)
                      ),
                      [_c("v-icon", [_vm._v("fal fa-ellipsis-v-alt")])],
                      1
                    )
                  ]
                }
              }
            : null
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu"
      }
    },
    [
      _c(
        "div",
        { staticClass: "editor-actions" },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.filteredActions, function(item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.doAction(item)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s(item.icon) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        staticStyle: { width: "130px" },
                        domProps: { textContent: _vm._s(_vm.$t(item.label)) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.localShowResetPasswordAlert, "max-width": "550" }
        },
        [
          _c(
            "v-card",
            { staticClass: "pb-3" },
            [
              _c("v-card-title", [
                _c("h4", [_vm._v(_vm._s(_vm.$t("passwordResetLabel")))])
              ]),
              _c("v-card-text", [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("passwordResetMsg", {
                        emailAddress: _vm.emailAddress
                      })
                    )
                  }
                })
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { width: "120px" },
                      attrs: {
                        depressed: "",
                        color: "error",
                        dark: "",
                        width: "auto",
                        type: "submit"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.closeResetAlert.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("okLabel")))]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }