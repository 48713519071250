var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        input: _vm.input,
        onApply: _vm.doApply,
        withActions: _vm.input.action === "E",
        typeLabel: _vm.$t("templateLabel"),
        isDirty: _vm.isDirty
      },
      on: { close: _vm.closeEditor },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("div", [
                _vm.input.action === "E"
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))])
                  : _vm.input.action === "V"
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$currentUser.isAdmin
                            ? _vm.$t("viewLabel")
                            : _vm.$t("applyLabel")
                        )
                      )
                    ])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))]),
                _c("span", [_vm._v(" " + _vm._s(_vm.$t("templateLabel")))])
              ])
            ]
          },
          proxy: true
        },
        {
          key: "action-button",
          fn: function() {
            return [
              _vm.input.action === "E"
                ? _c("template-actions", {
                    attrs: {
                      type: "button",
                      input: {
                        template: {
                          id: _vm.input.template.id,
                          isAdminTemplate: _vm.input.template.isAdminTemplate
                        }
                      }
                    }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "div",
        [
          _c("template-editor-form", {
            ref: "form",
            attrs: { input: _vm.input, isModal: _vm.isModal }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }