
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import { grades, genders } from '../../constants'
import FileServices from '@/services/file-services';
import { namespace } from 'vuex-class';
import { VForm } from 'vuetify/lib';
import { FormError } from '@/errors';
import ld from 'lodash';

const settings = namespace('settings');
const students = namespace('students');
const studentTags = namespace('studenttags');

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
})
export default class StudentEditor extends Vue {
    @Prop({
      type: Object,
      default: () => {
        return { action: 'A', data: {} };
      }
    })
    input!: any

    isEditable = true;
    selectedFile: any;
    selectingFile = false;
    errorMsg = '';
    emailError: any = [];
    keyError: any = [];
    shown = false;

    studentPassword = '';

    $refs!: {
      StudentEditorForm: InstanceType<typeof VForm>
      fileInput: HTMLInputElement,
    }

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @settings.Getter
    highContrastMode!: boolean;

    @students.Action
    addStudent!: (param?: any) => Promise<any>;

    @students.Action
    updateStudent!: (param?: any) => Promise<any>;

    @studentTags.Getter
    getTags!: Array<any>

    @students.Getter
    getSelectedTags!: Array<any>

    get gradesList(): any {
      return grades.filter(g => g.text !== this.$t('allLevelLabel')).concat([{ value: '99', text: this.$t('inactiveLabel').toString() }]);
    }

    get gendersList(): any {
      return genders;
    }

    get hasTags() {
      return CommonUtils.isNotEmpty(this.getSelectedTags);
    }

    get hasText() {
      return CommonUtils.hasText;
    }

    get localStudent() {
      return this.$store.state.students.localStudent
    }

    set localStudent(student: any) {
      this.$store.commit('students/setLocalStudent', student);
    }

    get localStudentClone() {
      return this.$store.state.students.origStudent;
    }

    set localStudentClone(student: any) {
      this.$store.commit('students/setOrigStudent', student);
    }

    get localTagsClone() {
      return this.$store.state.students.origTags;
    }

    set localTagsClone(student: any) {
      this.$store.commit('students/setOrigTags', student);
    }

    get selectedTags() {
      return this.getSelectedTags.map((t) => {
        const associatedTag = this.getTags.find((tag) => t.tagId === tag.studentTagId);
        t.color = associatedTag.color;
        t.tagText = associatedTag.tagText;
        return t;
      })
    }

    deleteTag(tag: any) {
      const selectedTagsCopy = this.getSelectedTags;
      const index = selectedTagsCopy.indexOf(tag.studentTagId);
      selectedTagsCopy.splice(index, 1);
      this.$store.commit('students/setSelectedTags', selectedTagsCopy);
    }

    clearFields() {
      this.localStudent = {
        photoUrl: '',
        code: '',
        studentId: '',
        emailAddress: '',
        firstName: '',
        parentEmailAddress: '',
        middleName: '',
        phoneNumber: '',
        lastName: '',
        birthDate: '',
        gradeLevel: '',
        gender: '',
        studentPassword: ''
      }
    }

    @Watch('localStudent.studentPassword')
    onPasswordChange() {
      if (this.shown === false && this.localStudent.studentPassword !== '********' && this.localStudent.studentPassword !== '' && this.studentPassword !== this.localStudent.studentPassword) {
        this.$snotify.info('Please take note of the Email and Student Key');
        this.shown = true;
      }
    }

    @Watch('input')
    doInit() {
      this.shown = false;
      const form: any = this.$refs.StudentEditorForm;
      if (this.input.loadData === false) {
        return;
      }
      try {
        form.resetValidation();
      } catch (e) {
      }
      if (this.input.action === 'A') {
        this.localTagsClone = [];
        this.$store.commit('students/setSelectedTags', []);
        this.clearFields();
        if (this.input.grade && this.input.grade.value !== 'A' && this.input.grade.value !== '-999') {
          this.localStudent.gradeLevel = this.input.grade;
        }
      } else if (this.input.action === 'E' || this.input.action === 'V') {
        const allTags = this.input.student.tags.map((t: any) => {
          const canEdit = this.userMode === 'A' || this.userMode === 'D' || t.type === 'TAG';
          return { editable: canEdit, tagId: t.typeId };
        })
        this.$store.commit('students/setSelectedTags', allTags);
        this.localTagsClone = this.selectedTags;
        this.localStudent = ld.cloneDeep(this.input.student);
        this.maskPassword(this.localStudent)
        if (this.localStudent.gradeLevel !== undefined) {
          this.localStudent.gradeLevel = this.gradesList.filter((a: any) => a.value === this.localStudent.gradeLevel.toString())[0];
        }
        this.localStudent.gender = this.gendersList.filter((a: any) => a.value === this.localStudent.gender)[0];
        this.isEditable = this.input.action === 'E';
      }
      this.localStudentClone = ld.cloneDeep(this.localStudent);
    }

    created() {
      this.doInit();
    }

    changesMade() {
      return !ld.isEqual(this.localStudentClone, this.localStudent) || !ld.isEqual(this.localTagsClone, this.getSelectedTags);
    }

    @Watch('localStudent.emailAddress')
    studentEmailUpdate() {
      this.emailError = [];
    }

    @Watch('localStudent.code')
    studentCodeUpdate() {
      this.keyError = [];
    }

    async onSave() {
      const form: any = this.$refs.StudentEditorForm;
      if (form.validate() && this.errorMsg.length === 0 && this.emailError.length === 0 && this.keyError.length === 0) {
        if (this.input.action === 'A') {
          return this.addStudent(this.localStudent).then(() => {
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg41') as string);
            }
            return Promise.resolve();
          }).catch((error) => {
            if (error instanceof FormError && error.data && (error.data.existingEmail === 'true' || error.data.existingEmail === true)) {
              this.emailError = [() => this.$t('studentErrorMsg4')];
              return Promise.reject(new FormError());
            } else if (error instanceof FormError && error.data && (error.data.existingStudentCode === 'true' || error.data.existingStudentCode === true)) {
              this.keyError = [() => this.$t('studentErrorMsg5')];
              return Promise.reject(new FormError());
            } else if (error instanceof FormError && error.data && (error.data.preventTeacherStudents === 'true' || error.data.preventTeacherStudents === true)) {
              this.errorMsg = this.$t('studentErrorMsg6').toString();
              return Promise.reject(new FormError());
            }
            return Promise.reject(error);
          });
        } else {
          return this.updateStudent(this.localStudent).then(() => {
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg42') as string);
            }
            return Promise.resolve();
          }).catch((error) => {
            if (error instanceof FormError && error.data && (error.data.existingEmail === 'true' || error.data.existingEmail === true)) {
              this.emailError = [() => this.$t('studentErrorMsg4')];
              return Promise.reject(new FormError());
            } else if (error instanceof FormError && error.data && (error.data.existingStudentCode === 'true' || error.data.existingStudentCode === true)) {
              this.keyError = [() => this.$t('studentErrorMsg5')];
              return Promise.reject(new FormError());
            }
            return Promise.reject(error);
          });
        }
      }
      return Promise.reject(new FormError());
    }

    // Student Password
    generateKey() {
      this.localStudent.studentPassword = this.pwGenerate();
    }

    pwGenerate() {
      var iteration = 0;
      var password = '';
      var randomNumber;

      while (iteration < 8) {
        randomNumber = (Math.floor((Math.random() * 100)) % 94) + 33;
        if ((randomNumber >= 33) && (randomNumber <= 47)) { continue; }
        if ((randomNumber >= 58) && (randomNumber <= 64)) { continue; }
        if ((randomNumber >= 91) && (randomNumber <= 96)) { continue; }
        if ((randomNumber >= 123) && (randomNumber <= 126)) { continue; }
        iteration++;
        password += String.fromCharCode(randomNumber);
      }
      return password;
    }

    // Profile Picture
    photoUpload = false;
    currentPhoto = '';
    get profilePhotoFileName() {
      return '#' + this.localStudent.studentId + '11';
    }

    editPhoto() {
      if (this.isEditable) {
        this.currentPhoto = this.localStudent.photoUrl;
        this.photoUpload = true;
      }
    }

    applyPhoto() {
      this.localStudent.photoUrl = this.currentPhoto;
      this.photoUpload = false;
    }

    defaultPhotoUrls(index: any) {
      if (index < 10) {
        return 'https://cdn.planbook.com/images/studentavatars/student-0' + index + '.png';
      } else {
        return 'https://cdn.planbook.com/images/studentavatars/student-' + index + '.png';
      }
    }

    selectDefault(index: number) {
      this.currentPhoto = this.defaultPhotoUrls(index);
    }

    onFileChanged(e: Event) {
      const fileInput: any = e.target;
      this.selectedFile = fileInput.files[0];
      this.currentPhoto = URL.createObjectURL(this.selectedFile);
      CommonUtils.showLoading();
      FileServices.uploadFile(this.selectedFile, this.profilePhotoFileName).then((resp) => {
        this.currentPhoto = resp.data.url;
        this.selectedFile = null;
      }).finally(CommonUtils.hideLoading);
    }

    selectImage() {
      if (!this.isEditable) {
        return;
      }
      this.selectingFile = true;
      window.addEventListener('focus', () => {
        this.selectingFile = false;
      }, { once: true });
      this.$refs.fileInput.click();
    }

    removePhoto() {
      this.currentPhoto = '';
      this.selectedFile = null;
    }

    maskPassword(localstudent : any) {
      this.studentPassword = localstudent.studentPassword;
      localstudent.studentPassword = '********';
    }
}
