



























































































































































































































































import { UserSession } from '@/common/user-session';
import EditorSection from '@/components/common/EditorSection.vue';
import EditorSectionList from '@/components/common/EditorSectionList.vue';
import { lessonSectionsPlaceHolders, sections } from '@/constants';
import { FormError } from '@/errors';
import FileServices from '@/services/file-services';
import LessonServices from '@/services/lesson-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VForm } from 'vuetify/lib';
import { namespace } from 'vuex-class';
import MultiSelectField from '../../components/core/MultiSelectField.vue';
import MyFilesPicker from '../pickers/MyFilesPicker.vue';
import MyListPicker from '../pickers/MyListPicker.vue';
import SchoolListPicker from '../pickers/SchoolListPicker.vue';
import StandardsPicker from '../pickers/StandardsPicker.vue';
import StrategiesPicker from '../pickers/StrategiesPicker.vue';
import Confirm from './../../components/core/Confirm.vue';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';

const settings = namespace('settings');
const classes = namespace('classes');
const templates = namespace('templates');
const plans = namespace('plans');
const index = namespace('index');

const attributeKeyMapping: any = {
  lesson: 'lessonContent',
  tab2: 'homeworkContent',
  tab3: 'notesContent',
  tab4: 'tab4Content',
  tab5: 'tab5Content',
  tab6: 'tab6Content'
};

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: {
    StandardsPicker,
    EditorSection,
    EditorSectionList,
    MyFilesPicker,
    MyListPicker,
    SchoolListPicker,
    StrategiesPicker,
    MultiSelectField,
    TextEditorFullScreenButton
  }
})
export default class TemplateEditor extends Vue {
    @Prop({
      type: Object,
      default: () => {
        return { loadData: true, data: {} };
      }
    })
    input!: any

    @Prop({ type: Boolean, default: true })
    isModal!: boolean;

    localRefreshKey = CommonUtils.generateUUID();
    userType: any;
    isEditable = true;
    classList:Array<any> = [];
    allTabs: Array<any> = [];
    dayList: Array<any> = [];
    panels: any = [];
    isSectionsHovered = false;
    isTextFieldsVisible = true;

    localEmbedSections: Array<string> = [];

    dialogs: any = {
      myLists: false,
      myFiles: false,
      myStandards: false,
      mySchools: false,
      myStrategies: false
    }

    $refs!: {
      templateEditorForm: InstanceType<typeof VForm>,
      confirm: Confirm,
      embedFiles: Confirm,
      observer: any,
      filesPicker: MyFilesPicker
    }

    get filteredTabs() {
      const fT = this.allTabs.filter((a) => (a.enabled === 'Y'))
      fT.sort((a, b) => a.displayOrder - b.displayOrder);
      fT.push({ section: 'attachments', label: 'Attachments', enabled: 'Y' })
      return fT;
    }

    @settings.Getter('getUserInfo')
    userInfo!: any;

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getLessonSectionLabel')
    getLessonSectionLabel!: (section: string) => string;

    @settings.Getter
    getLessonSections!: any;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @classes.Getter
    getClassesMap!: any;

    @classes.Getter
    cycleDays!: any;

    @templates.Action
    saveTemplate!: (param: any) => Promise<any>;

    @plans.Getter('getEnabledDayNums')
    weekdays!: any;

    @settings.Getter
    lessonLayoutItems!: Array<any>;

    @settings.Getter
    isCycleSchedule!: boolean;

    @index.State
    fullScreen!: boolean;

    get localLessonLayoutId() {
      return this.localTemplate.lessonLayoutId || this.$store.getters['settings/getCurrentLessonLayoutId'] || 0;
    }

    set localLessonLayoutId(value: number) {
      const clone = ld.cloneDeep(this.localTemplate);
      clone.lessonLayoutId = value;
      this.localTemplate = clone;
    }

    get localTemplate() {
      return this.$store.state.templates.localTemplate;
    }

    set localTemplate(template: any) {
      this.$store.commit('templates/setLocalTemplate', template);
    }

    get clonedTemplate() {
      return this.$store.state.templates.origTemplate;
    }

    set clonedTemplate(template: any) {
      this.$store.commit('templates/setOrigTemplate', template);
    }

    get clonedSections() {
      return this.$store.state.templates.origSections;
    }

    set clonedSections(sections: any) {
      this.$store.commit('templates/setOrigSections', sections);
    }

    get localSelectedTab() {
      return this.$store.state.templates.activeTab;
    }

    set localSelectedTab(tab: number) {
      this.$store.commit('templates/setActiveTab', tab);
    }

    get localTextEditorConfig() {
      return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill', minHeight: 370 };
    }

    get localEditorSectionConfig() {
      return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill', minHeight: 370 };
    }

    openDialog(type: string) {
      for (const key in this.dialogs) {
        this.dialogs[key] = type === key;
      }
    }

    loadClasses() {
      const classMap = this.getClassesMap;
      for (const i in classMap) {
        this.classList.push(classMap[i]);
      }
      this.classList.sort((c1, c2) => c1.cN.localeCompare(c2.cN))
    }

    loadDays() {
      this.dayList = [];
      this.loadEnabledDays();
      if (this.isCycleSchedule) {
        for (const i in this.cycleDays) {
          const dayKey = this.cycleDays[i].id.substring(10);
          this.dayList.push({ text: this.cycleDays[i].title, value: dayKey.toString() });
        }
      }
    }

    loadEnabledDays() {
      for (const i in this.weekdays) {
        if (this.weekdays[i] === 0) {
          this.dayList.push({ text: this.$t('sundayLabel'), value: 'U' });
        } else if (this.weekdays[i] === 1) {
          this.dayList.push({ text: this.$t('mondayLabel'), value: 'M' });
        } else if (this.weekdays[i] === 2) {
          this.dayList.push({ text: this.$t('tuesdayLabel'), value: 'T' });
        } else if (this.weekdays[i] === 3) {
          this.dayList.push({ text: this.$t('wednesdayLabel'), value: 'W' });
        } else if (this.weekdays[i] === 4) {
          this.dayList.push({ text: this.$t('thursdayLabel'), value: 'R' });
        } else if (this.weekdays[i] === 5) {
          this.dayList.push({ text: this.$t('fridayLabel'), value: 'F' });
        } else if (this.weekdays[i] === 6) {
          this.dayList.push({ text: this.$t('saturdayLabel'), value: 'S' });
        }
      }
    }

    created() {
      this.doInit();
    }

    toggleFavorite() {
      this.localTemplate.favorite = (this.localTemplate.favorite === 1) ? 0 : 1;
      this.localRefreshKey = CommonUtils.generateUUID();
    }

    clearFields() {
      this.localTemplate = {};
      this.localSelectedTab = 0;
      this.localTemplate.id = 0;
      this.localTemplate.schoolId = 0;
      this.localTemplate.districtId = 0;
      this.localTemplate.favorite = 0;
      this.localTemplate.title = '';
      this.localTemplate.lessonLayoutId = 0;
      this.localTemplate.lessonContent = '';
      this.localTemplate.homeworkContent = '';
      this.localTemplate.notesContent = '';
      this.localTemplate.tab4Content = '';
      this.localTemplate.tab5Content = '';
      this.localTemplate.tab6Content = '';
      this.selectedStandards = [];
      this.strategyItems = [];
      this.selectedMyStandards = [];
      this.selectedSchools = [];
      this.localTemplate.appliedClasses = Array<any>();
      this.localTemplate.removeAppliedClass = [];
      this.attachments = [];
    }

    @Watch('input')
    doInit() {
      this.loadClasses();
      this.loadDays();
      this.allTabs = this.getTabs;
      this.userType = this.userMode;

      if (this.input.loadData === false) {
        return;
      }

      this.clearFields();
      if (this.input.action === 'A') {
        this.isEditable = true;
      } else if (this.input.action === 'E' || this.input.action === 'V') {
        this.isEditable = !(this.input.action === 'V');
        this.localTemplate.id = this.input.template.id;
        this.localTemplate.schoolId = this.input.template.schoolId || 0;
        this.localTemplate.districtId = this.input.template.districtId || 0;
        this.localTemplate.favorite = this.input.template.favorite;
        this.localTemplate.title = this.input.template.title;
        this.localTemplate.lessonLayoutId = this.input.template.lessonLayoutId || 0;
        this.localTemplate.legacy = this.input.template.legacy;
        // text sections
        for (const section in this.input.template.content) {
          switch (this.input.template.content[section].section) {
            case 'L':
              this.localTemplate.lessonContent = this.input.template.content[section].value || ''
              break;
            case 'H':
              this.localTemplate.homeworkContent = this.input.template.content[section].value || ''
              break;
            case 'N':
              this.localTemplate.notesContent = this.input.template.content[section].value || ''
              break;
            case '4':
              this.localTemplate.tab4Content = this.input.template.content[section].value || ''
              break;
            case '5':
              this.localTemplate.tab5Content = this.input.template.content[section].value || ''
              break;
            case '6':
              this.localTemplate.tab6Content = this.input.template.content[section].value || ''
              break;
          }
        }
        // non-text sections
        this.insertMyStandards(this.input.template.myStandards)
        this.insertStandards(this.input.template.standards);
        this.insertSchools(this.input.template.schoolStandards);
        this.insertStrategies(this.input.template.strategies);
        this.attachments = this.input.template.attachments;

        // applied classes
        const appClassList = this.input.template.appliedToSubjects;
        this.localTemplate.appliedClasses = []
        for (const i in appClassList) {
          this.addAppliedClass(appClassList[i]);
        }
        this.localTemplate.removeAppliedClass = [];
      }
      // clone
      this.clonedTemplate = ld.cloneDeep(this.localTemplate);
      this.clonedSections = {
        strategies: ld.cloneDeep(this.strategyItems),
        myStandards: ld.cloneDeep(this.selectedMyStandards),
        schoolStandards: ld.cloneDeep(this.selectedSchools),
        sharedStandards: ld.cloneDeep(this.selectedStandards),
        attachments: ld.cloneDeep(this.attachments)
      }
      this.$nextTick(() => {
        this.panels = [0];
        this.localRefreshKey = CommonUtils.generateUUID();
      })
    }

    @Watch('localLessonLayoutId')
    onLayoutChanged() {
      this.allTabs = this.getTabs;
    }

    get getTabs() {
      const lessonSections = this.localLessonLayoutId > 0 ? LessonServices.getLessonSectionsByLayoutId(this.localLessonLayoutId) : this.getLessonSections;
      return sections.filter(s => s !== 'attachments').map((s: string) => {
        const section = lessonSections[s];
        return {
          section: s,
          displayOrder: section[s + 'DisplayOrder'],
          enabled: section[s + 'Enabled'],
          hide: section[s + 'Hide'],
          placeholder: lessonSectionsPlaceHolders.find((p: any) => p.value === s),
          label: section[s + 'Label'],
          textStyle: {
            fontFamily: CommonUtils.getFontFamily(section[s + 'TextFont']),
            fontSize: (section[s + 'TextSize'] || '10') + 'pt'
          }
        }
      });
    }

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    updateSelectedDays(items: Array<any>, index: number) {
      const clonedLocalTemplate = ld.cloneDeep(this.localTemplate);
      ld.set(clonedLocalTemplate, `appliedClasses[${index}].days`, items);
      this.localTemplate = clonedLocalTemplate;
    }

    changesMade() {
      const localAppliedClasses = this.localTemplate.appliedClasses.map((ac: any) => { return { class: ac.class, days: ac.days } })
      const clonedAppliedClasses = this.clonedTemplate.appliedClasses.map((ac: any) => { return { class: ac.class, days: ac.days } })

      const sections = {
        strategies: this.strategyItems,
        myStandards: this.selectedMyStandards,
        schoolStandards: this.selectedSchools,
        sharedStandards: this.selectedStandards,
        attachments: this.attachmentItems.map((a: any) => { return a.data })
      }
      return (!ld.isEqual(ld.omit(this.localTemplate, 'appliedClasses'), ld.omit(this.clonedTemplate, 'appliedClasses')) || !ld.isEqual(localAppliedClasses, clonedAppliedClasses) || !ld.isEqual(sections, this.clonedSections))
    }

    async onSave() {
      const appClasses = this.localTemplate.appliedClasses;
      let errorInAppliedClasses = false;
      for (const i in appClasses) {
        if (CommonUtils.hasText(appClasses[i].dateError)) {
          errorInAppliedClasses = true;
        }
      }
      const form: any = this.$refs.templateEditorForm;
      const dateFieldsValid = await this.$refs.observer.validate();
      if (form.validate() && dateFieldsValid && !errorInAppliedClasses) {
        this.localPage === 'templates' ? this.$store.commit('templates/setListLoading', true) : CommonUtils.showLoading();

        if (this.$currentUser.isDistrictAdmin) {
          return this.$refs.confirm.confirm({
            title: this.$t('saveTemplateLabel'),
            text: this.$t('saveTemplateWhere'),
            option1ButtonAlternativeText: this.$t('saveToSchoolLabel'),
            option2ButtonAlternativeText: this.$t('saveToDistrictLabel')
          }).then((result) => {
            if (result === 1) {
              this.localTemplate.schoolId = this.$store.getters['settings/getSchoolId'];
              this.localTemplate.districtId = 0;
              return this.doSaveTemplate();
            } else if (result === 2) {
              this.localTemplate.schoolId = 0;
              this.localTemplate.districtId = this.$store.getters['settings/getDistrictId'];
              return this.doSaveTemplate();
            }
          });
        } else {
          return this.doSaveTemplate();
        }
      }
      return Promise.reject(new FormError());
    }

    async doSaveTemplate() {
      const templateId = this.localTemplate.id;
      const isNew = !templateId || templateId === 0;
      return this.saveTemplate(this.localTemplate).then(() => {
        if (this.showSnackbarNotifications) {
          if (isNew) {
            this.$snotify.success(this.$t('statusMsg20') as string);
          } else {
            this.$snotify.success(this.$t('statusMsg21') as string);
          }
        }

        this.$eventBus.$emit('refreshTemplates');

        return Promise.resolve();
      });
    }

    // --- APPLIED CLASSES -----

    addAppliedClass(param?:any) {
      if (param && !(param instanceof MouseEvent)) {
        let when = [];
        if (param.when && param.when[0] === 'E') {
          when = this.dayList.map((d: any) => {
            return d.value
          })
        } else if (param.when) {
          when = this.dayList.filter((a) => param.when.includes(a.value)).map((d: any) => {
            return d.value;
          });
        }
        const associatedClass = this.classList.find((a) => a.cId === param.classId);
        const savedAppliedClass = {
          id: 'ac' + CommonUtils.generateUUID(),
          editable: (associatedClass !== undefined),
          class: (associatedClass !== undefined) ? associatedClass : param,
          days: when,
          dayList: ld.cloneDeep(this.dayList),
          startDate: param.start,
          endDate: param.end,
          customDayId: param.customDayId,
          dateError: '',
          dateRules: [() => '']
        }

        this.localTemplate.appliedClasses.push(savedAppliedClass)
      } else {
        if (this.classList.length === 0) {
          this.$refs.confirm.alert({
            title: this.$t('classesLabel'),
            text: this.$t('noAppliedClasses'),
            option1ButtonAlternativeText: this.$t('okLabel')
          })
          return;
        }
        this.localTemplate.appliedClasses.push({
          id: 'ac' + CommonUtils.generateUUID(),
          editable: true,
          class: this.classList[0],
          days: this.dayList.map((d: any) => d.value),
          dayList: ld.cloneDeep(this.dayList),
          startDate: this.classList[0].cSd,
          endDate: this.classList[0].cEd,
          customDayId: 0,
          dateError: '',
          dateRules: [() => '']
        })
        this.panels = [0];
      }
    }

    removeAppliedClass(i: number) {
      if (!this.localTemplate.appliedClasses[i].editable) {
        return false;
      }
      const removeID = this.localTemplate.appliedClasses[i].customDayId;
      if (removeID !== 0) {
        this.localTemplate.removeAppliedClass.push(removeID);
      }
      this.localTemplate.appliedClasses.splice(i, 1);
      this.panels = [0];
      return true;
    }

    validateDate(appliedClass: any) {
      const templateS = appliedClass.startDate;
      const templateE = appliedClass.endDate;
      const associatedClass = this.classList.filter((a) => a.cId === appliedClass.class.cId)[0];
      const classS = associatedClass.cSd;
      const classE = associatedClass.cEd;
      if (CommonUtils.hasNoText(templateS)) {
        appliedClass.dateError = this.$t('enterStartDate').toString();
      } else if (CommonUtils.hasNoText(templateE)) {
        appliedClass.dateError = this.$t('enterEndDate').toString();
      } else if (DateTimeUtils.isThisDateBeforeThatDate(templateS, classS)) {
        appliedClass.dateError = this.$t('editTemplateErrorMsg6').toString();
      } else if (DateTimeUtils.daysDiff(classS, templateS) > 365) {
        appliedClass.dateError = this.$t('editTemplateErrorMsg7').toString();
      } else if (DateTimeUtils.isThisDateBeforeThatDate(classE, templateE)) {
        appliedClass.dateError = this.$t('editTemplateErrorMsg8').toString();
      } else if (DateTimeUtils.daysDiff(templateE, classE) > 365) {
        appliedClass.dateError = this.$t('editTemplateErrorMsg9').toString();
      } else {
        appliedClass.dateError = '';
      }
      appliedClass.dateRules = [() => appliedClass.dateError];
      this.panels = [0];
    }

    // ---- ATTACHMENT FUNCTIONS -----
    get attachments(): Array<any> {
      return this.$store.state.templates.attachments || [];
    }

    set attachments(val: Array<any>) {
      this.$store.commit('templates/setAttachments', val);
    }

    get attachmentItems() {
      return this.attachments.map((a, i) => {
        const name = (a.name || a.filename || a.fileName || '');
        return {
          key: name + i,
          shortValue: '',
          value: name,
          data: a
        }
      })
    }

    deleteAttachment(item: any, index: number) {
      this.attachments = this.attachments.filter((s, i) => i !== index);
    }

    toggleAttachmentPrivateFlag(item: any, index: number) {
      const newAttachments = ld.cloneDeep(this.attachments);
      const attachment = newAttachments.find((a, i) => i === index);
      attachment.privateFlag = !attachment.privateFlag;
      ld.set(newAttachments, index, attachment);
      this.attachments = newAttachments;
    }

    addGoogleDriveAttachments() {
      this.$google.setAuthToken(UserSession.getGoogleAuthToken());
      this.$google.openPicker((data: any) => {
        this.updateAttachments([{
          filename: data.name,
          fileType: 'drive',
          url: data.url,
          id: data.id,
          privateFlag: false
        }])
      });
    }

    dropAttachment(e: any) {
      e.preventDefault();
      if (!this.isEditable) {
        return;
      }
      this.localSelectedTab = this.filteredTabs.findIndex((t) => t.section === 'attachments');
      const fileCount = e.dataTransfer.files.length;
      const droppedFiles = [];
      for (let i = 0; i < fileCount; i++) {
        droppedFiles.push(e.dataTransfer.files[i]);
      }
      this.$refs.filesPicker.appendFiles(droppedFiles);
      this.$refs.filesPicker.saveFiles();
    }

    clearAttachments() {
      const attachmentsLabel = this.$t('attachmentsLabel') as string;
      this.$refs.confirm.confirm({
        title: this.$t('clearAllLabel', { text: attachmentsLabel }),
        text: this.$t('clearAllMsg', { text: attachmentsLabel }),
        option1ButtonAlternativeText: this.$t('okLabel')
      }).then((result) => {
        if (result === 1) {
          this.attachments = [];
        }
      });
    }

    openLink(attachment: any) {
      const url = attachment.url;
      const name = attachment.name || attachment.filename || attachment.fileName || '';
      const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
      if (CommonUtils.hasText(url)) {
        window.open(url, '_blank');
      } else {
        downloadForm.innerHTML = '';
        const attachmentName = document.createElement('input');
        attachmentName.setAttribute('name', 'attachmentName');
        attachmentName.setAttribute('value', name);
        downloadForm.appendChild(attachmentName);

        const teacherId = document.createElement('input');
        teacherId.setAttribute('name', 'teacherId');
        teacherId.setAttribute('value', this.userInfo.teacherId);
        downloadForm.appendChild(teacherId);

        const collaborateSubjectId = document.createElement('input');
        collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
        collaborateSubjectId.setAttribute('value', '0');
        downloadForm.appendChild(collaborateSubjectId);

        const accessToken = document.createElement('input');
        accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
        accessToken.setAttribute('value', UserSession.getAccessToken());
        downloadForm.appendChild(accessToken);

        const yearId = document.createElement('input');
        accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
        accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
        downloadForm.appendChild(yearId);

        downloadForm.submit();
      }
    }

    // ---- STANDARD FUNCTIONS
    get selectedStandards() {
      return this.$store.state.templates.standards || [];
    }

    set selectedStandards(val: any) {
      this.$store.commit('templates/setStandards', val);
    }

    deleteStandard(item: any) {
      this.selectedStandards = this.selectedStandards.filter((s: any) => s.dbId !== item.dbId);
    }

    clearStandards() {
      const myClassesLabel = this.getLessonSectionLabel('sharedStandards');
      this.$refs.confirm.confirm({
        title: this.$t('clearAllLabel', { text: myClassesLabel }),
        text: this.$t('clearAllMsg', { text: myClassesLabel }),
        option1ButtonAlternativeText: this.$t('okLabel')
      }).then((result) => {
        if (result === 1) {
          this.selectedStandards = [];
        }
      });
    }

    insertStandards(param: any) {
      this.selectedStandards = param;
      for (const i in this.selectedStandards) {
        this.selectedStandards[i].value = this.selectedStandards[i].desc || this.selectedStandards[i].standardDesc;
        this.selectedStandards[i].shortValue = this.selectedStandards[i].id || this.selectedStandards[i].standardId;
        this.selectedStandards[i].key = this.selectedStandards[i].dbId || this.selectedStandards[i].standardDBId;
      }
    }

    // ---- STRATEGY FUNCTIONS
    get strategyItems() {
      return this.$store.state.templates.strategies || [];
    }

    set strategyItems(val: any) {
      this.$store.commit('templates/setStrategies', val);
    }

    deleteStrategy(item: any) {
      this.strategyItems = this.strategyItems.filter((s: any) => s.dbId !== item.dbId);
    }

    clearStrategies() {
      const strategiesLabel = this.getLessonSectionLabel('strategies');
      this.$refs.confirm.confirm({
        title: this.$t('clearAllLabel', { text: strategiesLabel }),
        text: this.$t('clearAllMsg', { text: strategiesLabel }),
        option1ButtonAlternativeText: this.$t('okLabel')
      }).then((result) => {
        if (result === 1) {
          this.strategyItems = [];
        }
      });
    }

    insertStrategies(param: any) {
      this.strategyItems = param;
      for (const i in this.strategyItems) {
        this.strategyItems[i].shortValue = this.strategyItems[i].code;
        this.strategyItems[i].value = this.strategyItems[i].title;
        this.strategyItems[i].key = this.strategyItems[i].strategyId;
      }
    }

    // ---- MY STANDARDS
    get selectedMyStandards() {
      return this.$store.state.templates.myStandards || [];
    }

    set selectedMyStandards(val: any) {
      this.$store.commit('templates/setMyStandards', val);
    }

    get myStandardItems() {
      if (!this.selectedMyStandards) {
        return [];
      }
      return this.selectedMyStandards.map((s: any) => {
        return {
          key: s.dbId,
          shortValue: s.id,
          value: s.desc
        }
      })
    }

    deleteMyStandard(item: any) {
      this.selectedMyStandards = this.selectedMyStandards.filter((s: any) => s.dbId !== item.key);
    }

    insertMyStandards(items: any) {
      this.selectedMyStandards = items;
      if (this.selectedMyStandards) {
        this.selectedMyStandards.map((s: any) => {
          s.dbId = s.standardDBId
          s.id = s.standardId
          s.desc = s.standardDesc
        })
      }
    }

    clearMyStandards() {
      const myStandardsLabel = this.getLessonSectionLabel('myStandards');
      this.$refs.confirm.confirm({
        title: this.$t('clearAllLabel', { text: myStandardsLabel }),
        text: this.$t('clearAllMsg', { text: myStandardsLabel }),
        option1ButtonAlternativeText: this.$t('okLabel')
      }).then((result) => {
        if (result === 1) {
          this.selectedMyStandards = [];
        }
      });
    }

    // ---- SCHOOL STANDARD FUNCTIONS
    get selectedSchools() {
      return this.$store.state.templates.schoolStandards || [];
    }

    set selectedSchools(val: any) {
      this.$store.commit('templates/setSchoolStandards', val);
    }

    deleteSchool(item: any) {
      const toDelete = item.standardDBId || item.dbId
      this.selectedSchools = this.selectedSchools.filter((s: any) => s.dbId === toDelete);
    }

    clearSchools() {
      const schoolListLabel = this.getLessonSectionLabel('schoolStandards');
      this.$refs.confirm.confirm({
        title: this.$t('clearAllLabel', { text: schoolListLabel }),
        text: this.$t('clearAllMsg', { text: schoolListLabel }),
        option1ButtonAlternativeText: this.$t('okLabel')
      }).then((result) => {
        if (result === 1) {
          this.selectedSchools = [];
        }
      });
    }

    insertSchools(param: any) {
      this.selectedSchools = param;
      for (const i in this.selectedSchools) {
        this.selectedSchools[i].dbId = this.selectedSchools[i].dbId || this.selectedSchools[i].standardDBId;
        this.selectedSchools[i].value = this.selectedSchools[i].desc || this.selectedSchools[i].standardDesc;
        this.selectedSchools[i].shortValue = this.selectedSchools[i].id || this.selectedSchools[i].standardId;
        this.selectedSchools[i].key = this.selectedSchools[i].dbId || this.selectedSchools[i].standardDBId;
      }
    }

    // --- ATTACHMENTS FUNCTIONS
    get enabledLessonTextSections() {
      return this.filteredTabs.filter((s: any) => ['lesson', 'tab2', 'tab3', 'tab4', 'tab5', 'tab6'].includes(s.section));
    }

    updateAttachments(files: Array<any>) {
      const extensions = ['ico', 'png', 'jpeg', 'jpg', 'svg', 'tif', 'webp'];
      files.forEach(a => {
        if (!this.attachmentExists(a) && !extensions.includes(this.getExtension(a))) {
          this.attachments.push(a);
        }
      });
      const imageFiles = files.filter(a => extensions.includes(this.getExtension(a)));
      if (imageFiles.length > 0) {
        return this.$refs.confirm.confirm({
          title: this.$t('imageActionsLabel'),
          text: this.$t('imageActionsMsg'),
          option1ButtonAlternativeText: this.$t('attachLabel'),
          option2ButtonAlternativeText: this.$t('embedLabel')
        }).then(async result => {
          if (result === 1) {
            files.forEach(a => {
              if (!this.attachmentExists(a)) {
                this.attachments.push(a);
              }
            });
          } else if (result === 2) {
            return this.$refs.embedFiles.confirm({
              title: this.$t('embedImagesLabel'),
              text: this.$t('imageActionsMsg2'),
              option1ButtonAlternativeText: this.$t('continueLabel')
            }).then(embedResult => {
              if (embedResult === 1) {
                if (CommonUtils.isNotEmpty(this.localEmbedSections)) {
                  this.localEmbedSections.forEach(s => {
                    imageFiles.forEach((img: any) => {
                      let url = img.url;
                      if (FileServices.isGoogleDriveFile(url)) {
                        const driveId = FileServices.guessGoogleDriveId(url);
                        if (CommonUtils.hasText(driveId)) {
                          url = `https://drive.google.com/uc?id=${driveId}&export=download`;
                        }
                      }
                      const attributeKey = `localTemplate.${attributeKeyMapping[s]}`;
                      let html = ld.get(this, attributeKey);
                      html += `<p><img style="width: 500px; max-width: 100%; height: auto;" src="${url}" alt="Image"/></p>`;
                      ld.set(this, attributeKey, html);
                    });
                  });
                  this.localSelectedTab = this.filteredTabs.findIndex(s => this.localEmbedSections.includes(s.section));
                }
              }
              return Promise.resolve();
            });
          }
          return Promise.resolve();
        }).finally(() => {
          this.localEmbedSections = [];
        });
      }
    }

    attachmentExists(file: any) {
      return this.attachments.some(a => this.getFileName(a) === this.getFileName(file));
    }

    getExtension(file: any) {
      const name = this.getFileName(file);
      return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    }

    getFileName(file: any) {
      return file.fileName || file.filename || file.name || '';
    }

    @Watch('fullScreen')
    onFullScreen() {
      this.$forceUpdate();
    }

    hideOrShowTextFields() {
      this.isTextFieldsVisible = !this.isTextFieldsVisible;
      this.localRefreshKey = CommonUtils.generateUUID();
    }
}
