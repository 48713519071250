
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SchoolYearEditorContainer extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  errorMsg: any;

  get hasErrorMsg() {
    return CommonUtils.hasText(this.errorMsg);
  }
}
