var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        input: _vm.input,
        withActions: _vm.withActions,
        withApply: _vm.withApply,
        onApply: _vm.doApply,
        typeLabel: _vm.$t("schoolYearLabel"),
        isDirty: _vm.isDirty,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "header",
            fn: function () {
              return [_c("span", [_vm._v(_vm._s(_vm.editorLabel))])]
            },
            proxy: true,
          },
          _vm.withActions
            ? {
                key: "action-button",
                fn: function () {
                  return [
                    _c("school-year-actions", {
                      attrs: { input: _vm.input, type: "button" },
                    }),
                  ]
                },
                proxy: true,
              }
            : null,
          _vm.withBottomActions
            ? {
                key: "bottom-actions",
                fn: function () {
                  return [
                    _c(
                      "v-card-actions",
                      [
                        _vm.canGoBack
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-3 mb-2 px-5",
                                attrs: { color: "info" },
                                on: { click: _vm.$refs.form.back },
                              },
                              [_vm._v(_vm._s(_vm.$t("backLabel")))]
                            )
                          : _vm._e(),
                        _c("v-spacer"),
                        _vm.canContinue
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mr-3 mb-2 px-5",
                                attrs: { color: "error" },
                                on: { click: _vm.doContinue },
                              },
                              [_vm._v(_vm._s(_vm.continueButtonLabel))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      [
        _c("school-year-editor-form", {
          ref: "form",
          attrs: { input: _vm.input, isModal: true, isActive: _vm.localValue },
        }),
        _c("pb-confirm", { ref: "confirm" }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }