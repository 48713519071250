import { render, staticRenderFns } from "./UnitEditor.vue?vue&type=template&id=48366261"
import script from "./UnitEditor.vue?vue&type=script&lang=ts"
export * from "./UnitEditor.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48366261')) {
      api.createRecord('48366261', component.options)
    } else {
      api.reload('48366261', component.options)
    }
    module.hot.accept("./UnitEditor.vue?vue&type=template&id=48366261", function () {
      api.rerender('48366261', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/units/UnitEditor.vue"
export default component.exports