var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.input.action === "G" || _vm.input.action === "GG"
    ? _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 500,
            expandable: false,
            withActions: false,
            withApply: false,
            onClose: _vm.refreshClasswork,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [_vm._v(_vm._s(_vm.$t("googleClasses")))]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2004078479
          ),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c("v-row", { staticClass: "mx-2" }, [
                _vm.autoClose
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("editGoogleCourseworkMessage1", {
                            title:
                              _vm.input.classwork.classworkTitle ||
                              _vm.input.classwork.assignmentTitle ||
                              _vm.input.assignment.assessmentTitle,
                          })
                        )
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("editGoogleCourseworkMessage3", {
                            title:
                              _vm.input.classwork.classworkTitle ||
                              _vm.input.classwork.assignmentTitle ||
                              _vm.input.assignment.assessmentTitle,
                          })
                        )
                      ),
                    ]),
              ]),
              _c(
                "v-row",
                { staticClass: "mx-2", attrs: { justify: "center" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("editGoogleCourseworkMessage2"))),
                  ]),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "mx-2 mt-4", attrs: { justify: "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.editGoogleClasswork(_vm.input.classwork)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("clickHereLabel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "pb-base-editor",
        {
          attrs: {
            isModal: _vm.isModal,
            isSubpage: _vm.isSubpage,
            withActions: !_vm.localReadOnlyMode,
            typeLabel: _vm.$t("classworkLabel"),
            isDirty: _vm.isDirty,
            withApply: !_vm.localReadOnlyMode,
            input: _vm.input,
            onApply: _vm.doApply,
            cardClass: "classwork-card",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _vm.input.classwork && _vm.input.classwork.isReadOnly
                        ? _c("div", [
                            _vm.localIsAssignment
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("assignmentsLabel"))),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.$t("assessmentsLabel"))),
                                ]),
                          ])
                        : _c("classwork-editor-header", {
                            attrs: {
                              isAssignment: _vm.localIsAssignment,
                              action: _vm.input.action,
                            },
                          }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "action-button",
              fn: function () {
                return [
                  !_vm.localReadOnlyMode
                    ? _c("classwork-actions", {
                        attrs: {
                          type: "button",
                          input: {
                            mode: _vm.input.action,
                            classwork: _vm.input.classwork,
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _c(
            "div",
            [
              _c("classwork-editor-form", {
                ref: "form",
                attrs: { input: _vm.input, isModal: _vm.isModal },
              }),
              _c("pb-confirm", { ref: "confirm" }),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }