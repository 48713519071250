
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import { VForm } from 'vuetify/lib';
import { Debounce } from 'vue-debounce-decorator';

const students = namespace('students');
const plans = namespace('plans');
const classes = namespace('classes');
const settings = namespace('settings');

@Component({
  components: {}
})
export default class StudentJoinClassEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  $refs!: {
    form: InstanceType<typeof VForm>
  }

  classJoinCode = '';
  notValidClassCodeError = false;
  isApplying = false;
  className = [];
  teacherFullName = [];
  teacherDisplayName = [];
  isValidJoinCode = false;
  typing = false;
  subjectIds = [];
  joinClassWarning = '';
  startDates = [];

  @Watch('classJoinCode')
  onJoinCodeChange(v:any) {
    if (v && this.notValidClassCodeError) {
      this.notValidClassCodeError = false;
    }
  }

  @Watch('classJoinCode')
  @Debounce(900)
  onClassJoinCodeChange() {
    this.typing = false;
  }

  @Watch('typing')
  onTypingChange() {
    if (!this.typing) {
      this.doVerify();
    }
  }

  @students.Action
  joinClass!: (param?: any) => Promise<any>;

  @students.Action
  verifyStudentJoinCode!: (value: string) => Promise<any>;

  @plans.Action
  reloadPlans!: () => Promise<any>;

  @classes.Action
  loadClasses!: (params?: any) => Promise<any>;

  @classes.Mutation
  setHaveClass!: (value: boolean) => void;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get localRules() {
    return [
      (v: any) => !!v || this.$t('requiredLabel'),
      () => {
        if (this.notValidClassCodeError) {
          return this.$t('joinClassErrorMsg');
        } else {
          return true;
        }
      }
    ];
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localClassesObject() {
    var result = [];
    const length = Math.min(this.subjectIds.length, this.className.length, this.teacherDisplayName.length);
    for (let i = 0; i < length; i++) {
      result.push({ subjectId: this.subjectIds[i], className: this.className[i], startDates: this.startDates[i], teacherDisplayName: this.teacherDisplayName[i] });
    }
    return result;
  }

  async doApply() {
    const form:any = this.$refs.form;
    if (!form.validate()) return Promise.resolve(true);
    this.isApplying = true;
    return this.joinClass({ studentClassJoinCode: this.classJoinCode })
      .then((resp:any) => {
        const data = resp.data;
        this.isApplying = false;
        if (data.count === 0) {
          this.notValidClassCodeError = true;
          form.validate();
          return Promise.resolve(true);
        } else {
          CommonUtils.showLoading();
          this.notValidClassCodeError = false;
          this.setHaveClass(false);
          this.loadClassesAndNotify(data).then(() => {
            return this.reloadPlans();
          })
            .finally(() => {
              CommonUtils.hideLoading();
            });
        }
      });
  }

  async loadClassesAndNotify(data: any) {
    var message = '';
    const allClassesVisible = !data.classesVisible.includes(0);
    if (data.count > 1) {
      message = allClassesVisible ? this.$t('classesAddedMsg') as string : this.$t('classNotVisibleMsg2') as string;
    } else {
      message = allClassesVisible ? this.$t('statusMsg5') as string : this.$t('classNotVisibleMsg') as string
    }
    return this.loadClasses().then(resp => {
      var timeout = allClassesVisible ? 2000 : 8000;
      if (this.showSnackbarNotifications) {
        this.$snotify.success(message, { timeout: timeout, html: '<div class="snotifyToast__body pa-2">' + message + '</div><div class="snotify-icon snotify-icon--success mx-2"></div>' });
      }
      return Promise.resolve(resp);
    })
  }

  async doVerify() {
    const form:any = this.$refs.form;
    CommonUtils.showLoading();
    this.verifyStudentJoinCode(this.classJoinCode).then((resp:any) => {
      if (resp.data.count === 0) {
        this.isValidJoinCode = false;
        this.teacherFullName = [];
        this.teacherDisplayName = [];
        this.className = [];
        this.startDates = [];
        this.notValidClassCodeError = true;
        this.joinClassWarning = '';
        form.validate();
        return Promise.resolve(true);
      }
      this.subjectIds = resp.data.subjectIds;
      this.teacherFullName = resp.data.teacherFullName;
      this.className = resp.data.className;
      this.startDates = resp.data.startDates;
      this.teacherDisplayName = resp.data.teacherDisplayName;
      this.isValidJoinCode = true;
      this.notValidClassCodeError = false;
      this.joinClassWarning = resp.data.subjectIds.length < this.classJoinCode.split(/\D/).length ? this.$t('joinClassWarningMsg').toString() : '';
    }).finally(() => {
      CommonUtils.hideLoading();
    });
  }
}
