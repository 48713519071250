
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop } from 'vue-property-decorator';
import StudentEditorForm from '@/components/students/StudentEditorForm.vue';
import StudentActions from '@/components/students/StudentActions.vue';
import { FormError } from '@/errors';
import { namespace } from 'vuex-class';
import { Editor } from '@/types/global.types';

const students = namespace('students');
@Component({
  components: {
    StudentEditorForm,
    StudentActions
  }
})
export default class StudentEditor extends Vue implements Editor {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @students.State
  listLoading!: any;

  $refs!: {
    form: StudentEditorForm
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  isDirty() {
    return this.$refs.form.changesMade()
  }

  typeChange() {
    this.input.loadData = false;
  }

  doApply() {
    this.localPage === 'students' ? this.$store.commit('students/setListLoading', true) : CommonUtils.showLoading();
    return this.$refs.form.onSave().then(() => {
      this.localValue = false;
      if (!this.isModal) {
        this.$eventBus.$emit('refreshStudents');
      }
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      }
      return Promise.resolve(true);
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$store.commit('students/setListLoading', false);
      return Promise.resolve(false);
    });
  }

  created() {
    this.$eventBus.$on('deleteStudent', (studentIds: any) => {
      if (!this.input.student) {
        return;
      }
      for (const i in studentIds) {
        if (studentIds[i] === this.input.student.studentId) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteStudent');
  }
}
