
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VForm } from 'vuetify/lib';
import Confirm from '../core/Confirm.vue';

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
})
export default class ResourcesEditorForm extends Vue {
    @Prop({
      type: Object,
      default: () => {
        return { loadData: true, data: {} };
      }
    })
    input!: any

    newFolderName = '';

    errorUploadFolderField = false;

    $refs!: {
      resourcesEditorForm: InstanceType<typeof VForm>,
      confirm: Confirm
    }
}
