import { render, staticRenderFns } from "./StudentActions.vue?vue&type=template&id=ee55dd4e&"
import script from "./StudentActions.vue?vue&type=script&lang=ts&"
export * from "./StudentActions.vue?vue&type=script&lang=ts&"
import style0 from "./StudentActions.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMenu,VRow})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ee55dd4e')) {
      api.createRecord('ee55dd4e', component.options)
    } else {
      api.reload('ee55dd4e', component.options)
    }
    module.hot.accept("./StudentActions.vue?vue&type=template&id=ee55dd4e&", function () {
      api.rerender('ee55dd4e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/students/StudentActions.vue"
export default component.exports