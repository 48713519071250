var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-container",
            { staticClass: "px-5 pt-4 mb-4" },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          label: _vm.$t("accountTypeLabel"),
                          items: _vm.accountTypes,
                          "hide-details": "auto",
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("requiredMsg2")
                            }
                          ],
                          outlined: "",
                          readonly: !_vm.isEditable,
                          autofocus: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.teacherId > 0
                              ? {
                                  key: "prepend",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "alignPhoto mr-6 pa-2 focus-border",
                                          attrs: {
                                            outlined: "",
                                            width: "80",
                                            height: "80px",
                                            loading: _vm.selectingFile,
                                            tabindex: "0",
                                            role: "button"
                                          },
                                          on: {
                                            click: _vm.choosePhoto,
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.choosePhoto.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            { staticClass: "pa-0" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "title-on-border"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("photoLabel"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm.hasPhotoUrl
                                                    ? _c("img", {
                                                        staticClass: "px-0",
                                                        staticStyle: {
                                                          width: "60px",
                                                          height: "60px",
                                                          position: "absolute",
                                                          top: "10px",
                                                          left: "10px",
                                                          "object-fit": "cover"
                                                        },
                                                        attrs: {
                                                          src: _vm.photoUrl,
                                                          role: "presentation"
                                                        }
                                                      })
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mt-3",
                                                          attrs: {
                                                            large: "",
                                                            color: "grey"
                                                          }
                                                        },
                                                        [_vm._v("fas fa-user")]
                                                      )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.userType,
                          callback: function($$v) {
                            _vm.userType = $$v
                          },
                          expression: "userType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("displayNameLabel"),
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable
                        },
                        model: {
                          value: _vm.displayName,
                          callback: function($$v) {
                            _vm.displayName = $$v
                          },
                          expression: "displayName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("emailAddressLabel") + "*",
                          "aria-required": "true",
                          "hide-details": "auto",
                          outlined: "",
                          disabled: "",
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("requiredMsg2")
                            }
                          ]
                        },
                        model: {
                          value: _vm.emailAddress,
                          callback: function($$v) {
                            _vm.emailAddress = $$v
                          },
                          expression: "emailAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("firstNameLabel") + "*",
                          "aria-required": "true",
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable,
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("requiredMsg2")
                            }
                          ]
                        },
                        model: {
                          value: _vm.firstName,
                          callback: function($$v) {
                            _vm.firstName = $$v
                          },
                          expression: "firstName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("lastNameLabel") + "*",
                          "aria-required": "true",
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable,
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("requiredMsg2")
                            }
                          ]
                        },
                        model: {
                          value: _vm.lastName,
                          callback: function($$v) {
                            _vm.lastName = $$v
                          },
                          expression: "lastName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.currentUserType == "D"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "teacher-schools-combobox",
                            attrs: {
                              chips: "",
                              multiple: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.schoolItems,
                              label: _vm.$t("schoolsLabel"),
                              clearable: _vm.teacherId > 0,
                              disabled: _vm.inviteId > 0
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var attrs = ref.attrs
                                    var item = ref.item
                                    var select = ref.select
                                    return [
                                      _c(
                                        "v-chip",
                                        _vm._b(
                                          {
                                            staticClass: "my-1",
                                            attrs: { close: _vm.teacherId > 0 },
                                            on: {
                                              click: select,
                                              "click:close": function($event) {
                                                return _vm.removeSchool(item)
                                              }
                                            }
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-1",
                                              staticStyle: {
                                                "margin-left": "-5px"
                                              },
                                              attrs: {
                                                small: "",
                                                icon: "",
                                                elevation: "0"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.teacherId > 0 &&
                                                    _vm.setPrimary(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm.isPrimary(item)
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "yellow accent-1"
                                                      }
                                                    },
                                                    [_vm._v("fa-solid fa-star")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("fa-light fa-star")]
                                                  )
                                            ],
                                            1
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(item.text))
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3881573049
                            ),
                            model: {
                              value: _vm.selectedSchools,
                              callback: function($$v) {
                                _vm.selectedSchools = $$v
                              },
                              expression: "selectedSchools"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 600, expandable: false, withActions: false },
          on: { apply: _vm.applyPhoto },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v(_vm._s(_vm.$t("editPhotoLabel")))]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isChoosingPhoto,
            callback: function($$v) {
              _vm.isChoosingPhoto = $$v
            },
            expression: "isChoosingPhoto"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex flex-nowrap" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "4" }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            outlined: "",
                            width: "160px",
                            height: "218px"
                          }
                        },
                        [
                          _c("v-card-text", [
                            _c("span", { staticClass: "title-on-border" }, [
                              _vm._v(_vm._s(_vm.$t("photoLabel")))
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: { height: "140px" }
                            },
                            [
                              _vm.hasLocalPhotoUrl
                                ? _c("img", {
                                    staticStyle: {
                                      width: "135px",
                                      height: "135px",
                                      "object-fit": "cover"
                                    },
                                    attrs: {
                                      src: _vm.localPhotoUrl,
                                      role: "presentation"
                                    }
                                  })
                                : _c(
                                    "v-icon",
                                    { attrs: { size: "120px", color: "grey" } },
                                    [_vm._v("fas fa-user")]
                                  ),
                              _c("input", {
                                ref: "fileInput",
                                staticClass: "d-none",
                                attrs: {
                                  type: "file",
                                  accept:
                                    ".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                },
                                on: { change: _vm.onFileChanged }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    text: "",
                                    loading: _vm.selectingFile
                                  },
                                  on: { click: _vm.selectImage }
                                },
                                [_vm._v(_vm._s(_vm.$t("uploadLabel")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    small: "",
                                    text: ""
                                  },
                                  on: { click: _vm.removePhoto }
                                },
                                [_vm._v(_vm._s(_vm.$t("removeLabel")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c("div", { staticClass: "mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("photoGalleryLabel")))
                    ]),
                    _c(
                      "div",
                      { staticStyle: { overflow: "none", maxHeight: "200px" } },
                      _vm._l(2, function(indexI) {
                        return _c(
                          "v-row",
                          {
                            key: indexI,
                            staticClass: "d-flex flex-nowrap mb-2",
                            class: { "mt-0": indexI === 1 }
                          },
                          _vm._l(5, function(indexJ) {
                            return _c(
                              "div",
                              {
                                key: indexJ,
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.$t("defaultPhotoLabel", {
                                    num: (indexI - 1) * 5 + indexJ + 1
                                  })
                                },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.selectDefault((indexI - 1) * 5 + indexJ)
                                  },
                                  click: function($event) {
                                    _vm.selectDefault((indexI - 1) * 5 + indexJ)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "px-2",
                                  staticStyle: {
                                    width: "75px",
                                    height: "75px",
                                    cursor: "pointer"
                                  },
                                  attrs: {
                                    src: _vm.defaultPhotoUrls(
                                      (indexI - 1) * 5 + indexJ
                                    ),
                                    role: "presentation"
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      1
                    ),
                    _c("div")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }