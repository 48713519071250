
import { Component, Prop, Vue } from 'vue-property-decorator';
import TemplateEditorForm from '@/components/templates/TemplateEditorForm.vue';
import TemplateActions from '@/components/templates/TemplateActions.vue';
import CommonUtils from '@/utils/common-utils';
import { FormError } from '@/errors';
import { namespace } from 'vuex-class';
import { Editor } from '@/types/global.types';

const templates = namespace('templates');
@Component({
  components: {
    TemplateEditorForm,
    TemplateActions
  }
})
export default class EventEditor extends Vue implements Editor {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @Prop({ type: Boolean })
  value!: boolean;

  @templates.State
  listLoading!: boolean;

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  $refs!: {
    form: TemplateEditorForm
  }

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  isDirty() {
    return this.$refs.form.changesMade();
  }

  typeChange() {
    this.input.loadData = false;
  }

  closeEditor() {
    return Promise.resolve();
  }

  doApply() {
    this.localPage === 'templates' ? this.$store.commit('templates/setListLoading', true) : CommonUtils.showLoading();
    return this.$refs.form.onSave().then(() => {
      this.localValue = false;
      if (!this.isModal) {
        this.$eventBus.$emit('refreshTemplates');
      }
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      }
      return Promise.resolve(true)
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$store.commit('templates/setListLoading', false)
      return Promise.resolve(false)
    });
  }

  created() {
    this.$eventBus.$on('deleteTemplate', (templateIds: any) => {
      if (!this.input.template) {
        return;
      }
      for (const i in templateIds) {
        if (templateIds[i] === this.input.template.id) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteTemplate');
  }
}
