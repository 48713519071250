
































































import { Deferred } from '@/common/deferred';
import Confirm from '@/components/core/Confirm.vue';
import { FormError } from '@/errors';
import UserServices from '@/services/user-services';
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const teachers = namespace('teachers');
const settings = namespace('settings');

@Component
export default class TeacherActions extends Vue implements ActionsModal {
  actions: Array<any> = [
    {
      id: 'copy',
      icon: 'fal fa-copy',
      label: 'copySchoolYearLabel'
    },
    {
      id: 'delete',
      icon: 'fal fa-trash-alt',
      label: 'removeTeacherLabel'
    },
    {
      id: 'reset',
      icon: 'fal fa-unlock',
      label: 'resetPasswordLabel'
    }
  ];

  @teachers.Action
  delete!: (params?: any) => Promise<any>;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @Prop({ required: false, type: String, default: 'absolute' })
  type!: string;

  showMenu = false;
  localShowResetPasswordAlert = false;
  localShowCopySchoolYear = false;
  deferredReset = new Deferred();
  x = 0;
  y = 0;

  $refs!: {
    confirm: Confirm,
    copySchoolYears: any
  };

  get teacher() {
    return this.$store.state.teachers.currentTeacher || {};
  }

  get inviteId(): number {
    return this.teacher.inviteId;
  }

  get emailAddress(): string {
    return this.teacher.emailAddress;
  }

  get filteredActions() {
    return this.actions.filter(a => {
      if (a.id === 'copy' && this.inviteId > 0) {
        return false;
      } else if (a.id === 'reset' && this.inviteId > 0) {
        return false;
      }
      return true;
    });
  }

  async doAction(action: any) {
    CommonUtils.showLoading();
    return Promise.resolve()
      .then(() => {
        if (action.id === 'delete') {
          return this.doDeleteAction();
        } else if (action.id === 'reset') {
          return this.doResetAction();
        } else if (action.id === 'copy') {
          this.showMenu = false;
          this.$eventBus.$emit('openCopySchoolYear');
          return Promise.resolve();
        }
        return Promise.resolve();
      })
      .finally(() => {
        this.showMenu = false;
        CommonUtils.hideLoading();
      });
  }

  closeEditor() {
    this.$eventBus.$emit('closeSubPage');
  }

  async doDeleteTeachers(params: any) {
    const teachers: Array<any> = params.teachers;
    const teacherIds: Array<number> = [];
    const inviteIds: Array<number> = [];
    teachers.forEach(teacher => {
      if (teacher.inviteId > 0) {
        inviteIds.push(teacher.inviteId);
      } else if (teacher.teacherId > 0) {
        teacherIds.push(teacher.teacherId);
      }
    });
    return this.delete({ teacherIds, inviteIds });
  }

  async doDeleteAction() {
    return this.$refs.confirm
      .confirm({
        title: this.$t('removeTeacherLabel'),
        text: this.$t('confirmRemoveTeacherMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      })
      .then(async result => {
        if (result === 1) {
          CommonUtils.showLoading();
          return this.doDeleteTeachers({
            teachers: [this.teacher]
          }).then(() => {
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg29') as string);
            }
            return Promise.resolve();
          });
        } else {
          return Promise.reject(new FormError());
        }
      })
      .then(() => {
        this.closeEditor();
        return Promise.resolve();
      })
      .finally(() => {
        CommonUtils.hideLoading();
      });
  }

  async alertReset() {
    this.localShowResetPasswordAlert = true;
    this.deferredReset = new Deferred();
    return this.deferredReset.promise;
  }

  closeResetAlert() {
    this.localShowResetPasswordAlert = false;
    this.deferredReset.resolve();
  }

  async doResetAction() {
    return UserServices.resetPassword({ email: this.emailAddress }).then(() => {
      return this.alertReset();
    });
  }

  public show(e: MouseEvent) {
    e.preventDefault();
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.showMenu = true;
      });
    });
  }
}
