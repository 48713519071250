var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type == "absolute",
        "position-x": _vm.x,
        "position-y": _vm.y
      },
      scopedSlots: _vm._u(
        [
          _vm.type == "button"
            ? {
                key: "activator",
                fn: function(ref) {
                  var onMenu = ref.on
                  return [
                    _c(
                      "pb-btn",
                      _vm._g(
                        {
                          attrs: {
                            label: _vm.$t("actionsLabel"),
                            icon: "",
                            right: ""
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "escape",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        },
                        Object.assign({}, onMenu)
                      ),
                      [_c("v-icon", [_vm._v("fal fa-ellipsis-v-alt")])],
                      1
                    )
                  ]
                }
              }
            : null
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu"
      }
    },
    [
      _c(
        "div",
        { staticClass: "editor-actions" },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm._l(_vm.filteredActions, function(item, i) {
                return _c(
                  "v-list-item",
                  {
                    key: i,
                    attrs: { link: "" },
                    on: {
                      click: function($event) {
                        return _vm.doAction(item)
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("v-icon", {
                          domProps: { textContent: _vm._s(item.icon) }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", {
                          domProps: { textContent: _vm._s(_vm.$t(item.label)) }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.getIsConnectedToGoogle
                ? _c(
                    "v-list-group",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "appendIcon",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-chevron-down")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "activator",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", [
                                      _vm._v("fa-brands fa-google")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(_vm.$t("transferToGoogle")))
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        4258314034
                      ),
                      model: {
                        value: _vm.showingGoogleClasses,
                        callback: function($$v) {
                          _vm.showingGoogleClasses = $$v
                        },
                        expression: "showingGoogleClasses"
                      }
                    },
                    [
                      _c("v-divider"),
                      _vm._l(_vm.googleClassesList, function(item, e) {
                        return _c(
                          "v-list-item",
                          {
                            key: "googleclass-" + e,
                            staticClass: "pl-4",
                            attrs: {
                              tabindex: _vm.showingGoogleClasses ? 0 : -1
                            },
                            on: {
                              click: function($event) {
                                return _vm.moveClassworkToGoogle(item)
                              }
                            }
                          },
                          [
                            _c("v-list-item-icon"),
                            _c("v-list-item-title", {
                              staticStyle: { width: "130px" },
                              domProps: { textContent: _vm._s(item.name) }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }