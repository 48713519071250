
import { PlanbookStorage } from '@/common/planbook-storage';
import { UserSession } from '@/common/user-session';
import { FormError } from '@/errors';
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';

const units = namespace('units');
const plans = namespace('plans');
const prints = namespace('prints');
const settings = namespace('settings');
const classes = namespace('classes');

@Component
export default class UnitActions extends Vue implements ActionsModal {
    actions: Array<any> = [
      {
        id: 'lessons',
        icon: 'fal fa-book',
        label: 'lessonsLabel'
      },
      {
        id: 'delete',
        icon: 'fal fa-trash-alt',
        label: 'deleteLabel'
      },
      {
        id: 'print',
        icon: 'fal fa-arrow-up-from-square',
        label: 'sendToPDFLabel2'
      }
    ];

    @Prop({
      required: true,
      type: Object,
      default: () => {
        return { loadData: true, data: {}, exclude: [] };
      }
    })
    input!: any;

    @Prop({ required: false, type: String, default: 'absolute' })
    type!: string;

    showMenu = false;
    x = 0;
    y = 0;

    @plans.State
    viewType!: string;

    @units.State
    unit: any;

    @prints.Action
    initPrint!: (value?: any) => Promise<any>;

    @prints.Action
    print!: (value?: any) => Promise<any>;

    @units.Action
    delete!: (value?: any) => Promise<any>;

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @settings.Getter
    getSharingOptionsSettings!: any;

    @classes.Getter('getClassItems')
    classItems!: any[];

    get sharedClasses():any[] {
      const sharedClasses:any[] = [];
      this.getSharingOptionsSettings.sharedList.forEach((shared:any) => {
        shared.years.forEach((year:any) => {
          if (year.yearId > 0) {
            year.classes.forEach((classes:any) => {
              classes.text = classes.className;
              sharedClasses.push(classes);
            });
          }
        });
      });
      return sharedClasses.map((s:any) => {
        s.value = s.classId;
        return s;
      })
        .sort((a:any, b:any) => {
          const fa = a.email.toLowerCase();
          const fb = b.email.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
    }

    get listViewClasses() {
      return this.classItems.concat(this.sharedClasses);
    }

    $refs!: {
        confirm: Confirm
    }

    get filteredActions() {
      return this.actions.filter(a => {
        if (this.userMode === 'A' && a.id === 'print') {
          return false;
        }
        return true;
      });
    }

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    get isPlansListView() {
      return this.localPage === 'plans' && this.viewType === 'L';
    }

    async doAction(action: any) {
      return Promise.resolve().then(() => {
        if (action.id === 'print') {
          return this.doPrint();
        } else if (action.id === 'delete') {
          return this.doDeleteUnit();
        } else if (action.id === 'lessons') {
          if (this.localPage === 'lesson-banks' || this.isPlansListView) {
            this.$eventBus.$emit('selectUnit', this.unit);
            return;
          }
          //, query: { unitId: this.unit.unitId, viewType: 'L', subjectId: this.unit.subjectId }
          PlanbookStorage.set('unitViewLessonsParams', { unitId: this.unit.unitId, viewType: 'L', subjectId: this.unit.subjectId });
          if (this.listViewClasses.some(l => +l.value === +this.unit.subjectId)) {
            if (this.localPage !== 'plans') {
              this.$nextTick(() => {
                this.$router.push({ path: 'plans' });
              });
            } else {
              this.$eventBus.$emit('selectUnit', this.unit);
            }
          } else {
            this.$nextTick(() => {
              this.$router.push({ path: 'lesson-banks' });
            });
          }
        }
      }).catch((error) => {
        if (!(error instanceof FormError)) {
          return Promise.reject(error);
        }
      }).finally(() => {
        this.showMenu = false;
      });
    }

    async doPrint() {
      CommonUtils.showLoading();
      return this.initPrint({ printView: 'UnitLessons' }).then(() => {
        return this.print({
          authToken: UserSession.getAccessToken(),
          unitId: this.unit.unitId
        });
      }).finally(() => {
        CommonUtils.hideLoading();
        this.closeEditor();
      });
    }

    async doDeleteUnit() {
      return this.$refs.confirm.confirm({
        title: this.$t('unitLabel'),
        text: this.$t('confirmDeleteUnit'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then(async (result) => {
        if (result === 1) {
          this.localPage === 'units' ? this.$store.commit('units/setListLoading', true) : CommonUtils.showLoading();
          return this.delete().then(() => {
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg40') as string);
            }
            return Promise.resolve();
          });
        } else {
          return Promise.reject(new FormError());
        }
      }).then(() => {
        this.localPage === 'units' ? this.$store.commit('units/setListLoading', false) : CommonUtils.hideLoading();
        this.closeEditor();
      });
    }

    closeEditor() {
      this.$eventBus.$emit('closeSubPage');
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }
}
