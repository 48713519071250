








































































































































































































































































































import { UserSession } from '@/common/user-session';
import EditorSection from '@/components/common/EditorSection.vue';
import EditorSectionList from '@/components/common/EditorSectionList.vue';
import { FormError } from '@/errors';
import FileServices from '@/services/file-services';
import LessonServices from '@/services/lesson-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import WebUtils from '@/utils/web-utils';
import ld from 'lodash';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VForm } from 'vuetify/lib';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import MyFilesPicker from '../pickers/MyFilesPicker.vue';
import MyListPicker from '../pickers/MyListPicker.vue';
import SchoolListPicker from '../pickers/SchoolListPicker.vue';
import StandardsPicker from '../pickers/StandardsPicker.vue';
import StrategiesPicker from '../pickers/StrategiesPicker.vue';
import ClassworkLessonAction from './actions/ClassworkLessonAction.vue';
import ClassworkEditor from '@/components/classwork/ClassworkEditor.vue';
import DateTimeRangeField from '@/components/core/DateTimeRangeField.vue';
import TextEditorFullScreenButton from '@/components/common/TextEditorFullScreenButton.vue';

const lessons = namespace('lessons');
const settings = namespace('settings');
const classes = namespace('classes');
const lessonlists = namespace('lessonlists');
const classwork = namespace('classwork');

@Component({
  components: {
    EditorSectionList,
    EditorSection,
    StandardsPicker,
    SchoolListPicker,
    MyListPicker,
    StrategiesPicker,
    MyFilesPicker,
    ClassworkLessonAction,
    ClassworkEditor,
    TextEditorFullScreenButton
  }
})
export default class LessonEditorForm extends Vue {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: { date: '' } };
    }
  })
  input!: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @lessons.State
  sections!: Array<any>;

  @lessons.Action
  init!: (params: any) => Promise<any>;

  @lessons.Mutation
  initSections!: () => Promise<any>;

  @lessons.Action
  public save!: (params?: any) => Promise<any>;

  @settings.Getter('getLessonSectionLabel')
  getLessonSectionLabel!: (section: string) => string;

  @settings.Getter
  highContrastMode!: boolean;

  @lessons.Getter
  canView!: (section: string) => boolean;

  @lessons.Getter
  canEdit!: (section: string) => boolean;

  @lessons.State
  lessonId!: number;

  @classes.Getter('getClassItems')
  classItems!: Array<any>;

  @classes.Getter('getLessonBankItems')
  lessonBankItems!: Array<any>;

  @classes.State
  lessonBanks!: any;

  @classes.Getter
  getClassLessonLayoutId!: (classId: number) => number;

  @classes.State
  classes!: any;

  @classes.State
  classUnits!: any;

  @lessonlists.Getter('getSelectedLessonBankClassId')
  selectedLessonBankClassId!: number;

  @classwork.Getter
  getAllClasswork!: any;

  @classwork.Action
  loadClasswork!: (param: any) => Promise<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  $refs!: {
    confirm: Confirm,
    embedFiles: Confirm,
    classworkAction: any,
    lessonEditorForm: InstanceType<typeof VForm>,
    observer: any,
    filesPicker: MyFilesPicker,
    lessonTime: DateTimeRangeField
  }

  dialogs: any = {
    standards: false,
    myStandards: false,
    schoolStandards: false,
    strategies: false,
    myFiles: false,
    classwork: false
  };

  @lessons.State
  linkedLesson!: any;

  initialized = false;
  localRefreshKey = CommonUtils.generateUUID();
  errorMsg = '';
  panels:any = [];
  localEmbedSections: Array<string> = [];
  isSectionsHovered = false;
  isTextFieldsVisible = true;

  @Watch('enabledSections')
  onEnabledSectionsChange(v:any) {
    this.panels = [];
    setTimeout(() => {
      if (this.isStackMode) {
        console.log(v);
        v.forEach((section:any, i:number) => {
          if (section.section === 'lesson') {
            if (CommonUtils.hasText(this.lessonText)) {
              this.panels.push(i);
            }
          } else if (section.section === 'tab2') {
            if (CommonUtils.hasText(this.tab2Text)) {
              this.panels.push(i);
            }
          } else if (section.section === 'tab3') {
            if (CommonUtils.hasText(this.tab3Text)) {
              this.panels.push(i);
            }
          } else if (section.section === 'tab4') {
            if (CommonUtils.hasText(this.tab4Text)) {
              this.panels.push(i);
            }
          } else if (section.section === 'tab5') {
            if (CommonUtils.hasText(this.tab5Text)) {
              this.panels.push(i);
            }
          } else if (section.section === 'tab6') {
            if (CommonUtils.hasText(this.tab6Text)) {
              this.panels.push(i);
            }
          } else if (section.section === 'strategies') {
            if (CommonUtils.isNotEmpty(this.strategyItems)) {
              this.panels.push(i);
            }
          } else if (section.section === 'myStandards') {
            if (CommonUtils.isNotEmpty(this.myStandardItems)) {
              this.panels.push(i);
            }
          } else if (section.section === 'sharedStandards') {
            if (CommonUtils.isNotEmpty(this.standardItems)) {
              this.panels.push(i);
            }
          } else if (section.section === 'schoolStandards') {
            if (CommonUtils.isNotEmpty(this.schoolStandardItems)) {
              this.panels.push(i);
            }
          } else if (section.section === 'attachments') {
            if (CommonUtils.isNotEmpty(this.attachmentItems)) {
              this.panels.push(i);
            }
          }
        });
      }
    }, 500);
  }

  localRules = {
    class: [(v: any) => !!v || this.$t('unitErrorMsg1')],
    date: [(v: any) => !!v || this.$t('enterALessonDate')]
  }

  async validateForm() {
    const form: any = this.$refs.lessonEditorForm
    const dateFieldsValid = await this.$refs.observer.validate();
    if (!dateFieldsValid) this.$refs.lessonTime.validate();
    return form.validate() && dateFieldsValid;
  }

  async saveLesson(params?: any) {
    if (await this.validateForm()) {
      return this.save(params);
    } else {
      return Promise.reject(new FormError());
    }
  }

  get isStackMode():boolean {
    if (this.input.lessonListsView) {
      return this.$store.state.lessons.listStackMode;
    } else {
      return this.$store.state.lessons.stackMode;
    }
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get hasAssignments() {
    return LessonServices.hasAssignments(this.classId);
  }

  get hasAssessments() {
    return LessonServices.hasAssessments(this.classId);
  }

  get assignments() {
    return this.$store.state.lessons.assignments || [];
  }

  set assignments(value: any) {
    this.$store.commit('lessons/setAssignments', value);
    this.addUpdatedField('SCHOOLWORK');
  }

  get assessments() {
    return this.$store.state.lessons.assessments || [];
  }

  set assessments(value: any) {
    this.$store.commit('lessons/setAssessments', value);
    this.addUpdatedField('SCHOOLWORK');
  }

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get isCalledByLessonBanks() {
    if (this.localPage === 'lesson-banks') {
      return true;
    } else {
      return false;
    }
  }

  get localClassItems() {
    if (this.isCalledByLessonBanks) {
      return this.lessonBankItems;
    } else {
      return this.classItems.filter(c => !c.isReplica);
    }
  }

  get canViewTitle() {
    return this.canView('lessonTitle')
  }

  get canEditTitle() {
    return this.canEdit('lessonTitle')
  }

  get hasErrorMsg() {
    return CommonUtils.hasText(this.errorMsg);
  }

  get formatToShow() {
    return DateTimeUtils.formatToShow;
  }

  get inputData() {
    return (this.input || {}).data;
  }

  get willIncludeClasswork() {
    const excludes:any[] = LessonServices.excludedLessonActions(this.input.data, this.userMode, this.localPage);
    return !excludes.includes('addClasswork');
  }

  get enabledSections() {
    const enabledSections = this.sections.filter(s => s.enabled)
    if (this.localPage !== 'plans' || !this.willIncludeClasswork) return enabledSections.filter(e => e.section !== 'classwork');
    return enabledSections
  }

  get enabledLessonTextSections() {
    return this.enabledSections.filter((s: any) => ['lesson', 'tab2', 'tab3', 'tab4', 'tab5', 'tab6'].includes(s.section));
  }

  get dayOfWeek(): string {
    return this.$store.state.lessons.dayOfWeek;
  }

  set dayOfWeek(val: string) {
    this.$store.commit('lessons/setDayOfWeek', val);
  }

  get date(): string {
    return this.$store.state.lessons.date;
  }

  set date(val: string) {
    this.$store.commit('lessons/setDate', val);
    this.addUpdatedField('LESSONDATE');
  }

  get className(): string {
    return this.$store.state.lessons.className;
  }

  set className(val: string) {
    this.$store.commit('lessons/setClassName', val);
  }

  get classId(): number {
    return +this.$store.state.lessons.classId;
  }

  set classId(val: number) {
    this.$store.commit('lessons/setClassId', val);
    this.addUpdatedField('LESSONCLASS');
  }

  get lessonTitle(): string {
    return this.$store.state.lessons.lessonTitle;
  }

  set lessonTitle(val: string) {
    this.$store.commit('lessons/setLessonTitle', val);
    this.addUpdatedField('LESSONTITLE');
  }

  get lessonText(): string {
    return this.$store.state.lessons.lessonText;
  }

  set lessonText(val: string) {
    this.$store.commit('lessons/setLessonText', val);
    this.addUpdatedField('LESSONTEXT');
  }

  get tab2Text(): string {
    return this.$store.state.lessons.tab2Text;
  }

  set tab2Text(val: string) {
    this.$store.commit('lessons/setTab2Text', val);
    this.addUpdatedField('HOMEWORKTEXT');
  }

  get tab3Text(): string {
    return this.$store.state.lessons.tab3Text;
  }

  set tab3Text(val: string) {
    this.$store.commit('lessons/setTab3Text', val);
    this.addUpdatedField('NOTESTEXT');
  }

  get tab4Text(): string {
    return this.$store.state.lessons.tab4Text;
  }

  set tab4Text(val: string) {
    this.$store.commit('lessons/setTab4Text', val);
    this.addUpdatedField('TAB4TEXT');
  }

  get tab5Text(): string {
    return this.$store.state.lessons.tab5Text;
  }

  set tab5Text(val: string) {
    this.$store.commit('lessons/setTab5Text', val);
    this.addUpdatedField('TAB5TEXT');
  }

  get tab6Text(): string {
    return this.$store.state.lessons.tab6Text;
  }

  set tab6Text(val: string) {
    this.$store.commit('lessons/setTab6Text', val);
    this.addUpdatedField('TAB6TEXT');
  }

  get standards(): Array<any> {
    return this.$store.state.lessons.standards;
  }

  set standards(val: Array<any>) {
    this.$store.commit('lessons/setStandards', val);
    this.addUpdatedField('STANDARDS');
  }

  get myStandards(): Array<any> {
    return this.$store.state.lessons.myStandards;
  }

  set myStandards(val: Array<any>) {
    this.$store.commit('lessons/setMyStandards', val);
    this.addUpdatedField('MYSTANDARDS');
  }

  get schoolStandards(): Array<any> {
    return this.$store.state.lessons.schoolStandards;
  }

  set schoolStandards(val: Array<any>) {
    this.$store.commit('lessons/setSchoolStandards', val);
    this.addUpdatedField('SCHOOLSTANDARDS');
  }

  get strategies(): Array<any> {
    return this.$store.state.lessons.strategies;
  }

  set strategies(val: Array<any>) {
    this.$store.commit('lessons/setStrategies', val);
    this.addUpdatedField('STRATEGIES');
  }

  get attachments(): Array<any> {
    return this.$store.state.lessons.attachments;
  }

  set attachments(val: Array<any>) {
    this.$store.commit('lessons/setAttachments', val);
    this.addUpdatedField('ATTACHMENTS');
  }

  get startTime(): Array<any> {
    return this.$store.state.lessons.startTime;
  }

  set startTime(val: Array<any>) {
    console.log('set start time')
    this.$store.commit('lessons/setStartTime', val);
    this.addUpdatedField('STARTTIME');
  }

  get endTime(): Array<any> {
    return this.$store.state.lessons.endTime;
  }

  set endTime(val: Array<any>) {
    this.$store.commit('lessons/setEndTime', val);
    this.addUpdatedField('ENDTIME');
  }

  get unitId(): number {
    return +this.$store.state.lessons.unitId;
  }

  set unitId(val: number) {
    this.$store.commit('lessons/setUnitId', val);
    this.addUpdatedField('UNITID');
  }

  get lesson() {
    return this.$store.state.lessons.lesson;
  }

  set lesson(value: any) {
    this.$store.commit('lessons/setLesson', value);
  }

  get unitItems(): Array<any> {
    if (this.isCalledByLessonBanks) {
      let unitsArray:any = [];
      this.lessonBanks.forEach((lb: any) => {
        if (+lb.cId === this.classId) {
          unitsArray = lb.units;
        }
      });
      const units:any[] = [];
      unitsArray.forEach((element:any) => {
        units.push({
          value: element.id,
          text: element.num + ' - ' + element.title
        });
      });
      return units;
    } else {
      const units: Array<any> = this.classUnits[this.classId] || [];
      return units.map(u => {
        let text = '';
        text += u.num;
        text += CommonUtils.hasNoText(text) ? u.title : ' - ' + u.title;
        return {
          value: u.id,
          text
        };
      });
    }
  }

  get statuses(): Array<any> {
    return this.$store.state.lessons.statuses;
  }

  set statuses(val: Array<any>) {
    this.$store.commit('lessons/setStatuses', val);
    this.addUpdatedField('STATUSES');
  }

  get schoolStatuses(): Array<any> {
    return this.$store.state.lessons.schoolStatuses;
  }

  set schoolStatuses(val: Array<any>) {
    this.$store.commit('lessons/setSchoolStatuses', val);
    this.addUpdatedField('SCHOOL_STATUSES');
  }

  get lessonLock(): string {
    return this.$store.state.lessons.lessonLock;
  }

  set lessonLock(val: string) {
    this.$store.commit('lessons/setLessonLock', val);
    this.addUpdatedField('LESSONLOCK');
  }

  get updatedFields(): Set<string> {
    return this.$store.state.lessons.updatedFields;
  }

  set updatedFields(val: Set<string>) {
    this.$store.commit('lessons/setUpdatedFields', val);
  }

  get extraLesson(): number {
    return +this.$store.state.lessons.extraLesson;
  }

  set extraLesson(val: number) {
    this.$store.commit('lessons/setExtraLesson', val);
  }

  get editLink(): string {
    return this.$store.state.lessons.editLink;
  }

  set editLink(val: string) {
    this.$store.commit('lessons/setEditLink', val);
  }

  get hasStatuses() {
    return CommonUtils.isNotEmpty(this.statuses) || CommonUtils.isNotEmpty(this.schoolStatuses);
  }

  get localTextEditorConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get localEditorSectionConfig() {
    return this.isModal ? { height: this.isTextFieldsVisible ? '370px' : 'fill' } : { height: 'fill' };
  }

  get localActiveTab() {
    return this.enabledSections.findIndex(s => s.active);
  }

  set localActiveTab(tab: number) {
    let section = this.enabledSections.find((s, i) => i === +tab);
    if (!section && CommonUtils.isNotEmpty(this.enabledSections)) section = this.enabledSections[0];
    this.$store.commit('lessons/setActiveTab', section?.section || '');
  }

  get hasStandards() {
    return CommonUtils.isNotEmpty(this.standards);
  }

  get standardItems() {
    return this.standards.map(s => {
      return {
        key: s.dbId,
        shortValue: s.id,
        value: s.desc
      }
    }).sort((s1, s2) => {
      const id1 = s1?.shortValue || '';
      const id2 = s2?.shortValue || '';
      if ((CommonUtils.hasText(id1) && CommonUtils.hasText(id2)) ||
         (CommonUtils.hasText(id1) && CommonUtils.hasNoText(id2)) ||
         (CommonUtils.hasNoText(id1) && CommonUtils.hasText(id2))) {
        return id1.localeCompare(id2);
      }
      return (s1.value || '').localeCompare((s2.value || ''));
    });
  }

  get localHasClasswork():boolean {
    return this.getAllClasswork.filter((c:any) => c.subjectId === this.classId).length > 0;
  }

  openLink(attachment: any) {
    const url = this.cleanAttachmentUrl(attachment.url || '');
    const name = attachment.name || attachment.filename || attachment.fileName;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', name);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', this.inputData.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', this.inputData.collaborateSubjectId);
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  cleanAttachmentUrl(url: string) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = url;
    return textArea.value;
  }

  deleteStatus(status: any) {
    this.statuses = this.statuses.filter((s: any) => s.lessonStatusId !== status.lessonStatusId);
  }

  deleteSchoolStatus(status: any) {
    this.schoolStatuses = this.schoolStatuses.filter((s: any) => s.schoolStatusId !== status.schoolStatusId);
  }

  deleteStandard(item: any) {
    this.standards = this.standards.filter(s => s.dbId !== item.key);
  }

  clearStandards() {
    const standardsLabel = this.getLessonSectionLabel('sharedStandards');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: standardsLabel }),
      text: this.$t('clearAllMsg', { text: standardsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.standards = [];
      }
    });
  }

  clearClasswork() {
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: this.$t('classworkLabel') }),
      text: this.$t('clearAllMsg', { text: this.$t('classworkLabel') }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.assignments = this.assignments.filter((c: any) => {
          return this.isClassworkBoundByDate(c);
        });
        this.assessments = this.assessments.filter((c: any) => {
          return this.isClassworkBoundByDate(c);
        });
      }
    });
  }

  get hasMyStandards() {
    return CommonUtils.isNotEmpty(this.myStandards);
  }

  get myStandardItems() {
    return this.myStandards.map(s => {
      return {
        key: s.dbId,
        shortValue: s.id,
        value: s.desc || ''
      }
    }).sort((s1, s2) => {
      const id1 = s1?.shortValue || '';
      const id2 = s2?.shortValue || '';
      if ((CommonUtils.hasText(id1) && CommonUtils.hasText(id2)) ||
         (CommonUtils.hasText(id1) && CommonUtils.hasNoText(id2)) ||
         (CommonUtils.hasNoText(id1) && CommonUtils.hasText(id2))) {
        return id1.localeCompare(id2);
      }
      return (s1.value || '').localeCompare((s2.value || ''));
    });
  }

  deleteMyStandard(item: any) {
    this.myStandards = this.myStandards.filter(s => s.dbId !== item.key);
  }

  clearMyStandards() {
    const myClassesLabel = this.getLessonSectionLabel('myStandards');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: myClassesLabel }),
      text: this.$t('clearAllMsg', { text: myClassesLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.myStandards = [];
      }
    });
  }

  get hasSchoolStandards() {
    return CommonUtils.isNotEmpty(this.schoolStandards);
  }

  get schoolStandardItems() {
    return this.schoolStandards.map(s => {
      return {
        key: s.dbId,
        shortValue: s.id,
        value: s.desc
      }
    }).sort((s1, s2) => {
      const id1 = s1?.shortValue || '';
      const id2 = s2?.shortValue || '';
      if ((CommonUtils.hasText(id1) && CommonUtils.hasText(id2)) ||
         (CommonUtils.hasText(id1) && CommonUtils.hasNoText(id2)) ||
         (CommonUtils.hasNoText(id1) && CommonUtils.hasText(id2))) {
        return id1.localeCompare(id2);
      }
      return (s1.value || '').localeCompare((s2.value || ''));
    });
  }

  get classworkItems() {
    const classwork = this.assignments.concat(this.assessments);
    return classwork.map((c:any) => {
      return {
        key: (c.source === 'gc') ? c.alternateLink : (c.assignmentId || c.assessmentId),
        value: c.assignmentTitle || c.assessmentTitle,
        isBoundByDate: this.isClassworkBoundByDate(c)
      }
    }).sort((c1: any, c2: any) => {
      return c1.value.localeCompare(c2.value);
    });
  }

  get isClassworkClearable() {
    return this.classworkItems.some((c: any) => {
      return !c.isBoundByDate;
    });
  }

  isClassworkBoundByDate(c: any) {
    if (c.assignmentId) {
      if (CommonUtils.hasText(c.assignmentStart) && CommonUtils.hasText(c.assignmentEnd) &&
          DateTimeUtils.isThisDateBeforeThatDate(c.assignmentStart, this.date) && DateTimeUtils.isThisDateAfterThatDate(c.assignmentEnd, this.date)) {
        return true;
      } else if (CommonUtils.hasText(c.assignmentStart) && DateTimeUtils.isThisDateEqualToThatDate(c.assignmentStart, this.date)) {
        return true;
      } else if (CommonUtils.hasText(c.assignmentEnd) && DateTimeUtils.isThisDateEqualToThatDate(c.assignmentEnd, this.date)) {
        return true;
      }
    } else {
      if (CommonUtils.hasText(c.assessmentStart) && CommonUtils.hasText(c.assessmentEnd) &&
          DateTimeUtils.isThisDateBeforeThatDate(c.assessmentStart, this.date) && DateTimeUtils.isThisDateAfterThatDate(c.assessmentEnd, this.date)) {
        return true;
      } else if (CommonUtils.hasText(c.assessmentStart) && DateTimeUtils.isThisDateEqualToThatDate(c.assessmentStart, this.date)) {
        return true;
      } else if (CommonUtils.hasText(c.assessmentEnd) && DateTimeUtils.isThisDateEqualToThatDate(c.assessmentEnd, this.date)) {
        return true;
      }
    }

    return false;
  }

  deleteSchoolStandard(item: any) {
    this.schoolStandards = this.schoolStandards.filter(s => s.dbId !== item.key);
  }

  deleteClasswork(item: any) {
    this.assignments = this.assignments.filter((c:any) => c.assignmentId !== item.key && c.alternateLink !== item.key);
    this.assessments = this.assessments.filter((c:any) => c.assessmentId !== item.key && c.alternateLink !== item.key);
  }

  clearSchoolStandards() {
    const schoolListLabel = this.getLessonSectionLabel('schoolStandards');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: schoolListLabel }),
      text: this.$t('clearAllMsg', { text: schoolListLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.schoolStandards = [];
      }
    });
  }

  get hasStrategies() {
    return CommonUtils.isNotEmpty(this.strategies);
  }

  get strategyItems() {
    return this.strategies.map(s => {
      return {
        key: s.strategyId,
        shortValue: s.code,
        value: s.title
      }
    }).sort((s1, s2) => {
      const id1 = s1?.shortValue || '';
      const id2 = s2?.shortValue || '';
      if ((CommonUtils.hasText(id1) && CommonUtils.hasText(id2)) ||
         (CommonUtils.hasText(id1) && CommonUtils.hasNoText(id2)) ||
         (CommonUtils.hasNoText(id1) && CommonUtils.hasText(id2))) {
        return id1.localeCompare(id2);
      }
      return (s1.value || '').localeCompare((s2.value || ''));
    })
  }

  get localClassworkInput() {
    return {
      action: 'A',
      loadData: true,
      classId: this.inputData.classId,
      unit: this.inputData.unitId !== 0 ? this.inputData.unitNum + ' - ' + this.inputData.unitTitle : undefined,
      type: 0,
      startDate: this.inputData.customStart ? moment(this.inputData.customStart, 'hh:mm A').format('MM/DD/YYYY') : '',
      endDate: this.inputData.customEnd ? moment(this.inputData.customEnd, 'hh:mm A').format('MM/DD/YYYY') : '',
      showClassworkPickerAfterSave: true
    };
  }

  deleteStrategy(item: any) {
    this.strategies = this.strategies.filter(s => s.strategyId !== item.key);
  }

  clearStrategies() {
    const strategiesLabel = this.getLessonSectionLabel('strategies');
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: strategiesLabel }),
      text: this.$t('clearAllMsg', { text: strategiesLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.strategies = [];
      }
    });
  }

  get hasAttachments() {
    return CommonUtils.isNotEmpty(this.attachments);
  }

  get attachmentItems() {
    return this.attachments.map((a, i) => {
      const name = (a.name || a.filename || a.fileName || '');
      return {
        key: name + i,
        shortValue: '',
        value: name,
        data: a
      }
    }).sort((a1, a2) => {
      return a1.value.trim().localeCompare(a2.value.trim());
    });
  }

  deleteAttachment(item: any) {
    this.attachments = this.attachments.filter((s) => s.url !== item.data.url);
  }

  toggleAttachmentPrivateFlag(item: any, index: number) {
    const newAttachments = ld.cloneDeep(this.attachments);
    const attachment = newAttachments.find((a) => a.url === item.data.url);
    const insertIndex = newAttachments.findIndex((a) => a.url === item.data.url);
    attachment.privateFlag = !attachment.privateFlag;
    ld.set(newAttachments, insertIndex, attachment);
    this.attachments = newAttachments;
    console.log(index);
  }

  addGoogleDriveAttachments() {
    this.$google.setAuthToken(UserSession.getGoogleAuthToken());
    this.$google.openPicker((data: any) => {
      this.updateAttachments([{
        filename: data.name,
        fileType: 'drive',
        url: data.url,
        id: data.id,
        privateFlag: false
      }])
    });
  }

  openDialog(type: string) {
    if (type === 'classwork' && this.localHasClasswork) {
      this.$refs.classworkAction.classwork(this.inputData.classId);
    } else {
      for (const key in this.dialogs) {
        this.dialogs[key] = type === key;
      }
    }
  }

  dropAttachment(e: any) {
    e.preventDefault();
    const attachmentSectionIndex = this.enabledSections.findIndex((t) => t.section === 'attachments');
    if (!this.isStackMode) {
      this.localActiveTab = attachmentSectionIndex;
    } else if (!this.panels.includes(attachmentSectionIndex)) {
      this.panels.push(attachmentSectionIndex);
    }
    const fileCount = e.dataTransfer.files.length;
    const droppedFiles = [];
    for (let i = 0; i < fileCount; i++) {
      droppedFiles.push(e.dataTransfer.files[i]);
    }
    this.$refs.filesPicker.appendFiles(droppedFiles);
    this.$refs.filesPicker.saveFiles()
  }

  clearAttachments() {
    const attachmentsLabel = this.$t('attachmentsLabel') as string;
    this.$refs.confirm.confirm({
      title: this.$t('clearAllLabel', { text: attachmentsLabel }),
      text: this.$t('clearAllMsg', { text: attachmentsLabel }),
      option1ButtonAlternativeText: this.$t('okLabel')
    }).then((result) => {
      if (result === 1) {
        this.attachments = [];
      }
    });
  }

  addUpdatedField(field: string) {
    if (this.initialized) {
      this.updatedFields.add(field);
    }
  }

  doSaveClasswork(classworkSelected: any) {
    const tempAssignments = [];
    const tempAssessments = [];

    for (const c of classworkSelected) {
      if (c.isAssignment) {
        c.assignmentDesc = c.classworkDesc;
        c.assignmentEnd = c.classworkEnd;
        c.assignmentId = c.classworkId;
        c.assignmentStart = c.classworkStart;
        c.assignmentTitle = c.classworkTitle;
        tempAssignments.push(c);
      } else {
        c.assessmentDesc = c.classworkDesc;
        c.assessmentEnd = c.classworkEnd;
        c.assessmentId = c.classworkId;
        c.assessmentStart = c.classworkStart;
        c.assessmentTitle = c.classworkTitle;
        tempAssessments.push(c);
      }
    }

    this.assignments = tempAssignments;
    this.assessments = tempAssessments;
  }

  @Watch('input')
  doInit() {
    this.errorMsg = '';
    const form: any = this.$refs.lessonEditorForm;
    if (form) form.resetValidation();
    if (this.editLink) CommonUtils.showLoading();
    this.initialized = false;
    this.init(this.input).then(() => {
      if (this.input.tab) {
        this.localActiveTab = this.input.tab
      }
      this.localRefreshKey = CommonUtils.generateUUID();
      this.$nextTick(() => {
        this.initialized = true;
      })
      if (this.editLink) CommonUtils.hideLoading();
    });
  }

  @Watch('classId')
  onClassIdChange() {
    const clonedLesson = ld.cloneDeep(this.lesson);
    clonedLesson.layoutId = this.getClassLessonLayoutId(this.classId);
    this.lesson = clonedLesson;
    this.initSections();
  }

  updateAttachments(files: Array<any>) {
    const extensions = ['ico', 'png', 'jpeg', 'jpg', 'svg', 'tif', 'webp'];
    files.forEach(a => {
      if (!this.attachmentExists(a) && !extensions.includes(this.getExtension(a))) {
        this.attachments.push(a);
        this.addUpdatedField('attachments');
      }
    });
    const imageFiles = files.filter(a => extensions.includes(this.getExtension(a)));
    if (imageFiles.length > 0) {
      return this.$refs.confirm.confirm({
        title: this.$t('imageActionsLabel'),
        text: this.$t('imageActionsMsg'),
        option1ButtonAlternativeText: this.$t('attachLabel'),
        option2ButtonAlternativeText: this.$t('embedLabel')
      }).then(async result => {
        if (result === 1) {
          files.forEach(a => {
            if (!this.attachmentExists(a)) {
              this.attachments.push(a);
              this.addUpdatedField('attachments');
            }
          });
        } else if (result === 2) {
          return this.$refs.embedFiles.confirm({
            title: this.$t('embedImagesLabel'),
            text: this.$t('imageActionsMsg2'),
            option1ButtonAlternativeText: this.$t('continueLabel')
          }).then(embedResult => {
            if (embedResult === 1) {
              if (CommonUtils.isNotEmpty(this.localEmbedSections)) {
                this.localEmbedSections.forEach(s => {
                  imageFiles.forEach((img: any) => {
                    let url = img.url;
                    if (FileServices.isGoogleDriveFile(url)) {
                      const driveId = FileServices.guessGoogleDriveId(url);
                      if (CommonUtils.hasText(driveId)) {
                        url = `https://drive.google.com/uc?export=view&id=${driveId}&export=download`;
                      }
                    }
                    const attributeKey = `${s}Text`;
                    let html = ld.get(this, attributeKey);
                    html += `<p><img style="width: 500px; max-width: 100%; height: auto;" src="${url}" alt="Image"/></p>`;
                    ld.set(this, attributeKey, html);
                  });
                  this.localActiveTab = this.enabledSections.findIndex(s => this.localEmbedSections.includes(s.section));
                });
              }
            }
            return Promise.resolve();
          });
        }
        return Promise.resolve();
      }).finally(() => {
        this.localEmbedSections = [];
      });
    }
  }

  attachmentExists(file: any) {
    return this.attachments.some(a => this.getFileName(a) === this.getFileName(file));
  }

  getExtension(file: any) {
    const name = this.getFileName(file);
    return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
  }

  getFileName(file: any) {
    return file.fileName || file.filename || file.name || '';
  }

  hideOrShowTextFields() {
    this.isTextFieldsVisible = !this.isTextFieldsVisible;
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  created() {
    if (this.isCalledByLessonBanks) {
      this.classId = this.selectedLessonBankClassId;
    }
    if (CommonUtils.isEmpty(this.getAllClasswork)) {
      this.loadClasswork({
        teacherId: 0
      }).then(() => {
        this.doInit();
      });
    } else {
      this.doInit();
    }
  }

  mounted() {
    const that = this;
    this.$nextTick(() => {
      that.$eventBus.$on('classworkHasBeenAdded', (data:any) => {
        setTimeout(() => {
          if (that.$refs.classworkAction) {
            let classworkId = 0;
            const respAssignments = data.resp.assignments || [];
            const respAssessments = data.resp.assessments || [];
            const respClasswork = respAssignments.concat(respAssessments);
            for (const c of respClasswork) {
              if ((c.assignmentTitle === data.localData.title || c.assessmentTitle === data.localData.title) && c.subjectId === data.localData.classID && (c.assignmentDesc === data.localData.description || c.assessmentDesc === data.localData.description)) {
                classworkId = c.assignmentId || c.assessmentId;
              }
            }
            that.$refs.classworkAction.classwork(that.inputData.classId, classworkId);
          }
        }, 500);
      });
    });
  }

  destroyed() {
    this.$eventBus.$off('classworkHasBeenAdded');
  }

  updated() {
    WebUtils.initMathJax();
  }
}
