






















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';
import { FormError } from '@/errors';
import ld from 'lodash';
import { accountTypes } from '@/constants';
import FileServices from '@/services/file-services';

const settings = namespace('settings');
const teachers = namespace('teachers');

@Component({
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
})
export default class TeacherEditor extends Vue {
    @Prop({
      type: Object,
      default: () => {
        return { loadData: true, data: {} };
      }
    })
    input!: any

    isEditable = true;
    selectedFile: any;
    selectingFile = false;
    localSelectedSchools: any = [];
    localPhotoUrl = '';
    isChoosingPhoto = false;

    $refs!: {
      form: Vue & { validate: () => boolean, resetValidation: () => void }
      fileInput: HTMLInputElement,
    }

    @settings.Getter('getSchools')
    schools!: Array<any>;

    @settings.Getter('getUserMode')
    userMode!: string;

    @teachers.Mutation
    setTeacher!: (teacher: any) => void;

    @teachers.Mutation
    setCurrentTeacher!: (teacher: any) => void;

    @teachers.Action
    save!: (param?: any) => Promise<any>;

    @settings.Getter('getUserType')
    currentUserType!: string;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    get schoolItems() {
      return this.schools.map((s: any) => {
        return { text: s.schoolName, value: +s.schoolId };
      });
    }

    get accountTypes() {
      return accountTypes.filter(accountType => {
        if (this.$currentUser.isAdmin && !this.$currentUser.isDistrictAdmin) {
          return accountType.value !== 'D';
        } else if (accountType.value === 'D') {
          return this.$currentUser.isDistrictAdmin && this.$currentUser.districtId > 0;
        }
        return true;
      });
    }

    get teacher() {
      return this.$store.state.teachers.teacher;
    }

    set teacher(v: any) {
      this.setTeacher(v);
    }

    get currentTeacher() {
      return this.$store.state.teachers.currentTeacher;
    }

    set currentTeacher(v: any) {
      this.setCurrentTeacher(v);
    }

    get teacherId(): number {
      return this.teacher.teacherId;
    }

    set teacherId(value: number) {
      this.setValue('teacherId', value);
    }

    get inviteId(): number {
      return this.teacher.inviteId;
    }

    set inviteId(value: number) {
      this.setValue('inviteId', value);
    }

    get userType(): string {
      return this.teacher.userType;
    }

    set userType(value: string) {
      this.setValue('userType', value);
    }

    get displayName(): string {
      return this.teacher.displayName;
    }

    set displayName(value: string) {
      this.setValue('displayName', value);
    }

    get emailAddress(): string {
      return this.teacher.emailAddress;
    }

    set emailAddress(value: string) {
      this.setValue('emailAddress', value);
    }

    get firstName(): string {
      return this.teacher.firstName;
    }

    set firstName(value: string) {
      this.setValue('firstName', value);
    }

    get lastName(): string {
      return this.teacher.lastName;
    }

    set lastName(value: string) {
      this.setValue('lastName', value);
    }

    get photoUrl(): string {
      return this.teacher.photoUrl;
    }

    set photoUrl(value: string) {
      this.setValue('photoUrl', value);
    }

    get teacherSchools(): Array<any> {
      return this.teacher.schools || [];
    }

    set teacherSchools(value: Array<any>) {
      this.setValue('schools', value);
    }

    get primarySchool() {
      return this.teacherSchools.find(s => CommonUtils.isTrue(s.primary)) || this.schools[0];
    }

    get primarySchoolId(): number {
      return this.primarySchool ? this.primarySchool.schoolId : 0;
    }

    get selectedSchools() {
      return this.teacherSchools.map(s => s.schoolId);
    }

    set selectedSchools(schoolIds: Array<number>) {
      const newTeacherSchools = ld.cloneDeep(this.schools.filter(s => schoolIds.includes(s.schoolId)));
      if (CommonUtils.isNotEmpty(newTeacherSchools)) {
        const hasPrimary = newTeacherSchools.some(s => s.schoolId === this.primarySchoolId);
        if (hasPrimary) {
          newTeacherSchools.forEach(s => {
            s.primary = CommonUtils.booleanToString(s.schoolId === this.primarySchoolId);
          });
        } else {
          newTeacherSchools.forEach(s => {
            s.primary = 'N';
          });
          newTeacherSchools[0].primary = 'Y';
        }
      }
      this.teacherSchools = newTeacherSchools;
    }

    removeSchool(item: any) {
      this.selectedSchools = this.selectedSchools.filter(schoolId => item.value !== schoolId);
    }

    setPrimary(item: any) {
      const newTeacherSchools = ld.cloneDeep(this.teacherSchools);
      this.teacherSchools = newTeacherSchools.map((s: any) => {
        s.primary = CommonUtils.booleanToString(item.value === s.schoolId);
        return s;
      });
    }

    isPrimary(item: any) {
      return item.value === this.primarySchoolId;
    }

    setValue(key: string, value: any): void {
      this.teacher = ld.set(ld.cloneDeep(this.teacher), key, value);
    }

    isDirty() {
      return !ld.isEqual(this.currentTeacher, this.teacher);
    }

    onSave() {
      if (this.$refs.form.validate()) {
        return this.uploadSelectedFile().then(this.save).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('teacherUpdatedMsg') as string);
          }
          return Promise.resolve();
        })
      }
      return Promise.reject(new FormError());
    }

    get hasPhotoUrl() {
      return CommonUtils.hasText(this.photoUrl);
    }

    get hasLocalPhotoUrl() {
      return CommonUtils.hasText(this.localPhotoUrl);
    }

    onFileChanged(e: Event) {
      const fileInput: any = e.target;
      this.selectedFile = fileInput.files[0];
      if (this.selectedFile) {
        this.localPhotoUrl = URL.createObjectURL(this.selectedFile);
      } else {
        this.localPhotoUrl = '';
      }
    }

    defaultPhotoUrls(index: any) {
      return 'https://cdn.planbook.com/images/teacheravatars/teacher-' + ('' + index).padStart(2, '0') + '.png';
    }

    selectDefault(index: number) {
      this.localPhotoUrl = this.defaultPhotoUrls(index);
    }

    async uploadSelectedFile() {
      if (this.selectedFile && CommonUtils.hasText(this.photoUrl) && this.photoUrl.startsWith('blob:')) {
        return FileServices.uploadFile(this.selectedFile).then((resp: any) => {
          this.photoUrl = resp.data.url;
          this.selectedFile = null;
        });
      } else {
        this.selectedFile = null;
        return Promise.resolve();
      }
    }

    removePhoto() {
      this.localPhotoUrl = '';
      this.selectedFile = null;
    }

    choosePhoto() {
      this.isChoosingPhoto = true;
    }

    applyPhoto() {
      this.photoUrl = this.localPhotoUrl;
      this.isChoosingPhoto = false;
    }

    selectImage() {
      if (!this.isEditable) {
        return;
      }
      this.selectingFile = true;
      window.addEventListener('focus', () => {
        this.selectingFile = false;
      }, { once: true });
      this.$refs.fileInput.click();
    }

    @Watch('isChoosingPhoto')
    onChoosingPhoto() {
      if (this.isChoosingPhoto) {
        this.localPhotoUrl = this.photoUrl;
      } else {
        this.localPhotoUrl = '';
      }
    }

    @Watch('input')
    doInit() {
      if (this.input.loadData === false) {
        return;
      }
      this.teacher = this.input.data || {
        teacherId: 0,
        inviteId: 0,
        userType: 'T',
        displayName: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        photoUrl: ''
      };
      this.currentTeacher = ld.cloneDeep(this.teacher);
      try {
        this.$refs.form.resetValidation();
      } catch (e) {
      }
    }

    created() {
      this.doInit();
    }
}
