var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "resourcesEditorForm", staticClass: "classwork-editor-tab" },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("folderNameLabel"),
                          "prepend-inner-icon": "fal fa-folder",
                          required: "",
                          outlined: "",
                          "hide-details": "auto",
                          error: _vm.errorUploadFolderField,
                          maxlength: "225",
                        },
                        model: {
                          value: _vm.newFolderName,
                          callback: function ($$v) {
                            _vm.newFolderName = $$v
                          },
                          expression: "newFolderName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("pb-confirm", { ref: "confirm" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }