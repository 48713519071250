var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c(
        "v-form",
        { ref: "StudentEditorForm" },
        [
          _c(
            "v-container",
            { staticClass: "px-5 pt-4 mb-4" },
            [
              _vm.hasText(_vm.errorMsg)
                ? _c(
                    "v-row",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                dense: "",
                                border: "left",
                                type: "error"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasTags
                ? _c(
                    "v-row",
                    { staticClass: "pb-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-chip-group",
                            _vm._l(_vm.selectedTags, function(tag) {
                              return _c(
                                "div",
                                { key: tag.tagId },
                                [
                                  tag.editable
                                    ? _c(
                                        "v-chip",
                                        {
                                          class: {
                                            "contrast-view":
                                              _vm.highContrastMode
                                          },
                                          attrs: {
                                            tabindex: "-1",
                                            color: tag.color,
                                            "text-color": "#FFFFFF",
                                            close: ""
                                          },
                                          on: {
                                            "click:close": function($event) {
                                              return _vm.deleteTag(tag)
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(tag.tagText))
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "v-chip",
                                        {
                                          class: {
                                            "contrast-view":
                                              _vm.highContrastMode
                                          },
                                          attrs: {
                                            tabindex: "-1",
                                            color: tag.color,
                                            "text-color": "#FFFFFF"
                                          }
                                        },
                                        [_vm._v(_vm._s(tag.tagText))]
                                      )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("studentIDLabel"),
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable,
                          rules: _vm.keyError,
                          autofocus: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function() {
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "alignPhoto focus-border mr-6",
                                    attrs: {
                                      outlined: "",
                                      width: "80",
                                      height: "80px",
                                      tabindex: "0",
                                      role: "button",
                                      "aria-label": _vm.$t("editPhotoLabel")
                                    },
                                    on: {
                                      keydown: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.editPhoto.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-card-text", [
                                      _c(
                                        "span",
                                        { staticClass: "title-on-border" },
                                        [_vm._v(_vm._s(_vm.$t("photoLabel")))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          on: { click: _vm.editPhoto }
                                        },
                                        [
                                          _vm.localStudent.photoUrl &&
                                          _vm.localStudent.photoUrl != ""
                                            ? _c("img", {
                                                staticClass: "px-0",
                                                staticStyle: {
                                                  width: "50px",
                                                  height: "50px"
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.localStudent.photoUrl,
                                                  role: "presentation"
                                                }
                                              })
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mt-3",
                                                  attrs: { color: "grey" }
                                                },
                                                [_vm._v("fas fa-user")]
                                              )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.localStudent.code,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "code", $$v)
                          },
                          expression: "localStudent.code"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("emailAddressLabel"),
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable,
                          rules: _vm.emailError
                        },
                        model: {
                          value: _vm.localStudent.emailAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "emailAddress", $$v)
                          },
                          expression: "localStudent.emailAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("firstNameLabel") + "*",
                          "aria-required": "true",
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable,
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("studentErrorMsg2")
                            }
                          ]
                        },
                        model: {
                          value: _vm.localStudent.firstName,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "firstName", $$v)
                          },
                          expression: "localStudent.firstName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("parentEmailLabel"),
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable
                        },
                        model: {
                          value: _vm.localStudent.parentEmailAddress,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.localStudent,
                              "parentEmailAddress",
                              $$v
                            )
                          },
                          expression: "localStudent.parentEmailAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("middleNameLabel"),
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable
                        },
                        model: {
                          value: _vm.localStudent.middleName,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "middleName", $$v)
                          },
                          expression: "localStudent.middleName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("phoneNumberLabel"),
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable
                        },
                        model: {
                          value: _vm.localStudent.phoneNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "phoneNumber", $$v)
                          },
                          expression: "localStudent.phoneNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("lastNameLabel") + "*",
                          "aria-required": "true",
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable,
                          rules: [
                            function(v) {
                              return !!v || _vm.$t("studentErrorMsg3")
                            }
                          ]
                        },
                        model: {
                          value: _vm.localStudent.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "lastName", $$v)
                          },
                          expression: "localStudent.lastName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("pb-date-field", {
                        attrs: {
                          label: _vm.$t("birthdateLabel"),
                          "hide-details": "auto",
                          readonly: !_vm.isEditable
                        },
                        model: {
                          value: _vm.localStudent.birthDate,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "birthDate", $$v)
                          },
                          expression: "localStudent.birthDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: _vm.$t("gradeLevelLabel"),
                          "display:inline-flex": "",
                          "return-object": "",
                          readonly: !_vm.isEditable,
                          "hide-details": "auto",
                          items: _vm.gradesList,
                          clearable: _vm.isEditable,
                          outlined: ""
                        },
                        model: {
                          value: _vm.localStudent.gradeLevel,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "gradeLevel", $$v)
                          },
                          expression: "localStudent.gradeLevel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: _vm.$t("genderLabel"),
                          "display:inline-flex": "",
                          "return-object": "",
                          readonly: !_vm.isEditable,
                          "hide-details": "auto",
                          items: _vm.gendersList,
                          clearable: _vm.isEditable,
                          outlined: ""
                        },
                        model: {
                          value: _vm.localStudent.gender,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "gender", $$v)
                          },
                          expression: "localStudent.gender"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("studentKeyLabel"),
                          "hide-details": "auto",
                          outlined: "",
                          readonly: !_vm.isEditable
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.isEditable
                              ? {
                                  key: "append-outer",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "alignButton",
                                          attrs: {
                                            color: "primary",
                                            "x-large": ""
                                          },
                                          on: { click: _vm.generateKey }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { attrs: { color: "primary" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("generateLabel"))
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.localStudent.studentPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.localStudent, "studentPassword", $$v)
                          },
                          expression: "localStudent.studentPassword"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 600, expandable: false, withActions: false },
          on: { apply: _vm.applyPhoto },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v(_vm._s(_vm.$t("editPhotoLabel")))]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.photoUpload,
            callback: function($$v) {
              _vm.photoUpload = $$v
            },
            expression: "photoUpload"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex flex-nowrap" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "4" }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            outlined: "",
                            width: "160px",
                            height: "218px"
                          }
                        },
                        [
                          _c("v-card-text", [
                            _c("span", { staticClass: "title-on-border" }, [
                              _vm._v(_vm._s(_vm.$t("photoLabel")))
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: { height: "140px" }
                            },
                            [
                              _vm.currentPhoto && _vm.currentPhoto != ""
                                ? _c("img", {
                                    staticStyle: {
                                      width: "135px",
                                      height: "135px",
                                      "object-fit": "cover"
                                    },
                                    attrs: {
                                      src: _vm.currentPhoto,
                                      role: "presentation"
                                    }
                                  })
                                : _c(
                                    "v-icon",
                                    { attrs: { size: "120px", color: "grey" } },
                                    [_vm._v("fas fa-user")]
                                  ),
                              _c("input", {
                                ref: "fileInput",
                                staticClass: "d-none",
                                attrs: {
                                  type: "file",
                                  accept:
                                    ".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                },
                                on: { change: _vm.onFileChanged }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    text: "",
                                    loading: _vm.selectingFile
                                  },
                                  on: { click: _vm.selectImage }
                                },
                                [_vm._v(_vm._s(_vm.$t("uploadLabel")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    small: "",
                                    text: ""
                                  },
                                  on: { click: _vm.removePhoto }
                                },
                                [_vm._v(_vm._s(_vm.$t("removeLabel")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c("div", { staticClass: "mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("photoGalleryLabel")))
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "overflow-y": "auto",
                          maxHeight: "180px"
                        }
                      },
                      _vm._l(5, function(indexI) {
                        return _c(
                          "v-row",
                          {
                            key: indexI,
                            staticClass: "d-flex flex-nowrap mb-2"
                          },
                          _vm._l(5, function(indexJ) {
                            return _c(
                              "div",
                              {
                                key: indexJ,
                                attrs: {
                                  tabindex: "0",
                                  role: "button",
                                  "aria-label": _vm.$t("defaultPhotoLabel", {
                                    num: (indexI - 1) * 5 + indexJ + 1
                                  })
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.selectDefault((indexI - 1) * 5 + indexJ)
                                  },
                                  click: function($event) {
                                    _vm.selectDefault((indexI - 1) * 5 + indexJ)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "px-2",
                                  staticStyle: {
                                    width: "75px",
                                    height: "75px"
                                  },
                                  attrs: {
                                    role: "presentation",
                                    src: _vm.defaultPhotoUrls(
                                      (indexI - 1) * 5 + indexJ
                                    )
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }