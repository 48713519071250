import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=8a153512&scoped=true"
import script from "./Loader.vue?vue&type=script&lang=ts"
export * from "./Loader.vue?vue&type=script&lang=ts"
import style0 from "./Loader.vue?vue&type=style&index=0&id=8a153512&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a153512",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardText,VDialog})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8a153512')) {
      api.createRecord('8a153512', component.options)
    } else {
      api.reload('8a153512', component.options)
    }
    module.hot.accept("./Loader.vue?vue&type=template&id=8a153512&scoped=true", function () {
      api.rerender('8a153512', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/Loader.vue"
export default component.exports