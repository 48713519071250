
import ResourcesEditorForm from '@/components/resources/ResourcesEditorForm.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Confirm from '../core/Confirm.vue';

@Component({
  components: {
    ResourcesEditorForm
  }
})
export default class ResourcesEditor extends Vue {
    @Prop({ type: Boolean })
    value!: boolean;

    @Prop({ type: Boolean, default: true })
    isModal!: boolean;

    @Prop({
      required: true,
      type: Object,
      default: () => {
        return {};
      }
    })
    input!: any;

    $refs!: {
    form: ResourcesEditorForm,
    confirm: Confirm
    }

    get lessonBanksId(): number {
      return 0;
    }

    get localValue() {
      return this.value;
    }

    set localValue(val: boolean) {
      this.$emit('input', val);
    }

    showActions() {
    //   this.$eventBus.$emit('actions', {
    //     type: 'lesson',
    //     event,
    //     input: {
    //       exclude: ['edit'],
    //       loadData: false,
    //       data: this.input.data
    //     }
    //   });
    }

    async doOnClose() {
    //   if (this.isDirty) {
    //     return this.$refs.confirm.confirm({
    //       title: this.$t('lessonLabel'),
    //       text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
    //       option1ButtonAlternativeText: this.$t('continueLabel')
    //     }).then((result) => {
    //       if (result === 1) {
    //         return Promise.resolve(false);
    //       } else {
    //         return Promise.resolve(true);
    //       }
    //     });
    //   } else {
    //     return Promise.resolve(false);
    //   }
    }

    doApply() {
    //   CommonUtils.showLoading();
    //   this.$refs.form.save({
    //     isCalledByLessonBank: true
    //   }).then(() => {
    //     this.localValue = false;
    //     if (!this.isModal) {
    //       this.$eventBus.$emit('closeSubPage');
    //     }
    //     if (this.selectedLessonBankClassId !== 0) {
    //       this.$eventBus.$emit('lessonHasBeenSave', true);
    //     }
    //   })
    }
}
