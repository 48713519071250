




















import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Reminders from '@/views/pages/Reminders.vue';
import { PlanbookStorage } from '@/common/planbook-storage';

const drawer = namespace('drawer');
const settings = namespace('settings');
@Component({
  components: {
    Reminders
  }
})
export default class RemindersMobileDrawer extends Vue {
  remindersModel = false;

  $refs!: {
    reminders: Reminders
  };

  @drawer.State
  remindersMobileDrawer!: boolean;

  @drawer.Mutation
  setRemindersMobileDrawer!: (v:boolean) => void;

  @drawer.State
  remindersMobileClickCounter!: number;

  @drawer.Mutation
  setRemindersMobileClickCounter!: (v: number) => void;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  get localRemindersMobileDrawer() {
    return this.remindersMobileDrawer;
  }

  set localRemindersMobileDrawer(v:boolean) {
    this.pbStorageReminders = v;
    this.setRemindersMobileDrawer(v);
    if (v) {
      this.$store.commit('plans/setRemindersFloatingIcon', 'fal fa-angle-right');
    } else {
      this.setRemindersMobileClickCounter(0);
      this.$store.commit('plans/setRemindersFloatingIcon', 'fal fa-angle-left');
    }
  }

  get pbStorageReminders() {
    return PlanbookStorage.get('reminders');
  }

  set pbStorageReminders(v:boolean) {
    PlanbookStorage.set('reminders', v);
  }

  get height() {
    if (this.remindersMobileClickCounter === 1) {
      return (this.browserHeight / 2) + 70
    } else if (this.remindersMobileClickCounter === 2) {
      return this.browserHeight - 98;
    }
  }

  @Watch('remindersMobileDrawer')
  updateLocalDrawerBottom(v: boolean) {
    this.$nextTick(() => {
      this.remindersModel = v;
    })
  }

  hideRemindersMobileDrawer() {
    this.localRemindersMobileDrawer = false;
  }
}
