
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VContainer } from 'vuetify/lib';

@Component
export default class ClassworkEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get pageTitle() {
    return this.$t('sideEditorLabel', { type: this.getTypeLabel });
  }

  get getTypeLabel() {
    switch (this.localPage) {
      case 'classwork': return this.$t('classworkLabel');
      case 'events': return this.$t('eventLabel');
      case 'lesson-banks': return this.$t('lessonLabel');
      case 'messages': return this.$t('messageLabel');
      case 'notes-todo': return this.$t('notesTodosLabel');
      case 'school-years': return this.$t('schoolYearLabel');
      case 'students': return this.$t('studentLabel');
      case 'teachers': return this.$t('teacherLabel');
      case 'templates': return this.$t('templateLabel');
      case 'units': return this.$t('unitLabel');
      case 'lesson-layouts': return this.$t('lessonLayoutLabel');
      case 'school-list': return this.$t('schoolListLabel');
      case 'my-list': return this.$t('myListLabel');
      case 'strategies': return this.$t('strategyLabel');
    }
  }

  get pageText() {
    switch (this.localPage) {
      case 'lesson-banks': return this.$t('clickLessonBankCaption');
      case 'messages': return this.$t('clickViewCaption');
      default: return this.$t('clickEditCaption');
    }
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }
}
