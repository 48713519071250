var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-modal",
    {
      attrs: {
        input: _vm.input,
        onClose: _vm.doOnClose,
        expandable: false,
        maxWidth: 500,
        subpage: true,
        withActions: _vm.input.action === "ULB" || _vm.input.action === "UDLB",
      },
      on: { apply: _vm.doApply },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", [
                _vm.input.action === "ULB" || _vm.input.action === "UDLB"
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))]),
                _vm.input.action === "SLB"
                  ? _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("schoolLessonBankLabel"))),
                    ])
                  : _vm.input.action === "DLB" || _vm.input.action === "UDLB"
                  ? _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("districtLessonBankLabel"))),
                    ])
                  : _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("lessonBankLabel"))),
                    ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "action-button",
          fn: function () {
            return [
              _c("lesson-banks-actions", {
                attrs: { type: "button", input: { data: _vm.input } },
              }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "div",
        [
          _c("lesson-banks-editor-form", { ref: "form" }),
          _c("pb-confirm", { ref: "confirm" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }