
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';

const lessonlists = namespace('lessonlists');
const settings = namespace('settings');

@Component
export default class LessonBanksActions extends Vue implements ActionsModal {
    actions: Array<any> = [
      {
        id: 'delete',
        icon: 'fal fa-trash-alt',
        label: 'deleteLabel'
      }
    ];

    @Prop({
      required: true,
      type: Object,
      default: () => {
        return { loadData: true, data: {}, exclude: [] };
      }
    })
    input!: any;

    @Prop({ required: false, type: String, default: 'absolute' })
    type!: string;

    showMenu = false;
    x = 0;
    y = 0;

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getSettings')
    settings!: any;

    @lessonlists.Action
    deleteLessonBank!: (param?: any) => Promise<any>;

    $refs!: {
        confirm: Confirm
    }

    get filteredActions() {
      return this.actions.filter(() => {
        return true;
      });
    }

    async doAction(action: any) {
      CommonUtils.showLoading();
      return Promise.resolve().then(() => {
        if (action.id === 'delete') {
          return this.doDeleteLessonBank();
        }
      }).finally(() => {
        this.showMenu = false;
        CommonUtils.hideLoading();
      });
    }

    async doDeleteLessonBank() {
      return this.$refs.confirm.confirm({
        title: this.$t('lessonBanksLabel'),
        text: this.$t('lessonBankDeleteConfirmMessage', { s: this.input.data.text }),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result:any) => {
        if (result === 1) {
          CommonUtils.showLoading();
          this.deleteLessonBank({
            classId: this.input.classId,
            lessonBankType: this.input.schoolOrDistrict,
            userMode: this.userMode,
            schoolId: this.settings.schoolSettings.schoolId
          }).then(() => {
            CommonUtils.hideLoading();
          }).finally(() => {
            this.closeEditor();
          });
          return Promise.resolve(false);
        } else {
          return Promise.resolve(true);
        }
      });
    }

    closeEditor() {
      this.$eventBus.$emit('closeSubPage');
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }
}
