
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VForm } from 'vuetify/lib';

const lessonlists = namespace('lessonlists');
const settings = namespace('settings');
@Component
export default class LessonBanksEditorForm extends Vue {
  localRules = [(v: any) => !!v || 'Required'];

  $refs!: {
    lessonBanksEditorForm: InstanceType<typeof VForm>;
  };

  @settings.Getter('getUserType')
  userType!: string;

  @lessonlists.Action
  public save!: (params?: any) => Promise<any>;

  @lessonlists.Action
  public refreshFields!: (params:any) => void;

  @settings.Getter
  lessonLayoutItems!: Array<any>;

  get saveToSchoolOrDistrict() {
    return this.$store.state.lessonlists.saveToSchoolOrDistrict;
  }

  set saveToSchoolOrDistrict(val:any) {
    this.$store.commit('lessonlists/setSaveToSchoolOrDistrict', val);
    this.addUpdatedField('LESSONBANKSSAVETOWHERE');
  }

  get lessonBanksTitle(): string {
    return this.$store.state.lessonlists.lessonBanksTitle;
  }

  set lessonBanksTitle(val: string) {
    this.$store.commit('lessonlists/setLessonBanksTitle', val);
    this.addUpdatedField('LESSONBANKSTITLE');
  }

  get lessonBankLayoutId() {
    return this.$store.state.lessonlists.lessonBankLayoutId;
  }

  set lessonBankLayoutId(value: number) {
    this.$store.commit('lessonlists/setLessonBankLayoutId', value);
    this.addUpdatedField('LAYOUTID');
  }

  get updatedFields(): Set<string> {
    return this.$store.state.lessonlists.updatedFields as Set<string>;
  }

  set updatedFields(val: Set<string>) {
    this.$store.commit('lessonlists/setUpdatedFields', val);
  }

  addUpdatedField(field: string) {
    this.updatedFields.add(field);
  }
}
