
import vuetify from '@/plugins/vuetify';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class BaseModalLessonNav extends Vue {
  @Prop({ required: false, type: [Object, Array], default: () => { return {} } })
  input!: any;

  @Prop({ required: false, type: Boolean, default: false })
  value!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  applying!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  withActions!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  withApply!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  expandable!: boolean;

  @Prop({ required: false, type: Number, default: 900 })
  maxWidth!: number;

  @Prop({ required: false, type: [String, Object], default: '' })
  cardClass!: any;

  maxContentHeight = 'auto';

  dialog: any = {};
  interval = -1;
  positions = {
    clientX: -9999,
    clientY: -9999,
    movementX: 0,
    movementY: 0
  }

  $refs!: {
    dialogCard: Vue,
    toolbar: HTMLElement,
    bottombar: HTMLElement
  }

  @Prop({ required: false, type: Function })
  onClose?: () => Promise<any>;

  @Prop({ required: false, type: Function })
  onApply?: () => Promise<any>;

  get fullScreen() {
    return this.$store.state.index.fullScreen;
  }

  set fullScreen(val: boolean) {
    this.$store.commit('index/setFullScreen', val, { root: true });
  }

  doClose(outsideClicked?: boolean) {
    if (this.fullScreen && outsideClicked) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
          this.localValue = true;
        });
      });
      return;
    }
    if (this.onClose) {
      return this.onClose().then((result: boolean) => {
        if (!result) {
          this.$eventBus.$emit('closeSubPage');
        }
        this.localValue = result;
      });
    } else {
      this.$eventBus.$emit('closeSubPage');
      this.localValue = false;
    }
  }

  doApply() {
    if (this.applying) {
      return;
    }
    if (this.onApply) {
      return this.onApply().then((result: boolean) => {
        this.localValue = result;
        if (!result) {
          this.$eventBus.$emit('closeSubPage');
        }
      })
    } else {
      this.$emit('apply');
    }
  }

  @Watch('localValue')
  onLocalValueChange() {
    if (!this.localValue) {
      clearInterval(this.interval);
      const dialog = this.getDialogElement();
      if (dialog) {
        dialog.style.removeProperty('position');
        dialog.style.removeProperty('margin');
        dialog.style.removeProperty('top');
        dialog.style.removeProperty('left');
      }
      this.dialog = {};
    } else {
      this.interval = setInterval(() => { // prevent out of bounds
        const dialog = this.getDialogElement();
        if (dialog) {
          dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + 'px';
          dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + 'px';
        }
      }, 100);
    }
  }

  mounted() {
    this.determineMaxContentHeight();
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  determineMaxContentHeight() {
    this.maxContentHeight = 'calc(100vh - 100px)';
  }

  getDialogElement() {
    try {
      return this.$refs.dialogCard.$el.closest('.v-dialog.v-dialog--active') as HTMLElement;
    } catch (e) {
      clearInterval(this.interval);
    }
  }

  mouseDown(e: MouseEvent) {
    this.dialog.el = this.getDialogElement();
    this.dialog.mouseStartX = e.clientX;
    this.dialog.mouseStartY = e.clientY;
    this.dialog.elStartX = this.dialog.el.getBoundingClientRect().left;
    this.dialog.elStartY = this.dialog.el.getBoundingClientRect().top;
    this.dialog.el.style.position = 'fixed';
    this.dialog.el.style.margin = 0;
    this.dialog.oldTransition = this.dialog.el.style.transition;
    this.dialog.el.style.transition = 'none'
    document.onmousemove = this.mouseMove;
    document.onmouseup = this.mouseUp;
  }

  mouseMove(e: MouseEvent) {
    if (this.dialog.el === undefined) return;
    this.dialog.el.style.left = Math.min(
      Math.max(this.dialog.elStartX + e.clientX - this.dialog.mouseStartX, 0),
      window.innerWidth - this.dialog.el.getBoundingClientRect().width
    ) + 'px';
    this.dialog.el.style.top = Math.min(
      Math.max(this.dialog.elStartY + e.clientY - this.dialog.mouseStartY, 0),
      window.innerHeight - this.dialog.el.getBoundingClientRect().height
    ) + 'px';
  }

  get isExpandable() {
    return this.expandable && !this.isMobile;
  }

  get isFullScreen() {
    return this.isMobile || this.fullScreen;
  }

  get isMobile() {
    return vuetify.framework.breakpoint.mobile;
  }

  mouseUp() {
    if (this.dialog.el === undefined) return;
    this.dialog.el.style.transition = this.dialog.oldTransition;
    this.dialog.el = undefined
    document.onmouseup = null;
    document.onmousemove = null;
  }

  toggleFullScreen() {
    if (!this.applying) {
      this.fullScreen = !this.fullScreen;
      const dialog = this.getDialogElement();
      if (dialog && dialog.style) {
        dialog.style.transition = 'none'
        if (!this.fullScreen) {
          dialog.style.left = this.dialog.oldLeft;
          dialog.style.top = this.dialog.oldTop;
        } else {
          this.dialog.oldLeft = dialog.style.left;
          this.dialog.oldTop = dialog.style.top;
          dialog.style.left = '0px';
          dialog.style.top = '0px';
        }
      }
    }
  }
}
