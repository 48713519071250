var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        input: _vm.input,
        withActions: _vm.unitId > 0 && !_vm.input.isReadOnly,
        onApply: _vm.doApply,
        withApply: !_vm.input.isReadOnly,
        applying: _vm.listLoading,
        typeLabel: _vm.$t("unitLabel"),
        isDirty: _vm.isDirty,
      },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
          )
            return null
          $event.stopPropagation()
          $event.preventDefault()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _vm.input.isReadOnly
                ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("unitLabel")))])])
                : _c("div", [
                    _vm.unitId > 0
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))])
                      : _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))]),
                    _c("span", [_vm._v(" " + _vm._s(_vm.$t("unitLabel")))]),
                  ]),
            ]
          },
          proxy: true,
        },
        {
          key: "action-button",
          fn: function () {
            return [
              _vm.unitId > 0 && !_vm.input.isReadOnly
                ? _c("unit-actions", {
                    attrs: {
                      type: "button",
                      input: { loadData: false, data: _vm.input.data },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c("unit-editor-form", {
        ref: "unitEditor",
        attrs: { input: _vm.input, isModal: _vm.isModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }