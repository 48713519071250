var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.localValue && _vm.input.openStickerPickerDefault
    ? _c(
        "div",
        [
          _c("event-editor-form", {
            ref: "form",
            attrs: { input: _vm.input, isModal: _vm.isModal },
            on: {
              eventStickerSaved: _vm.doApply,
              stickerPickerClosed: _vm.stickerPickerClosed,
            },
          }),
          _c("pb-confirm", { ref: "confirm" }),
        ],
        1
      )
    : _vm.localValue
    ? _c(
        "pb-base-editor",
        {
          attrs: {
            isModal: _vm.isModal,
            input: _vm.input,
            withActions: false,
            withApply: false,
            cardClass: "event-editor-card",
            applying: _vm.listLoading,
            typeLabel: _vm.$t("eventLabel"),
            isDirty: _vm.isDirty,
            isEditable: _vm.isEditable,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", [
                    _vm.eventId > 0 && _vm.isEditable
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))])
                      : _vm.eventId > 0
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("viewLabel")))])
                      : _c("span", [_vm._v(_vm._s(_vm.$t("addLabel")))]),
                    _c("span", [_vm._v(" " + _vm._s(_vm.$t("eventLabel")))]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "action-button",
              fn: function () {
                return [
                  _c("event-actions", {
                    attrs: {
                      type: "button",
                      input: {
                        mode: _vm.isEditable ? "E" : "V",
                        loadData: false,
                        data: _vm.input.data,
                      },
                    },
                  }),
                  _vm.$currentUser.isDistrictAdmin && !_vm.isEventDateRange
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-content-click": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              label: _vm.$t("applyLabel"),
                                              icon: "",
                                              right: "",
                                              disabled: _vm.listLoading,
                                            },
                                          },
                                          "pb-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.listLoading
                                          ? _c("v-icon", [
                                              _vm._v("fal fa-sync fa-spin"),
                                            ])
                                          : _c("v-icon", [
                                              _vm._v("fal fa-check"),
                                            ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            222031279
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.isEditable && _vm.doApply()
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("saveToSchoolLabel"))),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.isEditable && _vm.doApply(true)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("saveToDistrictLabel"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.$currentUser.isDistrictAdmin && _vm.districtId > 0
                    ? _c(
                        "pb-btn",
                        {
                          attrs: {
                            label: _vm.$t("saveLabel"),
                            icon: "",
                            right: "",
                            disabled: _vm.listLoading,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.isEditable && _vm.doApply(true)
                            },
                          },
                        },
                        [
                          _vm.listLoading
                            ? _c("v-icon", [_vm._v("fal fa-sync fa-spin")])
                            : _c("v-icon", [_vm._v("fal fa-check")]),
                        ],
                        1
                      )
                    : _c(
                        "pb-btn",
                        {
                          attrs: {
                            label: _vm.$t("saveLabel"),
                            icon: "",
                            right: "",
                            disabled: _vm.listLoading,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.doApply()
                            },
                          },
                        },
                        [
                          _vm.listLoading
                            ? _c("v-icon", [_vm._v("fal fa-sync fa-spin")])
                            : _c("v-icon", [_vm._v("fal fa-check")]),
                        ],
                        1
                      ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _c(
            "div",
            [
              _c("event-editor-form", {
                ref: "form",
                attrs: {
                  input: _vm.input,
                  isActive: _vm.localValue,
                  isModal: _vm.isModal,
                },
              }),
              _c("pb-confirm", { ref: "confirm" }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }