




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import LessonBanksEditorForm from '@/components/lessonlists/LessonBanksEditorForm.vue';
import LessonBanksActions from '@/components/lessonlists/LessonBanksActions.vue';

import CommonUtils from '@/utils/common-utils';

const settings = namespace('settings');
const lessonlists = namespace('lessonlists');
@Component({
  components: {
    LessonBanksEditorForm,
    LessonBanksActions
  }
})
export default class LessonBanksEditor extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  $refs!: {
    form: LessonBanksEditorForm,
    confirm: Confirm
  }

  @settings.State
  userMode!: string;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('getSettings')
  settings!: any;

  @lessonlists.State
  updatedFields!: Set<string>;

  @lessonlists.Getter('getSelectedLessonBankClassId')
  selectedLessonBankClassId!: number;

  @lessonlists.Getter('getLessonBanksTitle')
  lessonBanksTitle!: string;

  @lessonlists.Getter('getSaveToSchoolOrDistrict')
  saveToSchoolOrDistrict!: any;

  @lessonlists.State
  lessonBankLayoutId!: number;

  get isDirty() {
    return this.updatedFields && this.updatedFields.size > 0;
  }

  get lessonBanksId(): number {
    return 0;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  async doOnClose() {
    if (this.isDirty) {
      return this.$refs.confirm.confirm({
        title: this.$t('lessonBanksLabel'),
        text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          this.$eventBus.$emit('closeLessonBankEditor');
          return Promise.resolve(false);
        } else {
          return Promise.resolve(true);
        }
      });
    } else {
      return Promise.resolve(false);
    }
  }

  @Watch('input')
  init() {
    this.$nextTick(() => {
      if (this.$refs.form) {
        const localForm:any = this.$refs.form;
        if (this.input.action !== 'ULB' && this.input.action !== 'UDLB') {
          localForm.refreshFields(true);
        } else {
          localForm.refreshFields(false);
        }
        if (this.input.action === 'DLB' || this.input.action === 'UDLB') {
          this.$store.commit('lessonlists/setSaveToSchoolOrDistrict', 1);
        }
        localForm.$refs.lessonBanksEditorForm.resetValidation();
      }
    })
  }

  doApply() {
    const localForm:any = this.$refs.form;
    if ((localForm.$refs.lessonBanksEditorForm.validate())) {
      CommonUtils.showLoading();
      // SLB = School Lesson Bank
      // LB = Lesson Bank
      // ULB = Update Lesson Bank
      // DLB = District Leson Bank
      // UDLB = Update District Leson Bank
      if (this.userType === 'D') {
        this.applyModeDistrict();
      } else {
        this.applyModeNotDistrict();
      }
    }
  }

  applyModeDistrict() {
    let payload = {};
    if (this.input.action === 'UDLB' || this.input.action === 'ULB') {
      if (this.saveToSchoolOrDistrict === 1) {
        payload = {
          classId: this.input.classId,
          className: this.lessonBanksTitle,
          userMode: this.userMode,
          lessonLayoutId: this.lessonBankLayoutId,
          schoolId: 0,
          districtId: this.settings.schoolSettings.districtId,
          mode: 'DLB'
        };
      } else {
        payload = {
          classId: this.input.classId,
          className: this.lessonBanksTitle,
          userMode: this.userMode,
          lessonLayoutId: this.lessonBankLayoutId,
          schoolId: this.settings.schoolSettings.schoolId,
          districtId: 0,
          mode: 'LB'
        };
      }
    } else {
      if (this.saveToSchoolOrDistrict === 1) {
        payload = {
          className: this.lessonBanksTitle,
          userMode: this.userMode,
          lessonLayoutId: this.lessonBankLayoutId,
          schoolId: 0,
          districtId: this.settings.schoolSettings.districtId,
          mode: 'DLB'
        };
      } else {
        payload = {
          className: this.lessonBanksTitle,
          userMode: this.userMode,
          lessonLayoutId: this.lessonBankLayoutId,
          schoolId: this.settings.schoolSettings.schoolId,
          districtId: 0,
          mode: 'LB'
        };
      }
    }
    this.$refs.form.save(payload).then(() => {
      this.localValue = false;
      this.$eventBus.$emit('closeLessonBankEditor');
      if (this.selectedLessonBankClassId !== 0) {
        this.$eventBus.$emit('lessonHasBeenSave', true);
      } else {
        CommonUtils.hideLoading();
      }
    });
  }

  applyModeNotDistrict() {
    let payload = {};
    if (this.input.action === 'SLB') {
      payload = {
        className: this.lessonBanksTitle,
        userMode: this.userMode,
        schoolId: this.settings.schoolSettings.schoolId,
        forceSchoolLessonBank: true,
        lessonLayoutId: this.lessonBankLayoutId,
        mode: 'SLB'
      };
    } else if (this.input.action === 'ULB') {
      payload = {
        classId: this.input.classId,
        className: this.lessonBanksTitle,
        userMode: this.userMode,
        schoolId: this.settings.schoolSettings.schoolId,
        districtId: 0,
        lessonLayoutId: this.lessonBankLayoutId,
        mode: 'LB'
      };
    } else if (this.input.action === 'LB') {
      payload = {
        className: this.lessonBanksTitle,
        userMode: this.userMode,
        schoolId: this.settings.schoolSettings.schoolId,
        districtId: 0,
        lessonLayoutId: this.lessonBankLayoutId,
        mode: 'LB'
      };
    }
    this.$refs.form.save(payload).then(() => {
      this.$eventBus.$emit('closeLessonBankEditor');
      this.localValue = false;
      if (this.selectedLessonBankClassId !== 0) {
        this.$eventBus.$emit('lessonHasBeenSave', true);
      } else {
        CommonUtils.hideLoading();
      }
    });
  }
}
