var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-editor",
    {
      attrs: {
        isModal: _vm.isModal,
        input: _vm.input,
        withActions: true,
        onApply: _vm.doApply,
        typeLabel: _vm.$t("teacherLabel"),
        isDirty: _vm.isDirty,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("editLabel")))]),
                _c("span", [_vm._v(" " + _vm._s(_vm.$t("teacherLabel")))]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "action-button",
          fn: function () {
            return [_c("teacher-actions", { attrs: { type: "button" } })]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "div",
        [
          _c("teacher-editor-form", {
            ref: "form",
            attrs: { input: _vm.input },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }