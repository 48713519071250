var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _vm.showBlockedMessage
        ? [
            _c(
              "v-banner",
              {
                style: {
                  "padding-left": _vm.leftPaddingWidth + "px",
                  color: "#055160"
                },
                attrs: { color: "#cff4fc" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function() {
                        return [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("fas fa-circle-info")
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "pb-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { icon: "", label: _vm.$t("closeLabel") },
                              on: { click: _vm.blockMessageClosed }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "#055160" } },
                                [_vm._v("fal fa-times")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3492434600
                )
              },
              [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("notificationBlockedBrowser")))
                ])
              ]
            )
          ]
        : _vm._e(),
      _vm.showFirebaseMsg
        ? [
            _c(
              "v-banner",
              {
                style: {
                  "padding-left": _vm.leftPaddingWidth + "px",
                  color: "#055160"
                },
                attrs: { color: "#cff4fc" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function() {
                        return [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("fas fa-circle-info")
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "pb-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { icon: "", label: _vm.$t("closeLabel") },
                              on: { click: _vm.closeFirebaseMsg }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "#055160" } },
                                [_vm._v("fal fa-times")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1553726202
                )
              },
              [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.localFirebaseMsg) },
                  on: { click: _vm.promptNotification }
                })
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }