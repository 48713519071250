var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": _vm.maxWidth,
        fullscreen: _vm.isFullScreen,
        "content-class": "base-modal elevation-0",
        "retain-focus": false
      },
      on: {
        "click:outside": function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.doClose()
        }
      },
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass:
            "lesson-nav-container d-flex flex-nowrap no-gutters fill-width fill-height"
        },
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFullScreen,
                  expression: "!isFullScreen"
                }
              ],
              staticClass: "transparent elevation-0"
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height no-gutters",
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "nav-button",
                          staticStyle: { "border-radius": "20px 0px 0px 20px" },
                          attrs: { "aria-label": _vm.$t("previousLabel") },
                          on: {
                            click: function($event) {
                              return _vm.$emit("left")
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "primary" } },
                            [_vm._v("fa fa-chevron-left")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              ref: "dialogCard",
              staticClass: "base-modal-card elevation-24",
              class: _vm.cardClass,
              style: {
                width: _vm.isFullScreen ? "100%" : "calc(100% - 90px)",
                height: _vm.isFullScreen ? "100%" : "",
                "border-radius": "6px"
              }
            },
            [
              _c(
                "v-card-title",
                {
                  ref: "toolbar",
                  staticClass: "px-0 pt-0",
                  staticStyle: { cursor: "move" },
                  on: {
                    keyup: [
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "left", 37, $event.key, [
                            "Left",
                            "ArrowLeft"
                          ])
                        ) {
                          return null
                        }
                        if ("button" in $event && $event.button !== 0) {
                          return null
                        }
                        return _vm.$emit("left")
                      },
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "right", 39, $event.key, [
                            "Right",
                            "ArrowRight"
                          ])
                        ) {
                          return null
                        }
                        if ("button" in $event && $event.button !== 2) {
                          return null
                        }
                        return _vm.$emit("right")
                      }
                    ],
                    mousedown: _vm.mouseDown
                  }
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { "border-radius": "4px 4px 0px 0px" },
                      attrs: { color: "primary", dark: "" }
                    },
                    [
                      _c(
                        "pb-btn",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            icon: "",
                            right: "",
                            label: _vm.$t("closeLabel")
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.doClose()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-times")])],
                        1
                      ),
                      _c("div", {
                        style: {
                          width:
                            90 -
                            (_vm.withActions ? 0 : 50) -
                            (_vm.isExpandable ? 0 : 50) +
                            "px"
                        }
                      }),
                      _c("v-spacer"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "white-space": "nowrap",
                            overflow: "hidden"
                          }
                        },
                        [_vm._t("header", null, null, _vm.input)],
                        2
                      ),
                      _c("v-spacer"),
                      _vm._t(
                        "actions",
                        function() {
                          return [
                            _vm.isExpandable
                              ? _c(
                                  "pb-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      right: "",
                                      label: _vm.$t("fullscreenLabel")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.toggleFullScreen.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm.fullScreen
                                      ? _c("v-icon", [
                                          _vm._v("fal fa-compress-wide")
                                        ])
                                      : _c("v-icon", [
                                          _vm._v("fal fa-expand-wide")
                                        ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._t(
                              "action-button",
                              function() {
                                return [
                                  _vm.withActions
                                    ? _c(
                                        "pb-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            right: "",
                                            label: _vm.$t("actionsLabel")
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              !_vm.applying &&
                                                _vm.$emit("actions", $event)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fal fa-ellipsis-v-alt")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              },
                              null,
                              _vm.input
                            ),
                            _vm.withApply
                              ? _c(
                                  "pb-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      right: "",
                                      label: _vm.$t("saveLabel")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.doApply.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm.applying
                                      ? _c("v-icon", [
                                          _vm._v("fal fa-sync fa-spin")
                                        ])
                                      : _c("v-icon", [_vm._v("fal fa-check")])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "mr-1" })
                          ]
                        },
                        null,
                        _vm.input
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "px-0 py-0",
                  style: {
                    "overflow-x": "hidden",
                    "max-height": _vm.maxContentHeight,
                    "border-radius": "4px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "dialog-content fill-height" },
                    [_vm._t("default", null, null, _vm.input)],
                    2
                  )
                ]
              ),
              _c(
                "div",
                { ref: "bottombar", staticClass: "transparent elevation-24" },
                [_vm._t("bottom-actions")],
                2
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFullScreen,
                  expression: "!isFullScreen"
                }
              ],
              staticClass: "transparent elevation-0"
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height no-gutters",
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "nav-button",
                          staticStyle: { "border-radius": "0px 20px 20px 0px" },
                          attrs: { "aria-label": _vm.$t("nextLabel") },
                          on: {
                            click: function($event) {
                              return _vm.$emit("right")
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "primary" } },
                            [_vm._v("fa fa-chevron-right")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }