
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventEditorForm from '@/components/events/EventEditorForm.vue';
import EventActions from '@/components/events/EventActions.vue';
import CommonUtils from '@/utils/common-utils';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';
import { FormError } from '@/errors';
import { Editor } from '@/types/global.types';

const events = namespace('events');

@Component({
  components: {
    EventEditorForm,
    EventActions
  }
})
export default class EventEditor extends Vue implements Editor {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  $refs!: {
    form: EventEditorForm,
    confirm: Confirm
  }

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @events.State
  updatedFields!: Set<string>;

  @events.State
  listLoading!: any;

  localFullScreen = false;

  isDirty() {
    return this.updatedFields && this.updatedFields.size > 0;
  }

  get eventId(): number {
    return +this.$store.state.events.eventId;
  }

  get districtId(): number {
    return +this.$store.state.events.districtId;
  }

  get schoolId(): number {
    return +this.$store.state.events.schoolId;
  }

  get googleId(): string {
    return this.$store.state.events.googleId;
  }

  get isEventDateRange(): boolean {
    return (this.$store.state.events.eventDate !== this.$store.state.events.endDate || CommonUtils.isNotEmpty(this.$store.state.events.extraDays)) && this.eventId > 0;
  }

  get isEditable() {
    if (this.$currentUser.isStudent) {
      return false;
    }
    if (CommonUtils.hasText(this.googleId)) {
      return false;
    }
    if (this.districtId && this.districtId > 0 && !this.$currentUser.isDistrictAdmin) {
      return false;
    }
    if (this.schoolId && this.schoolId > 0 && !this.$currentUser.isAdmin) {
      return false;
    }
    return true;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  typeChange() {
    this.input.loadData = false
  }

  stickerPickerClosed() {
    this.localValue = false;
    this.input.openStickerPickerDefault = false;
  }

  async doApply(isDistrictEvent = false) {
    if (this.localPage === 'events') {
      this.$store.commit('events/setListLoading', true);
      this.$store.commit('events/setIsDistrictEvent', isDistrictEvent);
    } else {
      CommonUtils.showLoading();
    }
    return this.$refs.form.save().then(() => {
      this.localValue = false;
      if (!this.isModal) {
        this.$eventBus.$emit('refreshEvents');
        this.$eventBus.$emit('closeSubPage');
      }
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      } else {
        return Promise.resolve(true);
      }
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$store.commit('events/setListLoading', false);
      return Promise.resolve(false);
    });
  }

  created() {
    this.$store.commit('events/setIsDistrictEvent', false);
    this.$eventBus.$on('deleteEvent', (eventIds: any) => {
      if (!this.input.data) {
        return;
      }
      for (const i in eventIds) {
        if (this.input.data.eventId === eventIds[i]) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteEvent');
  }
}
