var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "editor",
      on: {
        drop: _vm.dropAttachment,
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragleave: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-form",
            { ref: "unitForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTextFieldsVisible,
                      expression: "isTextFieldsVisible",
                    },
                  ],
                  staticClass: "px-5 pt-4 mb-4",
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: _vm.localReadOnlyMode,
                              autofocus: "",
                              "hide-details": "auto",
                              label: _vm.$t("unitIDLabel") + "*",
                              "aria-required": "true",
                              outlined: "",
                              rules: _vm.localRules.unitNum,
                            },
                            model: {
                              value: _vm.unitNum,
                              callback: function ($$v) {
                                _vm.unitNum = $$v
                              },
                              expression: "unitNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "9" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: _vm.localReadOnlyMode,
                              "hide-details": "auto",
                              label: _vm.$t("unitTitleLabel") + "*",
                              "aria-required": "true",
                              outlined: "",
                              rules: _vm.localRules.unitTitle,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append-outer",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mt-n2" },
                                      [
                                        _c("pb-color-picker", {
                                          attrs: {
                                            icon: "fas fa-palette",
                                            origin: "top right",
                                            large: false,
                                          },
                                          model: {
                                            value: _vm.unitColor,
                                            callback: function ($$v) {
                                              _vm.unitColor = $$v
                                            },
                                            expression: "unitColor",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.unitTitle,
                              callback: function ($$v) {
                                _vm.unitTitle = $$v
                              },
                              expression: "unitTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: _vm.classYearId > 0 ? "6" : "12",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "hide-details": "auto",
                              outlined: "",
                              label: _vm.$t("classLabel") + "*",
                              "aria-required": "true",
                              items: _vm.classBankItems,
                              rules: _vm.localRules.class,
                              readonly: _vm.unitId > 0 || _vm.localReadOnlyMode,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    item.isBank
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "class-item class-item-type-b",
                                          },
                                          [_vm._v("B")]
                                        )
                                      : _vm._e(),
                                    _c("span", [_vm._v(_vm._s(item.text))]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.subjectId,
                              callback: function ($$v) {
                                _vm.subjectId = $$v
                              },
                              expression: "subjectId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.classYearId > 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("pb-date-time-range-field", {
                                attrs: {
                                  readonly: _vm.localReadOnlyMode,
                                  hideDetails: "auto",
                                  label: _vm.$t("unitDateLabel"),
                                  rules: _vm.localRules.dateRules,
                                  start: _vm.unitStart,
                                  end: _vm.unitEnd,
                                },
                                on: {
                                  "update:start": [
                                    _vm.validateDate,
                                    function ($event) {
                                      _vm.unitStart = $event
                                    },
                                  ],
                                  "update:end": [
                                    _vm.validateDate,
                                    function ($event) {
                                      _vm.unitEnd = $event
                                    },
                                  ],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.lessonStart != "" && _vm.yearId !== 0
                    ? _c("v-row", [
                        _c("span", { staticClass: "ml-4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("lessonRangeLabel", {
                                start: _vm.formatToShow(_vm.lessonStart),
                                end: _vm.formatToShow(_vm.lessonEnd),
                              })
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  key: _vm.localRefreshKey,
                  on: {
                    mouseenter: function ($event) {
                      _vm.isSectionsHovered = true
                    },
                    mouseleave: function ($event) {
                      _vm.isSectionsHovered = false
                    },
                  },
                },
                [
                  _c("text-editor-full-screen-button", {
                    attrs: {
                      isSectionsHovered: _vm.isSectionsHovered,
                      isTextFieldsVisible: _vm.isTextFieldsVisible,
                    },
                    on: { hideOrShowTextFields: _vm.hideOrShowTextFields },
                  }),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "editor-section-tabs",
                      attrs: {
                        "center-active": "",
                        centered: "",
                        "show-arrows": "",
                        "prev-icon": "fal fa-chevron-left",
                        "next-icon": "fal fa-chevron-right",
                      },
                      model: {
                        value: _vm.localActiveTab,
                        callback: function ($$v) {
                          _vm.localActiveTab = $$v
                        },
                        expression: "localActiveTab",
                      },
                    },
                    _vm._l(_vm.enabledSections, function (section) {
                      return _c("v-tab", { key: section.section }, [
                        _vm._v(_vm._s(section.label)),
                      ])
                    }),
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.localActiveTab,
                        callback: function ($$v) {
                          _vm.localActiveTab = $$v
                        },
                        expression: "localActiveTab",
                      },
                    },
                    [
                      _vm._l(_vm.enabledSections, function (section, index) {
                        return [
                          section.section === "description"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          disabled:
                                            section.disabled ||
                                            _vm.localReadOnlyMode,
                                          noAccess: section.noAccess,
                                        },
                                        model: {
                                          value: _vm.unitDesc,
                                          callback: function ($$v) {
                                            _vm.unitDesc = $$v
                                          },
                                          expression: "unitDesc",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "lesson"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.localReadOnlyMode,
                                          noAccess: section.noAccess,
                                        },
                                        model: {
                                          value: _vm.unitLessonText,
                                          callback: function ($$v) {
                                            _vm.unitLessonText = $$v
                                          },
                                          expression: "unitLessonText",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab2"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.localReadOnlyMode,
                                          noAccess: section.noAccess,
                                        },
                                        model: {
                                          value: _vm.unitHomeworkText,
                                          callback: function ($$v) {
                                            _vm.unitHomeworkText = $$v
                                          },
                                          expression: "unitHomeworkText",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab3"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.localReadOnlyMode,
                                          noAccess: section.noAccess,
                                        },
                                        model: {
                                          value: _vm.unitNotesText,
                                          callback: function ($$v) {
                                            _vm.unitNotesText = $$v
                                          },
                                          expression: "unitNotesText",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab4"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.localReadOnlyMode,
                                          noAccess: section.noAccess,
                                        },
                                        model: {
                                          value: _vm.unitSection4Text,
                                          callback: function ($$v) {
                                            _vm.unitSection4Text = $$v
                                          },
                                          expression: "unitSection4Text",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab5"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.localReadOnlyMode,
                                          noAccess: section.noAccess,
                                        },
                                        model: {
                                          value: _vm.unitSection5Text,
                                          callback: function ($$v) {
                                            _vm.unitSection5Text = $$v
                                          },
                                          expression: "unitSection5Text",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "tab6"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c("pb-text-editor", {
                                        attrs: {
                                          config: _vm.localTextEditorConfig,
                                          fontStyle: section.textStyle,
                                          disabled:
                                            section.disabled ||
                                            _vm.localReadOnlyMode,
                                          noAccess: section.noAccess,
                                        },
                                        model: {
                                          value: _vm.unitSection6Text,
                                          callback: function ($$v) {
                                            _vm.unitSection6Text = $$v
                                          },
                                          expression: "unitSection6Text",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "strategies"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            clearable: _vm.hasStrategies,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog(
                                                "strategies"
                                              )
                                            },
                                            clear: _vm.clearStrategies,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              disabled: _vm.localReadOnlyMode,
                                              items: _vm.strategyItems,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteStrategy,
                                            },
                                          }),
                                          _c("strategies-picker", {
                                            attrs: {
                                              strategies: _vm.strategies,
                                            },
                                            on: {
                                              "update:strategies": function (
                                                $event
                                              ) {
                                                _vm.strategies = $event
                                              },
                                            },
                                            model: {
                                              value: _vm.dialogs.strategies,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "strategies",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.strategies",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "myStandards"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            clearable: _vm.hasMyStandards,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog(
                                                "myStandards"
                                              )
                                            },
                                            clear: _vm.clearMyStandards,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              disabled: _vm.localReadOnlyMode,
                                              items: _vm.myStandardItems,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteMyStandard,
                                            },
                                          }),
                                          _c("my-list-picker", {
                                            attrs: {
                                              standards: _vm.myStandards,
                                            },
                                            on: {
                                              "update:standards": function (
                                                $event
                                              ) {
                                                _vm.myStandards = $event
                                              },
                                            },
                                            model: {
                                              value: _vm.dialogs.myStandards,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "myStandards",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.myStandards",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "sharedStandards"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            clearable: _vm.hasStandards,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog("standards")
                                            },
                                            clear: _vm.clearStandards,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              disabled: _vm.localReadOnlyMode,
                                              items: _vm.standardItems,
                                            },
                                            on: {
                                              deleteItem: _vm.deleteStandard,
                                            },
                                          }),
                                          _c("standards-picker", {
                                            attrs: {
                                              classId: _vm.subjectId,
                                              standards: _vm.standards,
                                            },
                                            on: {
                                              "update:standards": function (
                                                $event
                                              ) {
                                                _vm.standards = $event
                                              },
                                            },
                                            model: {
                                              value: _vm.dialogs.standards,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "standards",
                                                  $$v
                                                )
                                              },
                                              expression: "dialogs.standards",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "schoolStandards"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            clearable: _vm.hasSchoolStandards,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          on: {
                                            add: function ($event) {
                                              return _vm.openDialog(
                                                "schoolStandards"
                                              )
                                            },
                                            clear: _vm.clearSchoolStandards,
                                          },
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              disabled: _vm.localReadOnlyMode,
                                              items: _vm.schoolStandardItems,
                                            },
                                            on: {
                                              deleteItem:
                                                _vm.deleteSchoolStandard,
                                            },
                                          }),
                                          _c("school-list-picker", {
                                            attrs: {
                                              standards: _vm.schoolStandards,
                                            },
                                            on: {
                                              "update:standards": function (
                                                $event
                                              ) {
                                                _vm.schoolStandards = $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dialogs.schoolStandards,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogs,
                                                  "schoolStandards",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogs.schoolStandards",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          section.section === "attachments"
                            ? _c(
                                "v-tab-item",
                                { key: section.section },
                                [
                                  _vm.localActiveTab === index
                                    ? _c(
                                        "editor-section",
                                        {
                                          attrs: {
                                            config:
                                              _vm.localEditorSectionConfig,
                                            readonly: _vm.localReadOnlyMode,
                                            label: section.label,
                                            disabled: section.disabled,
                                            noAccess: section.noAccess,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.hasAttachments
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "clearAllLabel",
                                                                  {
                                                                    text: section.label,
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                !section.disabled &&
                                                                  _vm.clearAttachments()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-trash-alt"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addItemLabel",
                                                            {
                                                              text: section.label,
                                                            }
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            !section.disabled &&
                                                              _vm.openDialog(
                                                                "myFiles"
                                                              )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fal fa-paperclip"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          "aria-label": _vm.$t(
                                                            "addGoogleDriveLabel"
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            !section.disabled &&
                                                              _vm.addGoogleDriveAttachments()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "fab fa-google-drive"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("editor-section-list", {
                                            attrs: {
                                              disabled:
                                                section.disabled ||
                                                _vm.localReadOnlyMode,
                                              items: _vm.attachmentItems,
                                              showShortValue: false,
                                              clickable: true,
                                            },
                                            on: {
                                              otherAction:
                                                _vm.toggleAttachmentPrivateFlag,
                                              deleteItem: _vm.deleteAttachment,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "value",
                                                  fn: function ({
                                                    value,
                                                    data,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "section-editor-link",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                data
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "other-actions",
                                                  fn: function ({
                                                    item,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "section-editor-checkbox",
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "privateLabel"
                                                            ),
                                                          disabled:
                                                            section.disabled,
                                                          "input-value":
                                                            item.data
                                                              .privateFlag,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleAttachmentPrivateFlag(
                                                              item,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("pb-confirm", { ref: "confirm" }),
              _c(
                "pb-confirm",
                { ref: "embedFiles" },
                [
                  _c(
                    "v-chip-group",
                    {
                      attrs: {
                        "active-class": "accent1--text font-weight-bold",
                        column: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.localEmbedSections,
                        callback: function ($$v) {
                          _vm.localEmbedSections = $$v
                        },
                        expression: "localEmbedSections",
                      },
                    },
                    _vm._l(_vm.enabledLessonTextSections, function (item, i) {
                      return _c(
                        "v-chip",
                        { key: i, attrs: { value: item.section } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("my-files-picker", {
                ref: "filesPicker",
                attrs: { attachments: _vm.attachments },
                on: { "update:attachments": _vm.updateAttachments },
                model: {
                  value: _vm.dialogs.myFiles,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialogs, "myFiles", $$v)
                  },
                  expression: "dialogs.myFiles",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }