























import Confirm from '@/components/core/Confirm.vue';
import { FormError } from '@/errors';
import SchoolYearMixin from '@/mixins/school-year-mixin';
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const classes = namespace('classes');
const settings = namespace('settings');

@Component({
  mixins: [SchoolYearMixin]
})
export default class SchoolYearActions extends Vue implements ActionsModal {
    actions: Array<any> = [
      {
        id: 'delete',
        icon: 'fal fa-trash-alt',
        label: 'deleteYearLabel'
      },
      {
        id: 'import',
        icon: 'fal fa-file-import',
        label: 'importLessonsLabel'
      },
      {
        id: 'manageLessons',
        icon: 'fal fa-book-open',
        label: 'manageLessonsLabel'
      },
      {
        id: 'unlink',
        icon: 'fal fa-link-slash',
        label: 'unlinkLessonsLabel'
      }
    ];

    @classes.Action
    removeLinkedLessons!: (params?: any) => Promise<any>;

    @settings.State
    adminSchoolYears!: Array<any>;

    @settings.Getter('getSchoolYears')
    teacherSchoolYears!: Array<any>;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @Prop({ required: false, type: String, default: 'absolute' })
    type!: string;

    @Prop({
      required: true,
      type: Object,
      default: () => {
        return {};
      }
    })
    input!: any;

    showMenu = false;
    x = 0;
    y = 0;

    userMode!: string;
    deleteSchoolYears!: (params: any) => Promise<any>;
    getYearNames!: (item: any) => string;
    updatedSettings!: any;
    resetPlans!: () => void;
    setHaveClass!: (value: boolean) => void;
    reloadSettings!: (params?: any) => Promise<any>;
    loadClasses!: (params?: any) => Promise<any>;

    $refs!: {
        confirm: Confirm
    }

    get schoolYears() {
      return (this.userMode === 'A' ? this.adminSchoolYears : this.teacherSchoolYears) || [];
    }

    get filteredActions() {
      return this.actions.filter((a) => {
        return a.id !== 'delete' || this.schoolYears.length > 1;
      })
    }

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    async doAction(action: any) {
      if (action.id === 'delete') {
        this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', true) : CommonUtils.showLoading();
      }
      return Promise.resolve().then(() => {
        if (action.id === 'delete') {
          return this.doDelete();
        } else if (action.id === 'manageLessons') {
          return this.doManageLessons();
        } else if (action.id === 'unlink') {
          return this.doUnlink();
        } else if (action.id === 'import') {
          return this.doImport();
        }
      }).catch((error) => {
        if (!(error instanceof FormError)) {
          return Promise.reject(error);
        }
      }).finally(() => {
        this.showMenu = false;
        if (action.id === 'delete') {
          this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', false) : CommonUtils.hideLoading();
        }
      })
    }

    doImport() {
      this.$eventBus.$emit('openImportLessons');
    }

    doManageLessons() {
      this.$eventBus.$emit('openManageLesson');
    }

    async doUnlink() {
      const that = this;
      return this.$refs.confirm.confirm({
        title: this.$t('classLabel'),
        text: this.$t('retainContentsOfLessonsUnlinkedMsg'),
        option1ButtonAlternativeText: this.$t('yesLabel'),
        option2ButtonAlternativeText: this.$t('noLabel')
      }).then((result) => {
        const request = {
          yearId: that.input.data.yearId,
          linkAction: undefined as any
        };
        if (result === 1) {
          request.linkAction = 1;
        }
        if (result !== 0) {
          CommonUtils.showLoading();
          that.removeLinkedLessons(request)
            .then(() => {
              CommonUtils.hideLoading();
            });
        }
      });
    }

    async doDelete() {
      this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', true) : CommonUtils.showLoading();
      const years = [this.updatedSettings];
      return this.$refs.confirm.confirm({
        title: this.$t('schoolYearLabel'),
        text: this.$t('schoolYearMsg7', { yearName: this.getYearNames(years) }),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then(result => {
        if (result === 1) {
          return this.deleteSchoolYears({ years }).then(() => {
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('schoolYearDeletedLabel') as string);
            }
            return Promise.resolve();
          });
        } else {
          this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', false) : CommonUtils.hideLoading();
          return Promise.reject();
        }
      }).then(() => {
        if (this.userMode === 'A') {
          return Promise.resolve();
        } else {
          return this.reloadSettings();
        }
      }).then(async () => {
        if (this.userMode === 'A') {
          return Promise.resolve();
        } else {
          this.setHaveClass(false);
          return this.loadClasses().then(() => {
            this.resetPlans();
            return Promise.resolve();
          });
        }
      }).then(() => {
        this.resetPlans();
        return Promise.resolve();
      }).finally(() => {
        this.localPage === 'school-years' ? this.$store.commit('settings/setSchoolYearListLoading', false) : CommonUtils.hideLoading();
      });
    }

    closeEditor() {
      this.$eventBus.$emit('closeSubPage');
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }
}
