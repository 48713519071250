var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-modal",
    {
      attrs: {
        withActions: false,
        withApply: true,
        applying: _vm.isApplying,
        onApply: _vm.doApply,
        input: _vm.input,
        isModal: _vm.isModal,
        expandable: false,
        maxWidth: 500,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("addClassLabel")))]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          autofocus: "",
                          "hide-details": "auto",
                          label: _vm.$t("classJoinCodeLabel") + "*",
                          "aria-required": "true",
                          outlined: "",
                          rules: _vm.localRules,
                        },
                        on: {
                          input: function ($event) {
                            _vm.typing = true
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.classJoinCode,
                          callback: function ($$v) {
                            _vm.classJoinCode = $$v
                          },
                          expression: "classJoinCode",
                        },
                      }),
                      _vm.joinClassWarning
                        ? _c(
                            "span",
                            {
                              staticClass: "warning--text ml-2",
                              staticStyle: {
                                "line-height": "12px",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.joinClassWarning))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.localClassesObject.length > 0
                ? _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c("v-simple-table", {
                        staticStyle: { width: "100%" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(" Class Name "),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(" Start Date "),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(" Teacher "),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.localClassesObject,
                                      function (item) {
                                        return _c(
                                          "tr",
                                          { key: item.subjectId },
                                          [
                                            _c("td", [
                                              _vm._v(_vm._s(item.className)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.startDates)),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(item.teacherDisplayName)
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3764146593
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }