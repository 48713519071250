var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "content-class": "student-tags-menu-content",
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type == "absolute",
        "position-x": _vm.x,
        "position-y": _vm.y,
        width: "300px"
      },
      scopedSlots: _vm._u(
        [
          _vm.type == "button"
            ? {
                key: "activator",
                fn: function(ref) {
                  var onMenu = ref.on
                  return [
                    _c(
                      "pb-btn",
                      _vm._g(
                        {
                          ref: "actionsButton",
                          attrs: {
                            label: _vm.$t("actionsLabel"),
                            icon: "",
                            right: ""
                          },
                          on: {
                            keyup: [
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "escape",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                $event.preventDefault()
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "right",
                                    39,
                                    $event.key,
                                    ["Right", "ArrowRight"]
                                  )
                                ) {
                                  return null
                                }
                                if ("button" in $event && $event.button !== 2) {
                                  return null
                                }
                                return _vm.focusExtraOptions.apply(
                                  null,
                                  arguments
                                )
                              }
                            ]
                          }
                        },
                        Object.assign({}, onMenu)
                      ),
                      [_c("v-icon", [_vm._v("fal fa-ellipsis-v-alt")])],
                      1
                    )
                  ]
                }
              }
            : null
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu"
      }
    },
    [
      _c(
        "div",
        { staticClass: "editor-actions" },
        [
          _c(
            "v-list",
            { ref: "listActions", attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.stop",
                      modifiers: { stop: true }
                    }
                  ],
                  staticClass: "pb-4 student-tags",
                  attrs: { tabindex: "0" }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-0", attrs: { justify: "center" } },
                        _vm._l(_vm.allTags, function(tag, index) {
                          return _c(
                            "pb-btn",
                            {
                              key: tag.studentTagId,
                              ref: "status" + index,
                              refInFor: true,
                              attrs: { icon: "", label: tag.tagText },
                              on: {
                                click: function($event) {
                                  return _vm.addRemoveTag(tag)
                                },
                                keyup: [
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.addRemoveTag(_vm.status)
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "down",
                                        40,
                                        $event.key,
                                        ["Down", "ArrowDown"]
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.focusActionButton.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "up",
                                        38,
                                        $event.key,
                                        ["Up", "ArrowUp"]
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.focusActionButton.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "right",
                                        39,
                                        $event.key,
                                        ["Right", "ArrowRight"]
                                      )
                                    ) {
                                      return null
                                    }
                                    if (
                                      "button" in $event &&
                                      $event.button !== 2
                                    ) {
                                      return null
                                    }
                                    return _vm.focusStatus(index + 1)
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "left",
                                        37,
                                        $event.key,
                                        ["Left", "ArrowLeft"]
                                      )
                                    ) {
                                      return null
                                    }
                                    if (
                                      "button" in $event &&
                                      $event.button !== 0
                                    ) {
                                      return null
                                    }
                                    return _vm.focusStatus(index - 1)
                                  }
                                ]
                              }
                            },
                            [
                              _c("v-icon", {
                                attrs: { color: tag.color },
                                domProps: { textContent: _vm._s(tag.icon) }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "pb-btn",
                        {
                          ref: "manageStatusButton",
                          staticClass: "pt-2 pl-4",
                          attrs: { label: _vm.$t("manageTagsLabel"), icon: "" },
                          on: {
                            click: _vm.manageTags,
                            keyup: [
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.manageTags.apply(null, arguments)
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "left",
                                    37,
                                    $event.key,
                                    ["Left", "ArrowLeft"]
                                  )
                                ) {
                                  return null
                                }
                                if ("button" in $event && $event.button !== 0) {
                                  return null
                                }
                                return _vm.focusStatus(_vm.allTags.length - 1)
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                ) {
                                  return null
                                }
                                return _vm.focusActionButton.apply(
                                  null,
                                  arguments
                                )
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp"
                                  ])
                                ) {
                                  return null
                                }
                                return _vm.focusActionButton.apply(
                                  null,
                                  arguments
                                )
                              }
                            ]
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-cog")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm.filteredActions.length > 0
                ? _c("v-divider", { staticStyle: { margin: "10px 0px" } })
                : _vm._e(),
              _vm._l(_vm.filteredActions, function(item, i) {
                return _c(
                  "v-list-item",
                  {
                    key: i,
                    attrs: { link: "" },
                    on: {
                      click: function($event) {
                        return _vm.doAction(item)
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("v-icon", {
                          domProps: { textContent: _vm._s(item.icon) }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", {
                          staticStyle: { width: "130px" },
                          domProps: { textContent: _vm._s(_vm.$t(item.label)) }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c("student-tags", {
        attrs: { value: _vm.tagsDialog },
        on: {
          close: function($event) {
            _vm.tagsDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }