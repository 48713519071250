









































import { ActionsModal } from '@/types/global.types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import StudentTags from '@/components/students/StudentTags.vue';
import Confirm from '../core/Confirm.vue';
import CommonUtils from '@/utils/common-utils';
import { FormError } from '@/errors';
import { namespace } from 'vuex-class';

const students = namespace('students');
const studentTags = namespace('studenttags');
const settings = namespace('settings');

@Component({
  components: {
    StudentTags
  }
})
export default class StudentActions extends Vue implements ActionsModal {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: {}, mode: 'P' };
    }
  })
  input!: any;

  @Prop({ required: false, type: String, default: 'absolute' })
  type!: string;

    actions = [
      {
        id: 'delete',
        icon: 'fal fa-trash-alt',
        label: 'deleteLabel'
      },
      {
        id: 'proximity',
        icon: 'fal fa-arrow-up-from-square',
        label: 'proximityReportLabel'
      }
    ];

    showMenu = false;
    tagsDialog = false;
    x = 0;
    y = 0;

    $refs!: {
        confirm: Confirm,
        listActions: any
    }

    @students.Action
    deleteStudent!: (param: any) => Promise<any>;

    @students.Action
    getStudentProximityReport!: (param?: any) => Promise<any>;

    @studentTags.Getter
    getTags!: Array<any>;

    @students.Getter
    getSelectedTags!: Array<any>;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    get filteredActions() {
      return this.actions.filter((a) => {
        if (a.id === 'proximity' && this.input.mode === 'A') {
          return false;
        }
        if (a.id === 'delete' && this.input.mode !== 'E') {
          return false;
        }
        return true;
      })
    }

    get selectedTags() {
      return this.getSelectedTags.map((t) => {
        const associatedTag = this.getTags.find((tag) => t.tagId === tag.studentTagId);
        t.color = associatedTag.color;
        t.tagText = associatedTag.tagText;
        return t;
      })
    }

    get filteredTags() {
      if (this.$currentUser.isAdmin) {
        return this.getTags.filter((t) => t.districtId || t.schoolId)
      } else {
        return this.getTags;
      }
    }

    get allTags() {
      return this.filteredTags.map(t => {
        t.icon = this.selectedTags.find((tag: any) => tag.tagId === t.studentTagId) ? 'fas fa-check-circle' : 'fas fa-circle';
        return t;
      });
    }

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    addRemoveTag(tag?: any) {
      const selectedTagsCopy = this.selectedTags;
      const tagInList = this.selectedTags.find((t: any) => t.tagId === tag.studentTagId);
      if (tagInList) {
        if (!tagInList.editable) {
          return;
        }
        const index = selectedTagsCopy.indexOf(tagInList);
        selectedTagsCopy.splice(index, 1);
      } else {
        selectedTagsCopy.push({ editable: true, tagId: tag.studentTagId });
      }
      this.$store.commit('students/setSelectedTags', selectedTagsCopy);
    }

    manageTags() {
      this.showMenu = false;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.tagsDialog = true;
        });
      })
    }

    doAction(action: any) {
      Promise.resolve().then(() => {
        if (action.id === 'delete') {
          return this.doDeleteEvent();
        } else if (action.id === 'proximity') {
          return this.giveProximityReport();
        }
      }).catch((error) => {
        if (!(error instanceof FormError)) {
          return Promise.reject(error);
        }
      }).finally(() => {
        this.showMenu = false;
      });
    }

    async giveProximityReport() {
      const that = this;
      that.showMenu = false;
      CommonUtils.showLoading();
      this.getStudentProximityReport({ studentId: this.input.student.studentId }).then(() => {
        CommonUtils.hideLoading();
      });
    }

    async doDeleteEvent() {
      const that = this;
      that.showMenu = false;
      return Promise.resolve().then(() => {
        return this.$refs.confirm.confirm({
          title: this.$t('studentLabel'),
          text: this.$t('confirmDeleteGeneral', { type: this.$t('studentLabel') }),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then(async result => {
          if (result === 1) {
            this.localPage === 'students' ? this.$store.commit('students/setListLoading', true) : CommonUtils.showLoading();
            return this.deleteStudent({ studentId: this.input.student.studentId }).then(() => {
              this.$eventBus.$emit('closeSubPage');
              this.$eventBus.$emit('refreshStudents');
              if (this.showSnackbarNotifications) {
                this.$snotify.success(this.$t('statusMsg43') as string);
              }
              this.$store.commit('students/setListLoading', false);
              CommonUtils.hideLoading();
              return Promise.resolve();
            });
          } else {
            return Promise.reject(new FormError());
          }
        })
      })
    }

    focusExtraOptions() {
      try {
        const index = this.$refs.listActions.$children.findIndex((listItem: any) => {
          return listItem.$el.classList.contains('v-list-item--highlighted');
        });
        if (index === 0) {
          (this.$refs as any).status0[0].$el.querySelector('.v-btn').focus();
        }
      } catch (e) {
        console.log(this.$t('focusFailed'));
      }
    }

    focusStatus(index: number) {
      try {
        if (index === -1) {
          this.focusActionButton();
        } else if (index >= this.allTags.length) {
          (this.$refs as any).manageStatusButton.$el.querySelector('.v-btn').focus();
        } else {
          (this.$refs as any)['status' + index][0].$el.querySelector('.v-btn').focus();
        }
      } catch (e) {
        console.log(this.$t('focusFailed'));
      }
    }

    focusActionButton() {
      try {
        (this.$refs as any).actionsButton.$el.querySelector('.v-btn').focus();
      } catch (e) {
        console.log(this.$t('focusFailed'));
      }
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }
}
