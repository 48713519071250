var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multi-list-picker", {
    attrs: {
      label: _vm.getLessonSectionLabel("myStandards"),
      items: _vm.localMyListItems,
      selected: _vm.selected,
      withRefresh: true,
      contentClass: "my-list-picker-content"
    },
    on: { refresh: _vm.refresh, apply: _vm.doApply },
    model: {
      value: _vm.localValue,
      callback: function($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }