








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ClassworkEditorHeader extends Vue {
  @Prop({ type: Boolean, required: true, default: true })
  isAssignment!: boolean;

  @Prop({ type: String, required: true, default: 'A' })
  action!: string;
}
