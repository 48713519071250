/* eslint-disable no-useless-constructor */
import { Resizable } from 'agnostic-draggable';

export default class BaseSubPageResizeable extends Resizable {
  minWidth = 0;
  maxWidth = 600;
  constructor(element, options = {}, listeners = {}) {
    super(element, options, listeners);
    this.minWidth = options.minWidth;
    this.maxWidth = options.maxWidth;
  }

  resetBoundaries(options) {
    this.minWidth = options.minWidth;
    this.maxWidth = options.maxWidth;
  }

  applyBoundaries(styleProps) {
    const boundaries = {
      minWidth: isFinite(this.minWidth) ? Math.max(this.minWidth, 0) : 0,
      maxWidth: isFinite(this.maxWidth) ? this.maxWidth : Infinity,
      minHeight: isFinite(this.options.minHeight) ? Math.max(this.options.minHeight, 0) : 0,
      maxHeight: isFinite(this.options.maxHeight) ? this.options.maxHeight : Infinity
    };
    const { size: originalSize, position: originalPosition } = this.originalAttrs;
    const isWest = /sw|nw|w/.test(this.currentDirection);
    const isNorth = /nw|ne|n/.test(this.currentDirection);

    if (isFinite(styleProps.width) && boundaries.minWidth > styleProps.width) {
      styleProps.width = boundaries.minWidth;
      if (isWest) {
        styleProps.left = originalPosition.left + originalSize.width - boundaries.minWidth;
      }
    }
    if (isFinite(styleProps.width) && boundaries.maxWidth < styleProps.width) {
      styleProps.width = boundaries.maxWidth;
      if (isWest) {
        styleProps.left = originalPosition.left + originalSize.width - boundaries.maxWidth;
      }
    }
    if (isFinite(styleProps.height) && boundaries.minHeight > styleProps.height) {
      styleProps.height = boundaries.minHeight;
      if (isNorth) {
        styleProps.top = originalPosition.top + originalSize.height - boundaries.minHeight;
      }
    }
    if (isFinite(styleProps.height) && boundaries.maxHeight < styleProps.height) {
      styleProps.height = boundaries.maxHeight;
      if (isNorth) {
        styleProps.top = originalPosition.top + originalSize.height - boundaries.maxHeight;
      }
    }

    return styleProps;
  }
}
