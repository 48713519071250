import CommonUtils from '@/utils/common-utils';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Fuse from 'fuse.js';
import StudentUtils from '@/utils/student-utils';
import StickersServices from '@/services/stickers-services';

const settings = namespace('settings');
const classes = namespace('classes');
const students = namespace('students');
const notestodo = namespace('notestodo');
@Component
export default class RemindersMixin extends Vue {
  remindersStartDate: any = moment().format('MM/DD/YYYY');
  remindersEndDate: any = moment().add(6, 'd').format('MM/DD/YYYY');
  initialized = false;
  remindersSearchText = '';
  localAllTodosData:any[] = [];
  localRefreshKey = CommonUtils.generateUUID();

  localTodo:any = {
    startDate: '',
    dueDate: '',
    toDoText: '',
    priority: 0,
    done: 0,
    toDoId: null,
    repeats: 'daily',
    currentDate: '',
    updateCurrentTodo: true,
    customTodo: [],
    customTodoId: undefined,
    customToDoText: undefined,
    customPriority: undefined,
    customDone: undefined
  };

  @notestodo.State('remindersList')
  myallData!: any;

  @classes.Getter('getClassName')
  getClassName!: (param?: string | number) => string;

  @students.Getter('getNotesStudentsItems')
  studentItems!: Array<any>;

  @students.Action
  loadNotesStudents!: (params?: any) => Promise<any>;

  @settings.Getter('getOtherSettings')
  otherSettings!: any;

  @settings.Getter('getEventStyling')
  eventStyling!: any;

  @settings.Getter('getNotesTodosStyling')
  notesTodosStyling!: any;

  @settings.Getter('getClassworkStyling')
  classworkStyling!: any;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  get toDoAllData() {
    return this.localAllTodosData;
  }

  set toDoAllData(val: any[]) {
    this.localAllTodosData = val;
  }

  get eventsReminderFilter(): boolean {
    return this.$store.state.reminders.eventsReminderFilter;
  }

  set eventsReminderFilter(val: boolean) {
    this.$store.commit('reminders/setEventsReminderFilter', val);
  }

  get todosReminderFilter(): boolean {
    return this.$store.state.reminders.todosReminderFilter;
  }

  set todosReminderFilter(val: boolean) {
    this.$store.commit('reminders/setTodosReminderFilter', val);
  }

  get notesReminderFilter(): boolean {
    return this.$store.state.reminders.notesReminderFilter;
  }

  set notesReminderFilter(val: boolean) {
    this.$store.commit('reminders/setNotesReminderFilter', val);
  }

  get assignmentsReminderFilter(): boolean {
    return this.$store.state.reminders.assignmentsReminderFilter;
  }

  set assignmentsReminderFilter(val: boolean) {
    this.$store.commit('reminders/setAssignmentsReminderFilter', val);
  }

  get assessmentsReminderFilter(): boolean {
    return this.$store.state.reminders.assessmentsReminderFilter;
  }

  set assessmentsReminderFilter(val: boolean) {
    this.$store.commit('reminders/setAssessmentsReminderFilter', val);
  }

  get studentsBirthdayReminderFilter(): boolean {
    return this.$store.state.reminders.studentsBirthdayReminderFilter;
  }

  set studentsBirthdayReminderFilter(val: boolean) {
    this.$store.commit('reminders/setStudentsBirthdayReminderFilter', val);
  }

  getCurrentDateToBirthdayTimeDifference(date:any) {
    const a = moment().set('hour', 0).set('minute', 0).set('second', 0);
    const b = moment(date, 'MM/DD/YYYY');
    b.year(moment().year());
    return Math.round(b.diff(a, 'days', true));
  }

  stickerSize(stickerSize:string) {
    return stickerSize || this.getValue('stickerSize', this.otherSettings.stickerSize || '40')
  }

  stickerBackgroundStyle(stickerBackgroundStyle:any): string {
    return stickerBackgroundStyle || this.getValue('stickerBackgroundStyle', this.otherSettings.stickerBackgroundStyle);
  }

  stickerBackgroundColor(stickerBackgroundColor:any): string {
    return stickerBackgroundColor || this.getValue('stickerBackgroundColor', this.otherSettings.stickerBackgroundColor);
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get datas() {
    return this.myallData || [];
  }

  get notesClassId() {
    return this.$store.state.prints.notesClassId;
  }

  set notesClassId(val) {
    this.$store.commit('prints/setNotesClassId', val);
  }

  get notesStudentId() {
    return this.$store.state.prints.notesStudentId;
  }

  set notesStudentId(val) {
    this.$store.commit('prints/setNotesStudentId', val);
  }

  get fuseResultForTodos() {
    const options: any = {
      keys: ['toDoText', 'customToDoText']
    };
    if (CommonUtils.hasText(this.remindersSearchText)) {
      return (new Fuse(this.toDoAllData, options).search(this.remindersSearchText)).map(e => { return e.item });
    } else {
      return this.toDoAllData;
    }
  }

  get fuseResultForNotes() {
    const options: any = {
      keys: ['noteText']
    };
    if (CommonUtils.hasText(this.remindersSearchText)) {
      return (new Fuse(this.datas.NOTES, options).search(this.remindersSearchText)).map(e => { return e.item });
    } else {
      return this.datas.NOTES;
    }
  }

  get fuseResultForEvents() {
    const options: any = {
      keys: ['eventTitle']
    };
    if (CommonUtils.hasText(this.remindersSearchText)) {
      return (new Fuse(this.datas.EVENTS.events, options).search(this.remindersSearchText)).map(e => { return e.item });
    } else {
      return this.datas.EVENTS.events;
    }
  }

  get fuseResultForAssignments() {
    const options: any = {
      keys: ['assignmentTitle']
    };
    if (CommonUtils.hasText(this.remindersSearchText)) {
      return (new Fuse(this.datas.ASSIGNMENTS.assignments, options).search(this.remindersSearchText)).map(e => { return e.item });
    } else {
      return this.datas.ASSIGNMENTS.assignments;
    }
  }

  get fuseResultForAssessments() {
    const options: any = {
      keys: ['assessmentTitle']
    };
    if (CommonUtils.hasText(this.remindersSearchText)) {
      return (new Fuse(this.datas.ASSESSMENTS.assessments, options).search(this.remindersSearchText)).map(e => { return e.item });
    } else {
      return this.datas.ASSESSMENTS.assessments;
    }
  }

  get fuseResultForStudents() {
    const options: any = {
      keys: ['firstName', 'lastName', 'middleName', 'emailAddress']
    };
    if (CommonUtils.hasText(this.remindersSearchText)) {
      return (new Fuse(this.datas.STUDENTS.students, options).search(this.remindersSearchText)).map(e => { return e.item });
    } else {
      return this.datas.STUDENTS.students;
    }
  }

  get reminders():Array<any> {
    const data = this.datas;
    if (CommonUtils.isEmpty(data)) {
      return [];
    }
    const todosReminderFilter = this.todosReminderFilter;
    const notesReminderFilter = this.notesReminderFilter;
    const eventsReminderFilter = this.eventsReminderFilter;
    const assignmentsReminderFilter = this.assignmentsReminderFilter;
    const assessmentsReminderFilter = this.assessmentsReminderFilter;
    const studentsBirthdayReminderFilter = this.studentsBirthdayReminderFilter;
    let items: Array<any> = [];
    const todos = this.fuseResultForTodos;
    const notes = this.fuseResultForNotes;
    const events = this.fuseResultForEvents;
    const assessments = this.fuseResultForAssessments;
    const assignments = this.fuseResultForAssignments;
    const students = this.fuseResultForStudents;
    items = items.concat(todos.map((todo: any) => {
      if (CommonUtils.hasText(todo.currentDate)) {
        const customTodoData = todo.customTodo.find((ct:any) => ct.currentDate === todo.currentDate);
        if (customTodoData) {
          todo.customTodoId = customTodoData.customTodoId;
          todo.customToDoText = customTodoData.toDoText;
          todo.customPriority = customTodoData.priority;
          todo.customDone = customTodoData.done;
        }
      }
      if (CommonUtils.hasText(todo.currentDate)) {
        todo.dateToSort = moment(todo.currentDate, 'MM/DD/YYYY')
      } else {
        todo.dateToSort = moment(todo.dueDate, 'MM/DD/YYYY')
      }
      return todo;
    }).filter((todo: any) => {
      if (!todosReminderFilter) {
        return false;
      }

      if (todo.priority === 3 || todo.customPriority === 3) {
        // Show any high priority not repeating undone todos
        if ((todo.customTodoId === undefined && !todo.done) && todo.repeats === undefined) {
          return true;
        }
        // Show any high priority repeating todos current week, undone todos
        if ((CommonUtils.hasText(todo.currentDate) && !todo.customDone) && todo.repeats !== undefined
        ) {
          return this.isDateWithinCurrentWeek(todo.currentDate);
        }
      }

      if (CommonUtils.hasText(todo.startDate) && CommonUtils.hasText(todo.dueDate)) {
        // If DAILY To Do, then it should ONLY show on the current DAY
        if (todo.repeats !== undefined && todo.repeats === 'daily') {
          return this.isDateToday(todo.currentDate) && !todo.customDone;
        }

        // Show ALL current week undone todos (Repeating Weekly)
        if (CommonUtils.hasText(todo.currentDate)) {
          return this.isDateWithinCurrentWeek(todo.currentDate) && !todo.customDone;
        } else {
          // Show ANY Current Date + 6 Undone todos (Not Repeating) (With Start Date)
          return this.isDateWithinCurrentDatePlus6(todo.dueDate) && !todo.done;
        }
      } else if (CommonUtils.hasNoText(todo.startDate) && CommonUtils.hasNoText(todo.dueDate)) {
        // Show ANY undated todos
        return !todo.done;
      } else {
        // Show ANY Current Date + 6 Undone todos (Not Repeating) (Without Start Date or Due Date)
        return this.isDateWithinCurrentDatePlus6(todo.dueDate || todo.startDate) && !todo.done;
      }
    })
    );
    // NOTES
    items = items.concat(notes.map((note: any) => {
      note.JSON = {
        studentId: note.JSON.studentId,
        noteText: note.JSON.noteText,
        noteTitle: note.JSON.noteTitle,
        teacherId: note.JSON.teacherId,
        noteDate: note.JSON.noteDate,
        lastUpdate: note.JSON.lastUpdate,
        noteId: note.JSON.noteId,
        subjectId: note.JSON.subjectId,
        yearId: note.JSON.yearId
      }
      note.dateToSort = note.JSON.noteDate;
      return note;
    })
      .filter(() => {
        if (!notesReminderFilter) {
          return false;
        }
        return true;
      })
    );
    // EVENTS
    items = items.concat(events.map((event: any) => {
      event.eventStartDate = event.eventDate;
      event.eventEndDate = event.endDate;
      event.eventCurrentDate = event.currentDate;
      event.eventStickerId = event.stickerId;
      event.dateToSort = event.eventCurrentDate;
      return event;
    })
      .filter((event:any) => {
        if (!eventsReminderFilter) {
          return false;
        }
        if (!this.isDateWithinCurrentDatePlus6(event.currentDate)) {
          return false;
        }
        return true;
      })
    );
    // ASSIGNMENTS
    items = items.concat(assignments
      .map((assignment:any) => {
        assignment.dateToSort = assignment.assignmentEnd;
        return assignment;
      })
      .filter((assignment:any) => {
        if (!assignmentsReminderFilter) {
          return false;
        }
        if (!this.isDateWithinCurrentDatePlus6(assignment.assignmentEnd)) {
          return false;
        }
        return true;
      })
    );
    // ASSESSMENTS
    items = items.concat(assessments
      .map((assessment:any) => {
        assessment.dateToSort = assessment.assessmentEnd;
        return assessment;
      })
      .filter((assessment:any) => {
        if (!assessmentsReminderFilter) {
          return false;
        }
        if (!this.isDateWithinCurrentDatePlus6(assessment.assessmentEnd)) {
          return false;
        }
        return true;
      })
    );
    // STUDENTS
    items = items.concat(students
      .map((student:any) => {
        const dateArray = student.birthDate.split('/');
        student.dateToSort = dateArray[0] + '/' + dateArray[1] + '/' + moment().year();
        return student;
      })
      .filter(() => {
        if (!studentsBirthdayReminderFilter) {
          return false;
        }
        return true;
      })
    );
    return items.sort(function (a: any, b: any) {
      const ma = moment(a.dateToSort, 'MM/DD/YYYY');
      const mb = moment(b.dateToSort, 'MM/DD/YYYY');
      return ma.diff(mb, 'days');
    });
  }

  getInitials(student: any) {
    return StudentUtils.getStudentInitials(student);
  }

  isTrueForTodo(item:any):boolean {
    if (item.customTodoId === undefined) {
      return item.done;
    } else {
      return item.customDone;
    }
  }

  getValue(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return this.updatedSettings[key];
    }
    return defaultValue;
  }

  stickerURL(stickerId:number):string {
    if (stickerId > 0) {
      return 'https://cdn.planbook.com/images/planbook-stickers/' + StickersServices.getStickerFileName(stickerId) + '.png';
    } else {
      return '';
    }
  }

  isDateAllowedByFilter(date:any) {
    return moment(date, 'MM/DD/YYYY').isSameOrBefore(moment(this.remindersEndDate, 'MM/DD/YYYY')) && moment(date, 'MM/DD/YYYY').isSameOrAfter(moment(this.remindersStartDate, 'MM/DD/YYYY'))
  }

  isDateWithinCurrentWeek(date:any) {
    return moment(date, 'MM/DD/YYYY').isSameOrBefore(moment().endOf('week')) && moment(date, 'MM/DD/YYYY').isSameOrAfter(moment().startOf('week'))
  }

  isDateWithinCurrentDatePlus6(date:any) {
    const futureDate = moment().add(6, 'd').startOf('day');
    return moment(date, 'MM/DD/YYYY').isSameOrBefore(futureDate) && moment(date, 'MM/DD/YYYY').isSameOrAfter(moment().startOf('day'))
  }

  isDateToday(date:string) {
    return date === moment().format('MM/DD/YYYY');
  }

  getHasNoText(val:any) {
    return CommonUtils.hasNoText(val);
  }

  getHasText(val:any) {
    return CommonUtils.hasText(val);
  }

  hasContent(val: string) {
    return CommonUtils.isHTMLNotEmpty(val);
  }

  getCardBackgroundColor(noteId?:any, toDoId?:any, eventId?:any) {
    if (noteId) {
      return '#FFFFFF';
    } else if (toDoId) {
      return '#FFFFFF';
    } else if (eventId) {
      return '#FFFFFF';
    }
  }

  getClassNameById(classId: any) {
    return this.getClassName(classId);
  }

  getStudentLabel(student: any) {
    return StudentUtils.getStudentLabel(student);
  }

  getStudentsItemNameByID(studentItemId: any) {
    let studentItemName = this.loopStudentItemName(studentItemId);
    const studentItemNameSplit = studentItemName.split(', ');
    studentItemName = this.getStudentLabel({ lastName: studentItemNameSplit[0], firstName: studentItemNameSplit[1] });
    return studentItemName;
  }

  loopStudentItemName(studentItemId: any) {
    let returnedVal = '';
    this.studentItems.forEach((element) => {
      if (element.value === studentItemId.toString()) {
        returnedVal = element.text.toString();
      }
    });
    return returnedVal;
  }

  getMonth(date: Date) {
    if (CommonUtils.hasText(date)) {
      return moment(date, 'MM/DD/YYYY').format('MMM');
    } else {
      return '';
    }
  }

  getDisplayDate(date: Date) {
    if (CommonUtils.hasText(date)) {
      return moment(date, 'MM/DD/YYYY').format('DD');
    } else {
      return '';
    }
  }

  showDate(date:Date):boolean {
    if (moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY') === '12/31/9999') {
      return false;
    } else {
      return true;
    }
  }

  getPriorityColor(item: any) {
    if (item.customTodoId === undefined) {
      if (item.priority === 3) {
        return this.notesTodosStyling.highPriorityColor;
      } else if (item.priority === 2) {
        return this.notesTodosStyling.mediumPriorityColor;
      } else {
        return this.notesTodosStyling.lowPriorityColor;
      }
    } else {
      if (item.customPriority === 3) {
        return this.notesTodosStyling.highPriorityColor;
      } else if (item.customPriority === 2) {
        return this.notesTodosStyling.mediumPriorityColor;
      } else {
        return this.notesTodosStyling.lowPriorityColor;
      }
    }
  }

  getPriorityText(level: number) {
    if (level === 3) {
      return 'High';
    } else if (level === 2) {
      return 'Med';
    } else {
      return 'Low';
    }
  }
}
