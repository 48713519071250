
import { Vue, Component, Prop } from 'vue-property-decorator';
import Reminders from '@/views/pages/Reminders.vue';
import RemindersMixin from '@/mixins/reminders-mixin';
import CommonUtils from '@/utils/common-utils';
import EventStickerImage from '@/components/stickers/EventStickerImage.vue';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
@Component({
  mixins: [RemindersMixin],
  components: {
    Reminders,
    EventStickerImage
  }
})
export default class RemindersCard extends Vue {
  @settings.Getter
  highContrastMode!: boolean;

  getHasNoText!: (params?: any) => boolean;
  getHasText!: (params?: any) => boolean;
  showDate!: (params?: any) => boolean;
  isTrueForTodo!: (params?: any) => boolean;
  getMonth!: (params?: any) => string;
  getDisplayDate!: (params?: any) => string;
  getPriorityText!: (params?: any) => string;
  stickerURL!: (params?: any) => string;
  stickerBackgroundStyle!: (params?: any) => string;
  getClassNameById!: (params?: any) => any;
  getStudentsItemNameByID!: (params?: any) => any;
  getStudentLabel!: (params?: any) => any;
  loadNotesStudents!: (params?: any) => any;
  getCurrentDateToBirthdayTimeDifference!: (params?: any) => any;
  getPriorityColor!: (params?: any) => any;
  stickerSize!: (params?: any) => any;
  stickerBackgroundColor!: (params?: any) => any;
  hasContent!: (params?: any) => any;
  getInitials!: (params?: any) => any;
  notesTodosStyling!: any;
  eventStyling!: any;
  classworkStyling!:any;

  get hasText() {
    return CommonUtils.hasText;
  }

  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  item!: any;

  updateDoneTodo(item:any) {
    this.$emit('updateDoneTodo', item);
  }

  async initialize(initialLoad:boolean) {
    if (initialLoad) CommonUtils.showLoading();
    this.loadNotesStudents({}).then(() => {
      CommonUtils.hideLoading();
    });
  }

  created() {
    this.initialize(true);
  }
}
