























import { ActionsModal } from '@/types/global.types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Confirm from '../core/Confirm.vue';
import CommonUtils from '@/utils/common-utils';
import { FormError } from '@/errors';
import { namespace } from 'vuex-class';

const template = namespace('templates');
const settings = namespace('settings');

@Component
export default class TemplateActions extends Vue implements ActionsModal {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: {}, mode: 'P' };
    }
  })
  input!: any;

  @Prop({ required: false, type: String, default: 'absolute' })
  type!: string;

    actions = [
      {
        id: 'delete',
        icon: 'fal fa-trash-alt',
        label: 'deleteLabel'
      }
    ];

    showMenu = false;
    x = 0;
    y = 0;

    $refs!: {
        confirm: Confirm
    }

    @template.Action
    deleteTemplate!: (param: any) => Promise<any>;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    get filteredActions() {
      return this.actions;
    }

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    doAction(action: any) {
      Promise.resolve().then(() => {
        if (action.id === 'delete') {
          return this.doDeleteEvent();
        }
      }).catch((error) => {
        if (!(error instanceof FormError)) {
          return Promise.reject(error);
        }
      }).finally(() => {
        this.showMenu = false;
      });
    }

    async doDeleteEvent() {
      const that = this;
      that.showMenu = false;
      return Promise.resolve().then(() => {
        return this.$refs.confirm.confirm({
          title: this.$t('templateLabel'),
          text: this.$t('confirmDeleteGeneral', { type: this.$t('templateLabel') }),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result) => {
          if (result === 1) {
            this.localPage === 'templates' ? this.$store.commit('templates/setListLoading', true) : CommonUtils.showLoading();
            return this.deleteTemplate(this.input.template).then(() => {
              this.$eventBus.$emit('closeSubPage');
              this.$eventBus.$emit('refreshTemplates');
              if (this.showSnackbarNotifications) {
                this.$snotify.success(this.$t('statusMsg22') as string);
              }
              CommonUtils.hideLoading();
              this.$store.commit('templates/setListLoading', false)
              return Promise.resolve();
            });
          } else {
            return Promise.reject(new FormError());
          }
        })
      })
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }
}
