var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _vm.loading || _vm.schoolYearListLoading
        ? _c(
            "div",
            {
              staticClass:
                "fill-height d-flex flex-column justify-center align-center",
              style: { "min-height": _vm.minContainerHeight },
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  [
                    _c("v-icon", { attrs: { size: "200", color: "#b5bac1" } }, [
                      _vm._v("fas fa-school"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "mt-5",
                    staticStyle: { "font-size": "20px", color: "#b5bac1" },
                  },
                  [_vm._v(" Loading... ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && !_vm.schoolYearListLoading,
              expression: "!loading && !schoolYearListLoading",
            },
          ],
          staticClass: "school-year-editor editor pb-2",
          class: { "modal-editor": _vm.isModal },
          style: { "min-height": _vm.minContainerHeight },
        },
        [
          _c(
            "v-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            [
              _vm.yearId == 0 && _vm.userMode == "T"
                ? _c(
                    "school-year-editor-container",
                    {
                      staticStyle: { "font-size": "16px" },
                      attrs: { errorMsg: _vm.error },
                    },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            name: _vm.slideLeft
                              ? "slide-fade"
                              : "slide-fade-reverse",
                          },
                        },
                        [
                          !_vm.sliding && _vm.step == 1
                            ? _c(
                                "v-row",
                                { key: "1" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-2",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("schoolYearSubHeader")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-3",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass: "ma-0 pa-0",
                                                  attrs: { "hide-details": "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-table mb-2 font-weight-bold",
                                                                staticStyle: {
                                                                  width: "100%",
                                                                  "padding-left":
                                                                    "34px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "50%",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "yearNameLabel"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "25%",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "firstDayLabel"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-cell",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "25%",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "lastDayLabel"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    438254372
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.localParentYearId,
                                                    callback: function ($$v) {
                                                      _vm.localParentYearId =
                                                        $$v
                                                    },
                                                    expression:
                                                      "localParentYearId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.selectAdminYears,
                                                  function (item, i) {
                                                    return _c("v-radio", {
                                                      key: i,
                                                      attrs: {
                                                        value:
                                                          item.parentYearId,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-table-row",
                                                                      },
                                                                      [
                                                                        item.parentYearId ==
                                                                        0
                                                                          ? [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell font-italic",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "50%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "doNotUseSchoolYearLabel"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell font-italic",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "25%",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell font-italic",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "25%",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          : [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "50%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.yearName
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "25%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.firstDay
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-table-cell",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "25%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.lastDay
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : !_vm.sliding && _vm.step === 2
                            ? _c(
                                "v-row",
                                { key: "2" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm.parentYearId > 0
                                                ? _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pb-5",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold py-2",
                                                                  attrs: {
                                                                    cols: "4",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "connectedToYearLabel"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.parentYearName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold py-2",
                                                                  attrs: {
                                                                    cols: "4",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "firstAndLastDayLabel"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.firstAndLastDay
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          outlined: "",
                                                          "hide-details":
                                                            "auto",
                                                          label:
                                                            _vm.yearNameHint,
                                                          rules:
                                                            _vm.localRules
                                                              .required,
                                                          "aria-required":
                                                            "true",
                                                        },
                                                        model: {
                                                          value: _vm.yearName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.yearName = $$v
                                                          },
                                                          expression:
                                                            "yearName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "pb-date-time-range-field",
                                                        {
                                                          ref: "schoolYearDate",
                                                          attrs: {
                                                            hideDetails: "auto",
                                                            label:
                                                              _vm.$t(
                                                                "schoolYearDateLabel"
                                                              ) + "*",
                                                            required: "true",
                                                            start: _vm.firstDay,
                                                            end: _vm.lastDay,
                                                            rules:
                                                              _vm.dateRangeRule,
                                                            autoPopulateRange: false,
                                                          },
                                                          on: {
                                                            "update:start":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.firstDay =
                                                                  $event
                                                              },
                                                            "update:end":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.lastDay =
                                                                  $event
                                                              },
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.localLessonLayouts,
                                                          "prepend-inner-icon":
                                                            "fal fa-window-maximize",
                                                          label:
                                                            _vm.$t(
                                                              "lessonLayoutLabel"
                                                            ),
                                                          "hide-details":
                                                            "auto",
                                                          outlined: "",
                                                          disabled:
                                                            !_vm.isEditable,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedLessonLayoutId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedLessonLayoutId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedLessonLayoutId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c("span", {
                                              staticStyle: {
                                                "white-space": "nowrap",
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "selectClassesToCopyFromLabel",
                                                    {
                                                      text: _vm.htmlEscape(
                                                        _vm.currentYearName
                                                      ),
                                                    }
                                                  )
                                                ),
                                              },
                                            }),
                                          ]),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-nowrap no-gutters pt-2",
                                            },
                                            [
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    disabled:
                                                      _vm.localClassesToCopy
                                                        .length ===
                                                      _vm.classIds.length,
                                                    "input-value":
                                                      _vm.localClassesToCopy
                                                        .length ===
                                                      _vm.classIds.length,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.copyAllLocalClasses,
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.copyAllLocalClasses.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("allLabel"))
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    disabled:
                                                      _vm.classIds.length === 0,
                                                    "input-value":
                                                      _vm.classIds.length === 0,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.copyNoLocalClasses,
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.copyNoLocalClasses.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("noneLabel"))
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "my-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-chip-group",
                                                {
                                                  attrs: {
                                                    multiple: "",
                                                    column: "",
                                                  },
                                                  model: {
                                                    value: _vm.classIds,
                                                    callback: function ($$v) {
                                                      _vm.classIds = $$v
                                                    },
                                                    expression: "classIds",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.localClassesToCopy,
                                                  function (item, i) {
                                                    return _c(
                                                      "pb-chip",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "mr-2 mb-1",
                                                        staticStyle: {
                                                          opacity: "1",
                                                        },
                                                        attrs: {
                                                          "active-class":
                                                            "accent3--text font-weight-bold",
                                                          filter: "",
                                                          outlined: "",
                                                          value: item.value,
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.arrayAddOrRemove(
                                                              _vm.classIds,
                                                              item.value
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : !_vm.sliding && _vm.step === 3
                            ? _c(
                                "v-row",
                                { key: "3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-2",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "white-space": "nowrap",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "selectInformationToCopyLabel"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-nowrap no-gutters pt-2",
                                            },
                                            [
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    disabled:
                                                      _vm.isImportAllItems,
                                                    "input-value":
                                                      _vm.isImportAllItems,
                                                  },
                                                  on: {
                                                    click: _vm.importAllItems,
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.importAllItems.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("allLabel"))
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    disabled:
                                                      _vm.isImportNoItems,
                                                    "input-value":
                                                      _vm.isImportNoItems,
                                                  },
                                                  on: {
                                                    click: _vm.importNoItems,
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.importNoItems.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("noneLabel"))
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importLessons,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importLessons =
                                                        !_vm.importLessons
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importLessons =
                                                        !_vm.importLessons
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "classLessonsLabel"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importDays,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importDays =
                                                        !_vm.importDays
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importDays =
                                                        !_vm.importDays
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("classDaysLabel")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importTimes,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importTimes =
                                                        !_vm.importTimes
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importTimes =
                                                        !_vm.importTimes
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("classTimesLabel")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importTemplates,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importTemplates =
                                                        !_vm.importTemplates
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importTemplates =
                                                        !_vm.importTemplates
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "classTemplatesLabel"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importUnits,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importUnits =
                                                        !_vm.importUnits
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importUnits =
                                                        !_vm.importUnits
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("classUnitsLabel")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importAssessments,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importAssessments =
                                                        !_vm.importAssessments
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importAssessments =
                                                        !_vm.importAssessments
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "classAssessmentsLabel"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importAssignments,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importAssignments =
                                                        !_vm.importAssignments
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importAssignments =
                                                        !_vm.importAssignments
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "classAssignmentsLabel"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.parentYearId == 0
                                                ? _c(
                                                    "pb-chip",
                                                    {
                                                      staticClass: "mr-2 mb-1",
                                                      staticStyle: {
                                                        opacity: "1",
                                                      },
                                                      attrs: {
                                                        "active-class":
                                                          "accent3--text font-weight-bold",
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.importDisplayDays,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.importDisplayDays =
                                                            !_vm.importDisplayDays
                                                        },
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.importDisplayDays =
                                                            !_vm.importDisplayDays
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "displayDaysLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    filter: "",
                                                    outlined: "",
                                                    "input-value":
                                                      _vm.importStudents,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.importStudents =
                                                        !_vm.importStudents
                                                    },
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      _vm.importStudents =
                                                        !_vm.importStudents
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("studentsLabel")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isCurrentYearDateOnOrWithinSourceYearDate
                                                ? _c(
                                                    "pb-chip",
                                                    {
                                                      staticClass: "mr-2 mb-1",
                                                      staticStyle: {
                                                        opacity: "1",
                                                      },
                                                      attrs: {
                                                        "active-class":
                                                          "accent3--text font-weight-bold",
                                                        filter: "",
                                                        outlined: "",
                                                        "input-value":
                                                          _vm.importEvents,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.importEvents =
                                                            !_vm.importEvents
                                                        },
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          _vm.importEvents =
                                                            !_vm.importEvents
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("eventsLabel")
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "school-year-editor-container",
                    { staticStyle: { "font-size": "16px" } },
                    [
                      _c(
                        "v-expand-transition",
                        [
                          _vm.hasErrorMsg
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            dense: "",
                                            border: "left",
                                            type: "error",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(_vm.error) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: _vm.isEditSchoolYearWithParent ? "12" : "6",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          "hide-details": "auto",
                                          label: _vm.yearNameHint,
                                          "aria-required": "true",
                                          rules: _vm.localRules.yearName,
                                          disabled: !_vm.isEditable,
                                          autofocus: "",
                                        },
                                        model: {
                                          value: _vm.yearName,
                                          callback: function ($$v) {
                                            _vm.yearName = $$v
                                          },
                                          expression: "yearName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.isEditSchoolYearWithParent
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _vm.userMode == "A" ||
                                          _vm.parentYearId == 0
                                            ? _c("pb-date-time-range-field", {
                                                ref: "schoolYearDate",
                                                attrs: {
                                                  hideDetails: "auto",
                                                  label:
                                                    _vm.$t(
                                                      "schoolYearDateLabel"
                                                    ) + "*",
                                                  required: "true",
                                                  start: _vm.firstDay,
                                                  end: _vm.lastDay,
                                                  disabled: !_vm.isEditable,
                                                  autoPopulateRange: false,
                                                },
                                                on: {
                                                  "update:start": function (
                                                    $event
                                                  ) {
                                                    _vm.firstDay = $event
                                                  },
                                                  "update:end": function (
                                                    $event
                                                  ) {
                                                    _vm.lastDay = $event
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.localLessonLayouts,
                                          "prepend-inner-icon":
                                            "fal fa-window-maximize",
                                          label: _vm.$t("lessonLayoutLabel"),
                                          "hide-details": "auto",
                                          outlined: "",
                                          disabled: !_vm.isEditable,
                                        },
                                        model: {
                                          value: _vm.selectedLessonLayoutId,
                                          callback: function ($$v) {
                                            _vm.selectedLessonLayoutId = $$v
                                          },
                                          expression: "selectedLessonLayoutId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.userMode == "A" ||
                          (_vm.yearId > 0 && _vm.parentYearId == 0)
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { disabled: !_vm.isEditable },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "classesAreScheduledLabel"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1981761490
                                      ),
                                      model: {
                                        value: _vm.cycle,
                                        callback: function ($$v) {
                                          _vm.cycle = $$v
                                        },
                                        expression: "cycle",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: _vm.$t("onWeeklyBasisLabel"),
                                          value: "one",
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: _vm.$t("on2WeekBasisLabel"),
                                          value: "two",
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: { value: "cycle" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "onCycleOfBasisLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block px-2",
                                                        staticStyle: {
                                                          width: "50px",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          key: _vm.localRefreshKey,
                                                          staticClass:
                                                            "mt-0 pt-0 cycle-days",
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            type: "number",
                                                            disabled:
                                                              !_vm.isEditable,
                                                            min: "2",
                                                            max: "20",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.refreshKey.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.cycleDaysNum,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.cycleDaysNum =
                                                                $$v
                                                            },
                                                            expression:
                                                              "cycleDaysNum",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "daysLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1271528267
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.isEditSchoolYearWithParent
                        ? _c(
                            "v-container",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-right font-weight-bold",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("connectedToYearLabel")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-left",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.parentYearName) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-right font-weight-bold",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("scheduleTypeLabel")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-left",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.scheduleType) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-right font-weight-bold",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("firstAndLastDayLabel")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-left",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.firstAndLastDay) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-right font-weight-bold",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("schoolDaysLabel")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-left",
                                      attrs: { cols: "8" },
                                    },
                                    _vm._l(_vm.cycleDays, function (item, i) {
                                      return _c(
                                        "v-avatar",
                                        {
                                          key: i,
                                          staticClass: "mx-1",
                                          attrs: {
                                            color: _vm.getSchoolDayColor(
                                              item.value
                                            ),
                                            size: "24",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "white--text" },
                                            [_vm._v(_vm._s(item.shortValue))]
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.hasStartAndEndTime
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-right font-weight-bold",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("startAndEndTimesLabel")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-left",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.startAndEndTime)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticStyle: { margin: "20px -20px" } },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              ref: "panels",
                              attrs: {
                                accordion: "",
                                multiple: "",
                                flat: "",
                                tile: "",
                                role: "tablist",
                              },
                              model: {
                                value: _vm.panels,
                                callback: function ($$v) {
                                  _vm.panels = $$v
                                },
                                expression: "panels",
                              },
                            },
                            [
                              ((_vm.userMode == "T" && _vm.yearId > 0) ||
                                _vm.userMode == "A") &&
                              _vm.cycle == "cycle"
                                ? _c(
                                    "v-expansion-panel",
                                    {
                                      key: "cycleNames",
                                      attrs: { role: "tab" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        { attrs: { "hide-actions": "" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("dayNamesLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        { staticClass: "pt-4 pb-2" },
                                        [
                                          _vm.cycle == "cycle" &&
                                          ((_vm.parentYearId == 0 &&
                                            _vm.yearId > 0) ||
                                            _vm.userMode === "A") &&
                                          _vm.isNotEmpty(_vm.localCycleDayNames)
                                            ? _vm._l(
                                                _vm.localCycleDayNames,
                                                function (item, i) {
                                                  return _c(
                                                    "v-row",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            i <
                                                            +_vm.cycleDaysNum,
                                                          expression:
                                                            "i < +cycleDaysNum",
                                                        },
                                                      ],
                                                      key: i,
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              outlined: "",
                                                              "hide-details":
                                                                "",
                                                              dense: "",
                                                              label: _vm.$t(
                                                                "dayNumLabel",
                                                                { num: i + 1 }
                                                              ),
                                                              disabled:
                                                                !_vm.isEditable,
                                                            },
                                                            model: {
                                                              value:
                                                                item.dayName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "dayName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.dayName",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                          _vm.cycle == "cycle" &&
                                          _vm.parentYearId > 0 &&
                                          _vm.yearId > 0 &&
                                          _vm.isNotEmpty(_vm.localCycleDayNames)
                                            ? _vm._l(
                                                _vm.localCycleDayNames,
                                                function (item, i) {
                                                  return _c(
                                                    "v-row",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            i <
                                                            +_vm.cycleDaysNum,
                                                          expression:
                                                            "i < +cycleDaysNum",
                                                        },
                                                      ],
                                                      key: i,
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: { cols: "2" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dayNumLabel",
                                                                { num: i + 1 }
                                                              ) + ":"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-left",
                                                          attrs: { cols: "10" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.dayName)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              ((_vm.userMode == "T" && _vm.yearId > 0) ||
                                _vm.userMode == "A") &&
                              (_vm.cycle == "cycle" || _vm.cycle == "two")
                                ? _c(
                                    "v-expansion-panel",
                                    {
                                      key: "cycleResets",
                                      attrs: { role: "tab" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: {
                                            "disable-icon-rotate": "",
                                            "hide-actions": !(
                                              ((_vm.parentYearId == 0 &&
                                                _vm.yearId > 0) ||
                                                _vm.userMode == "A") &&
                                              _vm.isEditable
                                            ),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    ((_vm.parentYearId == 0 &&
                                                      _vm.yearId > 0) ||
                                                      _vm.userMode == "A") &&
                                                    _vm.isEditable
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticStyle: {
                                                              top: "6px",
                                                              right: "16px",
                                                            },
                                                            attrs: {
                                                              absolute: "",
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "addItemLabel",
                                                                  {
                                                                    text: _vm.$t(
                                                                      "cycleResetsLabel"
                                                                    ),
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.addCycleReset.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-plus"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2758050746
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("cycleResetsLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      (_vm.cycle == "cycle" ||
                                        _vm.cycle == "two") &&
                                      _vm.isNotEmpty(_vm.localTerms)
                                        ? _c(
                                            "v-expansion-panel-content",
                                            { staticClass: "pt-4 pb-2" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "5" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "resetNameLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "resetDateLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "startsOnLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-col", {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "1" },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._l(
                                                _vm.localTerms,
                                                function (item, i) {
                                                  return _c(
                                                    "v-row",
                                                    { key: i },
                                                    [
                                                      ((_vm.parentYearId == 0 &&
                                                        _vm.yearId > 0) ||
                                                        _vm.userMode == "A") &&
                                                      _vm.isEditable
                                                        ? [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      "hide-details":
                                                                        "",
                                                                      dense: "",
                                                                      label:
                                                                        _vm.$t(
                                                                          "resetNameLabel"
                                                                        ),
                                                                      "aria-label":
                                                                        _vm.$t(
                                                                          "resetNameNumLabel",
                                                                          {
                                                                            num:
                                                                              i +
                                                                              1,
                                                                          }
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.termName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "termName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.termName",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  cols: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "pb-date-field",
                                                                  {
                                                                    attrs: {
                                                                      hideDetails:
                                                                        "auto",
                                                                      dense: "",
                                                                      prependInnerIcon: false,
                                                                      rules:
                                                                        _vm
                                                                          .localRules
                                                                          .dateWithinRange,
                                                                      label:
                                                                        _vm.$t(
                                                                          "resetDateLabel"
                                                                        ),
                                                                      ariaLabel:
                                                                        _vm.$t(
                                                                          "resetNameDateLabel",
                                                                          {
                                                                            name: item.termName,
                                                                          }
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.firstDay,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "firstDay",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.firstDay",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  cols: "3",
                                                                },
                                                              },
                                                              [
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    "hide-details":
                                                                      "auto",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    items:
                                                                      _vm.cycleDayItems,
                                                                    label:
                                                                      item.startsOn
                                                                        ? _vm.$t(
                                                                            "startsOnLabel"
                                                                          )
                                                                        : _vm.$t(
                                                                            "notResetLabel"
                                                                          ),
                                                                    "aria-label":
                                                                      item.startsOn
                                                                        ? _vm.$t(
                                                                            "resetStartsOnLabel",
                                                                            {
                                                                              name: item.termName,
                                                                            }
                                                                          )
                                                                        : _vm.$t(
                                                                            "ResetNotResetLabel",
                                                                            {
                                                                              name: item.termName,
                                                                            }
                                                                          ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.startsOn,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "startsOn",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.startsOn",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  cols: "1",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      color:
                                                                        "error",
                                                                      "aria-label":
                                                                        _vm.$t(
                                                                          "removeItemLabel",
                                                                          {
                                                                            text: _vm.$t(
                                                                              "cycleResetsLabel"
                                                                            ),
                                                                          }
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.removeCycleReset(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-trash-alt"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.parentYearId > 0 &&
                                                      _vm.yearId > 0
                                                        ? [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  cols: "5",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.termName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  cols: "3",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatToShow(
                                                                        item.firstDay
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  cols: "3",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.startsOn ==
                                                                        0
                                                                        ? "Not Reset"
                                                                        : `Day ${item.startsOn}`
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.userMode === "T"
                                ? _c(
                                    "v-expansion-panel",
                                    {
                                      key: "displayDays",
                                      attrs: { role: "tab" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        { attrs: { "hide-actions": "" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("displayDaysLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        { staticClass: "pt-4" },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "11" } },
                                                [
                                                  _c(
                                                    "v-chip-group",
                                                    {
                                                      attrs: {
                                                        multiple: "",
                                                        column: "",
                                                      },
                                                      model: {
                                                        value: _vm.showWeekDays,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.showWeekDays = $$v
                                                        },
                                                        expression:
                                                          "showWeekDays",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.weekDays,
                                                      function (day) {
                                                        return _c(
                                                          "pb-chip",
                                                          {
                                                            key: day.value,
                                                            staticClass:
                                                              "mr-2 mb-1",
                                                            staticStyle: {
                                                              opacity: "1",
                                                            },
                                                            attrs: {
                                                              "active-class":
                                                                "accent3--text font-weight-bold",
                                                              filter: "",
                                                              outlined: "",
                                                              value: day.value,
                                                            },
                                                            on: {
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                )
                                                                  return null
                                                                return _vm.arrayAddOrRemove(
                                                                  _vm.showWeekDays,
                                                                  day.value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(day.text)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "1" } },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "ml-n2 mr-2 mt-2",
                                                                        attrs: {
                                                                          elevation:
                                                                            "0",
                                                                          fab: "",
                                                                          "x-small":
                                                                            "",
                                                                          "aria-label":
                                                                            _vm.$t(
                                                                              "copyDisplayDaysLabel"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.confirmCopyDisplayDays.apply(
                                                                                null,
                                                                                arguments
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-copy"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1117815462
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "copyDisplayDaysLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.yearId == 0 ||
                              _vm.isNotEmpty(_vm.localGradePeriods) ||
                              _vm.isEditable
                                ? _c(
                                    "v-expansion-panel",
                                    {
                                      key: "gradingPeriods",
                                      attrs: { role: "tab" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: { "disable-icon-rotate": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.isEditable &&
                                                    !(
                                                      _vm.userMode == "T" &&
                                                      _vm.hasAdminGradePeriods
                                                    )
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticStyle: {
                                                              top: "6px",
                                                              right: "16px",
                                                            },
                                                            attrs: {
                                                              absolute: "",
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "addItemLabel",
                                                                  {
                                                                    text: _vm.$t(
                                                                      "gradingPeriodLabel"
                                                                    ),
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.addGradingPeriod.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-plus"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1722526946
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("gradingPeriodsLabel")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.isNotEmpty(_vm.localGradePeriods)
                                        ? _c(
                                            "v-expansion-panel-content",
                                            { staticClass: "pt-4 pb-2" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "5" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "periodNameLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "startDateLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("endDateLabel")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-col", {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "1" },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._l(
                                                _vm.localGradePeriods,
                                                function (item, i) {
                                                  return _c(
                                                    "v-row",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              outlined: "",
                                                              "hide-details":
                                                                "auto",
                                                              dense: "",
                                                              label:
                                                                _vm.$t(
                                                                  "periodNameLabel"
                                                                ),
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "periodNumNameLabel",
                                                                  { num: i + 1 }
                                                                ),
                                                              rules:
                                                                _vm.localRules
                                                                  .gradePeriodName,
                                                              disabled:
                                                                !_vm.isEditable ||
                                                                (_vm.userMode ==
                                                                  "T" &&
                                                                  _vm.hasAdminGradePeriods),
                                                            },
                                                            model: {
                                                              value: item.name,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.name",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("pb-date-field", {
                                                            attrs: {
                                                              hideDetails:
                                                                "auto",
                                                              dense: "",
                                                              prependInnerIcon: false,
                                                              label:
                                                                _vm.$t(
                                                                  "startDateLabel"
                                                                ),
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "startDateLabel"
                                                                ) +
                                                                " " +
                                                                (i + 1),
                                                              rules:
                                                                _vm.localRules
                                                                  .dateWithinRange,
                                                              disabled:
                                                                !_vm.isEditable ||
                                                                (_vm.userMode ==
                                                                  "T" &&
                                                                  _vm.hasAdminGradePeriods),
                                                            },
                                                            model: {
                                                              value:
                                                                item.startDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "startDate",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.startDate",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("pb-date-field", {
                                                            attrs: {
                                                              hideDetails:
                                                                "auto",
                                                              dense: "",
                                                              prependInnerIcon: false,
                                                              label:
                                                                _vm.$t(
                                                                  "endDateLabel"
                                                                ),
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "endDateLabel"
                                                                ) +
                                                                " " +
                                                                (i + 1),
                                                              rules:
                                                                _vm.localRules
                                                                  .dateWithinRange,
                                                              disabled:
                                                                !_vm.isEditable ||
                                                                (_vm.userMode ==
                                                                  "T" &&
                                                                  _vm.hasAdminGradePeriods),
                                                            },
                                                            model: {
                                                              value:
                                                                item.endDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "endDate",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.endDate",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          _vm.isEditable &&
                                                          !(
                                                            _vm.userMode ==
                                                              "T" &&
                                                            _vm.hasAdminGradePeriods
                                                          )
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    color:
                                                                      "error",
                                                                    "aria-label":
                                                                      _vm.$t(
                                                                        "removeItemLabel",
                                                                        {
                                                                          text: _vm.$t(
                                                                            "gradingPeriodLabel"
                                                                          ),
                                                                        }
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        $event.stopPropagation()
                                                                        return _vm.removeGradingPeriod(
                                                                          i
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fal fa-trash-alt"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.yearId == 0 ||
                              _vm.isNotEmpty(_vm.localLetterGrades) ||
                              _vm.isEditable
                                ? _c(
                                    "v-expansion-panel",
                                    {
                                      key: "letterGrades",
                                      attrs: { role: "tab" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: { "disable-icon-rotate": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _vm.isEditable &&
                                                    !(
                                                      _vm.userMode == "T" &&
                                                      _vm.hasAdminLetterGrades
                                                    )
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticStyle: {
                                                              top: "6px",
                                                              right: "16px",
                                                            },
                                                            attrs: {
                                                              absolute: "",
                                                              icon: "",
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "addItemLabel",
                                                                  {
                                                                    text: _vm.$t(
                                                                      "letterGradeLabel"
                                                                    ),
                                                                  }
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.addLetterGrade.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "fal fa-plus"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            976384857
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("gradingScaleLabel")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.isNotEmpty(_vm.localLetterGrades)
                                        ? _c(
                                            "v-expansion-panel-content",
                                            { staticClass: "pt-4 pb-2" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "5" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "letterGradeLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-1",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "minimumGradeLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-col", {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "1" },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._l(
                                                _vm.localLetterGrades,
                                                function (item, i) {
                                                  return _c(
                                                    "v-row",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              outlined: "",
                                                              "hide-details":
                                                                "auto",
                                                              dense: "",
                                                              label:
                                                                _vm.$t(
                                                                  "letterGradeLabel"
                                                                ),
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "letterGradeNumLabel",
                                                                  { num: i + 1 }
                                                                ),
                                                              rules:
                                                                _vm.localRules
                                                                  .letterGradeName,
                                                              disabled:
                                                                !_vm.isEditable ||
                                                                (_vm.userMode ==
                                                                  "T" &&
                                                                  _vm.hasAdminLetterGrades),
                                                            },
                                                            model: {
                                                              value: item.name,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.name",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              outlined: "",
                                                              "hide-details":
                                                                "auto",
                                                              dense: "",
                                                              label:
                                                                _vm.$t(
                                                                  "minimumGradeLabel"
                                                                ),
                                                              "aria-label":
                                                                _vm.$t(
                                                                  "minimumGradeForLabel",
                                                                  {
                                                                    letter:
                                                                      item.name,
                                                                  }
                                                                ),
                                                              rules:
                                                                _vm.localRules
                                                                  .letterGradePercentage,
                                                              disabled:
                                                                !_vm.isEditable ||
                                                                (_vm.userMode ==
                                                                  "T" &&
                                                                  _vm.hasAdminLetterGrades),
                                                            },
                                                            model: {
                                                              value:
                                                                item.percentage,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "percentage",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.percentage",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          _vm.isEditable &&
                                                          !(
                                                            _vm.userMode ==
                                                              "T" &&
                                                            _vm.hasAdminLetterGrades
                                                          )
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    color:
                                                                      "error",
                                                                    "aria-label":
                                                                      _vm.$t(
                                                                        "removeItemLabel",
                                                                        {
                                                                          text: _vm.$t(
                                                                            "letterGradeLabel"
                                                                          ),
                                                                        }
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        $event.stopPropagation()
                                                                        return _vm.removeLetterGrade(
                                                                          i
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fal fa-trash-alt"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.userMode == "A" ||
                              (_vm.yearId > 0 && _vm.parentYearId == 0)
                                ? _c(
                                    "v-expansion-panel",
                                    {
                                      key: "schoolDays",
                                      attrs: { role: "tab" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        { attrs: { "hide-actions": "" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("schoolDaysLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        { staticClass: "pt-4" },
                                        [
                                          _c(
                                            "v-chip-group",
                                            {
                                              attrs: {
                                                multiple: "",
                                                column: "",
                                              },
                                              model: {
                                                value: _vm.showCycleDays,
                                                callback: function ($$v) {
                                                  _vm.showCycleDays = $$v
                                                },
                                                expression: "showCycleDays",
                                              },
                                            },
                                            _vm._l(
                                              _vm.cycleDays,
                                              function (day) {
                                                return _c(
                                                  "pb-chip",
                                                  {
                                                    key: day.value,
                                                    staticClass: "mr-2 mb-1",
                                                    staticStyle: {
                                                      opacity: "1",
                                                    },
                                                    attrs: {
                                                      "active-class":
                                                        "accent3--text font-weight-bold",
                                                      filter: "",
                                                      outlined: "",
                                                      value: day.value,
                                                      disabled: !_vm.isEditable,
                                                    },
                                                    on: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.arrayAddOrRemove(
                                                          _vm.showCycleDays,
                                                          day.value
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(day.text))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.userMode == "A" ||
                              (_vm.yearId > 0 && _vm.parentYearId == 0)
                                ? _c(
                                    "v-expansion-panel",
                                    {
                                      key: "schoolTimes",
                                      attrs: { role: "tab" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        { attrs: { "hide-actions": "" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("schoolTimesLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        { staticClass: "pt-4" },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "pb-date-time-range-field",
                                                    {
                                                      attrs: {
                                                        hideDetails: "auto",
                                                        type: "time",
                                                        dense: true,
                                                        disabled:
                                                          !_vm.isEditable,
                                                        start:
                                                          _vm.schoolStartTime,
                                                        end: _vm.schoolEndTime,
                                                      },
                                                      on: {
                                                        "update:start":
                                                          function ($event) {
                                                            _vm.schoolStartTime =
                                                              $event
                                                          },
                                                        "update:end": function (
                                                          $event
                                                        ) {
                                                          _vm.schoolEndTime =
                                                            $event
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c(
        "pb-confirm",
        { ref: "prompt" },
        [
          _c("v-text-field", {
            attrs: { label: _vm.$t("yearNameLabel") },
            model: {
              value: _vm.newSchoolYearText,
              callback: function ($$v) {
                _vm.newSchoolYearText = $$v
              },
              expression: "newSchoolYearText",
            },
          }),
        ],
        1
      ),
      _c("manage-lessons", {
        attrs: {
          classId: 0,
          startDate: _vm.firstDayClone,
          endDate: _vm.lastDayClone,
        },
        model: {
          value: _vm.showManageLessons,
          callback: function ($$v) {
            _vm.showManageLessons = $$v
          },
          expression: "showManageLessons",
        },
      }),
      _c("import-lessons", {
        attrs: { sharedYears: _vm.localSharedYears },
        model: {
          value: _vm.showImportLessons,
          callback: function ($$v) {
            _vm.showImportLessons = $$v
          },
          expression: "showImportLessons",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }