
import { Component, Prop, Vue } from 'vue-property-decorator';
import SectionCard from '@/components/plans/card/SectionCard.vue';
import moment from 'moment';
import { namespace } from 'vuex-class';
import PlansViewMixin from '@/mixins/plans-view-mixin';
import LessonServices from '@/services/lesson-services';
import CommonUtils from '@/utils/common-utils';

const settings = namespace('settings');
const plans = namespace('plans');
@Component({
  mixins: [PlansViewMixin],
  components: {
    SectionCard
  }
})
export default class LessonViewMode extends Vue {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: { date: '' } };
    }
  })
  input!: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({ type: Boolean })
  value!: boolean;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @plans.Getter
  getCurrentPlanSettings!: any;

  showDetails!: boolean;

  get localHeaderName() {
    return this.$t('lessonLabel') + ' ' + `#${this.rawDayObject.extraLesson}`;
  }

  get isLessonBanks() {
    return this.localPage === 'lesson-banks';
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  async doOnClose() {
    this.$eventBus.$emit('closeSubPage');
  }

  get dayObject() {
    try {
      return LessonServices.createDays([{ objects: [this.rawDayObject], date: this.rawDayObject.date, dayOfWeek: this.rawDayObject.dayOfWeek }], this.getCurrentPlanSettings, this.$currentUser.userMode).day1.dayObjects[0];
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  get rawDayObject() {
    return this.input.rawDayObject ||
    {
      lessonId: 0,
      date: '',
      dayOfWeek: '',
      assignments: [],
      tab4Text: '',
      type: 'L',
      homeworkText: '',
      customStart: '',
      tab5Text: '',
      customEnd: '',
      lessonLock: 'N',
      lessonText: '',
      tab6Text: '',
      standards: [],
      lessonTitle: '',
      notesText: '',
      attachments: [],
      extraLesson: 999,
      className: '',
      classId: 0,
      startTime: '',
      assessments: [],
      addClassDaysCode: '',
      strategies: [],
      myStandards: [],
      addendums: [],
      endTime: ''
    };
  }

  get zoom() {
    return 1;
  }

  stringifyDate() {
    const item = this.rawDayObject;
    if (item === undefined) return;
    if (this.userInfo.displaySettings.dateStyling.dateFormat === 1) {
      // MM/DD/YYYY
      const formattedDate = moment(item.date, 'MM/DD/YYYY').format('ddd MMM-DD');
      item.formattedDate = formattedDate;
      return formattedDate;
    } else if (this.userInfo.displaySettings.dateStyling.dateFormat === 2) {
      // DD/MM/YYYY
      const formattedDate = moment(item.date, 'MM/DD/YYYY').format('ddd DD-MMM');
      item.formattedDate = formattedDate;
      return formattedDate;
    }
  }

  previousLesson() {
    this.$eventBus.$emit('previousLesson', { item: this.input.rawDayObject, isEditMode: false });
  }

  nextLesson() {
    this.$eventBus.$emit('nextLesson', { item: this.input.rawDayObject, isEditMode: false });
  }
}
