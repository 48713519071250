













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MultiListPicker from './MultiListPicker.vue';

const settings = namespace('settings');
const standards = namespace('standards');

@Component({
  components: {
    MultiListPicker
  }
})
export default class MyListPicker extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Array, required: true, default: () => [] })
  standards!: Array<any>;

  @settings.State
  userMode!: string;

  @settings.Getter('getLessonSectionLabel')
  getLessonSectionLabel!: any;

  @standards.State('myList')
  myListData!: any;

  @standards.Action
  loadMyList!: (params?: any) => Promise<any>;

  @standards.Mutation
  setMyList!: any;

  @standards.Mutation
  setHaveMyList!: any;

  selected: Array<any> = [];
  filter = ''
  listViewLoaded = false;
  group = '';

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get localMyListData() {
    return this.myListData || {};
  }

  get localMyList() {
    return this.localMyListData.standards || [];
  }

  get localMyListItems() {
    return this.localMyList.map((s: any) => {
      return {
        key: s.dbId,
        shortValue: s.sI,
        value: s.sD
      }
    });
  }

  get selectedCount() {
    return this.selected.length;
  }

  @Watch('localValue')
  onValueChange() {
    if (this.value) {
      this.selected = this.standards.map((s: any) => s.dbId);
      this.initialize();
    } else {
      this.selected = [];
    }
  }

  unselectAll() {
    this.selected = [];
  }

  refresh() {
    this.setMyList({});
    return this.initialize();
  }

  async initialize() {
    const that = this;
    this.listViewLoaded = false;
    return this.loadMyList({
      userMode: this.userMode
    }).then(() => {
      that.setHaveMyList(true);
      that.listViewLoaded = true;
    });
  }

  doApply(selected: Array<any>) {
    const newStandards = this.localMyList.filter((s: any) => selected.includes(s.dbId)).map((s: any) => {
      return {
        dbId: s.dbId,
        id: s.sI,
        desc: s.sD
      }
    });
    this.$emit('update:standards', newStandards);
    this.localValue = false;
  }

  created() {
    this.setHaveMyList(false);
  }
}
