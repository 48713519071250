
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';

const events = namespace('events');

@Component
export default class EventActions extends Vue implements ActionsModal {
    @Prop({
      required: true,
      type: Object,
      default: () => {
        return { loadData: true, data: {}, mode: 'P' };
      }
    })
    input!: any;

    @Prop({ required: false, type: String, default: 'absolute' })
    type!: string;

    actions = [
      {
        id: 'hide',
        icon: 'fal fa-eye-slash',
        label: 'hideEventLabel'
      }
    ];

    showMenu = false;
    x = 0;
    y = 0;

    $refs!: {
        confirm: Confirm,
    }

    @events.State
    googleId!: string;

    @events.State
    eventCurrentDate!: string;

    @events.State
    eventTitle!: string;

    @events.Action
    init!: (params?: any) => Promise<any>;

    @events.Action('hideGoogleCalendarEvent')
    hide!: (params?: any) => Promise<any>;

    get hasGoogleId() {
      return CommonUtils.hasText(this.googleId);
    }

    get updateCurrentEvent() {
      return this.$store.state.events.updateCurrentEvent;
    }

    set updateCurrentEvent(val: boolean) {
      this.$store.commit('events/setUpdateCurrentEvent', val);
    }

    get shiftLessons() {
      return this.$store.state.events.updateCurrentEvent;
    }

    set shiftLessons(val: boolean) {
      this.$store.commit('events/setShiftLessons', val);
    }

    get filteredActions() {
      return this.actions;
    }

    get titleFont(): string {
      return this.$store.state.events.titleFont;
    }

    set titleFont(val: string) {
      this.$store.commit('events/setTitleFont', val);
    }

    get titleFontSize(): string {
      return this.$store.state.events.titleFontSize;
    }

    set titleFontSize(val: string) {
      this.$store.commit('events/setTitleFontSize', val);
    }

    get titleColor(): string {
      return this.$store.state.events.titleColor;
    }

    set titleColor(val: string) {
      this.$store.commit('events/setTitleColor', val);
    }

    get titleFillColor(): string {
      return this.$store.state.events.titleFillColor;
    }

    set titleFillColor(val: string) {
      this.$store.commit('events/setTitleFillColor', val);
    }

    get titleBold(): string {
      return this.$store.state.events.titleBold;
    }

    set titleBold(val: string) {
      this.$store.commit('events/setTitleBold', val);
    }

    get titleItalic(): string {
      return this.$store.state.events.titleItalic;
    }

    set titleItalic(val: string) {
      this.$store.commit('events/setTitleItalic', val);
    }

    get titleUnderline(): string {
      return this.$store.state.events.titleUnderline;
    }

    set titleUnderline(val: string) {
      this.$store.commit('events/setTitleUnderline', val);
    }

    get bodyFont(): string {
      return this.$store.state.events.bodyFont;
    }

    set bodyFont(val: string) {
      this.$store.commit('events/setBodyFont', val);
    }

    get bodyFontSize(): string {
      return this.$store.state.events.bodyFontSize;
    }

    set bodyFontSize(val: string) {
      this.$store.commit('events/setBodyFontSize', val);
    }

    get bodyColor(): string {
      return this.$store.state.events.bodyColor;
    }

    set bodyColor(val: string) {
      this.$store.commit('events/setBodyColor', val);
    }

    get bodyFillColor(): string {
      return this.$store.state.events.bodyFillColor;
    }

    set bodyFillColor(val: string) {
      this.$store.commit('events/setBodyFillColor', val);
    }

    get bodyBold(): string {
      return this.$store.state.events.bodyBold;
    }

    set bodyBold(val: string) {
      this.$store.commit('events/setBodyBold', val);
    }

    get bodyItalic(): string {
      return this.$store.state.events.bodyItalic;
    }

    set bodyItalic(val: string) {
      this.$store.commit('events/setBodyItalic', val);
    }

    get bodyUnderline(): string {
      return this.$store.state.events.bodyUnderline;
    }

    set bodyUnderline(val: string) {
      this.$store.commit('events/setBodyUnderline', val);
    }

    get updatedFields(): Set<any> {
      return this.$store.state.events.updatedFields;
    }

    set updatedFields(val: Set<any>) {
      this.$store.commit('events/setUpdatedFields', val);
    }

    get initialized(): boolean {
      return this.$store.state.events.editorInitialized;
    }

    get formattedDate() {
      if (CommonUtils.hasText(this.eventCurrentDate)) {
        return `${DateTimeUtils.formatToDisplay(this.eventCurrentDate)}`;
      }
    }

    get actionHeader() {
      return this.formattedDate + ' - ' + (this.eventTitle || 'Event');
    }

    doAction(action: any) {
      Promise.resolve().then(() => {
        if (action.id === 'hide') {
          CommonUtils.showLoading();
          return this.hide();
        }
        return Promise.resolve();
      }).finally(() => {
        this.showMenu = false;
        CommonUtils.hideLoading();
      });
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }

    doInit() {
      this.init(this.input);
    }

    @Watch('input')
    onInputChange() {
      this.doInit();
    }

    created() {
      this.doInit();
    }
}
