
import { namespace } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import EventEditorForm from '@/components/events/EventEditorForm.vue';
import { PlanbookStorage } from '@/common/planbook-storage';
import RemindersCard from '@/components/reminders/RemindersCard.vue';
import RemindersMixin from '@/mixins/reminders-mixin';
import NotesTodoServices from '@/services/notes-todo-services';
import ld from 'lodash';

const notestodo = namespace('notestodo');
const drawer = namespace('drawer');
const reminders = namespace('reminders');

@Component({
  mixins: [RemindersMixin],
  components: {
    EventEditorForm,
    RemindersCard
  }
})
export default class Reminders extends Vue {
  remindersSearchText!: string;
  eventsReminderFilter!: boolean;
  todosReminderFilter!: boolean;
  notesReminderFilter!: boolean;
  assignmentsReminderFilter!: boolean;
  assessmentsReminderFilter!: boolean;
  studentsBirthdayReminderFilter!: boolean;
  notesTodosStyling!: any;
  eventStyling!: any;
  classworkStyling!:any;
  localTodo!: any;
  remindersStartDate!: any;
  remindersEndDate!: any;
  datas!: any;
  localRefreshKey!: any;
  reminders!: Array<any>;
  toDoAllData!: Array<any>;
  initialized!: boolean;

  @notestodo.Mutation('setHaveReminders')
  setHaveReminders!: any;

  @notestodo.Action
  loadEventsReminders!: (params?: any) => Promise<any>;

  @notestodo.Action
  updateTodo!: (params?: any) => Promise<any>;

  @notestodo.Mutation('setReminders')
  setReminders!: any;

  @drawer.State
  remindersMobileClickCounter!: number;

  get remindersFilter(): any[] {
    return this.$store.state.reminders.remindersFilter;
  }

  set remindersFilter(val: any[]) {
    this.$store.commit('reminders/setRemindersFilter', val);
  }

  @Prop({
    required: false,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @Prop({ type: Number, required: false, default: 0 })
  offset!: number;

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  mobileDrawerMode!: boolean;

  get eventsRemindersHaveData() {
    return CommonUtils.isNotEmpty(this.reminders);
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get contentHeight(): number {
    return this.offset + 50;
  }

  get perfectScrollBarHeightMobile() {
    if (this.remindersMobileClickCounter === 1) {
      return (this.offset / 2) - 70;
    } else if (this.remindersMobileClickCounter === 2) {
      return this.offset - 235;
    }
  }

  @Watch('value')
  onValueChange(v:boolean) {
    if (v) this.refresh();
  }

  @Watch('browserWidth')
  onBrowserWidthChanged() {
    this.refreshDisplay();
  }

  @Watch('browserHeight')
  onBrowserHeightChanged() {
    this.refreshDisplay();
  }

  refresh() {
    this.setLocalTodoEmpty();
    this.setReminders([]);
    this.initialize();
  }

  hideRemindersMobileDrawer() {
    this.$emit('hideRemindersMobileDrawer');
  }

  async cancelEditor() {
    console.log('Cancel');
    PlanbookStorage.set('reminders', false);
    this.$store.commit('plans/setRemindersFloatingIcon', 'fal fa-angle-left');
  }

  refreshDisplay() {
    this.localRefreshKey = CommonUtils.generateUUID();
    this.$nextTick(() => {
      this.localValue = false;
    });
    this.$nextTick(() => {
      this.localValue = true;
    });
  }

  initialize() {
    this.initialized = false;
    const that = this;
    this.setHaveReminders(false);
    if (PlanbookStorage.get('reminders')) {
      this
        .loadEventsReminders({
          notesSearchStartDate: this.remindersStartDate,
          notesSearchEndDate: this.remindersEndDate
        })
        .then(() => {
          that.toDoAllData = that.datas.TODOS.toDos;
          that.setHaveReminders(true);
          that.initialized = true;
          that.localRefreshKey = CommonUtils.generateUUID();
        });
    }
  }

  closeRemindersPanelAndResetData() {
    this.setLocalTodoEmpty();
    this.setReminders([]);
    if (PlanbookStorage.get('reminders')) {
      PlanbookStorage.set('reminders', false);
      this.$eventBus.$emit('closeSubPage');
    }
  }

  onSchoolYearOrUserModeChanged() {
    Promise.resolve().then(() => {
      this.closeRemindersPanelAndResetData();
    });
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$on('schoolYearChanged', this.onSchoolYearOrUserModeChanged);
      this.$eventBus.$on('userModeChanged', this.onSchoolYearOrUserModeChanged);
      this.$eventBus.$on('refreshReminders', this.refresh);
    })
  }

  beforeDestroy() {
    this.$eventBus.$off('schoolYearChanged', this.onSchoolYearOrUserModeChanged);
    this.$eventBus.$off('userModeChanged', this.onSchoolYearOrUserModeChanged);
    this.$eventBus.$off('refreshReminders', this.refresh);
  }

  setLocalTodoEmpty() {
    this.localTodo = {
      startDate: '',
      dueDate: '',
      toDoText: '',
      priority: 0,
      done: 0,
      toDoId: null,
      repeats: 'daily',
      currentDate: '',
      updateCurrentTodo: true,
      customTodo: [],
      customTodoId: undefined,
      customToDoText: undefined,
      customPriority: undefined,
      customDone: undefined
    };
  }

  updateDoneTodo(toDoItem: any) {
    const that = this;
    toDoItem.done = CommonUtils.isTrue(toDoItem.done) ? 1 : 0;
    toDoItem.customDone = CommonUtils.isTrue(toDoItem.customDone) ? 1 : 0;
    this.localTodo = {
      startDate: toDoItem.startDate,
      dueDate: toDoItem.dueDate,
      toDoText: toDoItem.toDoText,
      priority: toDoItem.priority,
      done: toDoItem.done,
      toDoId: toDoItem.toDoId,
      repeats: toDoItem.repeats,
      currentDate: toDoItem.currentDate,
      updateCurrentTodo: true,
      customTodo: JSON.stringify(toDoItem.customTodo),
      customTodoId: toDoItem.customTodoId,
      customToDoText: toDoItem.customToDoText,
      customPriority: toDoItem.customPriority,
      customDone: toDoItem.customDone
    };
    if (CommonUtils.hasText(toDoItem.currentDate)) {
      this.localTodo.customDone = CommonUtils.isTrue(toDoItem.customDone) ? 0 : 1;
      if (!toDoItem.customToDoText && !toDoItem.customPriority) {
        this.localTodo.customToDoText = toDoItem.toDoText;
        this.localTodo.customPriority = toDoItem.priority;
      }
    } else {
      this.localTodo.done = CommonUtils.isTrue(toDoItem.done) ? 0 : 1;
    }
    if (this.localTodo.customTodoId === undefined && CommonUtils.isNotEmpty(JSON.parse(that.localTodo.customTodo || '[]'))) {
      // this.localTodo.customDone = CommonUtils.isTrue(this.localTodo.done) ? 0 : 1;
      NotesTodoServices.createNewCustomTodo(this.localTodo, toDoItem);
    }
    if (CommonUtils.hasNoText(toDoItem.currentDate) && toDoItem.customTodoId === undefined) {
      this.localTodo.updateCurrentTodo = false;
    }
    CommonUtils.showLoading();
    this.updateTodo(this.localTodo).then(() => {
      // this.initialize();
      toDoItem = ld.cloneDeep(that.localTodo);
      toDoItem.customTodo = JSON.parse(toDoItem.customTodo);
      let reminders = ld.cloneDeep(that.reminders);
      if (CommonUtils.hasText(toDoItem.currentDate)) {
        toDoItem.customDone = true;
        reminders = reminders.filter(r => r.toDoId !== undefined).map(r => {
          const myId = r.toDoId;
          const myDate = CommonUtils.hasText(r.currentDate) ? r.currentDate : r.dueDate;
          const itemId = toDoItem.toDoId;
          const itemDate = CommonUtils.hasText(toDoItem.currentDate) ? toDoItem.currentDate : toDoItem.dueDate;
          if (myId + myDate === itemId + itemDate) {
            // add new customTodo if customTodo is empty
            if (CommonUtils.hasText(r.currentDate) && (!r.customToDoText && !r.customPriority) && CommonUtils.isEmpty(toDoItem.customTodo)) {
              r.customToDoText = toDoItem.toDoText;
              r.customPriority = toDoItem.priority;
              const customTodo = {
                customTodoId: 0,
                currentDate: r.currentDate,
                toDoText: r.toDoText,
                priority: r.priority,
                done: true
              }
              toDoItem.customTodo.push(customTodo);
              toDoItem.customTodoId = 0;
            } else {
              const customTodoData = toDoItem.customTodo.find((c:any) => c.customTodoId === toDoItem.customTodoId);
              customTodoData.done = true;
            }
            r = toDoItem;
          }
          return r;
        });
      } else {
        toDoItem.done = true;
        reminders = reminders.map(r => {
          if (r.toDoId === toDoItem.toDoId) {
            r = toDoItem
          }
          return r;
        });
      }
      that.toDoAllData = reminders.filter(r => r.toDoId !== undefined);
      that.localRefreshKey = CommonUtils.generateUUID();
      CommonUtils.hideLoading();
    }).catch(() => {
      CommonUtils.hideLoading();
    });
  }
}
