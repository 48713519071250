var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("multi-list-picker", {
    attrs: {
      label: _vm.getLessonSectionLabel("schoolStandards"),
      items: _vm.localSchoolListItems,
      selected: _vm.selected,
      withRefresh: true,
      contentClass: "school-list-picker-content",
    },
    on: { refresh: _vm.refresh, apply: _vm.doApply },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }