




























































import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import ld from 'lodash';
const settings = namespace('settings');
const lessons = namespace('lessons');
@Component
export default class ImportLessons extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Array })
  sharedYears!: any[];

  selectedYear:any = null;
  selectedClass:any = 0;

  @settings.Getter
  getSchoolYearSettings!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @lessons.Action('importLessons')
  doImportLessons!: (params?: any) => Promise<any>;

  $refs!: {
    confirm: Confirm,
  }

  get currentYearId(): number {
    return this.$store.state.settings.updatedSettings.yearId;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get importSchoolYears() {
    return this.localSchoolYears.filter((y: any) => y.yearId !== this.currentYearId);
  }

  get localSchoolYears() {
    return this.getSchoolYearSettings.years || [];
  }

  @Watch('value')
  onOpen() {
    if (this.localValue) {
      this.selectedYear = this.importSchoolYears[0];
    }
  }

  get firstDayOfCurrentSchoolYear() {
    for (const years of this.localSchoolYears) {
      if (years.yearId === this.currentYearId) {
        return years.firstDay;
      }
    }
  }

  get localFromClasses(): any[] {
    let classes = [{ className: 'None', classId: 0 }];
    for (const years of ld.cloneDeep(this.sharedYears)) {
      if (years.yearId === this.selectedYear) {
        classes = classes.concat(years.classes.sort((c1: any, c2: any) => c1.className.localeCompare(c2.className)));
      }
    }
    return classes;
  }

  get localToClasses(): any[] {
    console.log('get to classes', this.firstDayOfCurrentSchoolYear)
    let classes = [];
    for (const years of ld.cloneDeep(this.sharedYears)) {
      if (years.yearId === this.currentYearId) {
        for (const classes of years.classes) {
          const fromClass = this.localFromClasses.find((c) => (classes.className === c.className))
          if (fromClass) {
            classes.selectedClassId = ld.cloneDeep(fromClass.classId);
          } else {
            classes.selectedClassId = 0;
          }
          classes.startingAt = ld.cloneDeep(this.firstDayOfCurrentSchoolYear);
        }
        classes = years.classes;
      }
    }
    return classes;
  }

  async doApply () {
    const that = this;
    return that.$refs.confirm.confirm({
      title: this.$t('importLessonsLabel'),
      text: this.$t('importLessonsWarningMessage'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        // continue
        CommonUtils.showLoading();
        const requests:any = [];
        for (const classes of that.localToClasses) {
          if (classes.selectedClassId !== 0) {
            const payload:any = {};
            payload.insertDate = classes.startingAt;
            payload.sharedClassId = classes.selectedClassId;
            payload.classId = classes.classId;
            payload.lessonUnitId = 0;
            payload.startDate = '';
            payload.endDate = '';
            payload.allTab1 = 'N';
            payload.lessonUnitId = '0';
            payload.confirmBump = 'false';
            payload.className = classes.className;
            requests.push(that.doImportLessons(payload));
          }
        }
        if (CommonUtils.isEmpty(requests)) {
          CommonUtils.hideLoading();
          that.localValue = false;
          return Promise.resolve();
        } else {
          Promise.all(requests).then((values) => {
            const error = values.filter((v:any) => v.error === 'true');
            let cNames = error.map((e:any) => e.className).join(', ');
            if (error.length > 0) {
              let alertMsg = 'Failed to import lessons from ';
              if (error.length > 1) {
                var lastIndex = cNames.lastIndexOf(', ');
                cNames = cNames.substring(0, lastIndex) + ' and ' + cNames.substring(lastIndex + 1);
              }
              alertMsg += cNames;
              CommonUtils.hideLoading();
              return this.$refs.confirm.alert({
                title: this.$t('importLessonsLabel'),
                text: alertMsg,
                option1ButtonAlternativeText: this.$t('returnLabel')
              });
            } else {
              CommonUtils.hideLoading();
              that.localValue = false;
            }
          });
        }
      } else {
        return Promise.resolve();
      }
    })
  }
}
