










import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');

@Component
export default class Loader extends Vue {
  @settings.State
  loading!: boolean;

  $refs!: {
    loader: Vue
  }

  @Watch('loading')
  onLoadingChange() {
    if (this.loading) {
      try {
        const element = this.$refs.loader.$el as HTMLElement;
        element.style.zIndex = '99999999999999999999';
      } catch (e) {
      }
    }
  }
}
