var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: { withActions: false, expandable: false, maxWidth: 1000 },
      on: { apply: _vm.doApply },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("importLessonsLabel")) + " ")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-container",
        [
          _c("v-select", {
            attrs: {
              "hide-details": "auto",
              outlined: "",
              items: _vm.importSchoolYears,
              "item-value": "yearId",
              "item-text": "yearName",
              label: _vm.$t("importPriorYearLabel"),
              autofocus: ""
            },
            model: {
              value: _vm.selectedYear,
              callback: function($$v) {
                _vm.selectedYear = $$v
              },
              expression: "selectedYear"
            }
          }),
          _c(
            "v-row",
            {
              staticClass: "mt-4",
              staticStyle: { "background-color": "#e7e7e7" }
            },
            [
              _c("v-col", { staticClass: "ml-5", attrs: { align: "start" } }, [
                _c("strong", { staticStyle: { "font-size": "12pt" } }, [
                  _vm._v(_vm._s(_vm.$t("importLessonsMessage")))
                ])
              ])
            ],
            1
          ),
          _vm._l(_vm.localToClasses, function(item, i) {
            return _c(
              "v-row",
              {
                key: i,
                staticClass: "mx-auto",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "v-col",
                  { attrs: { md: "3", sm: "3", cols: "auto" } },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.localFromClasses,
                        label: _vm.$t("selectClassLabel"),
                        "aria-label": _vm.$t("selectToInsertLabel", {
                          class: item.className
                        }),
                        "hide-details": "auto",
                        outlined: "",
                        "item-value": "classId",
                        "item-text": "className"
                      },
                      model: {
                        value: item.selectedClassId,
                        callback: function($$v) {
                          _vm.$set(item, "selectedClassId", $$v)
                        },
                        expression: "item.selectedClassId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "my-auto",
                    staticStyle: {
                      "flex-wrap": "wrap",
                      width: "27%",
                      "margin-left": "15%",
                      "margin-right": "7%"
                    }
                  },
                  [
                    _c("v-icon", { staticClass: "mr-5" }, [
                      _vm._v("fas fa-arrow-right")
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "13pt",
                          "margin-top": "5px"
                        }
                      },
                      [_vm._v(_vm._s(item.className))]
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { md: "3", sm: "3", cols: "auto" } },
                  [
                    _c("pb-date-field", {
                      attrs: {
                        label: _vm.$t("insertLessonsLabel"),
                        ariaLabel: _vm.$t("insertLessonsIntoLabel", {
                          class: item.className
                        }),
                        value: item.startingAt,
                        hideDetails: "auto"
                      },
                      on: {
                        "update:value": function($event) {
                          return _vm.$set(item, "startingAt", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }