var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      class: {
        firefox: _vm.$browserDetect.isFirefox,
        "mobile-app": _vm.$vuetify.breakpoint.smAndDown
      },
      style: _vm.$vuetify.breakpoint.smAndDown
        ? { position: "fixed", top: "0px" }
        : undefined
    },
    [
      _c("div", {
        ref: "globalStyleContainer",
        domProps: { innerHTML: _vm._s(_vm.globalStyles) }
      }),
      _c("link", {
        attrs: {
          rel: "stylesheet",
          type: "text/css",
          href: _vm.customFontsCSSUrl
        }
      }),
      _c("pb-banner"),
      _c("notification-permission"),
      _c("router-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEmbedded,
            expression: "!isEmbedded"
          }
        ],
        attrs: { name: "toolbar" }
      }),
      _c("pb-left-drawer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEmbedded,
            expression: "!isEmbedded"
          }
        ],
        attrs: { selected: _vm.selectedPage },
        on: {
          "update:selected": function($event) {
            _vm.selectedPage = $event
          }
        }
      }),
      _c("pb-right-drawer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEmbedded,
            expression: "!isEmbedded"
          }
        ]
      }),
      _c("pb-bottom-drawer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEmbedded,
            expression: "!isEmbedded"
          }
        ]
      }),
      ["plans"].includes(_vm.localPage) && _vm.$vuetify.breakpoint.smAndDown
        ? _c("reminders-mobile-drawer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isEmbedded,
                expression: "!isEmbedded"
              }
            ]
          })
        : _vm._e(),
      _c("loader"),
      _c(
        "v-row",
        {
          staticClass: "ma-0",
          style: {
            "padding-left": _vm.wrapperLeftPaddingWidth + "px",
            height: _vm.$vuetify.breakpoint.smAndDown ? "100%" : ""
          }
        },
        [
          ["plans", "assign-students", "messages"].includes(_vm.localPage) &&
          _vm.userMode == "A" &&
          _vm.localTeacherListDockPosition == "left"
            ? _c(
                "div",
                {
                  staticClass: "teacher-list-content",
                  style: {
                    width: _vm.teacherListContentWidth + "px",
                    overflow: "hidden"
                  }
                },
                [
                  _c("pb-teacher-list-admin-view", {
                    attrs: {
                      isTeacherListFullWidth: _vm.localIsTeacherListFullWidth,
                      dockPosition: _vm.localTeacherListDockPosition
                    },
                    on: {
                      "update:dockPosition": function($event) {
                        _vm.localTeacherListDockPosition = $event
                      },
                      "update:dock-position": function($event) {
                        _vm.localTeacherListDockPosition = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          ["plans", "assign-students", "messages"].includes(_vm.localPage) &&
          _vm.userMode == "A" &&
          _vm.localTeacherListDockPosition == "left"
            ? _c("v-divider", {
                staticStyle: { "z-index": "1" },
                attrs: { vertical: "" }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "main-content",
              class: "main-content__" + (_vm.localPage + "-page"),
              style: {
                width: _vm.mainContentWidth,
                overflow: "hidden",
                height: _vm.mainContentHeight
              }
            },
            [_c("pb-content")],
            1
          ),
          _vm.localShowRightColumn && !_vm.mobileMode
            ? _c("v-divider", {
                staticStyle: { "z-index": "1" },
                attrs: { vertical: "" }
              })
            : _vm._e(),
          _c(
            "div",
            {
              ref: "subPage",
              staticClass: "sub-page-content",
              style: {
                width: _vm.subPageContentWidth + "px",
                overflow: "hidden"
              },
              attrs: { role: "complementary" }
            },
            [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  ["plans"].includes(_vm.localPage) &&
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c("reminders", {
                        ref: "remindersPanel",
                        attrs: { offset: _vm.browserHeight - 200 },
                        model: {
                          value: _vm.subPages.reminder,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "reminder", $$v)
                          },
                          expression: "subPages.reminder"
                        }
                      })
                    : _vm._e(),
                  ["lesson-layouts"].includes(_vm.localPage)
                    ? _c("pb-lesson-layout-editor", {
                        ref: "lessonLayout",
                        attrs: {
                          input: _vm.localInput.lessonLayout,
                          isModal: _vm.localIsModal
                        },
                        model: {
                          value: _vm.subPages.lessonLayout,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "lessonLayout", $$v)
                          },
                          expression: "subPages.lessonLayout"
                        }
                      })
                    : _vm._e(),
                  ["notes-todo", "plans"].includes(_vm.localPage)
                    ? _c("pb-notestodo-editor", {
                        ref: "notesTodoEditor",
                        attrs: {
                          input: _vm.localInput.notesTodoEditor,
                          isModal: _vm.localIsModal
                        },
                        model: {
                          value: _vm.subPages.notesTodoEditor,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "notesTodoEditor", $$v)
                          },
                          expression: "subPages.notesTodoEditor"
                        }
                      })
                    : _vm._e(),
                  ["my-list", "school-list"].includes(_vm.localPage)
                    ? _c("pb-my-list-editor", {
                        ref: "mylist",
                        attrs: {
                          input: _vm.localInput.mylist,
                          isModal: _vm.localIsModal
                        },
                        model: {
                          value: _vm.subPages.mylist,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "mylist", $$v)
                          },
                          expression: "subPages.mylist"
                        }
                      })
                    : _vm._e(),
                  ["strategies"].includes(_vm.localPage)
                    ? _c("pb-my-strategies-editor", {
                        ref: "mystrategies",
                        attrs: {
                          input: _vm.localInput.mystrategies,
                          isModal: _vm.localIsModal
                        },
                        model: {
                          value: _vm.subPages.mystrategies,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "mystrategies", $$v)
                          },
                          expression: "subPages.mystrategies"
                        }
                      })
                    : _vm._e(),
                  ["sharing-options"].includes(_vm.localPage)
                    ? _c("pb-permissions-editor", {
                        ref: "sharingoptions",
                        attrs: {
                          input: _vm.localInput.sharingoptions,
                          isModal: _vm.localIsModal
                        },
                        model: {
                          value: _vm.subPages.sharingoptions,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "sharingoptions", $$v)
                          },
                          expression: "subPages.sharingoptions"
                        }
                      })
                    : _vm._e(),
                  ["plans", "messages", "reporting-plans"].includes(
                    _vm.localPage
                  )
                    ? _c("pb-message-editor", {
                        ref: "message",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.message
                        },
                        model: {
                          value: _vm.subPages.message,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "message", $$v)
                          },
                          expression: "subPages.message"
                        }
                      })
                    : _vm._e(),
                  ["plans", "school-years"].includes(_vm.localPage) &&
                  _vm.userType !== "S"
                    ? _c("school-year-editor", {
                        ref: "schoolyear",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.schoolyear
                        },
                        model: {
                          value: _vm.subPages.schoolyear,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "schoolyear", $$v)
                          },
                          expression: "subPages.schoolyear"
                        }
                      })
                    : _vm._e(),
                  ["plans", "grades", "classwork"].includes(_vm.localPage)
                    ? _c("classwork-editor", {
                        ref: "classwork",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.classwork
                        },
                        model: {
                          value: _vm.subPages.classwork,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "classwork", $$v)
                          },
                          expression: "subPages.classwork"
                        }
                      })
                    : _vm._e(),
                  ["plans", "events"].includes(_vm.localPage)
                    ? _c("event-editor", {
                        ref: "event",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.event
                        },
                        model: {
                          value: _vm.subPages.event,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "event", $$v)
                          },
                          expression: "subPages.event"
                        }
                      })
                    : _vm._e(),
                  ["plans", "units", "lesson-banks"].includes(_vm.localPage)
                    ? _c("unit-editor", {
                        ref: "unit",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.unit
                        },
                        model: {
                          value: _vm.subPages.unit,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "unit", $$v)
                          },
                          expression: "subPages.unit"
                        }
                      })
                    : _vm._e(),
                  ["plans", "templates"].includes(_vm.localPage)
                    ? _c("template-editor", {
                        ref: "template",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.template
                        },
                        model: {
                          value: _vm.subPages.template,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "template", $$v)
                          },
                          expression: "subPages.template"
                        }
                      })
                    : _vm._e(),
                  ["students"].includes(_vm.localPage)
                    ? _c("student-editor", {
                        ref: "student",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.student
                        },
                        model: {
                          value: _vm.subPages.student,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "student", $$v)
                          },
                          expression: "subPages.student"
                        }
                      })
                    : _vm._e(),
                  ["teachers"].includes(_vm.localPage)
                    ? _c("teacher-editor", {
                        ref: "teacher",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.teacher
                        },
                        model: {
                          value: _vm.subPages.teacher,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "teacher", $$v)
                          },
                          expression: "subPages.teacher"
                        }
                      })
                    : _vm._e(),
                  ["lesson-banks", "copy"].includes(_vm.localPage)
                    ? _c("lesson-banks-editor", {
                        ref: "lessonBanks",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.lessonBanks
                        },
                        model: {
                          value: _vm.subPages.lessonBanks,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "lessonBanks", $$v)
                          },
                          expression: "subPages.lessonBanks"
                        }
                      })
                    : _vm._e(),
                  ["resources"].includes(_vm.localPage)
                    ? _c("resources-editor", {
                        ref: "resources",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.resources
                        },
                        model: {
                          value: _vm.subPages.resources,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "resources", $$v)
                          },
                          expression: "subPages.resources"
                        }
                      })
                    : _vm._e(),
                  ["plans", "search", "lesson-banks", "reporting"].includes(
                    _vm.localPage
                  )
                    ? _c("lesson-editor", {
                        ref: "lesson",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.lesson
                        },
                        model: {
                          value: _vm.subPages.lesson,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "lesson", $$v)
                          },
                          expression: "subPages.lesson"
                        }
                      })
                    : _vm._e(),
                  _c("pb-csv-editor", {
                    ref: "csv",
                    attrs: {
                      isModal: _vm.localIsModal,
                      input: _vm.localInput.csv
                    },
                    model: {
                      value: _vm.subPages.csv,
                      callback: function($$v) {
                        _vm.$set(_vm.subPages, "csv", $$v)
                      },
                      expression: "subPages.csv"
                    }
                  }),
                  ["classes", "copy"].includes(_vm.localPage) &&
                  _vm.userType !== "S"
                    ? _c("class-editor", {
                        ref: "class",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.class
                        },
                        model: {
                          value: _vm.subPages.class,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "class", $$v)
                          },
                          expression: "subPages.class"
                        }
                      })
                    : _vm._e(),
                  _vm.hasDefaultSubpage.includes(_vm.localPage)
                    ? _c("default-subpage", {
                        attrs: { input: _vm.localInput.default },
                        model: {
                          value: _vm.subPages.default,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "default", $$v)
                          },
                          expression: "subPages.default"
                        }
                      })
                    : _vm._e(),
                  ["search", "lesson-banks", "reporting"].includes(
                    _vm.localPage
                  ) || _vm.isListView
                    ? _c("lesson-view-mode", {
                        ref: "lessonViewMode",
                        attrs: {
                          isModal:
                            _vm.localIsModal &&
                            _vm.$currentUser.defaultEditorMode === "modal" &&
                              !_vm.subPages.lesson,
                          input: _vm.localInput.lessonViewMode
                        },
                        model: {
                          value: _vm.subPages.lessonViewMode,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "lessonViewMode", $$v)
                          },
                          expression: "subPages.lessonViewMode"
                        }
                      })
                    : _vm._e(),
                  ["plans"].includes(_vm.localPage)
                    ? _c("student-join-class-editor", {
                        ref: "joinClass",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.joinClass
                        },
                        model: {
                          value: _vm.subPages.joinClass,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "joinClass", $$v)
                          },
                          expression: "subPages.joinClass"
                        }
                      })
                    : _vm._e(),
                  ["plans"].includes(_vm.localPage)
                    ? _c("student-manage-class-editor", {
                        ref: "manageClass",
                        attrs: {
                          isModal: _vm.localIsModal,
                          input: _vm.localInput.manageClass
                        },
                        model: {
                          value: _vm.subPages.manageClass,
                          callback: function($$v) {
                            _vm.$set(_vm.subPages, "manageClass", $$v)
                          },
                          expression: "subPages.manageClass"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          ["plans", "assign-students", "messages"].includes(_vm.localPage) &&
          _vm.userMode == "A" &&
          _vm.localTeacherListDockPosition == "right"
            ? _c("v-divider", {
                staticStyle: { "z-index": "1" },
                attrs: { vertical: "" }
              })
            : _vm._e(),
          ["plans", "assign-students", "messages"].includes(_vm.localPage) &&
          _vm.userMode == "A" &&
          _vm.localTeacherListDockPosition == "right"
            ? _c(
                "div",
                {
                  staticClass: "teacher-list-content",
                  style: {
                    width: _vm.teacherListContentWidth + "px",
                    overflow: "hidden"
                  }
                },
                [
                  _c("pb-teacher-list-admin-view", {
                    attrs: {
                      isTeacherListFullWidth: _vm.localIsTeacherListFullWidth,
                      dockPosition: _vm.localTeacherListDockPosition
                    },
                    on: {
                      "update:dockPosition": function($event) {
                        _vm.localTeacherListDockPosition = $event
                      },
                      "update:dock-position": function($event) {
                        _vm.localTeacherListDockPosition = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      ["plans", "search", "lesson-banks", "reporting"].includes(_vm.localPage)
        ? _c("lesson-actions", {
            ref: "lessonActions",
            attrs: { input: _vm.localInput.lessonActions }
          })
        : _vm._e(),
      ["plans", "events"].includes(_vm.localPage)
        ? _c("event-actions", {
            ref: "eventActions",
            attrs: { input: _vm.localInput.eventActions }
          })
        : _vm._e(),
      ["plans", "events"].includes(_vm.localPage)
        ? _c("google-event-actions", {
            ref: "googleEventActions",
            attrs: { input: _vm.localInput.googleEventActions }
          })
        : _vm._e(),
      _c("form", {
        staticClass: "d-none",
        staticStyle: { display: "none" },
        attrs: {
          id: "downloadForm",
          method: "post",
          action: "https://planbook.com/downloadAttachment"
        }
      }),
      _c("form", {
        staticClass: "d-none",
        staticStyle: { display: "none" },
        attrs: { id: "printForm", method: "post", action: "#" }
      }),
      _c("pb-confirm", { ref: "confirm" }),
      _vm.isPaymentNeeded || _vm.isSubscriptionExpiring
        ? [
            _c("subscription-renewal", { ref: "subscriptionRenewal" }),
            _c("school-subscription-renewal", {
              ref: "schoolSubscriptionRenewal"
            })
          ]
        : _vm._e(),
      _c("vue-snotify", { attrs: { role: "log" } }),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c("router-view", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isEmbedded,
                expression: "!isEmbedded"
              }
            ],
            staticClass: "bottom-toolbar",
            staticStyle: { position: "fixed", width: "100%", bottom: "0" },
            style: {
              "z-index": _vm.isListView || _vm.remindersDrawerMobile ? 300 : 1
            },
            attrs: { name: "bottomToolbar" }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }