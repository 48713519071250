
import UnitEditorForm from '@/components/units/UnitEditorForm.vue';
import UnitActions from '@/components/units/UnitActions.vue';
import { FormError } from '@/errors';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Editor } from '@/types/global.types';

const units = namespace('units');

@Component({
  components: {
    UnitEditorForm,
    UnitActions
  }
})
export default class UnitEditor extends Vue implements Editor {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return {};
    }
  })
  input!: any;

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  $refs!: {
    unitEditor: UnitEditorForm
  }

  @units.State
  updatedFields!: Set<string>;

  @units.State
  listLoading!: any;

  localFullScreen = false;

  isDirty() {
    return this.updatedFields && this.updatedFields.size > 0;
  }

  typeChange() {
    this.input.loadData = false
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get unit() {
    return this.$store.state.units.unit;
  }

  get unitId(): number {
    return this.unit.unitId || 0;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  doApply() {
    this.localPage === 'units' ? this.$store.commit('units/setListLoading', true) : CommonUtils.showLoading();
    return this.$refs.unitEditor.save().then(() => {
      this.localValue = false;
      if (!this.isModal) {
        this.$eventBus.$emit('refreshUnits');
      }
      if (this.localPage === 'lesson-banks') {
        this.$eventBus.$emit('refreshUnits');
      }
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        return Promise.reject(error);
      } else {
        return Promise.resolve(true);
      }
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$store.commit('units/setListLoading', false);
      return Promise.resolve(false);
    });
  }

  created() {
    this.$eventBus.$on('deleteUnit', (unitIds: any) => {
      for (const i in unitIds) {
        if (unitIds[i] === this.input.unitId) {
          this.$eventBus.$emit('closeSubPage');
        }
      }
    })
  }

  destroyed() {
    this.$eventBus.$off('deleteUnit');
  }
}
