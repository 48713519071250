var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-sub-page",
    {
      attrs: {
        input: _vm.input,
        expandable: false,
        preventClose: "",
        withActions: false,
        withApply: false
      },
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "px-5 py-2 mb-4",
          attrs: { "fill-height": "", align: "center" }
        },
        [
          _c(
            "v-row",
            { staticClass: "mb-8" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-8", attrs: { justify: "center" } },
                    [
                      _c("v-icon", { staticClass: "big-icon" }, [
                        _vm._v("fal fa-chalkboard-user")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { justify: "center" } },
                    [_c("span", [_vm._v(_vm._s(_vm.pageText))])]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }